import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { LineWave } from "react-loader-spinner";
import { current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../../utils/accessCheck";

const ScanReport = ({ handleCloseSideBar }) => {
  const [selectScanReport, setSelectScanReport] = useState(null);
  const [errorSelectScanReport, setErrorSelectScanReport] = useState(false);
  const [selectScanReportTime, setSelectScanReportTime] = useState(null);
  const [errorSelectScanReportTime, setErrorSelectScanReportTime] =
    useState(false);

  const userInfo = useSelector(getUserDetails);

  const [scanReportDate, setScanReportDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modulesToShow, setModulesToShoW] = useState([]);

  const scanReport = [
    { name: "Executive Summary Report", code: "executive" },
    { name: "Detailed Report", code: "detailed" },
  ];

  const MODULES = [
    {
      key: "attack_surface",
      title: "Attack Surface Management",
      type: ["full", "default_scan"],
      for: ["executive", "detailed"],
      submodules: [
        {
          key: "assets_discovery",
          title: "Assets Discovery",
        },
        {
          key: "vuln_intel",
          title: "Vulnerabilities Intelligence",
        },
        {
          key: "compliance",
          title: "Compliance",
        },
      ],
    },
    {
      key: "voc",
      title: "Vulnerability Operation Center",
      type: ["full", "default_scan"],
      for: ["detailed"],
    },
    {
      key: "digital_risk",
      title: "Digital Risk Protection",
      type: ["drp", "full"],
      for: ["executive", "detailed"],
      submodules: [
        {
          key: "data_breach_intel",
          title: "Data Breach Intelligence",
        },
        {
          key: "dark_web_mentions",
          title: "Dark Web Mentions",
        },
        {
          key: "secrets_leak",
          title: "Secrets Leak",
        },
        {
          key: "phishing_intel",
          title: "Phishing Intelligence",
        },
        {
          key: "brand_treat_intel",
          title: "Brand Threat Intelligence",
        },
      ],
    },
  ];

  const handleChangeReport = (e) => {
    setSelectScanReport(e.value);
    if (e.value.code === "executive") {
      setErrorSelectScanReport(false);
      setModulesToShoW(
        MODULES[0].submodules?.map((i) => {
          return i.key;
        })
      );
    } else if (e.value.code === "detailed") {
      setErrorSelectScanReport(false);
    }
  };

  const handleScanSubmit = () => {
    console.log("selectScanReportTime", selectScanReport);
    if (selectScanReportTime === null) {
      setErrorSelectScanReportTime(true);
    }
    if (selectScanReport === null) {
      setErrorSelectScanReport(true);
    }
    if (selectScanReportTime !== null && selectScanReport !== null) {
      setLoading(true);
      const id = selectScanReportTime.id;
      const target = selectScanReportTime.target;
      let _id = id;

      if (selectScanReport.code === "detailed") {
        _id = `${id}?detailed=true`;
      }

      DashboardApi.getScanReport(_id, { show: modulesToShow }).then(
        (response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          // Create a URL for the blob
          const url = URL.createObjectURL(blob);
          // Create a download link and trigger a click event to initiate the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `${target}_scan_report.pdf`; // Set the desired file name
          // document.body.appendChild(a);
          a.click();
          // Clean up by revoking the object URL
          URL.revokeObjectURL(url);
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    DashboardApi.getScanDateApi().then((response) => {
      if (response) {
        setScanReportDate(
          response.map((item) => {
            return {
              ...item,
              date: `${item.date} (${item.target}) - ${item?.scan_type}`,
            };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    const location = window.location.pathname;
    if (location == "/vulnerability-operation-center") {
      setModulesToShoW(["voc"]);
    }
    if (location == "/digital-risk-protection") {
      // setModulesToShoW(
      //   MODULES[2].submodules?.map((i) => {
      //     return i.key;
      //   })
      // );
    }
    if (location == "/attack-surface-management") {
      setModulesToShoW(
        MODULES[0].submodules?.map((i) => {
          return i.key;
        })
      );
    }
  }, []);

  const handleSubModule = (submodule) => {
    if (!modulesToShow?.includes(submodule)) {
      setModulesToShoW([...modulesToShow, submodule]);
    } else {
      setModulesToShoW(modulesToShow.filter((item) => item !== submodule));
    }
  };

  const handleModule = (module) => {
    let _mods =
      MODULES.find((item) => item.key === module)?.submodules?.map((i) => {
        return i.key;
      }) || null;
    if (_mods) {
      if (_mods.some((i) => modulesToShow.includes(i))) {
        setModulesToShoW((current) => {
          const copy = current;
          return copy.filter((i) => !_mods.includes(i));
        });
      } else {
        setModulesToShoW([...modulesToShow, ..._mods]);
      }
    } else {
      if (modulesToShow.includes(module)) {
        setModulesToShoW(modulesToShow.filter((item) => item !== module));
      } else {
        setModulesToShoW([...modulesToShow, module]);
      }
    }
  };

  return (
    <>
      <form className="py-5 flex flex-col w-full login-style">
        <div className="w-full mb-5">
          <label className="block text-gray-500 text-sm ">Scanning Date</label>
          <Dropdown
            value={selectScanReportTime}
            onChange={(e) => {
              setSelectScanReportTime(e.value);
              setErrorSelectScanReportTime(false);
            }}
            options={scanReportDate}
            optionLabel="date"
            placeholder="Scan Date"
            className=" w-full md:w-14rem h-[40px] my-1"
          />
          {errorSelectScanReportTime && (
            <p className="text-red-500 text-sm">Scan Date is required</p>
          )}
        </div>

        <div className="w-full mb-10">
          <label className="block text-gray-500 text-sm ">Report type</label>
          <Dropdown
            value={selectScanReport}
            onChange={(e) => handleChangeReport(e)}
            options={scanReport}
            optionLabel="name"
            placeholder="Scan Report Type"
            className=" w-full md:w-14rem h-[40px] my-1"
          />
          {errorSelectScanReport && (
            <p className="text-red-500 text-sm">Scan Report Type is required</p>
          )}
        </div>

        <div>
          <p className="font-bold text-[16px] text-[#111]">Module</p>
        </div>

        {MODULES.map((item) => {
          return (
            <>
              <div
                className="flex items-center gap-2 pt-2"
                onClick={() =>
                  item.for.includes(selectScanReport?.code) &&
                  item.type.includes(selectScanReportTime?.type)
                    ? handleModule(item.key)
                    : null
                }
              >
                <input
                  // id={item.key}
                  type="radio"
                  checked={
                    item.submodules
                      ? item.submodules.every((i) =>
                          modulesToShow.includes(i.key)
                        )
                      : modulesToShow.includes(item.key) || false
                  }
                  className="h-5 w-5 bg-red-500"
                />
                <label
                  className={`text-base font-normal ${
                    item.for.includes(selectScanReport?.code) &&
                    item.type.includes(selectScanReportTime?.type)
                      ? "text-color"
                      : "text-inactive"
                  }`}
                  htmlFor={item.key}
                >
                  {item.title}
                </label>
              </div>
              {item.submodules?.map((_item) => {
                return (
                  <div
                    key={_item.key}
                    className="flex items-center gap-2 py-2 ml-[28px]"
                    onClick={() =>
                      item.for.includes(selectScanReport?.code) &&
                      item.type.includes(selectScanReportTime?.type)
                        ? handleSubModule(_item.key)
                        : null
                    }
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      checked={modulesToShow.includes(_item.key) || false}
                    />
                    <label
                      className={`text-base font-normal ${
                        item.for.includes(selectScanReport?.code) &&
                        item.type.includes(selectScanReportTime?.type)
                          ? "text-color"
                          : "text-inactive"
                      }`}
                    >
                      {_item.title}
                    </label>
                  </div>
                );
              })}
            </>
          );
        })}
      </form>

      <div className="flex w-full pt-5">
        <button
          disabled={loading}
          onClick={handleScanSubmit}
          className={`p-1 border rounded-[16px] border-[#AAAAAA] ${
            loading ? "button-disabled" : "button-bg-color"
          } h-[40px] w-full bg-[#181818] text-[#ffff]`}
          type="submit"
        >
          {/* Download */}
          {loading ? (
            <div className="flex items-center justify-center">
              <div className="absolute bottom-1 right-[12rem]">
                <LineWave color="#fff" height={100} width={100} />
              </div>
              <span className="ml-14">Downloading...</span>
            </div>
          ) : (
            <>
              <span> Download</span>
            </>
          )}
        </button>
        {loading ? (
          ""
        ) : (
          <button
            onClick={handleCloseSideBar}
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2 "
            type="button"
          >
            Back
          </button>
        )}
      </div>
    </>
  );
};

export default ScanReport;
