import { useParams } from "react-router-dom";
import AddVaultFile from "../../modules/home/components/compliance/AddVaultFile";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";

export default function VaultFile() {
  const { id } = useParams();

  const columns = [
    { field: "name", header: "Name", type: "fileWithName" },
    // {
    //   field: "folder",
    //   header: "Folder",
    // },
    // {
    //   field: "uploaded_by",
    //   header: "Uploaded By",
    // },
    {
      field: "file_info.created_at",
      header: "Uploaded At",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Vault File"
      columns={columns}
      filter={{ folder: id }}
      url_path="/vault-file/"
      AddComponent={AddVaultFile}
    />
  );
}
