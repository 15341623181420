import TableFilterApiBase from "./base";

export const NmapFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/nmap/filter/"
);
export const HttpxFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/httpx/filter/"
);
export const SubdomainFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/subdomain/filter/"
);
export const KatanaFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/katana/filter/"
);
export const SSLFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/ssl/filter/"
);
export const TechnologiesFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/technology/filter/"
);
export const DNSFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/dns/filter/"
);
export const WhoisFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/whois/filter/"
);
export const ExploitFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/exploits/filter/"
);
export const ComplianceFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/asm/compliances/filter/"
);
export const VOCFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/dashboard/voc/intelligence/filter/"
);
export const UserFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/team/users/filter/"
);
export const AdminTeamSuperUserFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/team/users/admin/filter/super"
);
export const MTAdminUserFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/team/users/admin/filter/admin"
);
export const TargetFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/domains/target/filter/"
);
export const SubscriptionHistoryFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/subscription/history/filter/"
);
export const CloudFilterAPI = new TableFilterApiBase(
  "ultraheals/v1/web/cloud/cloudscan/filter/"
);
