import { useEffect, useRef, useState } from "react";
import axios from "axios";
import useDrivePicker from "react-google-drive-picker";
import { Sidebar } from "primereact/sidebar";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { GrDocument } from "react-icons/gr";
import { GoDownload } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { CiUser } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FaArrowRight, FaFolderPlus } from "react-icons/fa6";
import { VscTrash } from "react-icons/vsc";
import gdrive from "../../../../../assets/integraions/gdrive.svg";
import ultraheals from "../../../../../assets/integraions/ultraheals.svg";
import { DateTimeToDate } from "../../../../../helpers";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import fileUpload from "../../../../../utils/fileUpload";
import HandleResponseError from "../../../../../utils/error";
import PreviewDocs from "../PreviewDocs";
import IntegrateEvidence from "./IntegrateEvidence";

// Env
const GOOGLE_DRIVE_PICKER_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_DRIVE_PICKER_CLIENT_ID;
const GOOGLE_DEV_API_KEY = process.env.REACT_APP_GOOGLE_DEV_API_KEY;

export default function ViewEvidence({
  currentDetails,
  setCurrentDetails,
  onHide,
  columns,
}) {
  const fileInputRef = useRef(null);
  const commentRef = useRef(null);
  const urlsRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [service, setService] = useState(null);
  const [attachmentPopup, setAttachmentPopup] = useState(false);
  const [showGoogleDrive, setShowGoogleDrive] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [data, setData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [previewDoc, setPreviewDoc] = useState(null);

  const evidences = [
    "airtable",
    "appover",
    "asana",
    "clickup",
    "coda",
    "confluence",
    "freshdesk",
    "hubspot",
    "jira",
    "monday",
    "notion",
    "zendesk",
    "miro",
    "docusign",
    "azuredevops",
  ];

  const uploadAttachment = (f) => {
    const file = f.target ? f.target.files[0] : f;
    // file size <  30MB
    if (file.size > 30000000) {
      toast.error("File size should be less than 30MB");
      return;
    }
    if (file) {
      fileUpload("ultraheals/v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            complianceAPI
              .patch(`/evidence/${currentDetails.id}/`, {
                attachments: [...currentDetails.attachments, res.data.id],
              })
              .then((res) => {
                setCurrentDetails(res);
                // setCurrentDetails({
                //   ...currentDetails,
                //   attachments: [...currentDetails.attachments, res.data.id],
                //   attachments_info: [
                //     ...currentDetails.attachments_info,
                //     res.data,
                //   ],
                // });
                toast.success("Attachment uploaded successfully");
              })
              .catch((err) => {
                toast.error("Error in uploading attachment");
              });
          } else {
            HandleResponseError(res.message);
          }
        })
        .catch((err) => {
          toast.error("Error in uploading attachment");
        });
    }
  };

  const handleDownload = (file) => {
    axios
      .get(file.file, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers.contentType,
        });
        const url = URL.createObjectURL(blob);

        // Create a link and trigger a click event to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name; // Change file extension to .yml
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (id) => {
    const updatedAttachements = currentDetails.attachments_info.filter(
      (i) => i.id != id
    );
    complianceAPI
      .patch(`/evidence/${currentDetails.id}/`, {
        attachments: updatedAttachements.map((i) => i.id),
      })
      .then((res) => {
        // setCurrentDetails({
        //   ...currentDetails,
        //   attachments: updatedAttachements.map((i) => i.id),
        //   attachments_info: updatedAttachements,
        // });
        setCurrentDetails(res);
        toast.success("Attachment removed successfully");
      })
      .catch((err) => {
        toast.error("Error in uploading attachment");
      });
  };

  const uploadStatus = (status) => {
    complianceAPI
      .patch(`/evidence/${currentDetails.id}/`, {
        status: status,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Evidence updated successfully");
          onHide();
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in uploading attachment");
      });
  };

  const renderStatusButton = (status) => {
    let nextStatus = null;
    let buttonText = null;

    switch (status) {
      case "not_uploaded":
        nextStatus = "draft";
        buttonText = "Draft";
        break;
      case "draft":
        //   nextStatus = "uploaded";
        //   buttonText = "Upload";
        //   break;
        // case "uploaded":
        //   nextStatus = "need_attention";
        //   buttonText = "Need Attention";
        //   break;
        // case "need_attention":
        nextStatus = "published";
        buttonText = "Publish";
        break;
      case "published":
        nextStatus = null;
        buttonText = "Published";
      default:
        break;
    }

    return (
      <button
        className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff]"
        onClick={() => uploadStatus(nextStatus)}
        disabled={!nextStatus}
      >
        {buttonText}
      </button>
    );
  };

  const AddComment = () => {
    const comment = commentRef.current.value;
    if (comment) {
      complianceAPI
        .post("/comments/", {
          comment: comment,
        })
        .then((data) => {
          if (data.id) {
            complianceAPI
              .patch(`/evidence/${currentDetails.id}/`, {
                comments: [...currentDetails.comments, data.id],
              })
              .then(() => {
                setCurrentDetails({
                  ...currentDetails,
                  comments: [...currentDetails.comments, data.id],
                  comments_info: [...currentDetails.comments_info, data],
                });
                toast.success("Comment added successfully");
                commentRef.current.value = "";
              })
              .catch((err) => {
                console.log(err);

                toast.error("Error in adding comment");
              });
          } else {
            toast.error("Error in adding comment");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error in adding comment");
        });
    }
  };

  const AddURLs = (e) => {
    e.preventDefault();
    const url = e.target.url.value;

    if (currentDetails?.urls?.find((i) => url === i)) {
      toast.error("URL already exists");
      return;
    }

    complianceAPI
      .patch(`/evidence/${currentDetails.id}/`, {
        urls: currentDetails?.urls ? [...currentDetails?.urls, url] : [url],
      })
      .then((res) => {
        if (res.id) {
          toast.success("Evidence updated successfully");
          setCurrentDetails(res);
          e.target.url.value = "";
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in adding evidence");
      });
  };

  const handleURLsDelete = (i) => {
    const updatedURLs = currentDetails?.urls.filter((d) => d !== i);
    complianceAPI
      .patch(`/evidence/${currentDetails.id}/`, {
        urls: updatedURLs,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Evidence updated successfully");
          setCurrentDetails(res);
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in adding evidence");
      });
  };
  const patchCurrentDetails = (data) => {
    complianceAPI
      .patch(`/evidence/${currentDetails.id}/`, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId: GOOGLE_DRIVE_PICKER_CLIENT_ID,
      developerKey: GOOGLE_DEV_API_KEY,
      viewId: "DOCS",
      showUploadView: true,
      supportDrives: true,
      multiselect: true,
      token: accessToken,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }

        if (data.action === "picked" && data.docs.length !== 0) {
          console.log("Picked Data -> : ", data);
          setData(data);
        }
      },
    });
  };

  const downloadFile = () => {
    setAccessToken(authResponse.access_token);
    console.log(data?.docs[0].name);
    axios
      .get(
        `https://www.googleapis.com/drive/v3/files/${data?.docs[0].id}?alt=media`,
        {
          headers: {
            Authorization: "Bearer " + authResponse.access_token,
          },
        }
      )
      .then((res) => {
        const blob = new Blob([res], {
          name: data?.docs[0].name,
          type: res.headers.contentType,
        });
        uploadAttachment(blob);
      });
    setData(null);
  };

  useEffect(() => {
    if (authResponse && data) downloadFile();
  }, [data]);

  useEffect(() => {
    if (!currentDetails) return;
    for (let i of Object.keys(currentDetails)) {
      if (evidences.includes(i) && currentDetails[i]) {
        setService(i);
        setTableData(currentDetails[i]);
        break;
      }
    }
  }, [currentDetails]);

  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={onHide}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">{currentDetails?.name}</p>
      <div className="flex flex-col gap-3 px-6">
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">
              Evidence Requirement
            </h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.requirement}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Assigned To</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.assignee
                ? columns
                    ?.find((c) => c.field === "assignee")
                    ?.value.find((v) => v.value === currentDetails?.assignee)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Approver</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.approver
                ? columns
                    ?.find((c) => c.field === "approver")
                    ?.value.find((v) => v.value === currentDetails?.approver)
                    ?.label
                : null}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Entity</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.entities
                ? columns
                    ?.find((c) => c.field === "entities")
                    ?.value.find((v) => v.value === currentDetails?.entities)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Department</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.department
                ? columns
                    ?.find((c) => c.field === "department")
                    ?.value.find((v) => v.value === currentDetails?.department)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Recurrence</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.recurrence
                ? columns
                    ?.find((c) => c.field === "recurrence")
                    ?.value.find((v) => v.value === currentDetails?.recurrence)
                    ?.label
                : null}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-full flex justify-between">
            <h1 className="text-sm text-color font-bold">Attachments</h1>
            <div className="flex gap-1">
              <button
                className="text-[#074EE8] text-xs flex items-center gap-1"
                onClick={() => {
                  setAttachmentPopup(true);
                }}
              >
                <IoAddCircleOutline />
                Add Attachment
              </button>
              /
              <IntegrateEvidence id={currentDetails?.id} />
            </div>
          </div>
        </div>
        {/* Table */}
        <div
          className={`flex flex-col gap-4 ${
            tableData?.length > 0 ? "visible" : "invisible"
          }`}
        >
          <h1 className="text-sm text-color font-bold">Table</h1>
          <div className="relative overflow-x-auto rounded-md">
            <table className="w-full text-xs text-left rtl:text-right">
              <thead className="text-black bg-gray-100">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Assignee
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created On
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((v, i) => {
                  console.log(v);
                  return (
                    <tr className="border-b" key={i}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <a
                          href={v.web_url}
                          target="_blank"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          {v.id}
                        </a>
                      </th>
                      <td className="px-6 py-4">{v.name}</td>
                      <td className="px-6 py-4">
                        {v.assignee?.displayName ?? "No Assignee"}
                      </td>
                      <td className="px-6 py-4">{v.status ?? "No Status"}</td>
                      <td className="px-6 py-4">
                        {DateTimeToDate(v.createdat) ?? "No Date Specified"}
                      </td>
                      <td className="px-5 py-4">
                        <a
                          href="#"
                          className="font-medium text-red-600 hover:underline flex items-center justify-center"
                          onClick={() => {
                            let cd = {
                              ...currentDetails,
                              [service]: [
                                ...tableData.slice(0, i),
                                ...tableData.slice(i + 1, tableData.length),
                              ],
                            };
                            patchCurrentDetails(cd);
                            setCurrentDetails(cd);
                          }}
                        >
                          <VscTrash className="text-lg" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col pt-6 gap-2">
          {currentDetails?.attachments_info?.map((a, i) => (
            <div
              key={i}
              className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border"
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setPreviewDoc(a)}
              >
                <GrDocument />
                <div className="p-1 ml-2 flex flex-col">
                  <p className="text-sm text-color font-normal leading-5">
                    {a.name}
                  </p>
                  <p className="text-xs">{DateTimeToDate(a.created_at)}</p>
                </div>
              </div>
              <div className="justify-self-end flex divide-x gap-2">
                <div
                  className="text-sm flex items-center gap-1 cursor-pointer"
                  onClick={() => setPreviewDoc(a)}
                >
                  <FaEye />
                  <p>View</p>
                </div>
                <div
                  className="text-sm flex items-center gap-1 cursor-pointer"
                  onClick={() => handleDownload(a)}
                >
                  <GoDownload />
                  <p>Download</p>
                </div>
                <div
                  className="text-sm flex items-center gap-1 cursor-pointer"
                  onClick={() => handleDelete(a.id)}
                >
                  <MdDeleteOutline />
                  <p>Delete</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-between pt-6">
          <h1 className="text-sm text-color font-bold">URLs</h1>
          {currentDetails?.urls?.map((c, i) => (
            <div
              key={i}
              className="flex gap-2 my-1 items-center justify-between p-2 border rounded"
            >
              <a
                href={c}
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
              >
                {c}
              </a>
              <div
                className="text-sm flex items-center gap-1 cursor-pointer"
                onClick={() => handleURLsDelete(c)}
              >
                <MdDeleteOutline />
                <p>Delete</p>
              </div>
            </div>
          ))}
          <form onSubmit={(e) => AddURLs(e)} className="flex my-1">
            <input
              id="url"
              type="url"
              required
              rows={2}
              className="w-full border-b-[2px] rounded p-2"
              placeholder="Add a url to docs..."
            />
            <button
              className="mx-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff] self-end"
              type="submit"
            >
              Add
            </button>
          </form>
        </div>
        <div className="flex justify-end pt-6">
          {renderStatusButton(currentDetails?.status)}
        </div>
        <div className="flex flex-col justify-between pt-6">
          <h1 className="text-sm text-color font-bold">Comments</h1>
          {currentDetails?.comments_info?.map((c, i) => (
            <div
              key={i}
              className="flex gap-2 my-2 items-center p-2 border rounded"
            >
              <div className="flex w-10 h-10 rounded-full items-center justify-center">
                <CiUser />
              </div>
              <div className="flex flex-col">
                <p className="text-xs text-color font-normal">{c.comment}</p>
                {/* <p className="text-sm text-color font-bold">{c.user}</p> */}
              </div>
            </div>
          ))}
          <textarea
            ref={commentRef}
            rows={2}
            className="w-full border-b-[2px] rounded p-2"
            placeholder="Add a comment"
          ></textarea>
          <button
            className="mt-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff] self-end"
            onClick={AddComment}
          >
            Add Comment
          </button>
        </div>
      </div>

      {/* Dialog for Attachments */}
      <Dialog
        header="Add Attachments"
        visible={attachmentPopup}
        onHide={() => setAttachmentPopup(false)}
        modal
        className="w-[50vw] h-[60vh]"
      >
        <div className="w-full h-full flex">
          {/* Navbar */}
          <div className="p-1 flex flex-col gap-1 border-r border-slate-300 pr-3">
            <button
              className={`flex items-center gap-2 rounded-md p-2 hover:bg-slate-200 outline-none ${
                !showGoogleDrive ? "bg-slate-200" : ""
              }`}
              onClick={() => {
                setShowGoogleDrive(false);
              }}
            >
              <FaFolderPlus className="w-5" color="#252525" />
              <span>From Device</span>
            </button>
            <button
              className={`flex items-center gap-2 rounded-md p-2 hover:bg-slate-200 outline-none ${
                showGoogleDrive ? "bg-slate-200" : ""
              }`}
              onClick={() => {
                setShowGoogleDrive(true);
              }}
            >
              <img src={gdrive} alt="" className="w-4" />
              <span>Google Drive</span>
            </button>
          </div>
          {/* Content */}
          {showGoogleDrive ? (
            <div className="flex flex-1 flex-col items-center gap-6">
              <h1 className="font-bold p-3">Upload from Google Drive</h1>
              <div className="flex flex-col items-center justify-evenly grow">
                <div className="flex items-center gap-7">
                  <img src={gdrive} alt="" className="w-12" />
                  <p className="text-lg">
                    <FaArrowRight />
                  </p>
                  <img src={ultraheals} alt="" className="w-12" />
                </div>
                <h1 className="text-center text-md">
                  Connect your Google Drive account to <br />
                  UltraHeals to fetch evidences
                </h1>
                <Button
                  label={accessToken ? "Add a File" : "Connect"}
                  onClick={() => {
                    handleOpenPicker();
                  }}
                  className="p-1 border rounded-[8px] border-[#AAAAAA] h-[30px] bg-[#181818] text-[#ffff]"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center gap-6">
              <h1 className="font-bold p-3">Upload from your Device</h1>
              <div className="flex flex-col items-center">
                <Button
                  label="Upload"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                  className="p-1 border rounded-[8px] border-[#AAAAAA] h-[30px] bg-[#181818] text-[#ffff]"
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  hidden
                  onChange={uploadAttachment}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
      {previewDoc && <PreviewDocs data={previewDoc} setData={setPreviewDoc} />}
    </Sidebar>
  );
}
