import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = () => {
  const token = localStorage.getItem("token") === null ? false : true;

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: window.location.href }} />
  );
};
