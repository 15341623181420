import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddVendor, {
  category,
  risk_impact,
} from "../../modules/home/components/compliance/vendor/AddVendor";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { complianceAPI } from "../../Api/CSPM/api";
import { recurrence } from "../../modules/home/components/compliance/evidence/AddEvidence";

export default function Vendor() {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [entities, setEntities] = useState([]);

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Assigned",
          value: "assigned",
        },
        {
          label: "In Progress",
          value: "in_progress",
        },
        {
          label: "Not Assigned",
          value: "not_assigned",
        },
      ],
    },
    {
      field: "entities",
      header: "Entities",
      type: "select",
      value: entities.map((e) => ({ label: e.name, value: e.id })),
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "category",
      header: "Category",
      type: "select",
      value: category,
    },
    {
      field: "risk_score",
      header: "Risk Score",
    },
    {
      field: "inherent_risk",
      header: "Inherent Risk",
      type: "select",
      value: risk_impact,
    },
    {
      field: "residual_risk",
      header: "Residual Risk",
      type: "select",
      value: risk_impact,
    },
    {
      field: "last_assigned",
      header: "Last Assigned",
    },
    {
      field: "recurrence",
      header: "Recurrence",
      type: "select",
      value: recurrence,
    },
    {
      field: "next_assessment",
      header: "Next Assessment",
    },
    {
      field: "vendor_tier",
      header: "Vendor Tier",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getEntities();
  }, []);

  return (
    <ComplianceTable
      title="Vendor Management"
      columns={columns}
      url_path="/vendor/"
      AddComponent={AddVendor}
      onRowClick={(data) => {
        navigate(`./${data.id}`, { state: data });
      }}
      canImport
    />
  );
}
