import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { useParams } from "react-router-dom";
import HandleResponseError from "../../../../../utils/error";
import { toast } from "react-toastify";

export default function AddTreatment({
  editTreatment,
  setEditTreatment,
  data,
  setData,
}) {
  const { id } = useParams();
  const [treatment, setTreatment] = useState(
    editTreatment !== true ? editTreatment : null
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    complianceAPI
      .patch(`/risk/${id}/`, { ...data, treatment_strategy: treatment })
      .then((res) => {
        if (!res.id) {
          HandleResponseError(res.message);
          e.target.submit.removeAttribute("disabled");
        } else {
          toast.success("Treatment Strategy updated successfully");
          setData(res);
          setEditTreatment(null);
          e.target.submit.removeAttribute("disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        e.target.submit.removeAttribute("disabled");
      });
  };

  return (
    <Sidebar
      visible={editTreatment}
      position="right"
      onHide={() => setEditTreatment(null)}
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">
            Treatment Strategy
          </label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Enter Treatment Strategy"
            value={treatment}
            required
            onChange={(e) => {
              setTreatment(e.target.value);
            }}
          />
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              Save
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={() => {
                setEditTreatment(null);
              }}
              type="reset"
            >
              Back
            </button>
          </div>
        </div>
      </form>
    </Sidebar>
  );
}
