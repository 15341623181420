import { usePdf } from "@mikecousins/react-pdf";
import { useRef, useState } from "react";

export default function PdfViewer({ viewPDF, setViewPDF }) {
  const canvasRef = useRef(null);
  const [page, setPage] = useState(1);

  const { pdfDocument, pdfPage } = usePdf({
    file: viewPDF,
    page,
    canvasRef,
  });

  console.log(pdfDocument);
  return (
    <div className="flex flex-col gap-3 w-full justify-center">
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages > 1) && (
        <nav>
          <ul className="pager flex justify-between">
            {page > 1 ? (
              <li className="previous">
                <button
                  className="p-button-text flex p-1 gap- items-center rounded-md border border-gray-300"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </button>
              </li>
            ) : (
              <li></li>
            )}
            {page < pdfDocument.numPages && (
              <li className="next">
                <button
                  className="p-button-text flex p-1 gap- items-center rounded-md border border-gray-300"
                  disabled={page === pdfDocument.numPages}
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </button>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
