const LockedWidget = (props) => {
  const { lockImage, lockicon } = props;
  return (
    <div className="flex items-center relative h-full">
      <img src={lockImage} alt="complaince" />
      <div className="absolute text-center w-full h-full top-[50%]">
        <img src={lockicon} alt="complaince-assets" className="m-auto" />
        <button
          className="p-1 border rounded-[16px] border-[#111111] bg-[#181818] text-[#ffffff] h-[40px] w-[150px] top-[10px]"
          style={{ marginTop: "10px" }}
          type="button"
        >
          Click to upgrade
        </button>
      </div>
    </div>
  );
};

export default LockedWidget;
