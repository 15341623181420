import React, { useState } from "react";
import SSLActive from "./SSLActive";
import SSLExpired from "./SSLExpired";
import SSLExpiredSoon from "./SSLExpiredSoon";

const SSLStatus = (props) => {
  const { title } = props;

  const [countData, setCountData] = useState();
  return (
    <div className="ssl-status ">
      <h1 className="font-bold ">{title}</h1>

      <div className="ssl-active">
        <SSLActive title="SSL - active" />
      </div>
      <div className="ssl-active">
        <SSLExpiredSoon title="SSL - expire soon" />
      </div>
      <div className="ssl-active">
        <SSLExpired title="SSL - expired" />
      </div>
    </div>
  );
};

export default SSLStatus;
