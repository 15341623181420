import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import fileUpload from "../../../../../utils/fileUpload";
import { Dropdown } from "primereact/dropdown";

export default function AddCourse({ showCourse, setShowCourse, getData }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  // const [visible, setVisible] = useState(v);

  const handleAPI = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (showCourse?.id) {
      complianceAPI
        .patch(`/campaign/${showCourse.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Campaign updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/campaign/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Campaign created successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");

    const file = e.target.file.files[0];

    if (file) {
      fileUpload("ultraheals/v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            data.file = res.data.id;
            handleAPI(e);
          } else {
            toast.error(res.message);
            e.target.submit.removeAttribute("disabled");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
          e.target.submit.removeAttribute("disabled");
          return;
        });
    } else {
      handleAPI(e);
    }
  };

  // useEffect(() => {
  //   if (currentDetails) setData(currentDetails);
  // }, [currentDetails]);

  const cleanClose = () => {
    setShowCourse(false);
    setData({});
    setError({});
    getData();
  };

  useEffect(() => {
    if (showCourse?.id) setData(showCourse);
  }, [showCourse]);

  return (
    <Sidebar
      visible={showCourse}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        showCourse?.id ? "Edit" : "Create New"
      } Course`}</p>
      <p className="text-sm py-1">{`${
        showCourse?.id ? "Edit" : "Create a new"
      } course`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form
          onSubmit={(e) => handleCreate(e)}
          className="w-full flex flex-col gap-4"
        >
          {/* Image */}
          <div className="w-full flex flex-col gap-2">
            <label className="block text-gray-500 text-sm">Image</label>
            {/* <input type="file" accept="image/*" /> */}
            <input
              className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
              name="file"
              type="file"
            />
          </div>
          {/* Name */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter Course name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          {/* Description */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.desc}
              required
              onChange={(e) => {
                setData({ ...data, desc: e.target.value });
                setError({ ...error, desc: false });
              }}
            />
            {error.desc && <p className="text-red-500 text-sm">{error.desc}</p>}
          </div>
          {/* Dropdown */}
          <div className="w-full flex justify-between items-center">
            <label for="opts">Course state:</label>
            {/* <div className="py-2 pl-2 rounded-md border border-slate-200 outline-none"> */}
            {/* <select
                name="opts"
                id="opts"
                className="border-none outline-none"
              >
                <option value="Active" className="p-1">
                  Active
                </option>
                <option value="Draft" className="p-1">
                  Draft
                </option>
              </select> */}
            {/* <label className="block text-gray-500 text-sm">Entities</label> */}
            <Dropdown
              value={data.active}
              onChange={(e) => setData({ ...data, active: e.value })}
              options={[
                {
                  label: "Active",
                  value: true,
                },
                {
                  label: "Draft",
                  value: false,
                },
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-40 my-1"
            />
            {/* </div>   */}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {showCourse?.id ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
