import React from "react";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const AppCard = (props) => {
  const { title, fakeApps, isDarkMode, data, tooltip } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full h-full py-5 px-2">
        <div className="px-3 relative">
          <div className="flex justify-center">
            <h1 className="text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>
          <div className="absolute top-0 right-0 flex justify-end items-end w-[18px] cursor-pointer">
            <img
              className="w-[18px]"
              data-tooltip-id={`${title}-tooltip`}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>

        {fakeApps ? (
          <div
            className="flex justify-center items-center cursor-pointer h-full"
            onClick={() => navigate("brand-threat-intelligence#FakeApps")}
          >
            <h1 className="text-3xl font-bold">{data | 0}</h1>
          </div>
        ) : (
          <div className="flex justify-center items-center flex-1 flex-wrap gap-2 h-full">
            <div
              className="h-[4.8rem] w-[4.8rem] rounded-full border-[6px] border-[#E8001D] cursor-pointer"
              onClick={() => navigate(`brand-threat-intelligence#${title}`)}
            >
              <div className="p-2 flex flex-col justify-center items-center">
                <div className="bg-[#F9BFC6] rounded-full p-1">
                  <FiThumbsDown size={20} color="#E8001D" />
                </div>
                <span className=" text-xl font-bold">{data?.negative | 0}</span>
              </div>
            </div>
            <div
              className="h-[4.8rem] w-[4.8rem] rounded-full border-[6px] border-[#FDCA3B] cursor-pointer"
              onClick={() => navigate(`brand-threat-intelligence#${title}`)}
            >
              <div className="p-2 flex flex-col justify-center items-center">
                <div className="bg-[#FEECBA] rounded-full p-1 rotate-[270deg]">
                  <FiThumbsUp size={20} color="#FDCA3B" />
                </div>
                <span className="text-xl font-bold">{data?.neutral | 0}</span>
              </div>
            </div>
            <div
              className="h-[4.8rem] w-[4.8rem] rounded-full border-[6px] border-[#148842] cursor-pointer"
              onClick={() => navigate(`brand-threat-intelligence#${title}`)}
            >
              <div className="p-2 flex flex-col justify-center items-center">
                <div className="bg-[#C4E1CF] rounded-full p-1">
                  <FiThumbsUp size={20} color="#148842" />
                </div>
                <span className="text-xl font-bold">{data?.positive | 0}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactTooltip
        id={`${title}-tooltip`}
        place="top"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default AppCard;
