import React,{useState} from 'react'
import DNSMonitor from './DNSMonitor';
import Whois from './Whois';

const DNSRecord = (props) => {
    const { title } = props;

  return (
    <div className="ssl-status ">
    <h1 className="font-bold">
      {title}
    </h1>

    <div className="ssl-active">
        <DNSMonitor title='DNS Monitor'/>
    </div>
    
    <div className="ssl-active">
       <Whois title='Whois'/>
    </div>

  </div>
  )
}

export default DNSRecord