import React from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import { NewPasswordForm } from "../../modules/forgotPassword/components";
import AuthSideBar from "../../Component/Header/AuthSideBar";
import NewUserPasswordForm from "../../modules/forgotPassword/components/NewUserPasswordForm";

const NewUserPassword = () => {
  return (
    <div className="lg:h-screen">
      <LoginHeader title="" subTitle="" navigate="" />
      <div className="flex justify-evenly w-full">
    <div className="header-dark hidden md:block w-[60%]">
      <AuthSideBar />
    </div>
    <div className="md:w-[40%] sm:w-full">
    <NewUserPasswordForm />
    </div>
  </div>
      <hr />
      <div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default NewUserPassword;
