import React, { useRef, useState } from "react";
import DashboardCloudTables from "../../../../../common/Tables/DashboardCloudTables";
import DashboardExploitsTable from "../../../../../common/Tables/DashboardExploitsTable";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const ExploitabilityTable = (props) => {
  const {
    cloudAwsTableData,
    loadingTable,
    exploitabilityFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "cve", header: "CVE", value: exploitabilityFilterData?.cve },
    {
      field: "host",
      header: "Affected Hosts",
      value: exploitabilityFilterData?.host,
    },
    {
      field: "first_report",
      header: "First Report of Exploitation",
      value: exploitabilityFilterData?.first_report,
    },
    {
      field: "last_report",
      header: "Last Report of Exploitation",
      value: exploitabilityFilterData?.last_report,
    },
    {
      field: "link",
      header: "Exploit Link",
      value: exploitabilityFilterData?.link,
    },
    { field: "tags", header: "Tags", value: [] },
    // { field: "action", header: "Actions" },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={cloudAwsTableData}
          tableHeader={columns}
          dt={dt}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DashboardExploitsTable
            userInformation={cloudAwsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
            showReportDate={true}
          />
        )}
      </div>
    </>
  );
};

export default ExploitabilityTable;
