import React from "react";
import { useEffect, useState } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useSelector } from "react-redux";
import { getScanViewData } from "../../../redux-toolkit/redux/Sidebar";

const TargetInitiateScan = () => {
  const getScanDetails = useSelector(getScanViewData);

  const [targetScan, setTargetScan] = useState(getScanDetails);

  useEffect(() => {
    setTargetScan(getScanDetails);
  }, [targetScan]);
  return (
    <>
      <div className="mt-8 ">
        <div className="pb-2">
          <h1 className="text-base font-bold">Scan Engine</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.scan_type}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Cloud)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.cloud}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Darkweb)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.darkweb}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Secrets Leak)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.github}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Social)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.social}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Fakeapps)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.fakeapp}</p>
        <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Impersonation)</h1>
        </div>
        <p className="text-base font-normal pb-4">
          {targetScan.impersonations}
        </p>
        {/* <div className="pb-2">
          <h1 className="text-base font-bold">Keywords (Databreach)</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.databreach}</p> */}
        <div className="pb-2">
          <h1 className="text-base font-bold">Status</h1>
        </div>
        <p className="text-base font-normal pb-4">{targetScan.status}</p>
        {targetScan.scan_type === "Full scan" ||
        targetScan.scan_type === "Default scan" ? (
          <>
            <div className="pb-2">
              <h1 className="text-base font-bold">Subdomains</h1>
            </div>
            <div>
              <ul className="px-8 list-disc">
                {targetScan.subdomains.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default TargetInitiateScan;
