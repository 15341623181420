import React, { useCallback, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setScanDateTime } from "../../../../redux-toolkit/redux/Sidebar";
import TableApi from "../../../../Api/AuthenticationApi/TableApi";
import { data } from "autoprefixer";
import { DateTimeToDate } from "../../../../helpers";

const ScheduleScanForm = (props) => {
  const { handleCloseSideBar, setActiveScanForm } = props;
  const dispatch = useDispatch();

  const [openFirstScanningDate, setOpenFirstScanningDate] = useState(false);
  const [openSecondScanningDate, setOpenSecondScanningDate] = useState(false);
  const [firstScanningDate, setFirstScanningDate] = useState(null);
  const [secondScanningDate, setSecondScanningDate] = useState(null);
  const [firstScanningTime, setFirstScanningTime] = useState(null);
  const [secondScanningTime, setSecondScanningTime] = useState(null);
  const [toggleTime, setToggleTime] = useState("AM");
  const [scanEndDate, setScanEndDate] = useState();

  const firstScanningDateRef = useRef(null);
  const secondScanningDateRef = useRef(null);

  const currentDate = new Date();
  // const currentDate = "Fri Nov 06 2023 15:39:08 GMT+0530"


  const formatDate = (date) => {
    if (!date) return null;
    return date
      .toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, ".");
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        openFirstScanningDate &&
        firstScanningDateRef.current &&
        !firstScanningDateRef.current.contains(event.target)
      ) {
        setOpenFirstScanningDate(false);
      }

      if (
        openSecondScanningDate &&
        secondScanningDateRef.current &&
        !secondScanningDateRef.current.contains(event.target)
      ) {
        setOpenSecondScanningDate(false);
      }
    },
    [
      openFirstScanningDate,
      setOpenFirstScanningDate,
      openSecondScanningDate,
      setOpenSecondScanningDate,
      firstScanningDateRef,
      secondScanningDateRef,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const scanDateTime = {
      firstScanningDate: formatDate(firstScanningDate),
      secondScanningDate: formatDate(secondScanningDate),
      firstScanningTime: firstScanningTime,
      secondScanningTime: secondScanningTime,
    };
    dispatch(setScanDateTime(scanDateTime));
    setActiveScanForm((prev) => prev + 1);
  };

  const handleChange = (e, type) => {
    console.log("ScanTime", e.target.value, type, e);
    if (type === "firstDate") {
      const date = formatDate(e.value);
      setFirstScanningDate(e.target.value);
    }
    if (type === "firstTime") {
      const time = e.target.value;
      setFirstScanningTime(e.target.value);
    }
    if (type === "secondDate") {
      setSecondScanningDate(e.target.value);
    }
    if (type === "secondTime") {
      setSecondScanningTime(e.target.value);
    }
  };

  const getScheduleScan = () => {
    TableApi.getScheduleScanWeb().then((scanData) => {
      console.log("getScheduleScan", scanData);
      if (scanData.status === "success") {
        const maxDate = new Date(
          Number(scanData.end_year),
          Number(scanData.end_month) - 1,
          Number(scanData.end_date)
        );
        setScanEndDate(maxDate);
      }
    });
  };

  useEffect(() => {
    getScheduleScan();
  }, []);

  const formateDateTime = (date, time) => {
    if (!date) return null;
    return `${DateTimeToDate(date)} ${time || ''}`
  }

  console.log(firstScanningDate, firstScanningTime)
  return (
    <div>
      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-1 mx-auto">
          <label className="text-sm font-normal text-[#686868]">
            First Scanning Date
          </label>
          <div className="relative">
            <input
              value={formateDateTime(firstScanningDate, firstScanningTime)}
              placeholder="__.__.____"
              className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
            />
            <div
              className="absolute cursor-pointer top-[12px] right-[12px]"
              onClick={() => setOpenFirstScanningDate(!openFirstScanningDate)}
            >
              <AiOutlineCalendar />
            </div>
          </div>
          {/* <div ref={firstScanningDateRef}> */}
          {openFirstScanningDate && (
            <div className="mx-auto">
              <div className="">
                <Calendar
                  value={firstScanningDate}
                  onChange={(e) => handleChange(e, "firstDate")}
                  inline
                  style={{ width: "360px" }}
                  minDate={currentDate}
                  maxDate={scanEndDate}
                />
              </div>
              <div className="flex items-center mx-auto justify-between p-4">
                <h1 className="text-base text-color font-bold">Time</h1>
                <div className="flex items-center gap-[6px]">
                  <div className="bg-[#7676801F] px-4 py-[10px] rounded-md">
                    <input
                      type="time"
                      className="bg-transparent focus:outline-none"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      value={firstScanningTime}
                      onChange={(e) => handleChange(e, "firstTime")}
                      // onChange={(e) => setFirstScanningTime(e.value)}
                    />
                  </div>
                  <div className="bg-[#7676801F] p-[2px] rounded-md">
                    <button
                      type="button"
                      className={`text-base p-2 ${
                        toggleTime === "AM" ? "bg-[#FFFFFF] rounded-md" : ""
                      }`}
                      onClick={() => setToggleTime("AM")}
                    >
                      AM
                    </button>
                    <button
                      type="button"
                      className={`text-base p-2 ${
                        toggleTime === "PM" ? "bg-[#FFFFFF] rounded-md" : ""
                      }`}
                      onClick={() => setToggleTime("PM")}
                    >
                      PM
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
        <div className="flex flex-col gap-1 mx-auto mt-[10px]">
          <label className="text-sm font-normal text-[#686868]">
            Second Scanning Date
          </label>
          <div className="relative">
            <input
              placeholder="__.__.____"
              value={formateDateTime(secondScanningDate, secondScanningTime)}
              className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
            />
            <div
              className="absolute cursor-pointer top-[12px] right-[12px]"
              onClick={() => setOpenSecondScanningDate(!openSecondScanningDate)}
            >
              <AiOutlineCalendar />
            </div>
          </div>
          {/* <div>
                    <button 
                    type="button"
                    className='flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl'
                    onClick={() => setOpenSecondScanningDate(!openSecondScanningDate)}
                    >
                        <span>{formatDate(secondScanningDate)}</span>
                        <AiOutlineCalendar />
                    </button>
                </div> */}
          {/* <div ref={secondScanningDateRef}> */}
          {openSecondScanningDate && (
            <div className="mx-auto">
              <div className="">
                <Calendar
                  value={secondScanningDate}
                  onChange={(e) => handleChange(e, "secondDate")}
                  // onChange={(e) => setSecondScanningDate(e.value)}
                  inline
                  style={{ width: "360px" }}
                  minDate={firstScanningDate}
                  maxDate={scanEndDate}
                />
              </div>
              <div className="flex items-center mx-auto justify-between p-4">
                <h1 className="text-base text-color font-bold">Time</h1>
                <div className="flex items-center gap-[6px]">
                  <div className="bg-[#7676801F] px-4 py-[10px] rounded-md">
                    <input
                      type="time"
                      className="bg-transparent focus:outline-none"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      value={secondScanningTime}
                      onChange={(e) => handleChange(e, "secondTime")}
                      // onChange={(e) => setSecondScanningTime(e.target)}
                    />
                  </div>
                  <div className="bg-[#7676801F] p-[2px] rounded-md">
                    <button
                      type="button"
                      className={`text-base p-2 ${
                        toggleTime === "AM" ? "bg-[#FFFFFF] rounded-md" : ""
                      }`}
                      onClick={() => setToggleTime("AM")}
                    >
                      AM
                    </button>
                    <button
                      type="button"
                      className={`text-base p-2 ${
                        toggleTime === "PM" ? "bg-[#FFFFFF] rounded-md" : ""
                      }`}
                      onClick={() => setToggleTime("PM")}
                    >
                      PM
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
        <div className=" flex w-full pt-5">
        <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
          >
            Continue
          </button>
          <button
            className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
            type="button"
            onClick={handleCloseSideBar}
          >
            Back
          </button>
          
        </div>
      </form>
    </div>
  );
};

export default ScheduleScanForm;
