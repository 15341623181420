import { useContext, useEffect, useState } from "react";
import { complianceAPI } from "../../../Api/CSPM/api";
import { ThemeContext } from "../../../context/ThemeContext";
import AddLinkedPolicy from "../../../modules/home/components/compliance/people/AddLinkPolicy";

export default function LinkPolicy() {
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState([]);
  // const [policies, setPolicies] = useState([]);
  const [departments, setDepartments] = useState([]);
  // const [visible, setVisible] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);

  const getDepartments = () => {
    complianceAPI
      .post("/departments/filter/")
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    complianceAPI
      .get("/link-policy/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartments();
    getData();
  }, []);

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table justify-between">
          <p className="font-bold">Linked Policy</p>
          {/* <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff]"
            onClick={() => setVisible(true)}
          >
            Link Policies
          </button> */}
        </div>
        <div
          className={`w-full border-2 border-[#DEDEDE] p-4 ${
            isDarkMode ? "tab-dark-border" : "border-color"
          }`}
        >
          {departments.map((dept) => {
            return (
              <div
                key={dept.id}
                className="flex gap-2 items-center"
                onClick={() => {
                  setCurrentDetails({
                    department: dept.id,
                    policies:
                      data?.find((i) => i.department === dept.id)?.policies ||
                      [],
                  });
                }}
              >
                <input
                  type="checkbox"
                  checked={data.some((i) => i.department === dept.id)}
                />
                <p className="text-m cursor-pointer">{dept.name}</p>
              </div>
            );
          })}
        </div>
      </div>
      <AddLinkedPolicy
        departments={departments}
        currentDetails={currentDetails}
        setCurrentDetails={setCurrentDetails}
        onHide={getData}
      />
    </>
  );
}
