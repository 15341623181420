import { toast } from "react-toastify";

export default function HandleResponseError(error) {
  console.log(error);
  if (typeof error === "string") {
    toast.error(error);
  } else if (typeof error === "object") {
    Object.keys(error).forEach((key) => {
      toast.error(`${key}: ${error[key].join(", ")}`);
    });
  } else {
    toast.error("Something went wrong");
  }
}
