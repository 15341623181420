import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../Api/CSPM/api";
import HandleResponseError from "../../../../utils/error";

export const DOMAINS = [
  {
    label: "Security And Privacy Governance",
    value: "security_and_privacy_governance",
  },
  { label: "Asset Management", value: "asset_management" },
  {
    label: "Business Continuity And Disaster Recovery",
    value: "business_continuity_and_disaster_recovery",
  },
  {
    label: "Capacity And Performance Planning",
    value: "capacity_and_performance_planning",
  },
  { label: "Change Management", value: "change_management" },
  { label: "Cloud Security", value: "cloud_security" },
  { label: "Compliance", value: "compliance" },
  { label: "Configuration Management", value: "configuration_management" },
  { label: "Continuous Monitoring", value: "continuous_monitoring" },
  { label: "Cryptographic Protections", value: "cryptographic_protections" },
  {
    label: "Data Classification And Handling",
    value: "data_classification_and_handling",
  },
  { label: "Embedded Technology", value: "embedded_technology" },
  { label: "Endpoint Security", value: "endpoint_security" },
  { label: "Human Resources Security", value: "human_resources_security" },
  {
    label: "Identification And Authentication",
    value: "identification_and_authentication",
  },
  { label: "Incident Response", value: "incident_response" },
  { label: "Information Assurance", value: "information_assurance" },
  { label: "Maintenance", value: "maintenance" },
  { label: "Mobile Device Management", value: "mobile_device_management" },
  { label: "Network Se Management", value: "network_se_management" },
  { label: "Network Security", value: "network_security" },
  {
    label: "Physical And Environmental Security",
    value: "physical_and_environmental_security",
  },
  { label: "Privacy", value: "privacy" },
  {
    label: "Project And Resource Management",
    value: "project_and_resource_management",
  },
  { label: "Risk Management", value: "risk_management" },
  {
    label: "Secure Engineering And Architecture",
    value: "secure_engineering_and_architecture",
  },
  { label: "Security Operations", value: "security_operations" },
  {
    label: "Security Awareness And Training",
    value: "security_awareness_and_training",
  },
  {
    label: "Technology Development And Acquisition",
    value: "technology_development_and_acquisition",
  },
  { label: "Third-Party Management", value: "third_party_management" },
  { label: "Threat Management", value: "threat_management" },
  {
    label: "Vulnerability And Patch Management",
    value: "vulnerability_and_patch_management",
  },
  { label: "Web Security", value: "web_security" },
  { label: "Customer Management", value: "customer_management" },
  { label: "Fintech", value: "fintech" },
  { label: "Fraud Management", value: "fraud_management" },
  {
    label: "Artificial and Autonomous Technologies",
    value: "artificial_and_autonomous_technologies",
  },
  { label: "Payment System", value: "payment_system" },
  {
    label: "Cyber Security Incident Management",
    value: "cyber_security_incident_management",
  },
  { label: "Vulnerability Management", value: "vulnerability_management" },
  {
    label: "Cybersecurity and Privacy Governance",
    value: "cybersecurity_and_privacy_governance",
  },
];

export const GROUP = [
  {
    label: "Identify",
    value: "identify",
  },
  {
    label: "Protect",
    value: "protect",
  },
  {
    label: "Detect",
    value: "detect",
  },
  {
    label: "Recover",
    value: "recover",
  },
  {
    label: "Respond",
    value: "respond",
  },
];

export const SCOPE = [
  {
    label: "Out of Scope",
    value: true,
  },
  {
    label: "In Scope",
    value: false,
  },
];

export default function AddControls({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [employee, setEmployee] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [evidence, setEvidence] = useState([]);
  const [frameworks, setFrameworks] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/controls/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Control updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/controls/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Control added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getPolicies = () => {
    complianceAPI
      .post("/policy/filter/")
      .then(({ data }) => {
        setPolicies(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEvidence = () => {
    complianceAPI
      .post("/evidence/filter/")
      .then(({ data }) => {
        setEvidence(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then(({ data }) => {
        setFrameworks(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getPolicies();
    getEvidence();
    getFrameworks();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Controls`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      }  Controls`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <p className="font-bold text-xl">Controls Details</p>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm ">Name</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Control name"
                value={data.name}
                required
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  setError({ ...error, name: false });
                }}
              />
              {error.name && (
                <p className="text-red-500 text-sm">{error.name}</p>
              )}
            </div>
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm">Code</label>
              <input
                className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Control Code"
                value={data.code}
                required
                onChange={(e) => {
                  setData({ ...data, code: e.target.value });
                  setError({ ...error, code: false });
                }}
              />
              {error.code && (
                <p className="text-red-500 text-sm">{error.code}</p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-3">
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Assignee</label>
              <Dropdown
                value={data.assignee}
                onChange={(e) => setData({ ...data, assignee: e.value })}
                options={employee}
                filter
                optionLabel="email"
                optionValue="id"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Scope</label>
              <Dropdown
                value={data.out_of_scope}
                onChange={(e) => setData({ ...data, out_of_scope: e.value })}
                options={SCOPE}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
          </div>
          <div className="flex w-full gap-3">
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Domain</label>
              <Dropdown
                value={data.domain}
                onChange={(e) => setData({ ...data, domain: e.value })}
                options={DOMAINS}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Group</label>
              <Dropdown
                value={data.group}
                onChange={(e) => setData({ ...data, group: e.value })}
                options={GROUP}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm ">Weightage</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                placeholder="Weightage"
                value={data.weightage}
                required
                min={1}
                max={10}
                onChange={(e) => {
                  setData({ ...data, weightage: e.target.value });
                  setError({ ...error, weightage: false });
                }}
              />
              {error.weightage && (
                <p className="text-red-500 text-sm">{error.weightage}</p>
              )}
            </div>
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Framework</label>
            <Dropdown
              value={data.framework}
              onChange={(e) => setData({ ...data, framework: e.value })}
              filter
              options={frameworks}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="description"
              value={data.description}
              // required
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Question</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="question"
              value={data.question}
              // required
              onChange={(e) => {
                setData({ ...data, question: e.target.value });
                setError({ ...error, question: false });
              }}
            />
            {error.question && (
              <p className="text-red-500 text-sm">{error.question}</p>
            )}
          </div>
          <p className="font-bold text-xl">Control Artifacts</p>
          <div className="flex w-full gap-3">
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Policies</label>
              <MultiSelect
                className="w-full"
                optionLabel="name"
                optionValue="id"
                filter
                value={data.policies}
                options={policies}
                placeholder="Select Policies"
                onChange={(e) => setData({ ...data, policies: e.value })}
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Evidence</label>
              <MultiSelect
                className="w-full"
                optionLabel="name"
                optionValue="id"
                filter
                value={data.evidence}
                options={evidence}
                placeholder="Select Evidence"
                onChange={(e) => setData({ ...data, evidence: e.value })}
              />
            </div>
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
