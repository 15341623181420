import React from "react";
import DonutChart from "../../../common/Charts/DonutChart";
import VerticalBarChart from "../../../common/Charts/VerticalBarChart";

const LiveHostsCharts = (props) => {
  const {
    title,
    isDarkMode,
    httpStatusData,
    subdomainStatusData,
    endpointStatusData,
    subDomainStatus,
    subDomainLegends,
    subDomainCount,
    endPointCount,
    endPointLegends,
    endPointStatus,
    subDomainLiveCount,
    endPointLiveCount,
  } = props;
  //   const httpStatusData = [16, 2, 4, 3, 8, 5];
  const httpLabels = ["200", "400", "502", "301", "404", "403"];
  console.log(
    "response",
    // subdomainStatusData,
    // endpointStatusData,
    // subDomainLegends,
    subDomainCount,
    // subDomainStatus,
    subDomainLiveCount
  );

  return (
    <div>
      <h1 className="font-bold text-base">{title}</h1>
      <div className="py-6">
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 flex flex-col gap-6">
          {/* <div
            className={`lg:col-span-1 h-screen ${
              isDarkMode ? "header-dark" : "bg-white"
            }  h-fit w-full rounded-2xl`}
          > */}
          <div
            className={`lg:col-span-1 w-full rounded-2xl bg-white ${
              isDarkMode ? "header-dark" : "bg-white"
            }`}
          >
            <VerticalBarChart
              title="HTTP status"
              data={httpStatusData}
              labels={httpLabels}
              lockedWidget={true}
              backgroundColors={[
                "#33B2DF",
                "#546E7A",
                "#D4526E",
                "#13D8AA",
                "#A5978B",
                "#33B2DF",
              ]}
            />
          </div>

          {/* </div> */}
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 flex flex-col gap-6">
            <div
              className={`lg:col-span-1 ${
                isDarkMode ? "header-dark" : "bg-white"
              }   w-full rounded-2xl`}
            >
              <div className="px-3 flex flex-col h-full">
                <DonutChart
                  title="Subdomain Live Status"
                  type="normal"
                  labels={subDomainStatus}
                  colors={["#E8001D", "#FDCA3B"]}
                  chartData={subdomainStatusData}
                  conditionType="Subdomains"
                  screen="liveHosts"
                  total={subDomainLiveCount}
                  live={subDomainCount}
                  legends={subDomainLegends}
                />
              </div>
            </div>
            <div
              className={`lg:col-span-1 ${
                isDarkMode ? "header-dark" : "bg-white"
              }  w-full rounded-2xl`}
            >
              <div className="px-3 flex flex-col h-full">
                <DonutChart
                  title="Endpoints Live Status"
                  type="normal"
                  labels={endPointStatus}
                  colors={["#E8001D", "#FDCA3B"]}
                  chartData={endpointStatusData}
                  conditionType="Endpoints"
                  screen="liveHosts"
                  total={endPointLiveCount}
                  live={endPointCount}
                  legends={endPointLegends}
                />
                {/* <DonutChart
                title="Endpoints Live Status"
                type="normal"
                labels={["Total Endpoints - 400", "Live Endpoints - 344"]}
                colors={["#E8001D", "#FDCA3B"]}
                chartData={endpointStatusData}
                conditionType="Endpoints"
                // live={endpointStatusData.live_endpoints}
                total={endpointStatusData.total_endpoints}
                legends={[
                    { name: "Total Endpoints", color: "#E8001D" },
                    { name: "Live Endpoints", color: "#FDCA3B" },
                  ]}
              /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveHostsCharts;
