import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { toast } from "react-toastify";

import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../helpers";
import { complianceAPI } from "../../../../Api/CSPM/api";
import HandleResponseError from "../../../../utils/error";

export const category = [
  {
    label: "Finance",
    value: "finance",
  },
  {
    label: "Saas Software",
    value: "saas_software",
  },
  {
    label: "human resource",
    value: "human_resource",
  },
  {
    label: "Marketing & Sales",
    value: "marketing_sales",
  },
  {
    label: "Infrastructure",
    value: "infrastructure",
  },
  {
    label: "Security",
    value: "security",
  },
  {
    label: "Other",
    value: "other",
  },
];
export const criticality_rating = [
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "High",
    value: "high",
  },
];
export default function AddCorrectiveAction({
  visible,
  onHide,
  currentDetails,
}) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [openDueDate, setOpenDueDate] = useState(false);
  const [openDetectionDate, setOpenDetectionDate] = useState(false);

  const [employee, setEmployee] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/corrective-action/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Corrective Action updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/corrective-action/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Corrective Action created successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Corrective Action`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Corrective Action`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">
              Non-conformity Name
            </label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Non-conformity Name"
              value={data.non_conformity_name}
              required
              onChange={(e) => {
                setData({ ...data, non_conformity_name: e.target.value });
                setError({ ...error, non_conformity_name: false });
              }}
            />
            {error.non_conformity_name && (
              <p className="text-red-500 text-sm">
                {error.non_conformity_name}
              </p>
            )}
          </div>

          <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Non-Conformity Description
            </label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Non-Conformity Description"
              value={data.non_conformity_description}
              required
              onChange={(e) => {
                setData({
                  ...data,
                  non_conformity_description: e.target.value,
                });
                setError({ ...error, non_conformity_description: false });
              }}
            />
            {error.non_conformity_description && (
              <p className="text-red-500 text-sm">
                {error.non_conformity_description}
              </p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Weakness Identification
            </label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Weakness Identification"
              value={data.weakness_identified}
              required
              onChange={(e) => {
                setData({ ...data, weakness_identified: e.target.value });
                setError({ ...error, weakness_identified: false });
              }}
            />
            {error.weakness_identified && (
              <p className="text-red-500 text-sm">
                {error.weakness_identified}
              </p>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="text-sm font-normal text-[#686868]">
              Detection Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.detection_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenDetectionDate(!openDetectionDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openDetectionDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.detection_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        detection_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenDetectionDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    // minDate={currentDate}
                    // maxDate={scanEndDate}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              filter
              value={data.assignee}
              onChange={(e) => setData({ ...data, assignee: e.value })}
              options={employee}
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="text-sm font-normal text-[#686868]">
              Due Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.due_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenDueDate(!openDueDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openDueDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.due_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        due_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenDueDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    // minDate={currentDate}
                    // maxDate={scanEndDate}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">
              Criticality Rating
            </label>
            <Dropdown
              value={data.criticality}
              onChange={(e) => setData({ ...data, criticality: e.value })}
              options={criticality_rating}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Audit Note</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Audit Note"
              value={data.audit_note}
              required
              onChange={(e) => {
                setData({ ...data, audit_note: e.target.value });
                setError({ ...error, audit_note: false });
              }}
            />
            {error.audit_note && (
              <p className="text-red-500 text-sm">{error.audit_note}</p>
            )}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
