import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../context/ThemeContext";
import LiveHosts from "../../home/components/tables/AttackSurface/LiveHosts";
import LiveHostsCharts from "../charts/LiveHostsCharts";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { HttpxFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { useLocation } from "react-router-dom";
import { getFilter } from "../../../utils/utils";

const LiveHostsTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;
  const location = useLocation();

  const country = location.state?.country;

  const [liveHostTableData, setLiveHostTableData] = useState([]);
  const [liveHostFilterData, setLiveHostFitlerData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });
  const [httpStatusData, setHttpStatusData] = useState([]);
  const [subdomainStatusData, setSubdomainStatusData] = useState([]);
  const [endpointStatusData, setEndpointStatusData] = useState([]);
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [subDomainStatus, setSubDomainStatus] = useState([]);
  const [subDomainLegends, setSubDomainLegends] = useState([]);
  const [subDomainCount, setSubDomainCount] = useState();
  const [subDomainLiveCount, setSubDomainLiveCount] = useState();
  const [endPointStatus, setEndPointStatus] = useState([]);
  const [endPointLegends, setEndPointLegends] = useState([]);
  const [endPointCount, setEndPointCount] = useState();
  const [endPointLiveCount, setEndPointLiveCount] = useState();

  const { title } = props;

  const getLiveHostTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardLiveHostTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setLiveHostTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    HttpxFilterAPI.post({
      ...getFilter(liveHostFilterData, filterParams),
      scanID: scanID,
    })
      .then((res) => {
        setLiveHostTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    HttpxFilterAPI.get({ scanID: scanID })
      .then((res) => {
        setLiveHostFitlerData(res.data);
        // setFilterParams({ ...filterParams, ...res.data, country });
      })
      .catch((err) => console.log(err));
  };

  const getHttpStatus = (id) => {
    DashboardApi.dashboardHttpStatus(id).then((data) => {
      let httpValues = Object.values(data.data);
      setHttpStatusData(httpValues);
    });
  };

  const getSubdomainStatus = (id) => {
    DashboardApi.dashboardSubdomainStatus(id).then((data) => {
      console.log("DDFDSF123", data);
      let subdomainValues = Object.values(data.data);
      if (data.status === "success") {
        setSubdomainStatusData(subdomainValues);
        const vocOpenStatus = `Total Subdomains - ${data.data.total_subdomains}`;
        const vocCloseStatus = `Live Subdomains - ${data.data.live_subdomains}`;
        const vocDataStatus = [vocOpenStatus, vocCloseStatus];
        const vocLegendStatus = [
          { name: vocOpenStatus, color: "#E8001D" },
          { name: vocCloseStatus, color: "#FDCA3B" },
        ];
        setSubDomainCount(data.data.total_subdomains);
        setSubDomainLiveCount(data.data.live_subdomains);
        setSubDomainLegends([...vocLegendStatus]);
        setSubDomainStatus([...vocDataStatus]);
      }
    });
  };

  const getEndpointStatus = (id) => {
    DashboardApi.dashboardEndpointStatus(id).then((data) => {
      console.log("DDFDSF", data);

      let endpointValues = Object.values(data.data);
      if (data.status === "success") {
        setEndpointStatusData(endpointValues);
        const vocOpenStatus = `Total Endpoints - ${data.data.total_endpoints}`;
        const vocCloseStatus = `Live Endpoints - ${data.data.live_endpoints}`;
        const vocDataStatus = [vocOpenStatus, vocCloseStatus];
        const vocLegendStatus = [
          { name: vocOpenStatus, color: "#E8001D" },
          { name: vocCloseStatus, color: "#FDCA3B" },
        ];
        setEndPointCount(data.data.total_endpoints);
        setEndPointLiveCount(data.data.live_endpoints);
        setEndPointLegends([...vocLegendStatus]);
        setEndPointStatus([...vocDataStatus]);
      }
    });
  };

  useEffect(() => {
    const id = userDetails.scan_id;
    // getLiveHostTable(id);
    getTableFilterData();
    getHttpStatus(id);
    getSubdomainStatus(id);
    getEndpointStatus(id);
  }, []);

  useEffect(() => {
    if (liveHostFilterData) getLiveHostTable();
  }, [filterParams, liveHostFilterData]);

  return (
    <>
      <div>
        <LiveHostsCharts
          title={title}
          isDarkMode={isDarkMode}
          httpStatusData={httpStatusData}
          subdomainStatusData={subdomainStatusData}
          endpointStatusData={endpointStatusData}
          subDomainStatus={subDomainStatus}
          subDomainLegends={subDomainLegends}
          subDomainLiveCount={subDomainLiveCount}
          endPointLiveCount={endPointLiveCount}
          subDomainCount={subDomainCount}
          endPointStatus={endPointStatus}
          endPointLegends={endPointLegends}
          endPointCount={endPointCount}
        />
      </div>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <LiveHosts
          liveHostTableData={liveHostTableData}
          liveHostFilterData={liveHostFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          isDarkMode={isDarkMode}
          loadingTable={loadingTable}
        />
      </div>
    </>
  );
};

export default LiveHostsTable;
