export const REGEX = {
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&`~^])[A-Za-z\d@.#$!%`~*?&]{12,16}$/,
  OTP: /^\d{6}$/,
  MOBILE_NUMBER: /^\d{10}$/,
  EMAIL_ID:
    /^[a-z0-9]+([-._][a-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/,
  NAME: /^([a-zA-Z])|([a-zA-Z].[a-zA-Z])|([a-zA-Z].)/,
  SPECIAL_CHARACTERS: /[^a-zA-Z0-9]/,
  ONLY_ALPHABET: /^[a-z]+$/i,
};

export const maskedEmailAt = (email) => {
  if (
    email &&
    REGEX.EMAIL_ID.test(email.toLowerCase()) &&
    !email.includes("*")
  ) {
    let index = email.lastIndexOf("@");
    let indexdoma = email.lastIndexOf(".");
    let prefix = email.substring(0, index);
    let prefixDomai = email.substring(index + 1, indexdoma);
    let prefixLength = prefix.length;
    let prefixLengthDo = prefixDomai.length;
    let postfix = email.substring(indexdoma);
    let mask = prefix
      .split("")
      .map(function (o, i) {
        if (i < 1 || i > prefixLength - 1) {
          return o;
        } else {
          return "*";
        }
      })
      .join("");

    let maskEm = prefixDomai
      .split("")
      .map((o, i) => {
        if (i < 1 || i > prefixLengthDo - 1) {
          return o;
        } else {
          return "*";
        }
      })
      .join("");
    return mask + "@" + maskEm + postfix;
  }
  return email;
};

export const getLoggedCheck = () => {
  const userData = JSON.parse(window.localStorage.getItem("userDetails"));
  if (userData?.access != undefined) {
    return true;
  } else {
    return false;
  }
};

export const neutralizeBack = (window) => {
  window.history.pushState(null, document.title, window.location.href);
  window.onpopstate = () => {
    // window.history.pushState(null, document.title, window.location.href);
    window.history.go(1);
  };
};

export const removeAuthenticationData = () => {
  window.localStorage.removeItem("isLoggedIn");
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("userEmail");
  window.localStorage.removeItem("userDetails");
  window.localStorage.removeItem("selectedSubDomain");
  window.localStorage.removeItem("selectedSubDomains");
  window.localStorage.removeItem("targetID");
  window.localStorage.removeItem("scanId");
  window.location.href = "/login";
};

export const navigateErrorPage = () => {
  window.location.href = "/404";
};

export const GetSubString = (inputString, length) => {
  return inputString && typeof inputString === "string"
    ? inputString.substring(0, length || 20)
    : "";
};
