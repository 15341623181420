import { useEffect, useState } from "react";

import AddCorrectiveAction, {
  criticality_rating,
} from "../../modules/home/components/compliance/AddCorrectiveAction";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { complianceAPI } from "../../Api/CSPM/api";

export default function CorrectiveAction() {
  const [employee, setEmployee] = useState([]);

  const columns = [
    { field: "non_conformity_name", header: "Non-conformity Name" },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Open",
          value: "open",
        },
        {
          label: "Closed",
          value: "closed",
        },
      ],
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "due_date",
      header: "Due Date",
      type: "date",
    },
    {
      field: "criticality",
      header: "Criticality Rating",
      type: "select",
      value: criticality_rating,
    },

    {
      field: "detection_date",
      header: "Detection Date",
      type: "date",
    },
    {
      field: "source_name",
      header: "Source Name",
    },
    {
      field: "source_type",
      header: "Source type",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <ComplianceTable
      title="Corrective Action Center"
      columns={columns}
      url_path="/corrective-action/"
      AddComponent={AddCorrectiveAction}
    />
  );
}
