import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import { BiGlobe } from "react-icons/bi";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";

import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { SearchIcon } from "../../../../assets/svg";
import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../helpers";
import { ptaasAPI } from "../../../../Api/PTaaS/api";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../../../utils/utils";

export const TEST_TYPE = [
  {
    label: "Black Box",
    value: "black_box",
  },
  {
    label: "Grey Box",
    value: "grey_box",
  },
];

export const SERVICES = [
  {
    label: "Web Application",
    value: "web_application",
  },
  {
    label: "Server",
    value: "server",
  },
  {
    label: "Cloud",
    value: "cloud",
  },
  {
    label: "API",
    value: "api",
  },
  {
    label: "Infrastructure",
    value: "infrastructure",
  },
  {
    label: "Kubernetes",
    value: "kubernetes",
  },
  {
    label: "Mobile Application",
    value: "mobile_application",
  },
  {
    label: "IOT",
    value: "iot",
  },
];

export default function PtaasConfig({ setActiveScanForm, handleCloseSideBar }) {
  const targetId = localStorage.getItem("targetID");

  const [data, setData] = useState({
    target: targetId,
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [subdomains, setSubdomains] = useState([]);
  const [selectedSubdomain, setSelectedSubdomain] = useState([]);
  const [showAddSubdomain, setShowAddSubdomain] = useState(false);
  const [searchSubdomain, setSearchSubdomain] = useState(null);
  const [addSubdomain, setAddSubdomain] = useState(null);
  const [openScheduleDate, setOpenScheduleDate] = useState(false);
  const [openDeliveryDate, setOpenDeliveryDate] = useState(false);

  const currentDate = new Date();

  const handleBack = () => {
    if (showAddSubdomain) {
      setShowAddSubdomain(false);
    } else if (currentStep === 1) {
      setActiveScanForm(0);
      return;
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    DashboardApi.getSubDomainApi(targetId).then((res) => {
      setSubdomains(res.data);
    });
  }, [targetId]);

  const handleSubmit = (e) => {
    e.target.submit.setAttribute("disabled", "disabled");
    if (showAddSubdomain) {
      if (addSubdomain.split(".").length < 3) {
        toast.error("Please enter a valid subdomain");
        e.target.submit.removeAttribute("disabled");
        return;
      }
      DashboardApi.createSubDomainApi({
        id: targetId,
        subdomain: addSubdomain,
        host: addSubdomain.split(".").slice(-2).join("."),
      })
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setSearchSubdomain(null);
            setAddSubdomain(null);
            setShowAddSubdomain(false);
            setSubdomains([...subdomains, addSubdomain]);
            setSelectedSubdomain([...selectedSubdomain, addSubdomain]);
          } else {
            toast.error(data.message);
          }
          e.target.submit.removeAttribute("disabled");
        })
        .catch((err) => {
          console.log(err);
          e.target.submit.removeAttribute("disabled");
        });
      console.log("Add Subdomain", data, addSubdomain);
    } else if (currentStep === 3) {
      handleSubmitButtonPress(e);
      ptaasAPI
        .post("/init-scan/", {
          ...data,
          subdomains: selectedSubdomain,
        })
        .then((res) => {
          console.log(res);
          if (res.status === "error") {
            toast.error(res.message);
          } else {
            toast.success("Rule of Engagement request sent successfully");
            handleCloseSideBar();
          }
        })
        .catch((err) => {
          console.log(err);
          // handleCloseSideBar();
          toast.error("Something went wrong");
        })
        .finally(() => {
          handleSubmitButtonRelease(e);
        });
    } else {
      setCurrentStep(currentStep + 1);
      e.target.submit.removeAttribute("disabled");
    }
  };

  const returnStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">
                Type of Testing
              </label>
              <Dropdown
                value={data?.type}
                onChange={(e) => setData({ ...data, type: e.value })}
                options={TEST_TYPE}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                required
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Services</label>
              <MultiSelect
                className="w-full"
                optionLabel="label"
                optionValue="value"
                required
                value={data?.services}
                options={SERVICES}
                placeholder="Select services"
                onChange={(e) => setData({ ...data, services: e.value })}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="flex justify-between items-center">
              <h1 className="text-base font-bold">
                Subdomain ({selectedSubdomain.length}/{subdomains.length})
              </h1>
              {!showAddSubdomain ? (
                <button
                  type="button"
                  className="pl-2 font-normal text-[#074EE8] text-base"
                  onClick={() => setShowAddSubdomain(true)}
                >
                  Add Subdomain
                </button>
              ) : (
                <button
                  type="button"
                  className="pl-2 font-normal text-[#074EE8] text-base"
                  onClick={() => setShowAddSubdomain(false)}
                >
                  Close
                </button>
              )}
            </div>
            {showAddSubdomain ? (
              <form className="mt-4">
                <div className="w-full relative">
                  <div
                    className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE] focus-within:ring-[0.5px] ring-[#717171]`}
                  >
                    <BiGlobe
                      size={20}
                      color="#dedede"
                      className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="app.cyberheals.com"
                      value={addSubdomain}
                      onChange={(e) => setAddSubdomain(e.target.value)}
                      className="w-full ml-4 focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color"
                    />
                  </div>
                </div>
              </form>
            ) : (
              <form className="mt-4">
                <div className="w-full relative">
                  <div
                    className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE] focus-within:ring-[0.5px] ring-[#717171]`}
                  >
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchSubdomain}
                      onChange={(e) => setSearchSubdomain(e.target.value)}
                      className="w-full ml-4 focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color"
                    />
                  </div>
                </div>
              </form>
            )}
            {!showAddSubdomain && (
              <div className="grid grid-cols-1 gap-2" id="itemForm">
                <button
                  className="text-[#074EE8] text-base text-right"
                  onClick={() => {
                    if (selectedSubdomain.length === subdomains.length) {
                      setSelectedSubdomain([]);
                    } else {
                      setSelectedSubdomain(subdomains);
                    }
                  }}
                >
                  Select all
                </button>
                {subdomains
                  .filter((i) => {
                    if (searchSubdomain === null) {
                      return i;
                    } else if (
                      i.toLowerCase().includes(searchSubdomain.toLowerCase())
                    ) {
                      return i;
                    }
                  })
                  .map((checkboxName, index) => (
                    <div key={index} className="flex items-center gap-2 pt-2">
                      <input
                        id={"subDomain" + index}
                        type="checkbox"
                        className="h-4 w-4 custom-checkbox"
                        // value={checkboxName}
                        checked={
                          selectedSubdomain.includes(checkboxName)
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedSubdomain([
                              ...selectedSubdomain,
                              checkboxName,
                            ]);
                          } else {
                            setSelectedSubdomain(
                              selectedSubdomain.filter(
                                (item) => item !== checkboxName
                              )
                            );
                          }
                        }}
                      />
                      <label
                        className={`text-base font-normal text-color`}
                        for={"subDomain" + index}
                        style={{ cursor: "pointer" }}
                      >
                        {checkboxName}
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </>
        );

      case 3:
        return (
          <>
            <div className="w-full">
              <label className="text-sm font-normal text-[#686868]">
                Scheduled Date
              </label>
              <div className="relative">
                <input
                  value={DateTimeToDate(data?.scheduled_date)}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenScheduleDate(!openScheduleDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openScheduleDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(data?.scheduled_date)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          scheduled_date: DateSelectionToDjangoFormat(e.value),
                        });
                        setOpenScheduleDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      minDate={currentDate}
                      maxDate={new Date(data?.delivery_date)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="text-sm font-normal text-[#686868]">
                Delivery Date
              </label>
              <div className="relative">
                <input
                  value={DateTimeToDate(data?.delivery_date)}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenDeliveryDate(!openDeliveryDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openDeliveryDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(data?.delivery_date)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          delivery_date: DateSelectionToDjangoFormat(e.value),
                        });
                        setOpenDeliveryDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      minDate={new Date(data?.scheduled_date)}
                      // maxDate={}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm">Details</label>
              <textarea
                row={10}
                className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
                placeholder="Enter details"
                value={data.details}
                onChange={(e) => {
                  setData({ ...data, details: e.target.value });
                }}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm">Note</label>
              <textarea
                row={10}
                className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
                placeholder="Enter note"
                value={data.note}
                onChange={(e) => {
                  setData({ ...data, note: e.target.value });
                }}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          {returnStep()}
          <div className="flex w-full pt-5">
            <button
              type="submit"
              name="submit"
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            >
              {showAddSubdomain ? "Add Subdomain" : "Continue"}
            </button>
            {!showAddSubdomain && (
              <button
                onClick={handleBack}
                className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
                type="button"
              >
                Back
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
