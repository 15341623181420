import { useContext, useEffect, useState } from "react";

import { ThemeContext } from "../../../context/ThemeContext";
import {
  AWSWhite,
  aws,
  Azure,
  google,
  teams,
  slack,
  github,
  githubWhite,
  gitlab,
  jira,
  pabbly,
  makecom,
  zohowhite,
  zohodark,
  entra,
  zendesk,
  asana,
  monday,
  googleworkspace,
  clickup,
  coda,
  airtable,
  bitbucket,
  notion,
  azuredevops,
  freshdesk,
  zapier,
  hubspot,
  confluence,
  semgrep,
  miro,
  // docusign,
  attio,
  // contentful,
  hive,
  linear,
  // klaviyo,
  pagerduty,
  pivotaltracker,
  wrike,
  shortcut,
  breathe,
  // tines,
  pipedrive,
  recruitee,
  envoy,
  front,
  render,
  scalerway,
  terraform,
  rollbar,
  oneflow,
  vercel,
  xero,
  zohobook,
  sentry,
  articulate,
  qlik,
} from "../../../assets/svg";
// import confluence from "../../../assets/svg/confluence-svgrepo-com.svg"
import integrationsAPI from "../../../Api/integerations/api";
import { useNavigate } from "react-router";
import CloudIntegration from "./Integrations/CloudIntegrations";
import WebHooks from "./Integrations/WebHooks";
import Tokens from "./Integrations/Tokens";
import Gitlab from "./Integrations/Gitlab";
import Github from "./Integrations/Github";
import APIKey from "./Integrations/APIKey";
import APIKeySubdomain from "./Integrations/apikeysubdomain";
import APIKeyCompany from "./Integrations/apikeyCompany";

const GITLAB_APP_ID = process.env.REACT_APP_GITLAB_APP_ID;
const ZOHO_CLIENT_ID = process.env.REACT_APP_ZOHO_CLIENT_ID;
const JIRA_CLIENT_ID = process.env.REACT_APP_JIRA_CLIENT_ID;
const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
const GITHUB_APP_URL = process.env.REACT_APP_GITHUB_APP_URL;
const ZENDESK_CLIENT_ID = process.env.REACT_APP_ZENDESK_CLIENT_ID;
const ASANA_CLIENT_ID = process.env.REACT_APP_ASANA_CLIENT_ID;
// const MONDAY_CLIENT_ID = process.env.REACT_APP_MONDAY_CLIENT_ID;
const CLICKUP_CLIENT_ID = process.env.REACT_APP_CLICKUP_CLIENT_ID;
const BITBUCKET_CLIENT_ID = process.env.REACT_APP_BITBUCKET_CLIENT_ID;
const GOOGLEWORKSPACE_CLIENT_ID =
  process.env.REACT_APP_GOOGLEWORKSPACE_CLIENT_ID;
const NOTION_CLIENT_ID = process.env.REACT_APP_NOTION_CLIENT_ID;
const AZUREDEVOPS_CLIENT_ID = process.env.REACT_APP_AZUREDEVOPS_CLIENT_ID;
const HUBSPOT_CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
const CONFLUENCE_CLIENT_ID = process.env.REACT_APP_CONFLUENCE_CLIENT_ID;
const MIRO_CLIENT_ID = process.env.REACT_APP_MIRO_CLIENT_ID;
// const DOCUSIGN_CLIENT_ID = process.env.REACT_APP_DOCUSIGN_CLIENT_ID;
const XERO_CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
const ZOHOBOOK_CLIENT_ID = process.env.REACT_APP_ZOHOBOOK_CLIENT_ID;
const host = window.location.origin;

export default function Integration() {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentConfig, setCurrentConfig] = useState(null);

  const getData = () => {
    integrationsAPI
      .get("/counts/")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const Card = ({ logo, title, connections, onClick }) => {
    return (
      <div
        className={
          `rounded-2xl p-3 grid grid-cols-3	${
            isDarkMode ? "header-dark" : "bg-white"
          }` + " cursor-pointer"
        }
        onClick={onClick}
      >
        <div className="col-span-1  flex justify-center items-center">
          <img src={logo} alt={title} height={50} width={50} />
        </div>
        <div className="col-span-2">
          <p className="text-lg font-bold">{title}</p>
          <p className="text-sm">
            <span className="text-lg font-bold">{connections || 0}</span>{" "}
            Connection{connections > 1 && "s"}
          </p>
        </div>
      </div>
    );
  };

  switch (currentConfig) {
    case "aws":
      return (
        <CloudIntegration type="aws" setCurrentConfig={setCurrentConfig} />
      );
    case "azure":
      return (
        <CloudIntegration type="azure" setCurrentConfig={setCurrentConfig} />
      );
    case "gcp":
      return (
        <CloudIntegration type="gcp" setCurrentConfig={setCurrentConfig} />
      );
    case "teams":
      return <WebHooks type="teams" setCurrentConfig={setCurrentConfig} />;
    case "slack":
      return <WebHooks type="slack" setCurrentConfig={setCurrentConfig} />;
    case "pabbly":
      return <WebHooks type="pabbly" setCurrentConfig={setCurrentConfig} />;
    case "make":
      return <WebHooks type="make" setCurrentConfig={setCurrentConfig} />;
    case "zapier":
      return <WebHooks type="zapier" setCurrentConfig={setCurrentConfig} />;
    case "github":
      return (
        <Tokens
          type="github"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={GITHUB_APP_URL}
          ViewComponent={Github}
        />
      );
    case "entra":
      return (
        <Tokens
          type="entra"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${AZURE_CLIENT_ID}&response_type=code&redirect_uri=${host}/integrations/redirect/entra&response_mode=query&scope=https://graph.microsoft.com/.default`}
        />
      );
    case "gitlab":
      return (
        <Tokens
          type="gitlab"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://gitlab.com/oauth/authorize?client_id=${GITLAB_APP_ID}&redirect_uri=${window.location.origin}/integrations/redirect/gitlab&response_type=code&state=STATE&scope=read_api read_user read_repository read_registry read_observability`}
          ViewComponent={Gitlab}
        />
      );
    case "zoho":
      return (
        <Tokens
          type="zoho"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://accounts.zoho.com/oauth/v2/auth?scope=ZOHOPEOPLE.forms.READ,ZOHOPROFILE.userinfo.READ&client_id=${ZOHO_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${window.location.origin}/integrations/redirect/zoho&prompt=consent`}
        />
      );
    case "jira":
      return (
        <Tokens
          type="jira"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${JIRA_CLIENT_ID}&scope=read:jira-work read:me offline_access&redirect_uri=${window.location.origin}/integrations/redirect/jira&response_type=code&prompt=consent`}
        />
      );
    case "miro":
      return (
        <Tokens
          type="miro"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://miro.com/oauth/authorize?response_type=code&client_id=${MIRO_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/miro`}
        />
      );
    case "zendesk":
      return (
        <Tokens
          type="zendesk"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://cyberhealssupport.zendesk.com/oauth/authorizations/new?response_type=code&client_id=${ZENDESK_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/zendesk&scope=read tickets:read`}
        />
      );
    case "asana":
      return (
        <Tokens
          type="asana"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${ASANA_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/asana&scope=default`}
        />
      );
    case "googleworkspace":
      return (
        <Tokens
          type="googleworkspace"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLEWORKSPACE_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/googleworkspace&response_type=code&scope=https://www.googleapis.com/auth/admin.directory.user.readonly%20https://www.googleapis.com/auth/documents.readonly%20https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&access_type=offline`}
        />
      );
    case "clickup":
      return (
        <Tokens
          type="clickup"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://app.clickup.com/api?client_id=${CLICKUP_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/clickup`}
        />
      );
    case "bitbucket":
      return (
        <Tokens
          type="bitbucket"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://bitbucket.org/site/oauth2/authorize?client_id=${BITBUCKET_CLIENT_ID}&response_type=code`}
        />
      );
    case "notion":
      return (
        <Tokens
          type="notion"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://api.notion.com/v1/oauth/authorize?client_id=${NOTION_CLIENT_ID}&response_type=code&owner=user&redirect_uri=${window.location.origin}/integrations/redirect/notion`}
        />
      );
    case "confluence":
      return (
        <Tokens
          type="confluence"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${CONFLUENCE_CLIENT_ID}&scope=read:confluence-content.all%20read:confluence-space.summary%20read:confluence-content.summary%20search:confluence%20read:me%20offline_access&redirect_uri=${window.location.origin}/integrations/redirect/confluence&response_type=code&prompt=consent`}
        />
      );
    // case "docusign":
    // return (
    //   <Tokens
    //     type="docusign"
    //     setCurrentConfig={setCurrentConfig}
    //     integrationUrl={`https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature organization_read dtr.rooms.read&client_id=${DOCUSIGN_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/docusign`}
    //   />
    // );
    case "azuredevops":
      return (
        <Tokens
          type="azuredevops"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${AZUREDEVOPS_CLIENT_ID}&response_type=Assertion&scope=vso.project%20vso.work&redirect_uri=${window.location.origin}/integrations/redirect/azuredevops`}
        />
      );
    case "hubspot":
      return (
        <Tokens
          type="hubspot"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&scope=oauth crm.objects.contacts.read tickets&redirect_uri=${window.location.origin}/integrations/redirect/hubspot`}
        />
      );
    case "xero":
      return (
        <Tokens
          type="xero"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${window.location.origin}/integrations/redirect/xero&scope=offline_access%20openid %20profile%20email%20accounting.settings&state=xerodata`}
        />
      );
    case "zohobook":
      return (
        <Tokens
          type="zohobook"
          setCurrentConfig={setCurrentConfig}
          integrationUrl={`https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.settings.READ&client_id=${ZOHOBOOK_CLIENT_ID}&state=ZohoBookdata&response_type=code&redirect_uri=${window.location.origin}/integrations/redirect/zohobook&access_type=offline`}
        />
      );
    case "coda":
      return <APIKey type="coda" setCurrentConfig={setCurrentConfig} />;
    case "airtable":
      return <APIKey type="airtable" setCurrentConfig={setCurrentConfig} />;
    case "monday":
      return <APIKey type="monday" setCurrentConfig={setCurrentConfig} />;
    case "semgrep":
      return <APIKey type="semgrep" setCurrentConfig={setCurrentConfig} />;
    case "attio":
      return <APIKey type="attio" setCurrentConfig={setCurrentConfig} />;
    case "contentful":
      return <APIKey type="contentful" setCurrentConfig={setCurrentConfig} />;
    case "hive":
      return <APIKey type="hive" setCurrentConfig={setCurrentConfig} />;
    case "linear":
      return <APIKey type="linear" setCurrentConfig={setCurrentConfig} />;
    case "klaviyo":
      return <APIKey type="klaviyo" setCurrentConfig={setCurrentConfig} />;
    case "pagerduty":
      return <APIKey type="pagerduty" setCurrentConfig={setCurrentConfig} />;
    case "pivotaltracker":
      return (
        <APIKey type="pivotaltracker" setCurrentConfig={setCurrentConfig} />
      );
    case "wrike":
      return <APIKey type="wrike" setCurrentConfig={setCurrentConfig} />;
    case "shortcut":
      return <APIKey type="shortcut" setCurrentConfig={setCurrentConfig} />;
    case "breathe":
      return <APIKey type="breathe" setCurrentConfig={setCurrentConfig} />;
    case "tines":
      return <APIKey type="tines" setCurrentConfig={setCurrentConfig} />;
    case "pipedrive":
      return <APIKey type="pipedrive" setCurrentConfig={setCurrentConfig} />;
    case "envoy":
      return <APIKey type="envoy" setCurrentConfig={setCurrentConfig} />;
    case "front":
      return <APIKey type="front" setCurrentConfig={setCurrentConfig} />;
    case "render":
      return <APIKey type="render" setCurrentConfig={setCurrentConfig} />;
    case "terraform":
      return <APIKey type="terraform" setCurrentConfig={setCurrentConfig} />;
    case "rollbar":
      return <APIKey type="rollbar" setCurrentConfig={setCurrentConfig} />;
    case "oneflow":
      return <APIKey type="oneflow" setCurrentConfig={setCurrentConfig} />;
    case "vercel":
      return <APIKey type="vercel" setCurrentConfig={setCurrentConfig} />;
    case "articulate":
      return <APIKey type="articulate" setCurrentConfig={setCurrentConfig} />;

    case "freshdesk":
      return (
        <APIKeySubdomain type="freshdesk" setCurrentConfig={setCurrentConfig} />
      );
    case "qlik":
      return (
        <APIKeySubdomain type="qlik" setCurrentConfig={setCurrentConfig} />
      );
    case "scalerway":
      return (
        <APIKeyCompany
          type="scalerway"
          setCurrentConfig={setCurrentConfig}
          head="Organization id"
        />
      );
    case "recruitee":
      return (
        <APIKeyCompany type="recruitee" setCurrentConfig={setCurrentConfig} />
      );
    case "sentry":
      return (
        <APIKeyCompany type="sentry" setCurrentConfig={setCurrentConfig} />
      );

    default:
      return (
        <>
          <h1 className="text-2xl font-bold">Cloud</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={isDarkMode ? AWSWhite : aws}
              title="AWS"
              connections={data.aws}
              onClick={() => setCurrentConfig("aws")}
            />
            <Card
              logo={Azure}
              title="Azure"
              connections={data.azure}
              onClick={() => setCurrentConfig("azure")}
            />
            <Card
              logo={google}
              title="Google Cloud"
              connections={data.gcp}
              onClick={() => setCurrentConfig("gcp")}
            />
          </div>
          <h1 className="text-2xl font-bold">Notification</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={teams}
              title="Microsoft Teams"
              connections={data.teams}
              onClick={() => setCurrentConfig("teams")}
            />
            <Card
              logo={slack}
              title="Slack"
              connections={data.slack}
              onClick={() => setCurrentConfig("slack")}
            />
          </div>
          <h1 className="text-2xl font-bold">Version Control</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={isDarkMode ? githubWhite : github}
              title="Github"
              connections={data.github}
              onClick={() => setCurrentConfig("github")}
            />
            <Card
              logo={gitlab}
              title="Gitlab"
              connections={data.gitlab}
              onClick={() => setCurrentConfig("gitlab")}
            />
            <Card
              logo={bitbucket}
              title="Bitbucket"
              connections={data.bitbucket}
              onClick={() => setCurrentConfig("bitbucket")}
            />
          </div>
          <h1 className="text-2xl font-bold">Project Management</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={jira}
              title="Jira"
              connections={data.jira}
              onClick={() => setCurrentConfig("jira")}
            />
            <Card
              logo={asana}
              title="Asana"
              connections={data.asana}
              onClick={() => setCurrentConfig("asana")}
            />
            <Card
              logo={monday}
              title="Monday"
              connections={data.monday}
              onClick={() => setCurrentConfig("monday")}
            />
            <Card
              logo={clickup}
              title="ClickUp"
              connections={data.clickup}
              onClick={() => setCurrentConfig("clickup")}
            />

            <Card
              logo={coda}
              title="Coda"
              connections={data.coda}
              onClick={() => setCurrentConfig("coda")}
            />
            <Card
              logo={airtable}
              title="Airtable"
              connections={data.airtable}
              onClick={() => setCurrentConfig("airtable")}
            />
            <Card
              logo={notion}
              title="Notion"
              connections={data.notion}
              onClick={() => setCurrentConfig("notion")}
            />
            <Card
              logo={confluence}
              title="confluence"
              connections={data.confluence}
              onClick={() => setCurrentConfig("confluence")}
            />
            <Card
              logo={miro}
              title="Miro"
              connections={data.miro}
              onClick={() => setCurrentConfig("miro")}
            />
            {/* <Card
              logo={docusign}
              title="Docusign"
              connections={data.docusign}
              onClick={() => setCurrentConfig("docusign")}
            /> */}

            <Card
              logo={azuredevops}
              title="Azure devops"
              connections={data.azuredevops}
              onClick={() => setCurrentConfig("azuredevops")}
            />
          </div>
          <h1 className="text-2xl font-bold">External Services</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={pabbly}
              title="Pabbly"
              connections={data.pabbly}
              onClick={() => setCurrentConfig("pabbly")}
            />
            <Card
              logo={makecom}
              title="Make.com"
              connections={data.makecom}
              onClick={() => setCurrentConfig("make")}
            />
            <Card
              logo={zapier}
              title="Zapier"
              connections={data.zapier}
              onClick={() => setCurrentConfig("zapier")}
            />
          </div>

          <h1 className="text-2xl font-bold">Access Review</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            {/* <Card
              logo={contentful}
              title="Contentful"
              connections={data.contentful}
              onClick={() => setCurrentConfig("contentful")}
            /> */}
            <Card
              logo={hive}
              title="Hive"
              connections={data.hive}
              onClick={() => setCurrentConfig("hive")}
            />
            <Card
              logo={scalerway}
              title="Scalerway"
              connections={data.scalerway}
              onClick={() => setCurrentConfig("scalerway")}
            />
            <Card
              logo={render}
              title="Render"
              connections={data.render}
              onClick={() => setCurrentConfig("render")}
            />
            {/* <Card
              logo={klaviyo}
              title="Klaviyo"
              connections={data.klaviyo}
              onClick={() => setCurrentConfig("klaviyo")}
            /> */}
            <Card
              logo={pagerduty}
              title="Pagerduty"
              connections={data.pagerduty}
              onClick={() => setCurrentConfig("pagerduty")}
            />
            <Card
              logo={terraform}
              title="Terraform"
              connections={data.terraform}
              onClick={() => setCurrentConfig("terraform")}
            />
            <Card
              logo={rollbar}
              title="Rollbar"
              connections={data.rollbar}
              onClick={() => setCurrentConfig("rollbar")}
            />
            <Card
              logo={oneflow}
              title="Oneflow"
              connections={data.oneflow}
              onClick={() => setCurrentConfig("oneflow")}
            />
            <Card
              logo={wrike}
              title="Wrike"
              connections={data.wrike}
              onClick={() => setCurrentConfig("wrike")}
            />
            <Card
              logo={recruitee}
              title="Recruitee"
              connections={data.recruitee}
              onClick={() => setCurrentConfig("recruitee")}
            />
            <Card
              logo={pipedrive}
              title="PIpedrive"
              connections={data.pipedrive}
              onClick={() => setCurrentConfig("pipedrive")}
            />
            <Card
              logo={envoy}
              title="Envoy"
              connections={data.envoy}
              onClick={() => setCurrentConfig("envoy")}
            />
            <Card
              logo={front}
              title="Front"
              connections={data.front}
              onClick={() => setCurrentConfig("front")}
            />
            <Card
              logo={vercel}
              title="Vercel"
              connections={data.vercel}
              onClick={() => setCurrentConfig("vercel")}
            />
            <Card
              logo={xero}
              title="Xero"
              connections={data.xero}
              onClick={() => setCurrentConfig("xero")}
            />
            <Card
              logo={zohobook}
              title="Zohobook"
              connections={data.zohobook}
              onClick={() => setCurrentConfig("zohobook")}
            />
            <Card
              logo={sentry}
              title="Sentry"
              connections={data.sentry}
              onClick={() => setCurrentConfig("sentry")}
            />
            <Card
              logo={articulate}
              title="Articulate"
              connections={data.articulate}
              onClick={() => setCurrentConfig("articulate")}
            />
            <Card
              logo={qlik}
              title="Qlik"
              connections={data.qlik}
              onClick={() => setCurrentConfig("qlik")}
            />
          </div>
          {/* <h1 className="text-2xl font-bold">Security automation</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
           
            <Card
              logo={tines}
              title="Tines"
              connections={data.tines}
              onClick={() => setCurrentConfig("tines")}
            />
          </div> */}
          <h1 className="text-2xl font-bold">Identity Providers</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={entra}
              title="Microsoft Entra"
              connections={data.entra}
              onClick={() => setCurrentConfig("entra")}
            />
            <Card
              logo={googleworkspace}
              title="Google Workspace"
              connections={data.googleworkspace}
              onClick={() => setCurrentConfig("googleworkspace")}
            />
          </div>

          <h1 className="text-2xl font-bold">Vulnerability Scanning</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={semgrep}
              title="Semgrep"
              connections={data.semgrep}
              onClick={() => setCurrentConfig("semgrep")}
            />
          </div>
          <h1 className="text-2xl font-bold">HRMS</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={isDarkMode ? zohodark : zohowhite}
              title="Zoho"
              connections={data.zoho}
              onClick={() => setCurrentConfig("zoho")}
            />
            <Card
              logo={breathe}
              title="Breathe"
              connections={data.breathe}
              onClick={() => setCurrentConfig("breathe")}
            />
          </div>

          <h1 className="text-2xl font-bold">Ticketing</h1>
          <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 my-3 gap-3">
            <Card
              logo={zendesk}
              title="Zendesk"
              connections={data.zendesk}
              onClick={() => setCurrentConfig("zendesk")}
            />
            <Card
              logo={freshdesk}
              title="Freshdesk"
              connections={data.freshdesk}
              onClick={() => setCurrentConfig("freshdesk")}
            />
            <Card
              logo={hubspot}
              title="Hubspot"
              connections={data.hubspot}
              onClick={() => setCurrentConfig("hubspot")}
            />
            <Card
              logo={pivotaltracker}
              title="PivotalTracker"
              connections={data.pivotaltracker}
              onClick={() => setCurrentConfig("pivotaltracker")}
            />
            <Card
              logo={shortcut}
              title="Shortcut"
              connections={data.shortcut}
              onClick={() => setCurrentConfig("shortcut")}
            />
            <Card
              logo={linear}
              title="Linear"
              connections={data.linear}
              onClick={() => setCurrentConfig("linear")}
            />
            <Card
              logo={attio}
              title="Attio"
              connections={data.attio}
              onClick={() => setCurrentConfig("attio")}
            />
          </div>
        </>
      );
  }
}
