import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { TieredMenu } from "primereact/tieredmenu";
import { useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { ptaasAPI } from "../../../../Api/PTaaS/api";
import { DateTimeToDate } from "../../../../helpers";
import DetailedPtaasView, { STATUS } from "./detailedPtaasView";
import handleDownload from "../../../../utils/fileDownload";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { isAgentOrAdminOrSuperAdmin } from "../../../../utils/accessCheck";

const Actions = ({ item, setCurrentDetail, setRuleOfEngagement }) => {
  const menu = useRef(null);
  const userDetails = useSelector(getUserDetails);

  const requestRetest = (id) => {
    ptaasAPI
      .patch(`/scan/${id}/`, { status: "under_retesting" })
      .then(() => {
        toast.success("Status updated to under retesting");
        setRuleOfEngagement(null);
      })
      .catch((err) => console.log(err));
  };

  let items = [
    {
      label: "View",
      icon: "pi pi-search",
      command: () => setCurrentDetail(item),
    },
    {
      label: "Retest",
      icon: "pi pi-file-edit",
      command: () => requestRetest(item.id),
    },
  ];

  // if (isAgentOrAdminOrSuperAdmin(userDetails.role)) {
  //   items.push(
  //     {
  //     label: item.certificate_info
  //       ? "Download Certificate"
  //       : "Generate Certificate",
  //     icon: "pi pi-search",
  //     command: () => {
  //       if (item.certificate_info) {
  //         handleDownload(item.certificate_info);
  //         toast.success("Certificate downloading...");
  //       } else {
  //         setScanId(item.id);
  //       }
  //     },
  //   }
  // );
  // }

  return (
    <>
      <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
      <button label="Show" onClick={(e) => menu.current.toggle(e)}>
        <BsThreeDotsVertical />
      </button>
    </>
  );
};

export default function ViewPtaasScan({
  ruleOfEngagement,
  setRuleOfEngagement,
}) {
  const [data, setData] = useState([]);
  const [scanId, setScanId] = useState(null);
  const [currentDetail, setCurrentDetail] = useState(null);

  const getData = () => {
    ptaasAPI
      .get("/scan/filter/", {
        target: ruleOfEngagement,
        order_by: "-id",
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (ruleOfEngagement) {
      getData();
    }
  }, [ruleOfEngagement]);

  return (
    <>
      <Sidebar
        visible={ruleOfEngagement}
        position="right"
        onHide={() => setRuleOfEngagement(null)}
        className="w-full md:w-20rem lg:w-30rem sidebar-style"
      >
        <h1 className="text-2xl font-bold">Pentest History</h1>
        <div className="flex flex-col gap-2">
          {data.map((item) => (
            <div
              key={item.id}
              className="flex justify-between border p-2 rounded-md"
            >
              <p
                className="cursor-pointer"
                onClick={() => setCurrentDetail(item)}
              >
                {DateTimeToDate(item.created_at)}
              </p>
              <div className="flex items-center">
                <p
                  className="text-sm cursor-pointer"
                  onClick={() => setCurrentDetail(item)}
                >
                  {STATUS[item.status]}
                </p>
                <Actions
                  item={item}
                  setCurrentDetail={setCurrentDetail}
                  setRuleOfEngagement={setRuleOfEngagement}
                  setScanId={setScanId}
                />
              </div>
            </div>
          ))}
        </div>
      </Sidebar>
      <DetailedPtaasView
        currentDetail={currentDetail}
        setCurrentDetail={setCurrentDetail}
        setRuleOfEngagement={setRuleOfEngagement}
      />
    </>
  );
}
