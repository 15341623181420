import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { dataBreachAPI } from "../../../../Api/DRP/base";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function EmployeeCredentials() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scan });
  // const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const getFilterData = () => {
    dataBreachAPI
      .get("employee-credentials/", { scan })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    dataBreachAPI
      .post("employee-credentials/", {
        ...getFilter(filterData, filterParams),
        scan,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    { field: "username", header: "Username", value: filterData?.username },
    { field: "url_pass_strength.0.password", header: "Password" },
    { field: "url_pass_strength.0.url", header: "URL", value: filterData?.url },
    {
      field: "url_pass_strength.0.strength",
      header: "Password Strength",
      value: filterData?.password_strength,
    },
    { field: "date_compromised", header: "Date Compromised", value: [] },
  ];

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Compromised Credentials <span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              value={data}
              ref={ref}
              rows={10}
              removableSort
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              ))}
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
