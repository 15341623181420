import { useContext, useEffect, useState } from "react";
import { FaRegFolder } from "react-icons/fa";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { FiMoreVertical } from "react-icons/fi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import AddVaultFolder from "../../modules/home/components/compliance/AddVaultFolder";
import { complianceAPI } from "../../Api/CSPM/api";
import { ThemeContext } from "../../context/ThemeContext";
import { DateTimeToDate } from "../../helpers";

export default function VaultFolder() {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [editDetails, setEditDetails] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);

  const getData = () => {
    complianceAPI
      .post("/vault-folder/filter/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteItem = (id) => {
    complianceAPI
      .delete(`/vault-folder/${id}`)
      .then(() => {
        toast.success("Deleted Successfully.");
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderActions = (item) => {
    return [
      {
        label: "Edit",
        icon: "pi pi-refresh",
        command: () => {
          setEditDetails(item);
        },
      },
      {
        label: "Delete",
        icon: "pi pi-plus",
        command: () => {
          confirmDialog({
            header: `Delete ${item.name}`,
            message: "Are you Sure..?",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Delete",
            rejectLabel: "Cancel",
            accept: () => deleteItem(item.id),
          });
        },
      },
    ];
  };

  return (
    <div>
      <div className="flex justify-end pb-3">
        <button
          className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
            isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
          }`}
          onClick={() => setAddVisibility(true)}
        >
          Add Folder
        </button>
      </div>

      <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-4">
        {data.map((item) => (
          <div
            className={`rounded-2xl p-1 vaultFolder ${
              isDarkMode ? "header-dark" : "header-light white-background"
            }`}
          >
            <div
              className={`flex justify-end ${isDarkMode ? "dark" : "white"}`}
            >
              <SplitButton
                icon={<FiMoreVertical size={24} />}
                className={`p-button-text outline-none split-style`}
                model={renderActions(item)}
              ></SplitButton>
            </div>

            <div
              className="flex flex-col items-center justify-center pb-2"
              onClick={() => navigate(`./${item.id}`)}
            >
              <FaRegFolder size={50} />
              <p className="font-bold">{item.name}</p>
              <p className="text-xs">
                created on {DateTimeToDate(item.created_at)}
              </p>
            </div>
          </div>
        ))}
        <ConfirmDialog />
        <AddVaultFolder
          visible={addVisibility || editDetails}
          currentDetails={editDetails}
          onHide={() => {
            setAddVisibility(false);
            if (editDetails) {
              setEditDetails(null);
            }
            getData();
          }}
        />
      </div>
    </div>
  );
}
