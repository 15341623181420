import React, { useRef, useState } from "react";
import { useEffect } from "react";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import Tables from "../../../../../common/Tables/Tables";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const SubDomain = (props) => {
  const {
    subDomainTableData,
    loadingTable,
    subDomainFilterData,
    setFilterParams,
    filterParams,
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    {
      field: "subdomains",
      header: "Sub Domain",
      value: subDomainFilterData?.subdomains,
    },
    { field: "status", header: "Status", value: [] },
    { field: "title", header: "Title", value: [] },
    {
      field: "ip_address",
      header: "IP Address",
      value: subDomainFilterData?.ip_address,
    },
    { field: "port", header: "Port Number", value: [] },
    { field: "tags", header: "Tags", value: [] },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          tableData={subDomainTableData}
          dt={dt}
          tableHeader={columns}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DashboardTables
            userInformation={subDomainTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
            action={true}
          />
        )}
      </div>
    </>
  );
};

export default SubDomain;
