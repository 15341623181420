import { useNavigate, useSearchParams } from "react-router-dom";
import Auth0 from "../../services/auth0";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import AuthApi from "../../Api/AuthenticationApi/AuthApi";
import { useDispatch } from "react-redux";
import {
  setLoggedInValue,
  setUserDetails,
} from "../../redux-toolkit/redux/auth";
import { removeAuthenticationData } from "../../utils/constants";
import { LoginFooter, LoginHeader } from "../../Component/Header";
import AuthSideBar from "../../Component/Header/AuthSideBar";

export default function Authorize() {
  const hash = window.location.hash;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const authenticate = () => {
    Auth0.parseHash({ hash }, (err, result) => {
      if (err) {
        setError(err);
        return;
      }
      Auth0.client.userInfo(result.accessToken, function (err, user) {
        if (err) {
          setError(err);
          return;
        }
        if (user?.force_password_change)
          navigate("/password-change-required", {
            state: { email: user.email },
          });
        else {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...user, access: result.accessToken })
          );
          localStorage.setItem("token", result.accessToken);
          AuthApi.getMe()
            .then((res) => {
              if (res.status === "success") {
                dispatch(
                  setUserDetails({
                    ...res.data,
                    access: result.accessToken,
                    org_id: res.data.org?.[0]?.id,
                  })
                );
                dispatch(setLoggedInValue(true));
              } else {
                setError({
                  error: "Error",
                  errorDescription: res.message,
                });
                localStorage.clear();
              }
            })
            .catch((err) => {
              setError({
                error: "Error",
                errorDescription: "Something went wrong",
              });
              localStorage.clear();
              console.log(err);
            });
        }
      });
    });
  };

  useEffect(() => {
    if (hash) authenticate();
  }, [hash]);

  return (
    <div className="flex flex-col min-h-screen">
      <LoginHeader title="" subTitle="" navigate="" />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
          <div className="w-full h-full flex justify-center">
            {!error ? (
              <ProgressSpinner />
            ) : (
              <div className="flex flex-col items-center w-full py-24 lg:px-10 2xl:px-[115px] px-6 login-style ">
                <p className="font-bold text-2xl text-center">
                  {error.error.replace("_", " ")}
                </p>
                <div>
                  <p className="text-center ">{error.errorDescription}</p>
                </div>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className={`w-full p-3 my-2 rounded-[48px] text-white text-base relative button-bg-color flex justify-center items-center`}
                >
                  Try Again
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <LoginFooter />
      </div>
    </div>
  );
}
