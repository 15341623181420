import React from "react";
import { maskedEmailAt } from "../../../utils/constants";

const AuthenticateSignupPage = () => {
  const userEmail = localStorage.getItem("userEmail");

  return (
    <div className="flex flex-col items-center w-full py-24 lg:px-10 2xl:px-[115px] px-6 login-style ">
      <div className="mx-auto ">
        <h1 className="font-bold text-2xl text-center py-10">
          Activate Your Account
        </h1>
        <p className="text-center ">
          Lorem ipsum dolor sit amet, consec tetur adipiscing <br /> elit. Nam
          condimentum tempus diam, <br />
          ultricies sollicitudin dimentum
        </p>
        <p className="font-bold text-center  py-3">
          {maskedEmailAt(userEmail)}
        </p>
      </div>
    </div>
  );
};

export default AuthenticateSignupPage;
