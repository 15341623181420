import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { maskedEmailAt } from "../../../utils/constants";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { toast } from "react-toastify";

const RecoveryLinkForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location?.state?.email) {
    navigate("/reset/password");
  }

  const reSendRecoveryLink = () => {
    AuthApi.forgotPassword({ email: location?.state?.email })
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <div className="flex flex-col items-center py-24 lg:px-10 2xl:px-[115px] px-6 h-screen ">
      <div>
        <h1 className="text-xl font-bold text-center text-color">
          Reset your password
        </h1>
        <p className="text-base text-color pt-4 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
          condimentum tempus diam, ultricies sollicitudin erat facilisis eget.
          Vestibulum
        </p>

        <div className="text-center pt-2">
          <h1 className="text-lg font-bold text-color">
            {maskedEmailAt(location?.state?.email)}
          </h1>
        </div>

        <div className="text-center mt-6">
          <button
            className="p-4 text-base link-color"
            onClick={reSendRecoveryLink}
          >
            Resend recovery link
          </button>
        </div>
      </div>
      <Link to="/login">
        <div className="link-color flex gap-2 items-center cursor-pointer mb-20">
          <div>
            <AiOutlineArrowLeft size={18} />
          </div>
          <h1 className="text-base">Back to Log in</h1>
        </div>
      </Link>
    </div>
  );
};

export default RecoveryLinkForm;
