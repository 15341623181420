import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";

import integrationsAPI from "../../../../Api/integerations/api";

export default function Gitlab({ currentView, setCurrentView, onHide }) {
  const handleFetch = (e) => {
    integrationsAPI
      .post(`/${currentView.id}`, {
        action: "fetch_projects",
      })
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          setCurrentView({
            ...currentView,
            extra: {
              ...currentView.extra,
              projects: res.data.projects,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateProject = (id) => {
    integrationsAPI
      .patch(`/${currentView.id}`, {
        projects: currentView?.extra?.projects?.map((project) => {
          if (project.id === id) {
            return {
              ...project,
              enabled: !project.enabled,
            };
          }
          return project;
        }),
      })
      .then((res) => {
        toast.success("Project Updated Successfully");
        setCurrentView({
          ...currentView,
          extra: {
            ...currentView.extra,
            projects: currentView?.extra?.projects?.map((project) => {
              if (project.id === id) {
                return {
                  ...project,
                  enabled: !project.enabled,
                };
              }
              return project;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Sidebar visible={currentView} position="right" onHide={onHide}>
      <p className="font-bold text-2xl text-center mb-2">GitLab</p>
      <p>
        <span className="font-bold">Account ID : </span>
        {currentView?.account_id}
      </p>
      <div className="mt-3 flex justify-end">
        <button
          onClick={(e) => handleFetch(e)}
          className="p-2 border  rounded-[16px] border-[#AAAAAA] bg-[#181818] text-[#ffff]"
        >
          Fetch Projects
        </button>
      </div>
      {currentView?.extra?.projects?.map((project) => (
        <div
          key={project.id}
          className="mt-3 flex gap-3 cursor-pointer"
          onClick={() => UpdateProject(project.id)}
        >
          <button className="flex items-center gap-[6px] text-[#074EE8] text-base">
            <input type="checkbox" checked={project.enabled} />
          </button>
          <div>
            <p>
              <span className="font-bold">Project ID : </span>
              {project.id}
            </p>
            <p>
              <span className="font-bold">Project Name : </span>
              {project.name}
            </p>
          </div>
        </div>
      ))}
    </Sidebar>
  );
}
