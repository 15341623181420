import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { InformationIconDark, InformationIconLight } from "../../assets/svg";

export default function Header({ title, tooltip }) {
  const { isDarkMode } = useContext(ThemeContext);
  return (
    <div className="m-2 w-[100%] flex justify-center">
      <h1 className="flex w-[90%] items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
        <span className="truncate" title={title}>
          {title}
        </span>
      </h1>
      <button className="flex justify-end items-end cursor-pointer m-2 right-0">
        <img
          className="w-[18px]"
          data-tooltip-id={`${title}-tooltip`}
          src={isDarkMode ? InformationIconDark : InformationIconLight}
          alt="info"
        />
      </button>
      <ReactTooltip
        id={`${title}-tooltip`}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px", zIndex: 1 }}
      />
    </div>
  );
}
