import React, { useRef, useState } from "react";
import DashboardCloudTables from "../../../../../common/Tables/DashboardCloudTables";
import DashboardExploitsTable from "../../../../../common/Tables/DashboardExploitsTable";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const ExploitsTable = (props) => {
  const {
    cloudAwsTableData,
    loadingTable,
    exploitFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "cve", header: "CVE", value: exploitFilterData?.cve },
    { field: "host", header: "Affected Hosts", value: exploitFilterData?.host },
    {
      field: "date",
      header: "Date of Publishing",
      value: exploitFilterData?.date,
    },
    { field: "link", header: "Exploit Link", value: exploitFilterData?.link },
    { field: "tags", header: "Tags", value: [] },
    // { field: "action", header: "Actions" },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={cloudAwsTableData}
          tableHeader={columns}
          dt={dt}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DashboardExploitsTable
            userInformation={cloudAwsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};

export default ExploitsTable;
