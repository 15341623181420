import { useContext } from "react";
import { useNavigate } from "react-router";
import { Bar } from "react-chartjs-2";

import SecureScore from "../../../modules/attackSurfaceManagement/components/SecureScore";
import { ThemeContext } from "../../../context/ThemeContext";
import DonutChart from "../../../common/Charts/DonutChart";
import Listing from "../../../common/Listing";
import PassFailDonut from "../../../common/Charts/PassFailDonut";
import RiskListing from "../../../common/Charts/RiskListing";

import Maps from "./Maps";
import { DateTimeToDate } from "../../../helpers";
import Header from "../../../common/Charts/Header";

export default function AWSDashboard({ data, accountData }) {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const security_policy = [
    {
      key: "Internet Exposed",
      value: data?.security_policy?.internet_exposed_services || 0,
      filter: {
        checker__uid: [
          "ec2_elastic_ip_shodan",
          "ec2_instance_internet_facing_with_instance_profile",
          "ec2_instance_public_ip",
          "ecr_repositories_not_publicly_accessible",
          "efs_not_publicly_accessible",
          "elb_internet_facing",
          "elbv2_internet_facing",
          "emr_cluster_master_nodes_no_public_ip",
          "emr_cluster_publicly_accesible",
          "rds_instance_no_public_access",
          "rds_snapshots_public_access",
          "redshift_cluster_public_access",
          "s3_bucket_policy_public_write_access",
          "s3_bucket_public_access",
          "s3_bucket_public_list_acl",
          "s3_bucket_public_write_acl",
          "sns_topics_not_publicly_accessible",
          "sqs_queues_not_publicly_accessible",
          "ssm_documents_set_as_public",
          "opensearch_service_domains_not_publicly_accessible",
          "ec2_ami_public",
          "ec2_ebs_public_snapshot",
          "awslambda_function_not_publicly_accessible",
          "cloudtrail_logs_s3_bucket_is_not_publicly_accessible",
        ],
        status: 2,
      },
    },
    {
      key: "Exposed Secrets",
      value: data?.security_policy?.exposed_secrets || 0,
      filter: {
        checker__uid: [
          "ec2_instance_secrets_user_data",
          "awslambda_function_no_secrets_in_code",
          "awslambda_function_no_secrets_in_variables",
          "cloudformation_stack_outputs_find_secrets",
          "cloudwatch_log_group_no_secrets_in_logs",
          "ssm_document_secrets",
        ],
        status: 2,
      },
    },
    {
      key: "Privileged Escalation",
      value: data?.security_policy?.privilege_escalation || 0,
      filter: {
        checker__uid: [
          "ec2_instance_profile_attached",
          "iam_avoid_root_usage",
          "iam_aws_attached_policy_no_administrative_privileges",
          "iam_customer_attached_policy_no_administrative_privileges",
          "iam_customer_unattached_policy_no_administrative_privileges",
          "iam_inline_policy_no_administrative_privileges",
          "iam_no_custom_policy_permissive_role_assumption",
          "iam_policy_allows_privilege_escalation",
        ],
        status: 2,
      },
    },
    {
      key: "EC2 - IMDS V2 Enabled",
      value: data?.security_policy?.ec2_imds_v2 || 0,
      filter: {
        checker__uid: ["ec2_instance_imdsv2_enabled"],
        status: 2,
      },
    },
  ];

  return (
    <>
      <div className="lg:grid lg:grid-cols-4 lg:gap-6 flex flex-col gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <SecureScore
            secureScoreGraphCategory={
              data?.risk_score?.graph?.categories?.map(
                (item) => DateTimeToDate(item).split(",")[0]
              ) || []
            }
            secureScoreGraphData={data?.risk_score?.graph?.data || []}
            progressBar={[
              { name: "Critical", value: data?.risk_score?.critical || 0 },
              { name: "High", value: data?.risk_score?.high || 0 },
              { name: "Medium", value: data?.risk_score?.medium || 0 },
            ]}
            avg={data?.risk_score?.average || 0}
            tooltip="Ultra-Heals Risk Score reflects the current state of your cloud posture risk. A higher score denotes weaker security and more risks. A lower score denotes stronger security and less risks."
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Audit Status"
              type="normal"
              labels={["Pass", "Fail", "Unknown"]}
              colors={["#148842", "#E8001D", "#F5B207"]}
              chartData={[
                data?.audit_status?.success || 0,
                data?.audit_status?.failure || 0,
                data?.audit_status?.unknown || 0,
              ]}
              conditionType="Issues"
              total={`${data?.audit_status?.success || 0}/${
                (data?.audit_status?.success || 0) +
                (data?.audit_status?.failure || 0) +
                (data?.audit_status?.unknown || 0)
              }`}
              legends={[
                {
                  name: `Pass - ${data?.audit_status?.success || 0}`,
                  color: "#148842",
                },
                {
                  name: `Fail - ${data?.audit_status?.failure || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Unknown - ${data?.audit_status?.unknown || 0}`,
                  color: "#F5B207",
                },
              ]}
              navigateLink={"./audit-status"}
              navigateState={{
                title: "Audit Status",
              }}
              tooltip="Audit status is the status of the audit that is being performed on the system."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Severity"
              type="normal"
              labels={["Critical", "High", "Medium", "Low"]}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
              chartData={[
                data?.severity?.critical || 0,
                data?.severity?.high || 0,
                data?.severity?.medium || 0,
                data?.severity?.low || 0,
              ]}
              conditionType="Issues"
              total={data?.audit_status?.failure || 0}
              legends={[
                {
                  name: `Critical - ${data?.severity?.critical || 0}`,
                  color: "#A30123",
                },
                {
                  name: `High - ${data?.severity?.high || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Medium - ${data?.severity?.medium || 0}`,
                  color: "#F5B207",
                },
                {
                  name: `Low - ${data?.severity?.low || 0}`,
                  color: "#148842",
                },
              ]}
              navigateLink="./severity"
              navigateState={{
                title: "Severity",
                filter: {
                  status: 2,
                },
              }}
              tooltip="Cloud Security Vulnerabilities are classified into distinct categories, allowing for clear risk assessment and effective prioritization."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Security Policies"
              tooltip="Monitor for internet-exposed resources, exposed secrets, potential privilege escalation risks, and ensure EC2 instances use IMDSv2 for enhanced security."
            />
            <div className="grid grid-cols-1 gap-2 mb-2 h-full content-around">
              {security_policy.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`flex justify-between items-center w-full px-3 py-3 border-[1px] rounded-lg ${
                      isDarkMode ? "tab-dark-border" : "border-color"
                    } cursor-pointer`}
                    onClick={() => {
                      navigate(`./${item.key.replace(/ /g, "-")}`, {
                        state: {
                          title: item.key,
                          filter: item.filter,
                        },
                      });
                    }}
                  >
                    <p className="text-xl truncate" title={item.key}>
                      {item.key}
                    </p>
                    <h1 className="text-xl font-bold">{item.value}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="py-6">
        <h1 className="text-2xl font-bold">Services</h1>
      </div>
      <div className="lg:grid lg:grid-cols-4 lg:gap-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-56">
              <Header
                title="Identity and Access Management (IAM)"
                tooltip="Check the security status of IAM access and AWS IAM configurations, identifying passed and failed policies with severity ratings."
              />
              <div className="flex justify-center">
                <div className="grid grid-cols-2 gap-5 w-2/3">
                  <PassFailDonut
                    title="IAM Access"
                    data={data?.service_status?.accessanalyzer}
                    path="./accessanalyzer"
                    state={{
                      title: "IAM Access",
                      filter: { resource__group__name: "accessanalyzer" },
                    }}
                  />
                  <PassFailDonut
                    title="AWS IAM"
                    data={data?.service_status?.iam}
                    path="./iam"
                    state={{
                      title: "AWS IAM",
                      filter: { resource__group__name: "iam" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-56">
              <Header
                title="Monitoring and Logging"
                tooltip="Check the security status of Monitoring and Logging, identifying passed and failed policies with severity ratings."
              />
              <div className="grid grid-cols-3 gap-5">
                <PassFailDonut
                  title="Cloud Watch"
                  data={data?.service_status?.cloudwatch}
                  path="./cloudwatch"
                  state={{
                    title: "Cloud Watch",
                    filter: { resource__group__name: "cloudwatch" },
                  }}
                />
                <PassFailDonut
                  title="Cloud Trail"
                  data={data?.service_status?.cloudtrail}
                  path="./cloudtrail"
                  state={{
                    title: "Cloud Trail",
                    filter: { resource__group__name: "cloudtrail" },
                  }}
                />
                <PassFailDonut
                  title="Config"
                  data={data?.service_status?.config}
                  path="./config"
                  state={{
                    title: "Config",
                    filter: { resource__group__name: "config" },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-56">
              <Header
                title="Compute Services"
                tooltip="Check the security status of Compute Services, identifying passed and failed policies with severity ratings."
              />
              <div className="grid grid-cols-3 gap-5">
                <PassFailDonut
                  title="AWS EC2"
                  data={data?.service_status?.ec2}
                  path="./ec2"
                  state={{
                    title: "AWS EC2",
                    filter: { resource__group__name: "ec2" },
                  }}
                />
                <PassFailDonut
                  title="AWS EMR"
                  data={data?.service_status?.emr}
                  path="./emr"
                  state={{
                    title: "AWS EMR",
                    filter: { resource__group__name: "emr" },
                  }}
                />
                <PassFailDonut
                  title="AWS ECR"
                  data={data?.service_status?.ecr}
                  path="./emr"
                  state={{
                    title: "AWS ECR",
                    filter: { resource__group__name: "ecr" },
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-56">
              <Header
                title="Networking"
                tooltip="Check the security status of Networking, identifying passed and failed policies with severity ratings."
              />
              <div className="grid grid-cols-3 gap-5">
                <PassFailDonut
                  title="AWS VPC"
                  data={data?.service_status?.vpc}
                  path="./vpc"
                  state={{
                    title: "AWS VPC",
                    filter: { resource__group__name: "vpc" },
                  }}
                />
                <PassFailDonut
                  title="Firewall"
                  data={data?.service_status?.["network-firewall"]}
                  path="./firewall"
                  state={{
                    title: "Network Firewall",
                    filter: { resource__group__name: "network-firewall" },
                  }}
                />
                <PassFailDonut
                  title="ELB v2"
                  data={data?.service_status?.elbv2}
                  path="./elbv2"
                  state={{
                    title: "ELB v2",
                    filter: { resource__group__name: "elbv2" },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Analytics and Database Services"
              tooltip="Check the security status of Analytics and Database Services, identifying passed and failed policies with severity ratings."
            />
            <RiskListing
              data={[
                {
                  name: "AWS Athena",
                  data: data?.service_status?.athena,
                  onClick: () => {
                    navigate("./athena", {
                      state: {
                        title: "AWS Athena",
                        filter: { resource__group__name: "athena" },
                      },
                    });
                  },
                },
                {
                  name: "AWS redshift",
                  data: data?.service_status?.redshift,
                  onClick: () => {
                    navigate("./redshift", {
                      state: {
                        title: "AWS redshift",
                        filter: { resource__group__name: "redshift" },
                      },
                    });
                  },
                },
                {
                  name: "AWS RDS",
                  data: data?.service_status?.rds,
                  onClick: () => {
                    navigate("./rds", {
                      state: {
                        title: "AWS RDS",
                        filter: { resource__group__name: "rds" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Glue",
                  data: data?.service_status?.glue,
                  onClick: () => {
                    navigate("./glue", {
                      state: {
                        title: "AWS Glue",
                        filter: { resource__group__name: "glue" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Data Replications",
                  data: data?.service_status?.data_replication,
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Automation and Management"
              tooltip="Check the security status of Automation and Management, identifying passed and failed policies with severity ratings."
            />
            <RiskListing
              data={[
                {
                  name: "AWS Backup",
                  data: data?.service_status?.backup,
                  onClick: () => {
                    navigate("./backup", {
                      state: {
                        title: "AWS Backup",
                        filter: { resource__group__name: "backup" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Resource Group",
                  data: {
                    pass: 0,
                    fail: 0,
                  },
                },
                {
                  name: "AWS Organization",
                  data: data?.service_status?.organizations,
                  onClick: () => {
                    navigate("./organizations", {
                      state: {
                        title: "AWS Organization",
                        filter: { resource__group__name: "organizations" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Trust Advisor",
                  data: data?.service_status?.trustedadvisor,
                  onClick: () => {
                    navigate("./trust-advisor", {
                      state: {
                        title: "AWS Trust Advisor",
                        filter: { resource__group__name: "trustedadvisor" },
                      },
                    });
                  },
                },
                {
                  name: "AWS System manager",
                  data: { pass: 0, fail: 0 },
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Security Services"
              tooltip="Check the security status of Security Services, identifying passed and failed policies with severity ratings."
            />
            <RiskListing
              data={[
                {
                  name: "AWS Certificate Manager",
                  data: data?.service_status?.acm,
                  onClick: () => {
                    navigate("./certificate-manager", {
                      state: {
                        title: "AWS Certificate Manager",
                        filter: { resource__group__name: "acm" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Security Hub",
                  data: data?.service_status?.securityhub,
                },
                // {
                //   name: "AWS Network Firewall",
                //   pass: data?.service_status["network-firewall"]?.success || 0,
                //   fail: data?.service_status["network-firewall"]?.failure || 0,
                // },
                {
                  name: "AWS Secrets Manager",
                  data: data?.service_status?.secretsmanager,
                  onClick: () => {
                    navigate("./secrets-manager", {
                      state: {
                        title: "AWS Secrets Manager",
                        filter: { resource__group__name: "secretsmanager" },
                      },
                    });
                  },
                },
                {
                  name: "AWS KMS",
                  data: data?.service_status?.kms,
                },
                {
                  name: "AWS Guard Duty",
                  data: data?.service_status?.guardduty,
                  onClick: () => {
                    navigate("./guard-duty", {
                      state: {
                        title: "AWS Guard Duty",
                        filter: { resource__group__name: "guardduty" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Inspector",
                  data: data?.service_status?.inspector2,
                  onClick: () => {
                    navigate("./inspector", {
                      state: {
                        title: "AWS Inspector",
                        filter: { resource__group__name: "inspector2" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Macie",
                  data: data?.service_status?.macie,
                  onClick: () => {
                    navigate("./macie", {
                      state: {
                        title: "AWS Macie",
                        filter: { resource__group__name: "macie" },
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Storage and Content Delivery"
              tooltip="Check the security status of Storage and Content Delivery, identifying passed and failed policies with severity ratings."
            />
            <RiskListing
              data={[
                {
                  name: "AWS S3",
                  data: data?.service_status?.s3,
                  onClick: () => {
                    navigate("./s3", {
                      state: {
                        title: "AWS S3",
                        filter: { resource__group__name: "s3" },
                      },
                    });
                  },
                },
                {
                  name: "AWS EFS",
                  data: data?.service_status?.efs,
                  onClick: () => {
                    navigate("./efs", {
                      state: {
                        title: "AWS EFS",
                        filter: { resource__group__name: "efs" },
                      },
                    });
                  },
                },
                {
                  name: "AWS EBS",
                  data: data?.service_status?.ebs,
                  onClick: () => {
                    navigate("./ebs", {
                      state: {
                        title: "AWS EBS",
                        filter: { resource__group__name: "ebs" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Route 53",
                  data: data?.service_status?.route53,
                  onClick: () => {
                    navigate("./route53", {
                      state: {
                        title: "AWS Route 53",
                        filter: { resource__group__name: "route53" },
                      },
                    });
                  },
                },
                {
                  name: "AWS CloudFront",
                  data: data?.service_status?.cloudfront,
                  onClick: () => {
                    navigate("./cloudfront", {
                      state: {
                        title: "AWS CloudFront",
                        filter: { resource__group__name: "cloudfront" },
                      },
                    });
                  },
                },
                {
                  name: "AWS Data lifecycle Manager",
                  data: data?.service_status?.dlm,
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <Listing
            title="Top 10 Failed Services"
            tooltip="Insights about top 10 failed services in your cloud infrastructure."
            data={
              data?.service_severity
                ? Object.keys(data?.service_status)
                    .sort((a, b) => {
                      // sort by severity
                      return (
                        (data?.service_status[b]?.failure || 0) -
                        (data?.service_status[a]?.failure || 0)
                      );
                    })
                    .slice(0, 8)
                    .map((key) => {
                      return {
                        key: key,
                        value: data?.service_status[key]?.failure || 0,
                        onClick: () => {
                          navigate(`./${key}`, {
                            state: {
                              title: key.toUpperCase(),
                              filter: { resource__group__name: key, status: 2 },
                            },
                          });
                        },
                      };
                    })
                : []
            }
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <Listing
            title="Top 10 Failed Findings by Account"
            tooltip="Insights about top 10 failed findings by the accounts."
            data={accountData?.map((item) => {
              return {
                key: item.account_id,
                value:
                  (item.critical | 0) +
                  (item.high | 0) +
                  (item.medium | 0) +
                  (item.low | 0),
                onClick: () => {
                  navigate(`./${item.account_id}`, {
                    state: {
                      title: `Account: ${item.account_id}`,
                      filter: { status: 2 },
                    },
                  });
                },
              };
            })}
          />
        </div>
      </div>
      <div className="py-6">
        <h1 className="text-2xl font-bold">Compliance</h1>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-6 mb-4">
        <div
          className={`lg:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 pb-2 flex flex-col">
            <Header
              title="Top Compliances"
              tooltip="Insights about the status of top compliances such as ISO27001, SOC2, PCIDSS, GDPR and HIPAA."
            />
            <div
              className="flex justify-center min-h-[300px]"
              style={{
                minHeight: "300px",
              }}
            >
              <Bar
                data={{
                  labels: ["ISO 27001", "SOC 2", "PCI DSS", "GDPR", "HIPAA"],
                  datasets: [
                    {
                      label: "Passed",
                      data: [
                        data?.compliance?.ISO27001_2013?.success || 0,
                        data?.compliance?.SOC2?.success || 0,
                        data?.compliance?.PCI_3_2_1?.success || 0,
                        data?.compliance?.GDPR?.success || 0,
                        data?.compliance?.HIPAA?.success || 0,
                      ],
                      backgroundColor: [
                        "#33B2DF",
                        "#546E7A",
                        "#D4526E",
                        "#13D8AA",
                        "#A5978B",
                      ],
                      maxBarThickness: 50,
                    },
                    {
                      label: "Failed",
                      data: [
                        data?.compliance?.ISO27001_2013?.failure || 0,
                        data?.compliance?.SOC2?.failure || 0,
                        data?.compliance?.PCI_3_2_1?.failure || 0,
                        data?.compliance?.GDPR?.failure || 0,
                        data?.compliance?.HIPAA?.failure || 0,
                      ],
                      backgroundColor: [
                        "#FF4560",
                        "#775DD0",
                        "#FEB019",
                        "#FF7F00",
                        "#00E396",
                      ],
                      maxBarThickness: 50,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  onClick: (e, el) => {
                    const keys = [
                      "ISO27001-2013",
                      "SOC2",
                      "PCI-3.2.1",
                      "GDPR",
                      "HIPAA",
                    ];
                    let label = e.chart.data.labels[el[0].index];
                    navigate(`./${keys[el[0].index]}`, {
                      state: {
                        title: label,
                        filter: {
                          compliance__framework: keys[el[0].index],
                        },
                      },
                    });
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 pb-2 flex flex-col">
            <Header
              title="AWS Security Framework"
              tooltip="Insights about the status of AWS Security Frameworks."
            />
            <div
              className="flex justify-center min-h-[300px]"
              style={{
                minHeight: "300px",
              }}
            >
              <Bar
                data={{
                  labels: [
                    "Onboarding",
                    "Control Tower",
                    "Foundation Best Practices",
                    "Reliability Pillar",
                    "Security Pillar",
                  ],
                  datasets: [
                    {
                      label: "Passed",
                      data: [
                        data?.compliance?.AWS_Account_Security_Onboarding
                          ?.success || 0,
                        data?.compliance
                          ?.AWS_Audit_Manager_Control_Tower_Guardrails
                          ?.success || 0,
                        data?.compliance
                          ?.AWS_Foundational_Security_Best_Practices?.success ||
                          0,
                        data?.compliance
                          ?.AWS_Well_Architected_Framework_Reliability_Pillar
                          ?.success || 0,
                        data?.compliance
                          ?.AWS_Well_Architected_Framework_Security_Pillar
                          ?.success || 0,
                      ],
                      backgroundColor: [
                        "#33B2DF",
                        "#546E7A",
                        "#D4526E",
                        "#13D8AA",
                        "#A5978B",
                      ],
                      maxBarThickness: 50,
                    },
                    {
                      label: "Failed",
                      data: [
                        data?.compliance?.AWS_Account_Security_Onboarding
                          ?.failure || 0,
                        data?.compliance
                          ?.AWS_Audit_Manager_Control_Tower_Guardrails
                          ?.failure || 0,
                        data?.compliance
                          ?.AWS_Foundational_Security_Best_Practices?.failure ||
                          0,
                        data?.compliance
                          ?.AWS_Well_Architected_Framework_Reliability_Pillar
                          ?.failure || 0,
                        data?.compliance
                          ?.AWS_Well_Architected_Framework_Security_Pillar
                          ?.failure || 0,
                      ],
                      backgroundColor: [
                        "#FF4560",
                        "#775DD0",
                        "#FEB019",
                        "#FF7F00",
                        "#00E396",
                      ],
                      maxBarThickness: 50,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  onClick: (e, el) => {
                    const keys = [
                      "AWS-Account-Security-Onboarding",
                      "AWS-Audit-Manager-Control-Tower-Guardrails",
                      "AWS-Foundational-Security-Best-Practices",
                      "AWS-Well-Architected-Framework-Reliability-Pillar",
                      "AWS-Well-Architected-Framework-Security-Pillar",
                    ];
                    let label = e.chart.data.labels[el[0]?.index];
                    navigate(`./${keys[el[0].index]}`, {
                      state: {
                        title: label,
                        filter: {
                          compliance__framework: keys[el[0].index],
                        },
                      },
                    });
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 pb-2 flex flex-col">
            <Header
              title="US Government Compliances"
              tooltip="Insights about the status of US government compliances such as NIST, CISA and FedRAMP."
            />
            <div
              className="flex justify-center min-h-[300px]"
              style={{
                minHeight: "300px",
              }}
            >
              <Bar
                data={{
                  labels: [
                    "CISA",
                    "NIST 800-53 Rev 5",
                    "NIST CSF 1.1",
                    "FedRamp Moderate",
                    "FedRamp Low",
                  ],
                  datasets: [
                    {
                      label: "Passed",
                      data: [
                        data?.compliance?.CISA?.success || 0,
                        data?.compliance?.NIST_800_53_Revision_5?.success || 0,
                        data?.compliance?.NIST_CSF_1_1?.success || 0,
                        data?.compliance?.FedRamp_Moderate_Revision_4
                          ?.success || 0,
                        data?.compliance?.FedRAMP_Low_Revision_4?.success || 0,
                      ],
                      backgroundColor: [
                        "#33B2DF",
                        "#546E7A",
                        "#D4526E",
                        "#13D8AA",
                        "#A5978B",
                      ],
                      maxBarThickness: 50,
                    },
                    {
                      label: "Failed",
                      data: [
                        data?.compliance?.CISA?.failure || 0,
                        data?.compliance?.NIST_800_53_Revision_5?.failure || 0,
                        data?.compliance?.NIST_CSF_1_1?.failure || 0,
                        data?.compliance?.FedRamp_Moderate_Revision_4
                          ?.failure || 0,
                        data?.compliance?.FedRAMP_Low_Revision_4?.failure || 0,
                      ],
                      backgroundColor: [
                        "#FF4560",
                        "#775DD0",
                        "#FEB019",
                        "#FF7F00",
                        "#00E396",
                      ],
                      maxBarThickness: 50,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  // interaction: {
                  //   mode: "index",
                  // },
                  onClick: (e, el) => {
                    const keys = [
                      "CISA",
                      "NIST-800-53-Revision-5",
                      "NIST-CSF-1.1",
                      "FedRamp-Moderate-Revision-4",
                      "FedRAMP-Low-Revision-4",
                    ];
                    let label = e.chart.data.labels[el[0].index];
                    navigate(`./${keys[el[0].index]}`, {
                      state: {
                        title: label,
                        filter: {
                          compliance__framework: keys[el[0].index],
                        },
                      },
                    });
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 pb-2 flex flex-col">
            <Header
              title="Fintech Compliances"
              tooltip="Insights about the status of Fintech Compliances focused on RBI and FFIEC Frameworks."
            />
            {/* <div className="grid grid-cols-1 h-[300px] items-center"> */}
            {/* <div className="grid grid-cols-2 items-center self-center justify-between"> */}
            <div className="grid grid-cols-2 gap-5 h-full items-center p-5">
              <PassFailDonut
                title="RBIA CS Framework"
                data={data?.compliance?.RBI_Cyber_Security_Framework}
                path="./RBI-Cyber-Security-Framework"
                state={{
                  title: "RBI CS Framework",
                  filter: {
                    compliance__framework: "RBI-Cyber-Security-Framework",
                  },
                }}
              />
              <PassFailDonut
                title="FFIEC"
                data={data?.compliance?.FFIEC}
                path="./FFIEC"
                state={{
                  title: "FFIEC",
                  filter: { compliance__framework: "FFIEC" },
                }}
              />
            </div>
            {/* <PassFailDonut
                title="FFIEC"
                data={data?.compliance?.FFIEC}
                path="./FFIEC"
                state={{
                  title: "FFIEC",
                  filter: { compliance__framework: "FFIEC" },
                }}
              /> */}
            {/* </div> */}
            {/* <div
              className="flex justify-center min-h-[300px]"
              style={{
                minHeight: "300px",
              }}
            >
              <Bar
                data={{
                  labels: ["RBIA CS Framework", "FFIEC"],
                  datasets: [
                    {
                      label: "Passed",
                      data: [
                        data?.compliance?.RBI_Cyber_Security_Framework
                          ?.success || 0,
                        data?.compliance?.FFIEC?.success || 0,
                      ],
                      backgroundColor: [
                        "#33B2DF",
                        "#546E7A",
                        "#D4526E",
                        "#13D8AA",
                        "#A5978B",
                      ],
                      maxBarThickness: 50,
                    },
                    {
                      label: "Failed",
                      data: [
                        data?.compliance?.RBI_Cyber_Security_Framework
                          ?.success || 0,
                        data?.compliance?.FFIEC?.success || 0,
                      ],
                      backgroundColor: [
                        "#FF4560",
                        "#775DD0",
                        "#FEB019",
                        "#FF7F00",
                        "#00E396",
                      ],
                      maxBarThickness: 50,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  onClick: (e, el) => {
                    const keys = ["RBI-Cyber-Security-Framework", "FFIEC"];
                    let label = e.chart.data.labels[el[0].index];
                    navigate(`./${keys[el[0].index]}`, {
                      state: {
                        title: label,
                        filter: {
                          compliance__framework: keys[el[0].index],
                        },
                      },
                    });
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div> */}
          </div>
        </div>
        {/* <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-[300px]">
            <DonutChart
              title="Vulnerabilities by status"
              type="normal"
              labels={vocStatus}
              colors={["#E8001D", "#148842", "#F59107", "#7B7DE2"]}
              chartData={vocStatusOpen}
              conditionType="Issues"
              total={vocStatusTotal}
              legends={vocLegends}
              navigateLink="/vulnerability-operation-center"
              tooltip="Vulnerability intelligence sorts vulnerabilities by handling status, delivering a concise snapshot of security risk management."
            />
          </div>
        </div> */}
      </div>
      <Maps isDarkMode={isDarkMode} data={{ regions: data?.regions }} />
    </>
  );
}
