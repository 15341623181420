import { useContext, useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { FaRegTimesCircle } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useParams } from "react-router";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function PolicyAcceptance() {
  const { id } = useParams();
  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState(null);

  const getData = () => {
    complianceAPI
      .get(`/employee-campaign/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h1 className="font-bold mb-2">Policy Overview</h1>
      <div
        className={`rounded-2xl userTable-style ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div className="grid grid-cols-2">
          <p>
            <span className="font-bold">Total Policies: </span>
            {data?.policies?.length}
          </p>
          <div className="grid grid-cols-2">
            <p>
              <span className="font-bold">Policies Accepted: </span>{" "}
              {data?.accepted.length}
            </p>
            <ProgressBar
              value={
                Math.floor(
                  (data?.accepted.length / data?.policies?.length) * 100
                ) || 0
              }
              color="#148842"
              title={`Passed: 5`}
            ></ProgressBar>
          </div>
        </div>
      </div>
      <h1 className="font-bold my-2">List of Policies</h1>
      <div
        className={`rounded-2xl userTable-style ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        {data?.policies?.map((item) => (
          <div
            className={`flex flex-row justify-between items-center my-2 p-1 border ${
              isDarkMode ? "border-[#444]" : "border-[#DEDEDE]"
            }`}
          >
            <p className="text-sm">{item.name}</p>
            {data?.accepted.includes(item.id) ? (
              <p className="flex items-center text-sm text-green-500 gap-2">
                Accepted
                <IoMdCheckmarkCircleOutline />
              </p>
            ) : (
              <p className="flex items-center text-sm text-red-500 gap-2">
                Not Accepted
                <FaRegTimesCircle />
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
