import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ComplianceTable from "../complianceTable";
import AddQuestionnaire from "./AddQuestionnaire";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function Questionnaire() {
  const { id } = useParams();
  const [employee, setEmployee] = useState([]);

  const columns = [
    { field: "name", header: "Questionnaire Name" },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "submission_deadline",
      header: "Submission Deadline",
      type: "date",
    },

    {
      field: "reviewer",
      header: "Reviewer",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "review_date",
      header: "Review Date",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <ComplianceTable
      title="Questionnaire"
      columns={columns}
      filter={{ vendor: id }}
      url_path="/questionnaire/"
      AddComponent={AddQuestionnaire}
    />
  );
}
