import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../context/ThemeContext";
import CloudSurfaceGoogleCloudTable from "../../home/components/tables/AttackSurface/CloudSurfaceGoogleCloudTable";
import LiveHosts from "../../home/components/tables/AttackSurface/LiveHosts";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { CloudFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { getCloudFilter } from "../../../utils/utils";

const CloudSurfaceGoogleCloud = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const [googleCloudTableData, setGoogleCloudTableData] = useState([]);
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [FilterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    scanID: scanID,
    host_type: "google",
  });

  const { title } = props;

  const getGoogleCloudTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardGoogleCloudTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setGoogleCloudTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    CloudFilterAPI.post({
      ...getCloudFilter(FilterData, filterParams),
      scanID: scanID,
      host_type: "google",
    })
      .then((res) => {
        setGoogleCloudTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    CloudFilterAPI.get({ scanID: scanID, host_type: "google" })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (FilterData) getGoogleCloudTable();
  }, [filterParams, FilterData]);

  // useEffect(() => {
  //   const id = userDetails.scan_id;
  //   getGoogleCloudTable(id);
  // }, []);

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <CloudSurfaceGoogleCloudTable
          googleCloudTableData={googleCloudTableData}
          loadingTable={loadingTable}
          FilterData={FilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default CloudSurfaceGoogleCloud;
