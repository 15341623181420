import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";

import TableFilter from "../../../../common/Tables/TableFilter";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { DateTimeToDate } from "../../../../helpers";
import { getFilter } from "../../../../utils/utils";

export default function FakeApps() {
  const { isDarkMode } = useContext(ThemeContext);
  const ref = useRef(null);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.scan_id;

  const api = new TableFilterApiBase(
    "ultraheals/v1/web/digital-risk/fakeappsleaks/filter/"
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterParams, setFilterParams] = useState({ scan });
  const [filterData, setFilterData] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "keyword", header: "Keyword", value: filterData?.keyword },
    { field: "app_name", header: "App Name", value: filterData?.app_name },
    { field: "platform", header: "Market", value: filterData?.platform },
    { field: "app_url", header: "Source", value: filterData?.app_url },
    { field: "date_added", header: "Date", type: "date" },
    { field: "update_field_name", header: "Tags", value: [] },
    { field: "update_field_name", header: "Status", value: [] },
  ];

  const getFilterData = () => {
    api
      .get({ scan })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...getFilter(filterData, filterParams), scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Fake Apps <span> ({data?.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              value={data}
              rows={10}
              ref={ref}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.type === "date") {
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      sortable
                      body={(rowData) => {
                        return DateTimeToDate(rowData[col.field]);
                      }}
                    />
                  );
                }
                return (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                );
              })}
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
