import { ConfirmDialog } from "primereact/confirmdialog";
import { complianceAPI } from "../../../../Api/CSPM/api";
import AddCourse from "./Add/AddCourse";
import AddLessons from "./Add/AddLessons";
import AddQuiz from "./Add/AddQuiz";
import AwarenessMaterials from "./AwarenessMaterials";
import { useEffect, useState } from "react";

export default function SecurityAwareness() {
  const [showCourse, setShowCourse] = useState(null);
  const [showLessons, setShowLessons] = useState(null);
  const [showQuiz, setShowQuiz] = useState(null);
  const [data, setData] = useState([]);

  const getData = () => {
    complianceAPI
      .post("/campaign/filter/")
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AwarenessMaterials
        setShowCourse={setShowCourse}
        setShowLessons={setShowLessons}
        setShowQuiz={setShowQuiz}
        data={data}
        getData={getData}
      />
      <AddCourse
        showCourse={showCourse}
        setShowCourse={setShowCourse}
        getData={getData}
      />
      <AddLessons showLessons={showLessons} setShowLessons={setShowLessons} />
      <AddQuiz showQuiz={showQuiz} setShowQuiz={setShowQuiz} />
      <ConfirmDialog />
    </>
  );
}
