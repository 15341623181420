import { createElement, useContext, useState } from "react";
import AddVaultFolder from "../../modules/home/components/compliance/AddVaultFolder";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { ThemeContext } from "../../context/ThemeContext";
import VaultFile from "./VaultFile";
import VaultFolder from "./VaultFolder";

export default function Vault() {
  const { isDarkMode } = useContext(ThemeContext);

  const [currentTab, setCurrentTab] = useState("folder");

  const TABS = [
    {
      id: "folder",
      title: "Folder",
      component: VaultFolder,
    },
    {
      id: "Document",
      title: "Document",
      component: VaultFile,
    },
  ];

  return (
    <>
      <h1 className="font-bold">Vault</h1>
      <div
        className={`flex gap-5 border-b-2 my-6 mx-8 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="pb-4 px-8">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component)}
      </div>
    </>
  );
}
