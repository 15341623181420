export const groupDataByYear = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const year = new Date(item.date).getFullYear();
    if (!groupedData[year]) {
      groupedData[year] = [];
    }
    groupedData[year].push(item);
  });

  return groupedData;
};

export const convertIsoToReadableFormat = (isoTimestamp) => {
  const dateObj = new Date(isoTimestamp);
  const month = dateObj.toLocaleString("en-US", { month: "short" });
  const day = dateObj.getDate();
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${month} ${day}, ${hours}:${minutes}`;
};

export const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split("-");
  let newDate = `${day}-${month}-${year}`;
  return newDate;
};

export function DateTimeToDate(datetime) {
  if (!datetime) return;
  const date = new Date(datetime).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });
  if (date != "Invalid Date") return date;
  return;
}

export function DateSelectionToDjangoFormat(date) {
  // YYYY-MM-DD
  if (!date) return;
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function SeverityLevelLabel(level) {
  switch (parseInt(level)) {
    case 1:
      return "Critical";
    case 2:
      return "High";
    case 3:
      return "Medium";
    case 4:
      return "Low";
    default:
      return "Info";
  }
}

export function DateTimeToDateTime(datetime) {
  if (!datetime) return;
  const date = new Date(datetime).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: false, // 24-hour format,
    timeZone: "UTC",
  });
  if (date != "Invalid Date") return date;
  return;
}
