import React from "react";
import NonExpandableSideBar from "./NonExpandableSideBar";
import ExpandableSideBar from "./ExpandableSideBar";

const SideBar = (props) => {

  const { expandSideBar, setExpandSideBar } = props

  return (
    <div>
      {expandSideBar ? (
        <ExpandableSideBar 
        setExpandSideBar={setExpandSideBar}
        expandSideBar={expandSideBar}
        />
      ) : (
        <NonExpandableSideBar 
        setExpandSideBar={setExpandSideBar}
        expandSideBar={expandSideBar}
        />
      )}
    </div>
  );
};

export default SideBar;
