import { useParams } from "react-router-dom";

import ComplianceTable from "../complianceTable";
import AddOnBoarding, { TYPE } from "./AddOnBoarding";

export default function Onboarding() {
  const { id } = useParams();

  const columns = [
    {
      field: "type",
      header: "Type",
      type: "select",
      value: TYPE,
    },
    {
      field: "attachment_info",
      header: "Attachment",
      type: "file",
    },
    {
      field: "date",
      header: "Date",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Onboarding"
      columns={columns}
      filter={{
        employee: id,
      }}
      url_path="/onboarding-document/"
      AddComponent={AddOnBoarding}
    />
  );
}
