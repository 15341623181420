import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userTableData:
    localStorage.getItem("userTable") != undefined
      ? JSON.parse(localStorage.getItem("userTable"))
      : {},
  sidebarVisible: false,
  projectsTable:
    localStorage.getItem("projectTable") != undefined
      ? JSON.parse(localStorage.getItem("projectTable"))
      : {},
  subscriptionData: {},
  planAndPricingData: [],
};

export const userTableSlice = createSlice({
  name: "userTable",
  initialState,
  reducers: {
    setUserTableData: (state, action) => {
      state.userTableData = action.payload;
      localStorage.setItem("userTable", JSON.stringify(action.payload));
    },
    setSidebarVisible: (state, action) => {
      state.sidebarVisible = action.payload;
    },
    setProjectsTable: (state, action) => {
      state.projectsTable = action.payload;
      localStorage.setItem("projectTable", JSON.stringify(action.payload));
    },
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    setPlanAndPricingData: (state, action) => {
      state.planAndPricingData = action.payload;
    },
  },
});

export const {
  setUserTableData,
  setSidebarVisible,
  setProjectsTable,
  setSubscriptionData,
  setPlanAndPricingData,
} = userTableSlice.actions;
export const getUserTableData = (state) => state.userTable.userTableData;
export const getSidebarVisible = (state) => state.userTable.sidebarVisible;
export const getProjectTable = (state) => state.userTable.projectsTable;
export const getSubscription = (state) => state.userTable.subscriptionData;
export const getPlanAndPricingData = (state) =>
  state.userTable.planAndPricingData;

export default userTableSlice.reducer;
