import { useNavigate } from "react-router-dom";

import AddFramework from "../../../modules/home/components/compliance/AddFramework";
import ComplianceTable from "../../../modules/home/components/compliance/complianceTable";

export default function Framework() {
  const navigate = useNavigate();

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "description",
      header: "Description",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Framework"
      columns={columns}
      url_path="/framework/"
      AddComponent={AddFramework}
      onRowClick={(data) => {
        navigate(`./${data.id}`, { state: { id: data.id, name: data.name } });
      }}
    />
  );
}
