import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { BsInfoCircle } from "react-icons/bs";
import { ThemeContext } from "../../context/ThemeContext";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
// import lockImage from "../../assets/locksceen/image.jpg";
import lockicon from "../../assets/lockscreen/lock.svg";
import LockedWidget from "../../modules/sidebar/components/LockedWidget";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalBarChart = (props) => {
  const {
    title,
    data,
    labels,
    lockedWidget,
    lockImage,
    tooltip,
    backgroundColors,
    navigateLink,
    onClick = null,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const onClickHandler = (c, i) => {
    if (onClick) onClick(c, i);
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: { color: isDarkMode ? "#fff" : "#111", beginAtZero: true },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? "#fff" : "#111",
          beginAtZero: true,
          stepSize: 5,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (x) => {
            x.dataset.data.map((ydd) => {
              return ydd;
            });
          },
          title: () => {
            return null;
          },
        },
      },
    },
    onClick: onClickHandler,
  };

  const data1 = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors,
        borderRadius: 5,
        barPercentage: 0.4,
        borderSkipped: false,
      },
    ],
  };

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>
          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id={title}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        <div
          className="h-full cursor-pointer"
          style={{ minHeight: "300px" }}
          onClick={() => navigate(navigateLink)}
        >
          {lockedWidget ? (
            <Bar options={options} data={data1} />
          ) : (
            <LockedWidget lockImage={lockImage} lockicon={lockicon} />
          )}
        </div>
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default VerticalBarChart;
