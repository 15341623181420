import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { complianceAPI } from "../../../../Api/CSPM/api";
import HandleResponseError from "../../../../utils/error";
import { Dropdown } from "primereact/dropdown";

export default function AddRequirement({ visible, onHide, currentDetails }) {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [controls, setControls] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/requirement/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Requirement updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/requirement/", { ...data, framework: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Requirement created successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getControls = () => {
    complianceAPI
      .post("/controls/filter/")
      .then(({ data }) => {
        setControls(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getControls();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Requirement`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } requirement`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Category</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Category"
              value={data.category}
              required
              onChange={(e) => {
                setData({ ...data, category: e.target.value });
                setError({ ...error, category: false });
              }}
            />
            {error.category && (
              <p className="text-red-500 text-sm">{error.category}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Code</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter Code"
              value={data.code}
              required
              onChange={(e) => {
                setData({ ...data, code: e.target.value });
                setError({ ...error, code: false });
              }}
            />
            {error.code && <p className="text-red-500 text-sm">{error.code}</p>}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Controls</label>
            <MultiSelect
              className="w-full"
              optionLabel="name"
              optionValue="id"
              filter
              value={data.controls}
              options={controls}
              placeholder="Select Controls"
              onChange={(e) => setData({ ...data, controls: e.value })}
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Scope</label>
            <Dropdown
              value={data.out_of_scope}
              onChange={(e) => setData({ ...data, out_of_scope: e.value })}
              options={[
                {
                  label: "Out of Scope",
                  value: true,
                },
                {
                  label: "In Scope",
                  value: false,
                },
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
