import { useNavigate } from "react-router-dom";
import JustDonut from "./JustDonut";

export default function PassFailDonut({
  title,
  data,
  path,
  state,
  direction = "horizontal",
}) {
  const navigate = useNavigate();
  return (
    <JustDonut
      title={title}
      labels={["Failed", "Passed"]}
      colors={["#E8001D", "#148842"]}
      data={[data?.failure | 0, data?.success | 0]}
      centerText={`${data?.success | 0}/${
        (data?.success | 0) + (data?.failure | 0)
      }`}
      onClick={path ? () => navigate(path, { state }) : null}
      direction={direction}
    />
  );
}
