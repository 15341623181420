import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { recurrence } from "../policy/AddPolicy";
import { category, risk_impact } from "./AddVendor";

export default function Details() {
  const { id } = useParams();

  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState(null);

  const [employee, setEmployee] = useState([]);

  const getData = () => {
    complianceAPI
      .get(`/vendor/${id}`)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
    getEmployee();
  }, []);

  return (
    <div
      className={`rounded-2xl userTable-style  ${
        isDarkMode ? "header-dark" : "header-light white-background"
      }`}
    >
      <p className="font-bold">Vendor Details</p>
      <div className="flex justify-between pt-6">
        <div className="w-full">
          <h1 className="text-sm  font-bold">URL</h1>
          <p className="text-xs font-normal leading-5">{data?.url}</p>
        </div>
      </div>
      <div className="flex justify-between pt-6">
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Assigned To</h1>
          <p className="text-xs font-normal leading-5">
            {data?.assignee
              ? employee.find((v) => v.id === data?.assignee)?.email
              : null}
          </p>
        </div>
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Recurrence</h1>
          <p className="text-xs font-normal leading-5">
            {data?.recurrence
              ? recurrence.find((v) => v.value === data?.recurrence)?.label
              : null}
          </p>
        </div>
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Category</h1>
          <p className="text-xs font-normal leading-5">
            {data?.category
              ? category.find((v) => v.value === data?.category)?.label
              : null}
          </p>
        </div>
      </div>
      <div className="flex justify-between pt-6">
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Inherent Risk</h1>
          <p className="text-xs font-normal leading-5">
            {data?.inherent_risk
              ? risk_impact.find((v) => v.value === data?.inherent_risk)?.label
              : null}
          </p>
        </div>
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Residual Risk</h1>
          <p className="text-xs font-normal leading-5">
            {data?.residual_risk
              ? risk_impact.find((v) => v.value === data?.residual_risk)?.label
              : null}
          </p>
        </div>
        <div className="w-1/3">
          <h1 className="text-sm font-bold">Vendor Tier</h1>
          <p className="text-xs font-normal leading-5">{data?.vendor_tier}</p>
        </div>
      </div>
    </div>
  );
}
