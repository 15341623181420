import { useEffect, useState } from "react";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { complianceAPI } from "../../Api/CSPM/api";
import AddIncidentManagement from "../../modules/home/components/compliance/IncidentManagement/AddIncidentManagement";
import { useSearchParams } from "react-router-dom";
import ViewIncidentManagement from "../../modules/home/components/compliance/IncidentManagement/viewIncidentManagement";

export const STATUS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
];

export default function IncidentManagement() {
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const columns = [
    {
      field: "title",
      header: "Title",
    },
    {
      field: "reported_by",
      header: "Reported By",
      type: "select",
      value: users.map((u) => ({
        label: u.email,
        value: u.id,
      })),
    },
    {
      field: "date_time",
      header: "Date",
      type: "date",
    },
    {
      field: "investigator",
      header: "Investigator",
      type: "select",
      value: users.map((u) => ({
        label: u.email,
        value: u.id,
      })),
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <ComplianceTable
      title="Incident Management"
      columns={columns}
      url_path="/incidents/"
      AddComponent={AddIncidentManagement}
      ViewComponent={ViewIncidentManagement}
      onRowClick="view"
      defaultOpen={id}
      defaultClose={() => {
        if (id) {
          searchParams.delete("id");
          setSearchParams(searchParams);
          window.history.replaceState({}, "", window.location.pathname);
        }
      }}
    />
  );
}
