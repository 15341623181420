import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { useSelector, useDispatch } from "react-redux";
import Card from "./Card";

function AwarenessMaterials({
  setShowCourse,
  setShowLessons,
  setShowQuiz,
  data,
  getData,
}) {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const addHandler = (e) => {
    e.stopPropagation();
    setShowCourse(true);
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#171717]" : "bg-white"
      } p-10 rounded-xl flex flex-col gap-7 border-none`}
    >
      <div className="flex items-end justify-between">
        <h1 className="text-2xl font-bold">Courses</h1>
        {userDetails.role === "Super Admin" || userDetails.role === "Admin" ? (
          <button
            class={`${
              isDarkMode
                ? "text-[white] border-white"
                : "border-[#181818] text-[#111]"
            } border-[1px] rounded-[14px] text-[16px] px-3 h-[32px]`}
            onClick={addHandler}
          >
            Add
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="w-full grid place-items-center grid-cols-2 min-[1200px]:grid-cols-3 min-[1400px]:grid-cols-4 min-[2110px]:grid-cols-5 gap-7">
        {data.map((item, i) => (
          <Card
            key={i}
            id={i}
            item={item}
            setShowCourse={setShowCourse}
            setShowLessons={setShowLessons}
            setShowQuiz={setShowQuiz}
            getData={getData}
          />
        ))}
      </div>
    </div>
  );
}

export default AwarenessMaterials;
