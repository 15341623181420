import React, { useEffect } from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import Carousel from "../../Component/carousel/Carousel";
import { AuthenticateSignupPage } from "../../modules/SignUp";
import AuthSideBar from "../../Component/Header/AuthSideBar";

const AuthenticateSignUp = () => {
  return (
    <div className="lg:h-screen">
      <LoginHeader
        title=""
        subTitle=""
        navigate=""
      />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
        <AuthenticateSignupPage />
        </div>
      </div>
      <hr />
      <div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default AuthenticateSignUp;
