import React,{useState} from 'react'
import TechnologiesIntelligence from './TechnologiesIntelligence';

const Technologies = (props) => {
    const { title } = props;

  return (
    <div className="ssl-status ">
    <h1 className="font-bold">
      {title}
    </h1>

    <div className="ssl-active">
     <TechnologiesIntelligence title = 'Technologies'/>
    </div>
    
  </div>
  )
}

export default Technologies