import React, { useState, useRef } from "react";
import SSLTables from "../../../../../common/Tables/SSLTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";
import { sslTableData } from "../../../../../constants";

const SSLExpireSoonTable = (props) => {
  const {
    loadingTable,
    sslExpireSoonTableData,
    sslExpireSoonFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const columns = [
    { field: "host", header: "Host", value: sslExpireSoonFilterData?.host },
    {
      field: "expiry_date",
      header: "Expiry Date",
      value: sslExpireSoonFilterData?.expiry_date,
      type: "date",
    },
    {
      field: "provider",
      header: "SSL Provider",
      value: sslExpireSoonFilterData?.provider,
    },
  ];
  return (
    <>
      <TableFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        setFilterParams={setFilterParams}
        dt={dt}
        filterParams={filterParams}
        columns={columns}
        tableData={sslExpireSoonTableData}
        tableHeader={columns}
      />
      {loadingTable ? (
        <SkeletonDataTable columns={columns} />
      ) : (
        <SSLTables
          userInformation={sslExpireSoonTableData}
          columns={columns}
          dt={dt}
          globalFilter={globalFilter}
        />
      )}
    </>
  );
};

export default SSLExpireSoonTable;
