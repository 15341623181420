import React, { useRef, useState } from "react";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../../../../utils/paginationTemplate";

const LiveHosts = (props) => {
  const {
    liveHostTableData,
    isDarkMode,
    loadingTable,
    liveHostFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedLiveHost, setSelectedLiveHost] = useState(null);

  const dt = useRef(null);
  const columns = [
    // { field: "host", header: "Hosts", value: liveHostFilterData?.host },
    { field: "ip", header: "IP Address", value: liveHostFilterData?.ip },
    { field: "url", header: "URL", value: liveHostFilterData?.url },
    {
      field: "status_code",
      header: "Status",
      value: liveHostFilterData?.status_code,
    },
    { field: "hosting_provider", header: "Hosting Provider" },
    {
      field: "country",
      header: "Hosted Country",
      value: liveHostFilterData?.country,
    },
    { field: "tags", header: "Tags" },
  ];

  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          dt={dt}
          columns={columns}
          tableData={liveHostTableData}
          tableHeader={columns}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DataTable
            removableSort
            selection={selectedLiveHost}
            onSelectionChange={(e) => setSelectedLiveHost(e.value)}
            ref={dt}
            value={liveHostTableData}
            dataKey="id"
            paginator
            paginatorTemplate={paginationTemplate()}
            rows={10}
            globalFilter={globalFilter}
            className={`${
              isDarkMode ? "dark-mode text-white" : "text-color"
            } text-xs`}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            {/* <Column field="host" header="Hosts" sortable></Column> */}
            <Column field="ip" header="IP Address" sortable></Column>
            <Column
              field="url"
              header="URL"
              body={(rowData) => renderLink(rowData.url)}
              sortable
            ></Column>
            <Column field="status_code" header="Status" sortable></Column>
            <Column
              field="hosting_provider"
              header="Hosting Provider"
              sortable
            ></Column>
            <Column field="country" header="Hosted Country" sortable></Column>
            <Column field="tags" header="Tags" sortable></Column>
            <Column
              header="Actions"
              body={actionBodyTemplate}
              exportable={false}
            />
          </DataTable>
        )}
      </div>
    </>
  );
};

export default LiveHosts;
