export const SEVERITY_TEXT = {
  0: "Unknown",
  1: "Informational",
  2: "Low",
  3: "Medium",
  4: "High",
  5: "Critical",
  6: "Fatal",
  99: "Other",
};

export const SEVERITY_COLOR = {
  Unknown: "text-gray-500",
  Informational: "text-[#7B7DE2]",
  Low: "text-[#148842]",
  Medium: "text-[#F5B207]",
  High: "text-[#E8001D]",
  Critical: "text-[#A30123]",
  Fatal: "text-[#A30123]",
  Other: "text-gray-500",
};

export const STATUS_TEXT = {
  0: "Unknown",
  1: "Success",
  2: "Failure",
  99: "Other",
};

export const STATUS_COLOR = {
  Unknown: "text-[#F5B207]",
  Success: "text-[#148842]",
  Failure: "text-[#E8001D]",
  Other: "text-[#F5B207]",
};
