import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { ProgressBar } from "primereact/progressbar";

const ProgressChart = (props) => {
  const { title, value, secure } = props;
  useEffect(() => {}, [value]);

  console.log("ASASFASvalue", value);

  const [options] = useState({
    chart: {
      height: 70,
      type: "bar",
      stacked: true,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "20%",
        borderRadius: "5%",
        colors: {
          backgroundBarColors: ["#F2F2F2"],
          ranges: [
            {
              from: 0,
              to: value,
              color: "#F14D34",
            },
          ],
        },
      },
    },
    stroke: {
      width: 0,
    },
    series: [
      {
        name: title,
        data: [value],
      },
    ],
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: [title],
    },
    yaxis: {
      max: 100,
    },
  });

  return (
    <div className="flex w-full justify-evenly items-center gap-2">
      <div className="w-1/4 text-left">
        <h1 className="text-sm leading-normal font-normal">{title}</h1>
      </div>
      <div className="flex-1">
        {/* <ApexCharts
          options={options}
          series={options.series}
          type="bar"
          height={20}
        /> */}
        <div className={`${secure ? "progressBarSecure-Style" : "progressBarSubDomain-Style"}`}>
          <ProgressBar id="progressBar" value={value}></ProgressBar>
        </div>
      </div>
      <div className="w-[20%] text-right">
        <p className="text-sm leading-normal font-normal">{`${Number(
          value
        )}%`}</p>
      </div>
    </div>
  );
};

export default ProgressChart;
