import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import AddEntities from "../../modules/home/components/compliance/AddEntities";

export default function Entities() {
  const columns = [
    { field: "name", header: "Name" },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Entities"
      columns={columns}
      url_path="/entities/"
      AddComponent={AddEntities}
    />
  );
}
