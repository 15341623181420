import React, { useState } from "react";
import { SearchIcon } from "../../../../assets/svg";
import Chip from "../../../../common/Chip";
import { useDispatch } from "react-redux";
import {
  setSearchKeywordGithub,
  setSearchKeywordDarkweb,
  setSearchKeywordCloud,
  setSearchKeywordTwitter,
} from "../../../../redux-toolkit/redux/Sidebar";
import TableApi from "../../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";
import { setUserDetails } from "../../../../redux-toolkit/redux/auth";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { getScanDateTime } from "../../../../redux-toolkit/redux/Sidebar";

const InitialScanForm = (props) => {
  const {
    handleCloseSideBar,
    setActiveScanForm,
    getProjectTableData,
    type,
    selectedOption,
  } = props;
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserDetails);
  const scanDateTime = useSelector(getScanDateTime);
  const [inputFocus, setInputFocus] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchDarkwebKeyword, setSearchDarkwebKeyword] = useState("");
  const [searchCloudKeyword, setSearchCloudKeyword] = useState("");
  const [searchTwitterKeyword, setSearchTwitterKeyword] = useState("");
  const [searchFakeAppKeyword, setSearchFakeAppKeyword] = useState("");
  const [searchImpersonationKeyword, setSearchImpersonationKeyword] =
    useState("");
  const [searchDataBreachKeyword, setSearchDataBreachKeyword] = useState("");
  const [activeGit, setActiveGit] = useState(false);
  const [activeDark, setActiveDark] = useState(false);
  const [activeCloud, setActiveCloud] = useState(false);
  const [activeTwitter, setActiveTwitter] = useState(false);
  const [activeFakeApp, setActiveFakeApp] = useState(false);
  const [activeImpersonation, setActiveImpersonation] = useState(false);
  const [acitveDataBreach, setActiveDataBreach] = useState(false);
  const [keywordCount, setKeywordCount] = useState();
  const [totalKeywordCount, setTotalKeywordCount] = useState([]);
  const [disableField, setDisableField] = useState(false);
  // const [totalKeywordCounts, setTotalKeywordCounts] = useState([]);

  // const initialKeywords = ["endpoints", "domain", "attack", "GitHub", "target", "applications", "vulnerability"];
  const [keywords, setKeywords] = useState([]);
  const [darkwebKeyword, setDarkwebKeyword] = useState([]);
  const [cloudKeyword, setCloudKeyword] = useState([]);
  const [twitterKeyword, setTwitterKeyword] = useState([]);
  const [fakeAppKeyword, setFakeAppKeyword] = useState([]);
  const [impersonationKeyword, setImpersonationKeyword] = useState([]);
  const [dataBreachKeyword, setDataBreachKeyword] = useState([]);
  const [disable, setDisable] = useState(false);

  const getStartScan = (startScanId) => {
    const payload = {
      scan_id: startScanId,
    };
    TableApi.startScanWeb(payload).then((data) => {
      // if(data.status === "success"){
      // setTimeout(() => {
      //   if (selectedOption !== "drp") toast.success(data.message);
      // }, 1000);
      // // }
      if (data.status === "success") {
        setTimeout(() => {
          toast.success(data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error(data.message);
        }, 1000);
      }
    });
  };

  const targetID = localStorage.getItem("targetID");

  useEffect(() => {
    TableApi.initiateScanKeywords().then((data) => {
      if (data.status === "success") {
        if (data.status === "success") {
          setActiveGit(data.data.github);
          setActiveDark(data.data.darkweb);
          setActiveCloud(data.data.cloud);
          setActiveTwitter(data.data.social);
          setActiveFakeApp(data.data.fakeapp);
          setActiveImpersonation(data.data.impersonation);
          setActiveDataBreach(data.data.databreach);
          setKeywordCount(data.data.keywords);
        }
      }
    });
    TableApi.getTargetKeywords(targetID)
      .then(({ data }) => {
        if (data.cloud) {
          setCloudKeyword(data.cloud.split(","));
        }
        if (data.darkweb) {
          setDarkwebKeyword(data.darkweb.split(","));
        }
        if (data.github) {
          setKeywords(data.github.split(","));
        }
        if (data.twitter) {
          setTwitterKeyword(data.twitter.split(","));
        }
        if (data.fakeapp) {
          setFakeAppKeyword(data.fakeapp.split(","));
        }
        if (data.impersonation) {
          setImpersonationKeyword(data.impersonation.split(","));
        }
        if (data.databreach) {
          setDataBreachKeyword(data.databreach.split(","));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userDetails]);

  const handleSubmit = (e) => {
    setDisable(true);
    e.preventDefault();
    const totalCount =
      keywords.length +
      darkwebKeyword.length +
      cloudKeyword.length +
      twitterKeyword.length +
      fakeAppKeyword.length +
      impersonationKeyword.length +
      dataBreachKeyword.length;
    console.log("Keyword", totalCount, keywordCount, type);
    dispatch(setSearchKeywordGithub(keywords.toString()));
    dispatch(setSearchKeywordDarkweb(darkwebKeyword.toString()));
    dispatch(setSearchKeywordCloud(cloudKeyword.toString()));
    dispatch(setSearchKeywordTwitter(twitterKeyword.toString()));
    const targetID = localStorage.getItem("targetID");
    const selectedSubDomains = JSON.parse(
      localStorage.getItem("selectedSubDomains")
    )
      ? JSON.parse(localStorage.getItem("selectedSubDomains"))
      : [];
    if (type === "initiate-scan") {
      const payload = {
        target: targetID,
        scan_type: selectedOption,
        scan_darkweb_keywords: darkwebKeyword.toString(),
        scan_twitter_keywords: twitterKeyword.toString(),
        scan_github_keywords: keywords.toString(),
        scan_cloud_keywords: cloudKeyword.toString(),
        scan_fakeapp_keywords: fakeAppKeyword.toString(),
        scan_impersonation_keywords: impersonationKeyword.toString(),
        scan_databreach_keywords: dataBreachKeyword.toString(),
        subdomains: selectedSubDomains,
      };
      console.log(payload);
      if (totalKeywordCount.length > keywordCount) {
        toast.warning("SearchKeyword Limits Over");
      } else {
        TableApi.initiateScanWeb(payload).then((data) => {
          if (data.status === "success") {
            let userData = { ...userDetails };
            const id = data.scan_id;
            const unique_id = data.unique_id;
            if (selectedOption === "full") {
              userData.scan_id = id;
              userData.unique_id = unique_id;
              userData.drp_scan_id = id;
              userData.drp_unique_id = unique_id;
            } else if (selectedOption === "drp") {
              userData.drp_scan_id = id;
              userData.drp_unique_id = unique_id;
            } else if (selectedOption === "cloud_intel") {
              userData.scan_id = id;
              userData.unique_id = unique_id;
            } else if (selectedOption === "discovery") {
              userData.scan_id = id;
              userData.unique_id = unique_id;
            } else if (selectedOption === "brand_threat") {
              userData.brand_scan_id = id;
              userData.brand_unique_id = unique_id;
            }
            dispatch(setUserDetails(userData));
            getProjectTableData();
            localStorage.removeItem("selectedSubDomain");
            localStorage.removeItem("selectedSubDomains");
            handleCloseSideBar();
            setDisable(false);
            setTimeout(() => {
              // if (selectedOption === "drp") {
              //   toast.success(
              //     "DRP Keywords Added Successfully, Scan results will be available in 24 hours"
              //   );
              // } else {
              toast.success(data.message);
              getStartScan(data.scan_id);
              // }
            }, 1000);
          } else {
            localStorage.removeItem("selectedSubDomain");
            localStorage.removeItem("selectedSubDomains");
            toast.error(data.message);
            setDisable(false);
          }
        });
      }
    } else if (type === "schedule-scan") {
      const date_1 = scanDateTime.firstScanningDate?.split(".");
      const time_1 = scanDateTime.firstScanningTime?.split(":");
      const date_2 = scanDateTime.secondScanningDate?.split(".");
      const time_2 = scanDateTime.secondScanningTime?.split(":");
      console.log(date_1, date_2, time_1, time_2);
      const payload = {
        target: targetID,
        scan_type: selectedOption,
        scan_darkweb_keywords: darkwebKeyword.toString(),
        scan_twitter_keywords: twitterKeyword.toString(),
        scan_github_keywords: keywords.toString(),
        scan_cloud_keywords: cloudKeyword.toString(),
        scan_fakeapp_keywords: fakeAppKeyword.toString(),
        scan_impersonation_keywords: impersonationKeyword.toString(),
        scan_databreach_keywords: dataBreachKeyword.toString(),
        subdomains: selectedSubDomains,
        first_scanning_date: date_1
          ? new Date(
              date_1[2],
              parseInt(date_1[0]) - 1,
              date_1[1],
              time_1?.[0] | 0,
              time_1?.[1] | 0
            ).toISOString()
          : null,
        second_scanning_date: date_2
          ? new Date(
              date_2[2],
              parseInt(date_2[0]) - 1,
              date_2[1],
              time_2?.[0] | 0,
              time_2?.[1] | 0
            ).toISOString()
          : null,
      };
      if (totalKeywordCount.length > keywordCount) {
        toast.warning("SearchKeyword Limits Over");
      } else {
        TableApi.scheduleScanWeb(payload).then((data) => {
          if (data.status === "success") {
            let userData = { ...userDetails };
            const id = data.scan_id;
            const unique_id = data.unique_id;
            userData.scan_id = id;
            userData.unique_id = unique_id;
            dispatch(setUserDetails(userData));
            getProjectTableData();
            localStorage.removeItem("selectedSubDomain");
            localStorage.removeItem("selectedSubDomains");
            handleCloseSideBar();
            setDisable(false);
            setTimeout(() => {
              toast.success(data.message);
            }, 1000);
          } else {
            localStorage.removeItem("selectedSubDomain");
            localStorage.removeItem("selectedSubDomains");
            toast.error(data.message);
            setDisable(false);
          }
        });
      }
    }
  };

  const handleEnterKeyPress = (e, type) => {
    if (
      type === "searchKey" &&
      e.key === "Enter" &&
      searchKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchKeyword) {
        setKeywords([...keywords, searchKeyword.trim()]);
        setTotalKeywordCount([...totalKeywordCount, searchKeyword.trim()]);
        setSearchKeyword("");
      }
    }

    if (
      type === "searchDarkWeb" &&
      e.key === "Enter" &&
      searchDarkwebKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchDarkwebKeyword) {
        setDarkwebKeyword([...darkwebKeyword, searchDarkwebKeyword.trim()]);
        setTotalKeywordCount([
          ...totalKeywordCount,
          searchDarkwebKeyword.trim(),
        ]);
        setSearchDarkwebKeyword("");
      }
    }
    if (
      type === "searchCloud" &&
      e.key === "Enter" &&
      searchCloudKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchCloudKeyword) {
        setCloudKeyword([...cloudKeyword, searchCloudKeyword.trim()]);
        setTotalKeywordCount([...totalKeywordCount, searchCloudKeyword.trim()]);
        setSearchCloudKeyword("");
      }
    }

    if (
      type === "searchTwitter" &&
      e.key === "Enter" &&
      searchTwitterKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchTwitterKeyword) {
        setTwitterKeyword([...twitterKeyword, searchTwitterKeyword.trim()]);
        setTotalKeywordCount([
          ...totalKeywordCount,
          searchTwitterKeyword.trim(),
        ]);
        setSearchTwitterKeyword("");
      }
    }
    if (
      type === "searchFakeApp" &&
      e.key === "Enter" &&
      searchFakeAppKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchFakeAppKeyword) {
        setFakeAppKeyword([...fakeAppKeyword, searchFakeAppKeyword.trim()]);
        setTotalKeywordCount([
          ...totalKeywordCount,
          searchFakeAppKeyword.trim(),
        ]);
        setSearchFakeAppKeyword("");
      }
    }
    if (
      type === "searchImpersonation" &&
      e.key === "Enter" &&
      searchImpersonationKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchImpersonationKeyword) {
        setImpersonationKeyword([
          ...impersonationKeyword,
          searchImpersonationKeyword.trim(),
        ]);
        setTotalKeywordCount([
          ...totalKeywordCount,
          searchImpersonationKeyword.trim(),
        ]);
        setSearchImpersonationKeyword("");
      }
    }
    if (
      type === "searchDataBreach" &&
      e.key === "Enter" &&
      searchDataBreachKeyword.trim() !== ""
    ) {
      e.preventDefault();
      if (searchDataBreachKeyword) {
        setDataBreachKeyword([
          ...dataBreachKeyword,
          searchDataBreachKeyword.trim(),
        ]);
        setTotalKeywordCount([
          ...totalKeywordCount,
          searchDataBreachKeyword.trim(),
        ]);
        setSearchDataBreachKeyword("");
      }
    }
  };

  useEffect(() => {
    if (totalKeywordCount) {
      if (totalKeywordCount.length === keywordCount) {
        setDisableField(true);
        toast.warning("SearchKeyword Limits Over");
      } else {
        setDisableField(false);
      }
    }
  }, [keywords, darkwebKeyword, cloudKeyword, twitterKeyword, fakeAppKeyword]);

  const handleDeleteKeyWord = (keywordToDelete, type) => {
    if (type === "searchKey") {
      const updatedKeywords = keywords.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setKeywords(updatedKeywords);
      setTotalKeywordCount([...totalKeywordCount, ...updatedKeywords]);
    }
    if (type === "searchDarkWeb") {
      const updatedKeywords = darkwebKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setDarkwebKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
    if (type === "searchCloud") {
      const updatedKeywords = cloudKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setCloudKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
    if (type === "searchTwitter") {
      const updatedKeywords = twitterKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setTwitterKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
    if (type === "searchFakeApp") {
      const updatedKeywords = fakeAppKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setFakeAppKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
    if (type === "searchImpersonation") {
      const updatedKeywords = impersonationKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setImpersonationKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
    if (type === "searchDataBreach") {
      const updatedKeywords = dataBreachKeyword.filter(
        (keyword) => keyword !== keywordToDelete
      );
      const updatedTotalKeywords = totalKeywordCount.filter(
        (keyword) => keyword !== keywordToDelete
      );
      setDataBreachKeyword(updatedKeywords);
      setTotalKeywordCount([...updatedTotalKeywords]);
    }
  };

  return (
    <div>
      <form className="mt-8" onSubmit={handleSubmit}>
        {activeCloud &&
          (selectedOption === "cloud_intel" ||
            selectedOption === "full" ||
            selectedOption === "default_scan") && (
            <>
              <div className="w-full relative">
                <label className="block text-color text-sm pb-1">
                  Keywords (Cloud)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchCloudKeyword}
                    disabled={disableField}
                    onChange={(e) => setSearchCloudKeyword(e.target.value)}
                    onKeyDown={(e) => handleEnterKeyPress(e, "searchCloud")}
                    className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {cloudKeyword.map((keyword, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyword}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyword, "searchCloud")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {activeDark &&
          (selectedOption === "drp" || selectedOption === "full") && (
            <>
              <div className="w-full relative pt-5">
                <label className="block text-color text-sm pb-1">
                  Keywords (Darkweb)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchDarkwebKeyword}
                    disabled={disableField}
                    onChange={(e) => setSearchDarkwebKeyword(e.target.value)}
                    onKeyDown={(e) => handleEnterKeyPress(e, "searchDarkWeb")}
                    className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {darkwebKeyword.map((keyworddark, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyworddark}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyworddark, "searchDarkWeb")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {activeGit &&
          (selectedOption === "drp" || selectedOption === "full") && (
            <>
              <div className="w-full relative pt-5">
                <label className="block text-color text-sm pb-1">
                  Keywords (Secrets Leak)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => handleEnterKeyPress(e, "searchKey")}
                    className="w-full ml-3 focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {keywords.map((keyword, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyword}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyword, "searchKey")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {activeTwitter &&
          (selectedOption === "drp" ||
            selectedOption === "full" ||
            selectedOption === "brand_threat") && (
            <>
              <div className="w-full relative pt-5">
                <label className="block text-color text-sm pb-1">
                  Keywords (Social)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchTwitterKeyword}
                    disabled={disableField}
                    onChange={(e) => setSearchTwitterKeyword(e.target.value)}
                    onKeyDown={(e) => handleEnterKeyPress(e, "searchTwitter")}
                    className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {twitterKeyword.map((keyword, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyword}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyword, "searchTwitter")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {activeFakeApp &&
          (selectedOption === "drp" || selectedOption === "full") && (
            <>
              <div className="w-full relative pt-5">
                <label className="block text-color text-sm pb-1">
                  Keywords (Fakeapps)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchFakeAppKeyword}
                    disabled={disableField}
                    onChange={(e) => setSearchFakeAppKeyword(e.target.value)}
                    onKeyDown={(e) => handleEnterKeyPress(e, "searchFakeApp")}
                    className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {fakeAppKeyword.map((keyword, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyword}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyword, "searchFakeApp")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {activeImpersonation &&
          (selectedOption === "drp" ||
            selectedOption === "full" ||
            selectedOption === "brand_threat") && (
            <>
              <div className="w-full relative pt-5">
                <label className="block text-color text-sm pb-1">
                  Keywords (Impersonation)
                </label>
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchImpersonationKeyword}
                    disabled={disableField}
                    onChange={(e) =>
                      setSearchImpersonationKeyword(e.target.value)
                    }
                    onKeyDown={(e) =>
                      handleEnterKeyPress(e, "searchImpersonation")
                    }
                    className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-wrap flex-center">
                  {impersonationKeyword.map((keyword, index) => (
                    <div key={index} className="mx-2 pt-3">
                      <Chip
                        keyword={keyword}
                        onDeleteKeyword={() =>
                          handleDeleteKeyWord(keyword, "searchImpersonation")
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

        {/* {acitveDataBreach &&
          (selectedOption === "drp" || selectedOption === "full") && (
            <>
            <div className="w-full relative pt-5">
              <label className="block text-color text-sm pb-1">
                Keywords (Data Breach)
              </label>
              <div
                className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE]
          ${inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
          `}
              >
                <img
                  src={SearchIcon}
                  alt="search"
                  className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                />
                <input
                  type="text"
                  placeholder="search"
                  value={searchDataBreachKeyword}
                  disabled={disableField}
                  onChange={(e) => setSearchDataBreachKeyword(e.target.value)}
                  onKeyDown={(e) => handleEnterKeyPress(e, "searchDataBreach")}
                  className="focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color w-full ml-3 "
                />
              </div>
            </div>
              <div>
          <div className="flex flex-wrap flex-center">
            {dataBreachKeyword.map((keyword, index) => (
              <div key={index} className="mx-2 pt-3">
                <Chip
                  keyword={keyword}
                  onDeleteKeyword={() =>
                    handleDeleteKeyWord(keyword, "searchDataBreach")
                  }
                />
              </div>
            ))}
          </div>
        </div>
        </>
          )} */}

        <div className=" flex w-full pt-5">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            disabled={disable}
          >
            Continue
          </button>
          <button
            className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
            type="button"
            onClick={() => {
              if (
                selectedOption === "drp" ||
                selectedOption === "cloud_intel"
              ) {
                setActiveScanForm((prev) => prev - 2);
              } else {
                setActiveScanForm((prev) => prev - 1);
              }
            }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default InitialScanForm;
