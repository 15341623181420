import { ProgressBar } from "primereact/progressbar";

export default function RiskListing({ data }) {
  return (
    <div className="flex flex-col gap-5 py-2">
      {data.length ? (
        data?.map((item) => (
          <div
            key={item.name}
            className="flex flex-row justify-between items-center gap-1 p-1"
          >
            <p
              className={`w-5/12 ${item.onClick ? "cursor-pointer" : ""}`}
              onClick={item.onClick}
              title={item.name}
            >
              {item.name}
            </p>
            <div className="progressBarSecure-Style2 w-7/12 flex flex-col gap-3">
              <ProgressBar
                id="pass"
                zIndex={-1}
                value={
                  ((item.data?.success /
                    ((item.data?.success | 0) + (item.data?.failure | 0))) *
                    100) |
                  0
                }
                color="#148842"
                title={`Passed: ${item.data?.success | 0}`}
              ></ProgressBar>
              <ProgressBar
                id="fail"
                zIndex={-1}
                value={
                  ((item.data?.failure /
                    ((item.data?.success | 0) + (item.data?.failure | 0))) *
                    100) |
                  0
                }
                color="#E8001D"
                title={`Failed: ${item.data?.failure | 0}`}
              ></ProgressBar>
            </div>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}
