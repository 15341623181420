import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ProgressChart from "./ProgressChart";
import { useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { data } from "autoprefixer";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VocBarChart = (props) => {
  const { title, categories, data, value, tooltip } = props;
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    console.log("ASASFAScategories", categories, value);
  }, [value]);

  //   const chartData = {
  //     series: [
  //       {
  //         name: title,
  //         data: data,
  //       },
  //     ],
  //     options: {
  //       chart: {
  //         type: "bar",
  //         height: 10,
  //         toolbar: {
  //           show: false,
  //         },
  //       },
  //       dataLabels: {
  //         enabled: true,
  //         // offsetX: -115,

  //         textAnchor: 'start',
  //         style: {
  //           fontSize: '12px',
  //           colors: ['#fff'],
  //         //  opacity:0.1,

  //         },
  //         dropShadow: {
  //             enabled: true,
  //             textAnchor: 'start',
  //             top: 0,
  //             left: 80,
  //             blur: 0,
  //             color: '#111',
  //             opacity: 1
  //         }
  //       },

  //     //   stroke: {
  //     //     show: true,
  //     //     width: 1,
  //     //     colors: ['#fff']
  //     //   },
  //       plotOptions: {
  //         bar: {
  //           borderRadius: 5,
  //           horizontal: true,
  //           barHeight: 10,
  //           colors: {
  //             backgroundBarColors: ["#F2F4F6"],
  //           },
  //         },
  //       },
  //     //   dataLabels: {
  //     //     enabled: false,
  //     //   },
  //       grid: {
  //         show: false,
  //       },
  //       xaxis: {
  //         categories: categories,
  //         axisBorder: {
  //           color: "transparent",
  //         },
  //         labels: {
  //           style: {
  //             colors: isDarkMode ? "#fff" : "#111",
  //             fontSize: "0.875rem",
  //           },
  //         },
  //       },
  //       colors: ["#f14d34"],
  //       yaxis: {
  //         show: true,
  //         labels: {
  //           style: {
  //             colors: isDarkMode ? "#fff" : "#111",
  //             fontSize: "0.875rem",
  //           },
  //         },
  //       },
  //     //   tooltip: {
  //     //     shared: true,
  //     //     intersect: false
  //     //   },
  //     //   tooltip: {
  //     //     theme: "dark",
  //     //   },
  //     },
  //   };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? "#fff" : "#111",
          align: "start",
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? "#fff" : "#111",
          beginAtZero: true,
          crossAlign: "far",
        },
      },
    },
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: (x) => {
          x.dataset.data.map((ydd) => {
            return ydd;
          });
        },
        title: () => {
          return null;
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  const labels = categories;

  const data1 = {
    labels,
    datasets: [
      {
        data: data,
        borderRadius: 5,
        barPercentage: 0.4,
        borderWidth: 10,
        borderColor: "#f14d34",
        backgroundColor: "#f14d34",
        borderSkipped: false,
      },
    ],
  };

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly mt-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>
          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id={title}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        <div className="px-3 flex items-center justify-center">
          <div className="voc-barchart">
            <Bar options={options} data={data1} />
          </div>

          <div className="voc-barchart-text">
            <div className="critical">
              <p className="text-sm font-bold">{value?.critical}</p>
            </div>
            <div className="high">
              <p className="text-sm font-bold">{value?.high}</p>
            </div>
            <div className="medium">
              <p className="text-sm font-bold">{value?.medium}</p>
            </div>
            <div className="low">
              <p className="text-sm font-bold">{value?.low}</p>
            </div>

            {/* <p className="text-sm font-bold">12.3</p>
<p className="text-sm font-bold">12.3</p>
<p className="text-sm font-bold">12.3</p>
<p className="text-sm font-bold">12.3</p> */}
          </div>
        </div>
        {/* <div className="voc-barchart">
          <Bar options={options} data={data1} />
        </div> */}
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default VocBarChart;
