export default function HorizontalBar({
  title,
  data = {
    success: 0,
    failure: 0,
    unknown: 0,
  },
  onClick = null,
  type = null,
}) {
  const { success = 0, failure = 0, unknown = 0 } = data;
  const total = success + failure + unknown;
  const pass = ((success / total) * 100) | 0;
  const fail = ((failure / total) * 100) | 0;
  const unkn = ((unknown / total) * 100) | 0;

  return (
    <div
      className="flex flex-col gap-3 items-center cursor-pointer"
      onClick={onClick}
    >
      <div className="grid grid-cols-1 gap-5">
        {type === "bricks" ? (
          <>
            <div className="flex gap-1 w-[150px]">
              {Array.from({ length: 12 }).map((_, index) => {
                const color = index < pass / 8 ? "#008C1B" : "#E4E4E4";
                return (
                  <div
                    className="h-5 w-2.5"
                    style={{ backgroundColor: color }}
                  ></div>
                );
              })}
            </div>
            <div className="flex gap-1 w-[150px]">
              {Array.from({ length: 12 }).map((_, index) => {
                const color = index < fail / 8 ? "#D11305" : "#E4E4E4";
                return (
                  <div
                    className="h-5 w-2.5"
                    style={{ backgroundColor: color }}
                  ></div>
                );
              })}
            </div>
            <div className="flex gap-1 w-[150px]">
              {Array.from({ length: 12 }).map((_, index) => {
                const color = index < unkn / 8 ? "#FDCA3B" : "#E4E4E4";
                return (
                  <div
                    className="h-5 w-2.5"
                    style={{ backgroundColor: color }}
                  ></div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="h-5 w-[150px] bg-[#E4E4E4] rounded-md">
              <div
                className="h-5 bg-[#008C1B] rounded-md"
                style={{ width: `${pass}%` }}
              ></div>
            </div>
            <div className="h-5 w-[150px] bg-[#E4E4E4] rounded-md">
              <div
                className="h-5 bg-[#D11305] rounded-md"
                style={{ width: `${fail}%` }}
              ></div>
            </div>
            <div className="h-5 w-[150px] bg-[#E4E4E4] rounded-md">
              <div
                className="h-5 bg-[#FDCA3B] rounded-md"
                style={{ width: `${unkn}%` }}
              ></div>
            </div>
          </>
        )}
      </div>
      <h1 className="font-semibold text-center">{title}</h1>
      <div className="grid justify-center gap-1">
        <div className="flex items-center gap-2">
          <div className="w-4 h-2 rounded-full bg-[#008C1B]"></div>
          Pass ({success})
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-2 rounded-full bg-[#D11305]"></div>
          Fail ({failure})
        </div>
        <div className="flex items-center  gap-2">
          <div className="w-4 h-2 rounded-full bg-[#FDCA3B]"></div>
          Unknown ({unknown})
        </div>
      </div>
    </div>
  );
}
