import React, { useContext, useEffect } from "react";
import { Header } from "../../Component/Header";
import SideBar from "../../Component/sideBar/SideBar";
import { DashBoard } from "../../modules/home/components";
import { ThemeContext } from "../../context/ThemeContext";
import { getLoggedCheck, neutralizeBack } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedInValue } from "../../redux-toolkit/redux/auth";
import DashboardProfile from "../../modules/home/components/DashboardProfile";
import GenericLayout from "../../common/layout/GenericLayout";

const MyProfile = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (getLoggedCheck()) {
      neutralizeBack(window);
      const user = localStorage.getItem("token");
      if (user) {
        const decodedJwt = parseJwt(user);
        if (decodedJwt.exp * 5000 < Date.now()) {
          dispatch(setLoggedInValue(false));
          localStorage.removeItem("token");
          navigate("/login");
        }
      }else{
        navigate("/login");
      }
    }
  }, []);

  return (
    <div>
      <GenericLayout>
        <DashBoard />
      </GenericLayout>
    </div>
  );
};

export default MyProfile;
