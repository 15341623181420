import baseAPI, { baseUrl } from "../Repository.js"; //just axios instance
import { removeAuthenticationData } from "../../utils/constants";

class DRPAPI {
  constructor() {
    this.baseUrl = baseUrl;
    this.endpoint = this.baseUrl + "ultraheals/v1/drp";
  }

  get(path, params) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .get(url, { params })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }
}

class DataBreachAPI {
  constructor() {
    this.baseUrl = baseUrl;
    this.endpoint = this.baseUrl + "ultraheals/v1/web/data-breach/";
  }

  get(path, params) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .get(url, { params })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  post(path, params) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .post(url, params)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }
}

const drpAPI = new DRPAPI();
const dataBreachAPI = new DataBreachAPI();

export default drpAPI;
export { dataBreachAPI };
