import React from "react";
import SkeletonBasic from "../../SkeletonAnimation/SkeletonPlanCard";
import SkeletonWebText from "../../SkeletonAnimation/SkeletonWebText";

const DarkWebInformation = (props) => {
  const { sideBarVocIntelligenceDetail } = props;

  const getTextColor = (severity) => {
    console.log(severity);
    if (severity === "Medium") {
      return (
        <p className="text-xs font-normal leading-5 text-[#F5B207]">Medium</p>
      );
    } else if (severity === "Critical") {
      return (
        <p className="text-[#A30123] text-xs font-normal leading-5">Critical</p>
      );
    } else if (severity === "High") {
      return (
        <p className="text-[#E8001D] text-xs font-normal leading-5">High</p>
      );
    } else if (severity === "Low") {
      return (
        <p className="text-[#148842] text-xs font-normal leading-5">Low</p>
      );
    } else {
      return (
        <p className="text-[#7B7DE2] text-xs font-normal leading-5">Info</p>
      );
    }
  };

  return (
    <>
      {/* {loadingSidebarVocDetail ? (
        <SkeletonWebText />
      ) : ( */}
      <div className="px-6">
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Keywords</h1>
            <p className="text-xs text-color font-normal leading-5">
              {sideBarVocIntelligenceDetail?.keyword}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">URL</h1>
            <p className="text-xs text-color font-normal leading-5">
            {sideBarVocIntelligenceDetail?.url || sideBarVocIntelligenceDetail?.channel}
            </p>
          </div>
        </div>
        <div className="flex  justify-between gap-2 pt-6">
          <div className="w-1/2">
            <div>
              <h1 className="text-sm text-color font-bold">Severity</h1>
              {getTextColor(sideBarVocIntelligenceDetail?.severity || "info")}
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Date & Time</h1>
            <p className="text-xs text-color font-normal leading-5">
              {sideBarVocIntelligenceDetail?.date_added}
            </p>
          </div>
        </div>

        <div className="flex  justify-between gap-2 pt-6">
          <div className="w-1/1">
            <div>
              <h1 className="text-sm text-color font-bold">
                Threat Classification
              </h1>
              {sideBarVocIntelligenceDetail?.threat || sideBarVocIntelligenceDetail?.label}
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-2 pt-6">
          <div className="w-1/1">
            <h1 className="text-sm text-color font-bold">Content</h1>
            <p className="text-xs text-color font-normal leading-5">
              {sideBarVocIntelligenceDetail?.content ||
                sideBarVocIntelligenceDetail?.message}
            </p>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default DarkWebInformation;
