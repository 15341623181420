import React from "react";
import slider1 from "../../assets/Images/carouselimage1.png";
import slider2 from "../../assets/Images/carouselimage2.png";
import slider3 from "../../assets/Images/carouselimage3.png";
import Slider from "react-slick";
import { CarouselImages } from "./CarouselImages";

const Carousel = () => {
  var settings = {
    className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    centerPadding: "1px",
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  const cauroselImage = [
    {
      img: slider1,
    },
    {
      img: slider2,
    },
    {
      img: slider3,
    },
    {
      img: slider1,
    },
    {
      img: slider2,
    },
    {
      img: slider3,
    },
  ];

  return (
    <div className=" min-h-screen style-image text-white hidden md:block">
      <h1 className="text-white text-center font-bold text-4xl px-10 py-16 ">
        Explore{" "}
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#F14D34] to-[#F2DE4E]">
          {" "}
          UltraHeals
        </span>{" "}
        new features!
      </h1>
      <div className="px-16 ">
        <Slider {...settings}>
          {cauroselImage.map((item, i) => (
            <CarouselImages item={item} key={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
