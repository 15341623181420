import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import TableApi from "../../../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function AddEmployee({ visible, onHide }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [departments, setDepartments] = useState([]);

  const validateEmail = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    }
    return false;
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    if (!data.email) {
      setError({ ...error, email: "Email is required" });
    }
    if (!data.role) {
      setError({ ...error, role: "Role is required" });
    }
    const validEmail = validateEmail(data["email"]);
    if (validEmail) {
      TableApi.createUser({
        email: data.email,
        role: "Employee",
        department: data.department,
      }).then((data) => {
        if (data.status === "success") {
          console.log("User Created Successfully");
          cleanClose();
        } else {
          console.log(data.message);
          toast.error(data.message);
        }
      });
    } else {
      toast.error("Invalid email address");
    }
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
    getDepartment();
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">Add New Employee</p>
      <p className="text-sm py-1">Create a account for new employee</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreateUser(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Email</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="email"
              placeholder="john@gmail.com"
              value={data.email}
              required
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
                setError({ ...error, email: false });
              }}
            />
            {error.email && (
              <p className="text-red-500 text-sm">{error.email}</p>
            )}
          </div>
          {/* <div className="w-full">
            <label className="block text-gray-500 text-sm ">Role</label>
            <Dropdown
              value={data.role}
              onChange={(e) => {
                setData({ ...data, role: e.value });
                setError({ ...error, role: false });
              }}
              options={[
                { name: "Auditor", value: "Auditor" },
                { name: "Editor", value: "Editor" },
                { name: "Employee", value: "Employee" },
              ]}
              optionLabel="name"
              required
              placeholder="Select a Role"
              className=" w-full md:w-14rem h-[40px] my-1"
            />
            {error.role && <p className="text-red-500 text-sm">{error.role}</p>}
          </div> */}
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Department</label>
            <Dropdown
              filter
              value={data?.department}
              onChange={(e) => setData({ ...data, department: e.value })}
              options={departments}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              // onClick={(e) => handleCreateUser(e)}
              type="submit"
            >
              Invite
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
