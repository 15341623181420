import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";

import { DateTimeToDate } from "../../helpers";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../utils/utils";
import { ptaasAPI } from "../../Api/PTaaS/api";
import { report } from "../../assets/svg";
import handleDownload from "../../utils/fileDownload";
import { toast } from "react-toastify";

export default function Report({ data }) {
  const [showReport, setShowReport] = useState(false);
  const [scan, setScan] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [publicView, setPublicView] = useState(null);

  const downloadReport = (e) => {
    handleSubmitButtonPress(e);
    setLoading(true);
    setPublicView(null);
    ptaasAPI
      .get(
        `/report/${scan}/?detailed=${type === "detailed" ? "true" : "false"}`
      )
      .then(({ data }) => {
        if (type === "detailed") {
          handleDownload(data.detailed_report_info);
          setPublicView(
            `${window.location.origin}/pentest-report/${data.certificate_unique_id}?type=detailed`
          );
        } else {
          handleDownload(data.summary_report_info);
          setPublicView(
            `${window.location.origin}/pentest-report/${data.certificate_unique_id}?type=summary`
          );
        }
        // const blob = new Blob([response], { type: "application/pdf" });
        // // Create a URL for the blob
        // const url = URL.createObjectURL(blob);
        // // Create a download link and trigger a click event to initiate the download
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = `scan_report.pdf`; // Set the desired file name
        // // document.body.appendChild(a);
        // a.click();
        // // Clean up by revoking the object URL
        // URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleSubmitButtonRelease(e);
        setLoading(false);
      });
  };

  const cleanClose = () => {
    setScan(null);
    setType(null);
    setShowReport(false);
    setPublicView(null);
  };

  return (
    <>
      <button
        className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
        onClick={() => setShowReport(true)}
      >
        Report
        <img src={report} />
      </button>
      <Sidebar visible={showReport} position="right" onHide={cleanClose}>
        <p className="font-bold text-2xl">Report</p>
        <form onSubmit={downloadReport}>
          <div className="w-full mb-5">
            <label className="block text-gray-500 text-sm ">Scan</label>
            <Dropdown
              value={scan}
              onChange={(e) => {
                setScan(e.value);
              }}
              options={data.map((item) => ({
                value: item.id,
                label: `${DateTimeToDate(item.created_at)} (${item.target})`,
              }))}
              optionLabel="label"
              placeholder="Select a Scan"
              className=" w-full md:w-14rem h-[40px] my-1"
            />
          </div>
          <div className="w-full mb-5">
            <label className="block text-gray-500 text-sm">
              Scan Report Type
            </label>
            <Dropdown
              value={type}
              onChange={(e) => {
                setType(e.value);
              }}
              options={[
                { label: "Executive Summary Report", value: "executive" },
                { label: "Detailed Report", value: "detailed" },
              ]}
              optionLabel="label"
              placeholder="Select a Scan"
              className=" w-full md:w-14rem h-[40px] my-1"
            />
          </div>
          {/* show line and copy option  */}
          {publicView && (
            <>
              <label className="block text-gray-500 text-sm">View URL</label>
              <div className="flex w-full">
                <input
                  type="text"
                  disabled
                  value={publicView}
                  className="min-w-[80%] h-[40px] border border-[#AAAAAA] rounded-[16px] p-2"
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(publicView);
                    toast.success("Copied to clipboard");
                  }}
                  className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2"
                  type="button"
                >
                  Copy
                </button>
              </div>
            </>
          )}
          <div className="flex w-full pt-5">
            <button
              name="submit"
              className={`p-1 border rounded-[16px] border-[#AAAAAA] button-bg-color h-[40px] w-full bg-[#181818] text-[#ffff]`}
              type="submit"
            >
              {loading ? "Downloading..." : "Download"}
            </button>
            {!loading && (
              <button
                onClick={() => {
                  setShowReport(false);
                }}
                className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2 "
                type="button"
              >
                Back
              </button>
            )}
          </div>
        </form>
      </Sidebar>
    </>
  );
}
