import React, { useContext, useState } from "react";
import MyProjectsTable from "./tables/MyProjectsTable";
import { ThemeContext } from "../../../context/ThemeContext";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import { useEffect } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setProjectsTable } from "../../../redux-toolkit/redux/TableData";
import { TargetFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { DateTimeToDate } from "../../../helpers";
import { getFilter } from "../../../utils/utils";

const MyProjects = (props) => {
  const { title, subtitle, btnText } = props;
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);

  const [projectsTableData, setProjectsTableData] = useState([]);
  const [loadingProjectTable, setLoadingProjectTable] = useState(true);
  const [FilterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({});

  const getProjectTableData = async () => {
    setLoadingProjectTable(true);
    // await TableApi.getAllProjects().then((data) => {
    //   setProjectsTableData(data);
    //   setLoadingProjectTable(false);
    // });
    TargetFilterAPI.post(getFilter(FilterData, filterParams))
      .then((res) => {
        setProjectsTableData(
          res.data.map((item) => {
            return {
              ...item,
              scan_started: DateTimeToDate(item.scan_started),
              scan_ended: DateTimeToDate(item.scan_ended),
            };
          })
        );
        setLoadingProjectTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    TargetFilterAPI.get()
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  console.log("Filter Data -> ", FilterData);
  console.log("Filter Params -> ", filterParams);

  useEffect(() => {
    // TableApi.getAllProjects().then((data) => {
    //   console.log("ProjectsDAT", data);
    //     setProjectsTableData(data)
    //   dispatch(setProjectsTableData(data));
    // });
    // getProjectTableData();
    getTableFilterData();
    dispatch(setProjectsTable(projectsTableData));
  }, []);

  useEffect(() => {
    if (FilterData) getProjectTableData();
  }, [filterParams, FilterData]);

  return (
    <>
      {/* Add Target */}
      <MyProjectsTable
        isDarkMode={isDarkMode}
        projectsTableData={projectsTableData}
        loadingProjectTable={loadingProjectTable}
        btnText
        btnName="Add Target"
        getProjectTableData={getTableFilterData}
        FilterData={FilterData}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
      />
    </>
  );
};

export default MyProjects;
