export default function HorizontalSpanBar({
  title,
  data = {
    success: 0,
    failure: 0,
    unknown: 0,
  },
  onClick = null,
}) {
  const { success = 0, failure = 0, unknown = 0 } = data;
  const total = success + failure + unknown;
  const pass = ((success / total) * 100) | 0;
  const fail = ((failure / total) * 100) | 0;
  const unkn = ((unknown / total) * 100) | 0;

  return (
    <div
      className="border border-1 rounded-md p-2 cursor-pointer"
      onClick={onClick}
    >
      <h1 className="font-semibold">{title}</h1>
      <div className="grid grid-cols-2 items-center gap-2">
        <div className="h-4 bg-[#E4E4E4] rounded-md">
          <div
            className="h-4 bg-[#008C1B] rounded-md"
            style={{ width: `${pass}%` }}
          ></div>
        </div>
        <div className="flex justify-between">
          <p>Pass</p>
          <p>{success}</p>
        </div>
        <div className="h-4 bg-[#E4E4E4] rounded-md">
          <div
            className="h-4 bg-[#D11305] rounded-md"
            style={{ width: `${fail}%` }}
          ></div>
        </div>
        <div className="flex justify-between">
          <p>Fail</p>
          <p>{failure}</p>
        </div>
        <div className="h-4 bg-[#E4E4E4] rounded-md">
          <div
            className="h-4 bg-[#FDCA3B] rounded-md"
            style={{ width: `${unkn}%` }}
          ></div>
        </div>
        <div className="flex justify-between">
          <p>Unknown</p>
          <p>{unknown}</p>
        </div>
      </div>
    </div>
  );
}
