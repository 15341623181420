import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useRef, useState } from "react";
import { loginHistoryData } from "../../../constants";
import { FcGoogle } from "react-icons/fc";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useEffect } from "react";
import SkeletonDataTable from "../../SkeletonAnimation/SkeletonDataTable";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { DateTimeToDateTime } from "../../../helpers";
import paginationTemplate from "../../../utils/paginationTemplate";

const SecurityScreenTable = (props) => {
  const { isDarkMode } = props;
  const dt = useRef(null);
  const [loginHistory, setloginHistory] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const columns = [
    { field: "date", header: "Date" },
    { field: "ip", header: "IP Address" },
    { field: "browser", header: "Browser" },
    { field: "agent", header: "User Agent" },
  ];

  const browserTemplate = (product) => {
    return (
      <div className="flex">
        <FcGoogle size={21} />
        <p className="ml-1">{product.browser}</p>
      </div>
    );
  };

  const getLoginHistoryData = () => {
    setLoadingTable(true);
    TableApi.getLoginHistory().then((data) => {
      if (data.status === "success") {
        setloginHistory(data.data);
        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    getLoginHistoryData();
  }, []);

  return (
    <div>
      <div>
        <p className="text-[16px] font-bold pb-2">Login History</p>
      </div>
      {loadingTable ? (
        <SkeletonDataTable columns={columns} length={true} />
      ) : (
        <div>
          <DataTable
            removableSort
            value={loginHistory}
            ref={dt}
            paginator
            rows={5}
            paginatorTemplate={paginationTemplate()}
            className={`${
              isDarkMode ? "dark-mode text-dark" : " text-color"
            } text-xs`}
          >
            <Column
              field="date"
              header="Date"
              body={({ date }) => {
                return DateTimeToDateTime(date);
              }}
            />
            <Column field="ip" header="IP Address" />
            <Column field="browser" header="Browser" body={browserTemplate} />
            <Column field="agent" header="User Agent" />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default SecurityScreenTable;
