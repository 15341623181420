import { useNavigate } from "react-router-dom";
import JustDonut from "./JustDonut";

export default function SeverityDonut({ title, data, path, state }) {
  const navigate = useNavigate();
  return (
    <JustDonut
      title={title}
      labels={["Critical", "High", "Medium", "Low"]}
      colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
      data={[
        data?.critical | 0,
        data?.high | 0,
        data?.medium | 0,
        data?.low | 0,
      ]}
      centerText={
        <span className="flex text-center">
          {(data?.critical | 0) +
            (data?.high | 0) +
            (data?.medium | 0) +
            (data?.low | 0)}
          <br />
          Issues
        </span>
      }
      onClick={path ? () => navigate(path, { state }) : null}
    />
  );
}
