import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import { FiArrowUpRight } from "react-icons/fi";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import paginationTemplate from "../../../../utils/paginationTemplate";
import AnalysisView from "../../../../common/Tables/AnalysisView";
import { getFilter } from "../../../../utils/utils";

export default function Phishing() {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const api = new TableFilterApiBase(
    "ultraheals/v1/web/digital-risk/domainphishing/filter/"
  );
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scan });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    {
      field: "similar_domain",
      header: "Similar Domains",
      value: filterData?.similar_domain,
    },
    {
      field: "ip",
      header: "IP Address",
      value: filterData?.ip,
    },
    {
      field: "unsafe",
      header: "Unsafe",
      value: filterData?.unsafe,
      type: "AnalysisView",
    },
    {
      field: "spam",
      header: "Spam",
      value: filterData?.spam,
      type: "AnalysisView",
    },
    {
      field: "malware",
      header: "Malware",
      value: filterData?.malware,
      type: "AnalysisView",
    },
    {
      field: "phishing",
      header: "Phishing",
      value: filterData?.phishing,
      type: "AnalysisView",
    },
    {
      field: "suspicious",
      header: "Suspicious",
      value: filterData?.suspicious,
      type: "AnalysisView",
    },
    {
      field: "proxy",
      header: "Proxy",
      value: filterData?.proxy,
      type: "AnalysisView",
    },
    {
      field: "vpn",
      header: "VPN",
      value: filterData?.vpn,
      type: "AnalysisView",
    },
    {
      field: "tor",
      header: "TOR",
      value: filterData?.tor,
      type: "AnalysisView",
    },
    { field: "update_field_name", header: "Status" },
  ];

  const getFilterData = () => {
    api
      .get({ scan })
      .then((res) => {
        console.log("setFilterData", res);
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...getFilter(filterData, filterParams), scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-status ">
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={() => setCurrentDetails(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        closeOnEscape={false}
        dismissable={false}
      >
        <p className="font-bold text-2xl text-center">Phishing Intelligence</p>
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Similar Domain</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.similar_domain}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Country</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.country}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">IP Address</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.ip}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">DNS Valid</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.network} */}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Server</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.isp} */}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Category</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.network} */}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Domain Age</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.sentimental_analysis}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Hosting Provider</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.isp}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Created Date</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.created_date}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Expiry Date</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.expiry_date}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Parked Domain</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.sentimental_analysis}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">ASN</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.asn}
              </p>
            </div>
          </div>
          <div className="flex justify-center pt-6">
            <div className="w-full">
              <table className="table-auto w-full border">
                <thead>
                  <tr>
                    <td>
                      <div className="table-header"></div>
                    </td>
                  </tr>
                  <tr className="bg-[#F5F5F7]">
                    <th className="border border-slate-700 p-1">Unsafe</th>
                    <th className="border border-slate-700 p-1">Spam</th>
                    <th className="border border-slate-700 p-1">Malware</th>
                    <th className="border border-slate-700 p-1">Phishing</th>
                    <th className="border border-slate-700 p-1">Suspicious</th>
                    <th className="border border-slate-700 p-1">Proxy</th>
                    <th className="border border-slate-700 p-1">VPN</th>
                    <th className="border border-slate-700 p-1">TOR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border border-slate-700">
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.unsafe)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.spam)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.phishing)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.malware)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.suspicious)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.proxy)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.vpn)}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {AnalysisView(currentDetails?.tor)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Sidebar>
      <h1 className="font-bold">Phishing Intelligence</h1>
      <div className="ssl-active">
        <div
          className={` rounded-2xl userTable-style  ${
            isDarkMode
              ? "header-dark  text-white"
              : "header-light white-background  text-color"
          }`}
        >
          <div className="flex flex-wrap dashboard-table">
            <p className="font-bold">
              Phishing Intelligence <span> ({data?.length}) </span>
            </p>
          </div>
          <div>
            <TableFilter
              columns={columns}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              dt={ref}
              tableData={data}
            />
            {loading ? (
              <SkeletonDataTable columns={columns} />
            ) : (
              <DataTable
                paginator
                paginatorTemplate={paginationTemplate()}
                removableSort
                value={data}
                rows={10}
                ref={ref}
                globalFilter={globalFilter}
                className={`${
                  isDarkMode ? "dark-mode text-dark" : " text-color"
                } text-xs`}
              >
                {columns.map((col, i) => {
                  if (col.type === "AnalysisView") {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={(rowData) => {
                          return AnalysisView(rowData[col.field]);
                        }}
                        sortable
                      />
                    );
                  }
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                })}
                <Column
                  header="Full info"
                  body={(rowData) => {
                    return (
                      <button
                        onClick={() => {
                          setCurrentDetails(rowData);
                        }}
                      >
                        <FiArrowUpRight size={20} color="#074EE8" />
                      </button>
                    );
                  }}
                  exportable={false}
                />
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
