import ArrowDownIconLight from "./ArrowDownIconLight.svg";
import ArrowDownIconDark from "./ArrowDownIconDark.svg";
import ChartIconLight from "./ChartLight.svg";
import ChartIconDark from "./ChartDark.svg";
import ControlsIconLight from "./ControlsIconLight.svg";
import ControlsIconDark from "./ControlsIconDark.svg";
import DashBoardIconLight from "./DashBoardLight.svg";
import DashBoardIconDark from "./DashBoardDark.svg";
import CpuIconLight from "./CpuIconLight.svg";
import CpuIconDark from "./CpuIconDark.svg";
import GlobeIconLight from "./GlobeIconLight.svg";
import GlobeIconDark from "./GlobeIconDark.svg";
import LogoLight from "./LogoLight.svg";
import LogoDark from "./LogoDark.svg";
import MoonIconLight from "./MoonIconLight.svg";
import MoonIconDark from "./MoonIconDark.svg";
import NotificationIconLight from "./NotificationIconLight.svg";
import NotificationIconDark from "./NotificationIconDark.svg";
import SystemIconLight from "./SystemIcon.svg";
import SystemIconDark from "./SystemIconDark.svg";
import UserIcon from "./UserIconDark.svg";
import Circle from "./Circle.svg";
import CheckBoxTick from "./CheckboxTick.svg";
import CheckboxCross from "./CheckboxCross.svg";
import DownloadIcon from "./DownloadIcon.svg";
import SearchIcon from "./SearchIcon.svg";
import MoreIcon from "./MoreIcon.svg";
import filter from "./filter.svg";
import share from "./share.svg";
import Domain from "./Domain.svg";
import LiveHosts from "./LiveHosts.svg";
import OpenPorts from "./openPorts.svg";
import subDomain from "./subdomains.svg";
import ip from "./IP.svg";
import endPoints from "./endpoints.svg";
import aws from "./aws.svg";
import Azure from "./Azure.svg";
import google from "./Google.svg";
import DigitalOcean from "./DigitalOcean.svg";
import edit from "./edit.svg";
import report from "./report.svg";
import SideBarIconLight from "./SideBarLightIcon.svg";
import SideBarIconDark from "./SideBarIconDark.svg";
import InformationIconDark from "./InformationIconDark.svg";
import InformationIconLight from "./Information.svg";
import Negative from "./Negative.svg";
import ScheduleCalender from "./ScheduleCalender.svg";
import Start from "./Start.svg";
import Close from "./Close.svg";

import AWSWhite from "./awsWhite.svg";
import LocationPin from "./LocationPin.svg";
import ec2 from "./ec2.svg";
import glue from "./glue.svg";
import guardduty from "./guardduty.svg";
import athena from "./athena.svg";
import cloudtrail from "./cloudtrail.svg";
import config from "./config.svg";
import firewall from "./firewall.svg";
import inspector from "./inspector.svg";
import macie from "./macie.svg";
import securityhub from "./securityhub.svg";
import vpc from "./vpc.svg";
import s3 from "./s3.svg";
import rds from "./rds.svg";
import LocationPinDark from "./LocationPinDark.svg";

import slack from "./slack.svg";
import github from "./github-mark.svg";
import githubWhite from "./github-mark-white.svg";
import gitlab from "./gitlab.svg";
import teams from "./teams.svg";
import jira from "./Jira-icon-blue.svg";
import pabbly from "./Pabbly Logo Icon SVG.svg";
import makecom from "./Make-App-Icon-Circle.svg";
import zohowhite from "./zoho-white.svg";
import zohodark from "./zoho-dark.svg";
import entra from "./Microsoft_Entra_ID_color_icon.svg";
import thumbnail from "./thumbnail.svg";
import SOC_2 from "./soc2.svg";
import GDPR from "./gdpr.svg";
import ISO from "./iso.svg";
import HIPPA from "./hippa.svg";
import PCI from "./pci_dss.svg";
import MSlogo from "./Microsoft_logo.svg";
import AppleLogo from "./Apple_logo_black.svg";
import zendesk from "./zendesk.svg";
import asana from "./asana-logo.svg";
import monday from "./monday-icon.svg";
import googleworkspace from "./Google.svg";
import clickup from "./clickup-logo.svg";
import coda from "./coda-icon.svg";
import airtable from "./airtable-icon.svg";
import bitbucket from "./bitbucket-icon.svg";
import notion from "./notion-logo-svgrepo-com.svg";
import azuredevops from "./azure-devops-svgrepo-com.svg";
import freshdesk from "./freshdesk-icon.svg";
import zapier from "./zapier-icon.svg";
import hubspot from "./hubspot-icon.svg";
import confluence from "./confluence-svgrepo-com.svg";
import miro from "./miro.svg";
import docusign from "./docusign.svg";
import semgrep from "./semgrep.svg";
import attio from "./attio.svg";
import contentful from "./contentful.svg";
import hive from "./hive.svg";
import linear from "./linear-icon.svg";
import klaviyo from "./klaviyo.svg";
import pagerduty from "./pagerduty.svg";
import pivotaltracker from "./pivotaltracker.svg";
import wrike from "./wrike.svg";
import shortcut from "./shortcut.svg";
import breathe from "./breatrhe.svg";
import tines from "./tines.svg";
import pipedrive from "./pipedrive.svg";
import recruitee  from "./recruitee.svg";
import envoy from "./envoy.svg";
import front from "./Front.svg";
import render from "./render.svg";
import scalerway from "./scalerway.svg"; 
import terraform from "./terraform.svg";
import rollbar from "./rollbar.svg";
import oneflow from "./oneflow.svg";
import vercel from "./vercel.svg";
import xero from "./xero-svgrepo-com.svg";
import zohobook from "./zohobook .svg";
import sentry from "./sentry-icon-svgrepo-com.svg";
import articulate from "./airticulate.svg";
import qlik from "./qlik.svg";


export {
  SOC_2,
  PCI,
  GDPR,
  ISO,
  HIPPA,
  MSlogo,
  AppleLogo,
  thumbnail,
  semgrep,
  LocationPin,
  ec2,
  s3,
  rds,
  AWSWhite,
  glue,
  guardduty,
  athena,
  cloudtrail,
  config,
  firewall,
  inspector,
  macie,
  securityhub,
  vpc,
  slack,
  github,
  githubWhite,
  gitlab,
  teams,
  jira,
  pabbly,
  makecom,
  zohowhite,
  zohodark,
  entra,
  LocationPinDark,
  ArrowDownIconDark,
  ArrowDownIconLight,
  ChartIconDark,
  ChartIconLight,
  ControlsIconLight,
  ControlsIconDark,
  DashBoardIconLight,
  DashBoardIconDark,
  CpuIconLight as GearIconLight,
  CpuIconDark as GearIconDark,
  GlobeIconLight,
  GlobeIconDark,
  LogoLight,
  LogoDark,
  MoonIconLight,
  MoonIconDark,
  NotificationIconLight,
  NotificationIconDark,
  SystemIconLight,
  SystemIconDark,
  UserIcon,
  Circle,
  CheckBoxTick,
  CheckboxCross,
  DownloadIcon,
  SearchIcon,
  MoreIcon,
  filter,
  share,
  Domain,
  LiveHosts,
  OpenPorts,
  subDomain,
  ip,
  endPoints,
  aws,
  Azure,
  google,
  DigitalOcean,
  edit,
  report,
  SideBarIconLight,
  SideBarIconDark,
  InformationIconDark,
  InformationIconLight,
  ScheduleCalender,
  Start,
  Close,
  zendesk,
  asana,
  monday,
  googleworkspace,
  clickup,
  coda,
  airtable,
  bitbucket,
  notion,
  azuredevops,
  freshdesk,
  zapier,
  hubspot,
  confluence,
  miro,
  docusign,
  attio,
  contentful,
  hive,
  linear,
  klaviyo,
  pagerduty,
  pivotaltracker,
  wrike,
  shortcut,
  breathe,
  tines,
  pipedrive,
  recruitee,
  envoy,
  front,
  render,
  scalerway,
  terraform,
  rollbar,
  oneflow,
  vercel,
  xero,
  zohobook,
  sentry,
  articulate,
  qlik
};
