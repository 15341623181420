import React, { useRef, useState } from "react";
import DashboardCloudTables from "../../../../../common/Tables/DashboardCloudTables";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import SSLTables from "../../../../../common/Tables/SSLTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import TechnologiesDataTables from "../../../../../common/Tables/TechnologiesDataTables";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const TechnologiesTable = (props) => {
  const {
    technologyTableData,
    loadingTable,
    technologyFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "host", header: "Host", value: technologyFilterData?.host },
    { field: "tech", header: "Technology", value: technologyFilterData?.tech },
    { field: "category", header: "Category", value: [] },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={technologyTableData}
          dt={dt}
          tableHeader={columns}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <TechnologiesDataTables
            userInformation={technologyTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};

export default TechnologiesTable;
