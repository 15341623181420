import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import { GoKebabHorizontal } from "react-icons/go";
import { TbBook, TbLock, TbTrash, TbPencil } from "react-icons/tb";
import { VscDebugStart } from "react-icons/vsc";

import { thumbnail } from "../../../../assets/svg";
import { confirmDialog } from "primereact/confirmdialog";
import { complianceAPI } from "../../../../Api/CSPM/api";
import HandleResponseError from "../../../../utils/error";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { isAuditorOrSubscriberOrAdminOrSuperAdmin } from "../../../../utils/accessCheck";

function Card({
  id,
  setShowCourse,
  setShowLessons,
  setShowQuiz,
  item,
  getData,
}) {
  const navigate = useNavigate();
  const userDetails = useSelector(getUserDetails);
  const { isDarkMode } = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState(false);

  const menuHandler = (e) => {
    e.stopPropagation();
    setShowMenu((prev) => !prev);
  };

  const editHandler = (e) => {
    // e.stopPropagation();
    setShowCourse(e);
  };

  const lessonHandler = (e) => {
    // e.stopPropagation();
    setShowLessons(e);
  };

  const quizHandler = (e) => {
    // e.stopPropagation();
    setShowQuiz(e);
  };

  const deleteHandler = (e) => {
    // e.stopPropagation();
    console.log("eeeee");
    confirmDialog({
      header: `Delete ${item.name}`,
      message: "Are you Sure..?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: () => {
        complianceAPI
          .delete(`/campaign/${item?.id}`)
          .then((res) => {
            if (res.error) {
              HandleResponseError(res.message);
            } else {
              toast.success("Deleted Successfully.");
              getData();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      reject: () => console.log("Cancel"),
    });
  };

  const launchHandler = (e) => {
    complianceAPI
      .post("/launch-awareness/", {
        name: item.name,
      })
      .then((res) => {
        if (res.error) {
          HandleResponseError(res.message);
        } else {
          toast.success("Launched Successfully.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const menuData = {
    Edit: {
      icon: <TbPencil />,
      handler: editHandler,
    },
    Lessons: {
      icon: <TbBook />,
      handler: lessonHandler,
    },
    Quiz: {
      icon: <TbBook />,
      handler: quizHandler,
    },
    Launch: {
      icon: <VscDebugStart />,
      handler: launchHandler,
    },
    Delete: {
      icon: <TbTrash />,
      handler: deleteHandler,
    },
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", menuHandler);
    } else {
      document.removeEventListener("click", menuHandler);
    }

    return () => {
      document.removeEventListener("click", menuHandler);
    };
  }, [showMenu]);

  return (
    <div
      className={`${
        isDarkMode
          ? "bg-[#0c0c0c] hover:shadow-slate-800/40"
          : "bg-slate-50 text-black hover:shadow-black/10"
      } relative w-80 h-full flex flex-col border-none  outline-none rounded-xl gap-5 cursor-pointer hover:shadow-lg group`}
      onClick={() => {
        navigate(`training`, { state: item });
      }}
    >
      <div
        className="w-full h-[160px] bg-cover bg-center rounded-t-xl"
        style={{
          backgroundImage: `url('${
            item?.file ? item.file_info.file : thumbnail
          }')`,
        }}
      ></div>
      <div className="w-full flex flex-col gap-3 p-2">
        <div className="flex items-center justify-between">
          <h1 className="w-11/12 text-lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
            {item.name}
          </h1>
          {isAuditorOrSubscriberOrAdminOrSuperAdmin(userDetails?.role) && (
            <div
              className="w-1/12 p-1 rounded-md hover:bg-slate-400/20 group-hover:visible invisible transform rotate-90"
              onClick={menuHandler}
            >
              <GoKebabHorizontal />
            </div>
          )}
        </div>
        <p className="font-light mb-2">{item.description}</p>
        <div className="flex justify-between items-center text-sm font-thin">
          <p className="flex items-center gap-1">
            <TbBook /> <span>{item.lessons} lessons</span>
          </p>
          <p className="flex items-center gap-1">
            <TbLock /> <span>{item.duration} minutes</span>
          </p>
        </div>
      </div>
      {/* Dropdown  Menu */}
      <ul
        className={`${
          isDarkMode
            ? "bg-[#171717] border-[#171717]"
            : "bg-white border-slate-200"
        } absolute z-[1] p-1 text-right flex flex-col border rounded-md bottom-14 right-8 ${
          showMenu &&
          isAuditorOrSubscriberOrAdminOrSuperAdmin(userDetails?.role)
            ? "block"
            : "hidden"
        }`}
      >
        {Object.entries(menuData).map(([key, { icon, handler }]) => (
          <li
            key={key}
            className={`${
              isDarkMode ? "hover:bg-[#0c0c0c]" : "hover:bg-slate-200"
            } w-full flex items-center justify-between gap-2 p-1 rounded-md`}
            onClick={(e) => {
              e.stopPropagation();
              handler(item);
            }}
          >
            {icon} {key}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Card;
