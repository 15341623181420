import { Sidebar } from "primereact/sidebar";
import { useContext, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { ThemeContext } from "../../../../../context/ThemeContext";
import integrationsAPI from "../../../../../Api/integerations/api";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function IntegratePeople() {
  const { isDarkMode } = useContext(ThemeContext);

  const [visible, setVisible] = useState(false);
  const [services, setServices] = useState([]);
  const SERVICES = [
    {
      label: "Microsoft Entra",
      value: "entra",
    },
    {
      label: "Zoho People",
      value: "zoho",
    },
    {
      label: "Google workspace",
      value: "googleworkspaceuser",
    },
    {
      label: "Breathe",
      value: "breathe",
    },
    {
      label: "Recruitee",
      value: "recruitee",
    },
    {
      label: "Envoy",
      value: "envoy",
    },
    // {
    //   label: "Contentful",
    //   value: "contentful",
    // },
    {
      label: "PagerDuty",
      value: "pagerduty",
    },
    {
      label: "Wrike",
      value: "wrike",
    },
    {
      label: "Pipedrive",
      value: "pipedrive",
    },
    {
      label: "Hive",
      value: "hive",
    },
    {
      label: "Front",
      value: "front",
    },
    {
      label: "Render",
      value: "render",
    },
    {
      label: "Scalerway",
      value: "scalerway",
    },
    {
      label: "Terraform",
      value: "terraform",
    },
    {
      label: "Rollbar",
      value: "rollbar",
    },
    {
      label: "Oneflow",
      value: "oneflow",
    },
    {
      label: "Vercel",
      value: "vercel",
    },
    {
      label: "Xero",
      value: "xero",
    },
    {
      label: "Zohobook",
      value: "zohobook",
    },
    {
      label: "Sentry",
      value: "sentry",
    },
    {
      label: "Qlik",
      value: "qlik",
    },
    {
      label: "Articulate",
      value: "articulate",
    },
    
    // {
    //   label: "Klaviyo",
    //   value: "klaviyo",
    // },
  ];

  const runService = () => {
    complianceAPI
      .post(`/collect/${services}/`)
      .then((res) => {
        if (res.status === "success") {
          toast.success(
            "Employee collection started in background, will update once completed"
          );
          setVisible(false);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const renderService = () => {
    switch (services) {
      case "entra":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
        case "zoho":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
        case "googleworkspaceuser":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
        case "breathe":
          return (
            <div className="flex w-full mt-9">
              <button
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                onClick={runService}
              >
                Collect Employee Data from {services}
              </button>
            </div>
          );
          case "recruitee":
          return (
            <div className="flex w-full mt-9">
              <button
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                onClick={runService}
              >
                Collect Employee Data from {services}
              </button>
            </div>
          );
          case "envoy":
            return (
              <div className="flex w-full mt-9">
                <button
                  className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                  onClick={runService}
                >
                  Collect Employee Data from {services}
                </button>
              </div>
            );
            case "contentful":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "pagerduty":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "wrike":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "pipedrive":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "xero":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "sentry":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "hive":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "klaviyo":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "front":
              return (
                <div className="flex w-full mt-9">
                  <button
                    className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                    onClick={runService}
                  >
                    Collect Employee Data from {services}
                  </button>
                </div>
              );
              case "render":
                return (
                  <div className="flex w-full mt-9">
                    <button
                      className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                      onClick={runService}
                    >
                      Collect Employee Data from {services}
                    </button>
                  </div>
                );
                case "scalerway":
                return (
                  <div className="flex w-full mt-9">
                    <button
                      className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                      onClick={runService}
                    >
                      Collect Employee Data from {services}
                    </button>
                  </div>
                );
                case "terraform":
                return (
                  <div className="flex w-full mt-9">
                    <button
                      className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                      onClick={runService}
                    >
                      Collect Employee Data from {services}
                    </button>
                  </div>
                );
                case "rollbar":
                  return (
                    <div className="flex w-full mt-9">
                      <button
                        className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                        onClick={runService}
                      >
                        Collect Employee Data from {services}
                      </button>
                    </div>
                  );
                  case "oneflow":
                    return (
                      <div className="flex w-full mt-9">
                        <button
                          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                          onClick={runService}
                        >
                          Collect Employee Data from {services}
                        </button>
                      </div>
                    );
                  case "vercel":
                    return (
                      <div className="flex w-full mt-9">
                        <button
                          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                          onClick={runService}
                        >
                          Collect Employee Data from {services}
                        </button>
                      </div>
                    );
                    case "zohobook":
                    return (
                      <div className="flex w-full mt-9">
                        <button
                          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                          onClick={runService}
                        >
                          Collect Employee Data from {services}
                        </button>
                      </div>
                    );
                    case "articulate":
                    return (
                      <div className="flex w-full mt-9">
                        <button
                          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                          onClick={runService}
                        >
                          Collect Employee Data from {services}
                        </button>
                      </div>
                    );
                    case "qlik":
                      return (
                        <div className="flex w-full mt-9">
                          <button
                            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                            onClick={runService}
                          >
                            Collect Employee Data from {services}
                          </button>
                        </div>
                      );
      default:
        return null;
    }
  };

  return (
    <>
      <button
        className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
          isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
        }`}
        onClick={() => setVisible(true)}
      >
        Integrate
      </button>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Integrate Employee</p>
        <p className="text-sm py-1">
          Integrate employee data from different sources
        </p>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Service</label>
          <Dropdown
            value={services}
            onChange={(e) => setServices(e.value)}
            options={SERVICES}
            optionLabel="label"
            optionValue="value"
            placeholder="Select Service"
            className="w-full md:w-14rem my-1"
          />
        </div>
        {renderService()}
      </Sidebar>
    </>
  );
}
