import AppCard from "./AppCard";

const BrandTreat = (props) => {
  const { isDarkMode, data } = props;

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 h-full">
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <AppCard
          title="Twitter"
          isDarkMode={isDarkMode}
          data={data?.twitter}
          tooltip="Protect your brand on Twitter by monitoring threats and performing sentiment analysis to gauge audience sentiment."
        />
      </div>
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <AppCard
          title="Instagram"
          isDarkMode={isDarkMode}
          data={data?.instagram}
          tooltip="Protect your brand on Instgram by monitoring threats and performing sentiment analysis to gauge audience sentiment."
        />
      </div>
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <AppCard
          title="Youtube"
          isDarkMode={isDarkMode}
          data={data?.youtube}
          tooltip="Protect your brand on Youtube by monitoring threats and performing sentiment analysis to gauge audience sentiment."
        />
      </div>
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        {/* <AppCard
          title="Fake Apps"
          fakeApps={true}
          data={data?.fake_app}
          isDarkMode={isDarkMode}
        /> */}
        <AppCard
          title="Facebook"
          isDarkMode={isDarkMode}
          data={data?.facebook}
          tooltip="Protect your brand on Facebook by monitoring threats and performing sentiment analysis to gauge audience sentiment."
        />
      </div>
    </div>
  );
};

export default BrandTreat;
