import AddUser from "./AddUser";
import SuperUserTable from "./SuperUserTable";

export default function UserTable() {

  const columns = [
    {
      field: "first_name",
      header: "First Name"
    },
    {
      field: "last_name",
      header: "Last Name"
    },
    {
      field: "email",
      header: "Email"
    },
    {
      field: "referred_by",
      header: "Referred by"
    },
    {
      field: "action",
      type: "action",
      header: "Actions",
    }
  ];

  return (
    <SuperUserTable
      // title="Users"
      columns={columns}
      AddComponent={AddUser}
    />
  );
}
