import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddRisk, {
  STATUS,
  category,
} from "../../modules/home/components/compliance/risk/AddRisk";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { complianceAPI } from "../../Api/CSPM/api";
import { RiskView } from "../../modules/home/components/compliance/risk/DetailedView";

export default function Risk() {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [department, setDepartment] = useState([]);
  const [entities, setEntities] = useState([]);

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({ label: e.email, value: e.id })),
    },
    {
      field: "entities",
      header: "Entity",
      type: "select",
      value: entities.map((e) => ({ label: e.name, value: e.id })),
    },
    {
      field: "department",
      header: "Department",
      type: "select",
      value: department.map((e) => ({ label: e.name, value: e.id })),
    },
    {
      field: "category",
      header: "Category",
      type: "select",
      value: category,
    },
    {
      field: "application_name",
      header: "Application Name",
    },
    {
      field: "source",
      header: "Source",
    },
    {
      field: "source_type",
      header: "Source Type",
    },
    {
      field: "last_assigned",
      header: "Last Assigned",
    },
    {
      field: "likelihood",
      header: "Likelihood",
    },
    {
      field: "impact",
      header: "Impact",
    },
    {
      field: "inherent_risk",
      header: "Inherent Risk",
      type: "func",
      func: (e) => {
        return `${e.inherent_risk} (${RiskView(e.inherent_risk)})`;
      },
    },
    {
      field: "residual_risk_likelihood",
      header: "Residual Risk Likelihood",
    },
    {
      field: "residual_risk_impact",
      header: "Residual Risk Impact",
    },
    {
      field: "residual_risk",
      header: "Residual Risk",
      type: "func",
      func: (e) => {
        return `${e.residual_risk} (${RiskView(e.residual_risk)})`;
      },
    },
    {
      field: "treatment_strategy",
      header: "Treatment Strategy",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getDepartment();
    getEntities();
  }, []);

  return (
    <ComplianceTable
      title="Risk Management"
      columns={columns}
      url_path="/risk/"
      AddComponent={AddRisk}
      onRowClick={(data) => {
        navigate(`./${data.id}`);
      }}
      canImport
    />
  );
}
