import ComplianceTable from "../../../modules/home/components/compliance/complianceTable";
import AddSecurityAwareness from "../../../modules/home/components/compliance/AddSecurityAwareness";

export default function SecurityAwareness() {
  const columns = [
    { field: "name", header: "Name" },
    {
      field: "launch_date",
      header: "Launch Date",
      type: "date",
    },
    {
      field: "end_date",
      header: "End Date",
      type: "date",
    },
    // NOTE: current no status in db need know how we handler status
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Open",
          value: "open",
        },
        {
          label: "Closed",
          value: "closed",
        },
      ],
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Security Awareness Campaign"
      columns={columns}
      url_path="/security-awareness/"
      AddComponent={AddSecurityAwareness}
    />
  );
}
