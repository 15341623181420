import React from 'react';
import { Skeleton } from 'antd';

const SkeletonWebText = ({plan}) => {
    return (
    
    <div className=" w-full px-5 m-2 p-5  ">
        <div className='flex justify-between'>
        <p className={`w-full mb-5 ${plan && "text-right"}`}>
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
        </div>
        <div className='flex justify-between'>
        <p className={`w-full mb-5 ${plan && "text-right"}`}>
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
        </div>
        <div className='flex justify-between'>
        <p className={`w-full mb-5 ${plan && "text-right"}`}>
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
        </div>
        <p className="w-full mb-5">
        <Skeleton active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton active={true} width={500} />{" "}
      </p>
      
    </div>
      
    );
};

export default SkeletonWebText;