import React, { useContext, useEffect, useState } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../context/ThemeContext";
import Endpoints from "../../home/components/tables/AttackSurface/EndPoints";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { KatanaFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { getFilter } from "../../../utils/utils";

const EndPointsTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const { title } = props;
  const [endPointsTableData, setEndPointsTableData] = useState([]);
  const [endPointsFilterData, setEndPointsFitlerData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getEndPointsDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardEndPointsTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setEndPointsTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    KatanaFilterAPI.post({
      ...getFilter(endPointsFilterData, filterParams),
      scanID: scanID,
    })
      .then((res) => {
        setEndPointsTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    KatanaFilterAPI.get({ scanID: scanID })
      .then((res) => {
        setEndPointsFitlerData(res.data);
        // setFilterParams({...filterParams, ...res.data})
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (endPointsFilterData) getEndPointsDataTable();
  }, [filterParams, endPointsFilterData]);

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <Endpoints
          endPointsTableData={endPointsTableData}
          endPointsFilterData={endPointsFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          loadingTable={loadingTable}
        />
      </div>
    </>
  );
};

export default EndPointsTable;
