import { useContext, useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { FaRegTimesCircle } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import ViewAcceptance from "./ViewAcceptance";

export default function Training() {
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(null);

  const getData = () => {
    complianceAPI
      .get("/employee-campaign/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h1 className="font-bold mb-2">Policy Overview</h1>
      <div
        className={`rounded-2xl userTable-style ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div className="grid grid-cols-2">
          <p>
            <span className="font-bold">Total Policies: </span>
            {data?.policies?.length}
          </p>
          <div className="grid grid-cols-2">
            <p>
              <span className="font-bold">Policies Accepted: </span>{" "}
              {data?.accepted.length}
            </p>
            <ProgressBar
              value={
                Math.floor(
                  (data?.accepted.length / data?.policies?.length) * 100
                ) || 0
              }
              color="#148842"
              title={`Passed: 5`}
            ></ProgressBar>
          </div>
        </div>
        <p className="text-sm text-gray-500 mt-3 px-2">
          It contains the list of all the policies that are assigned to the
          employee. The employee can view the policy details and accept or
          reject the policy. if you have any queries, please contact the
          administrator.
        </p>
      </div>
      <h1 className="font-bold my-2">List of Policies</h1>
      <div
        className={`rounded-2xl userTable-style ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        {data?.policies?.map((item) => (
          <div
            className={`flex flex-row justify-between items-center my-2 p-1 cursor-pointer border ${
              isDarkMode ? "border-[#444]" : "border-[#DEDEDE]"
            }`}
            onClick={() => {
              setCurrentDetails({
                ...item,
                is_accepted: data?.accepted.includes(item.id),
              });
            }}
          >
            <p className="text-sm">{item.name}</p>
            {data?.accepted.includes(item.id) ? (
              <p className="flex items-center text-sm text-green-500 gap-2">
                Accepted
                <IoMdCheckmarkCircleOutline />
              </p>
            ) : (
              <p className="flex items-center text-sm text-red-500 gap-2">
                Not Accepted
                <FaRegTimesCircle />
              </p>
            )}
          </div>
        ))}
      </div>
      <ViewAcceptance
        currentDetails={currentDetails}
        onHide={() => {
          setCurrentDetails(null);
          getData();
        }}
      />
    </div>
  );
}
