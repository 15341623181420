import React from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import CompanyLoginForm from "../../modules/login/components/CompanyLoginForm";
import AuthSideBar from "../../Component/Header/AuthSideBar";

const CompanyLogin = () => {
  return (
    <div className='h-screen flex flex-col overflow-x-hidden'>
      <LoginHeader 
        title = "Have a personal account?"
        subTitle = ""
        navigate = ""
      />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
        <CompanyLoginForm />
        </div>
      </div>
      <hr />
      <div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default CompanyLogin;
