import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import Assests from "./Assests";
import VulnerabilityIntelligence from "./VulnerabilityIntelligence";
import Compliances from "./Compliances";
import Hosts from "./Hosts";

const AttackSurfaceManagementComponent = (props) => {
  const {
    assetsData,
    secureScoreData,
    secureScoreGraphCategory,
    secureScoreGraphData,
  } = props;

  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div>
      <Assests
        assetsData={assetsData}
        secureScoreData={secureScoreData}
        secureScoreGraphCategory={secureScoreGraphCategory}
        secureScoreGraphData={secureScoreGraphData}
      />
      <div>
        <VulnerabilityIntelligence />
      </div>
      <div>
        <Compliances />
      </div>
      <div>
        <Hosts />
      </div>
    </div>
  );
};

export default AttackSurfaceManagementComponent;
