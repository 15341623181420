import PassFailDonut from "./Charts/PassFailDonut";
import Header from "./Charts/Header";

export default function JustDonutListing(props) {
  const { title, data, tooltip } = props;

  return (
    <div className="px-3 flex flex-col w-full">
      <Header title={title} tooltip={tooltip} />
      <div className="flex flex-col gap-2">
        {data?.map((item) => (
          <PassFailDonut title={item.key} data={item} direction="vertical" />
        ))}
      </div>
    </div>
  );
}
