import { createElement } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoRemoveCircleOutline } from "react-icons/io5";

export default function AnalysisView(type) {
  console.log(type);
  if (!type) return;
  let icon = null;
  let color = null;
  // eslint-disable-next-line default-case
  switch (type) {
    case "True":
      color = "text-red-600	";
      icon = FaRegTimesCircle;
      break;
    case "False":
      color = "text-green-600	";
      icon = IoMdCheckmarkCircleOutline;
      break;
    default:
      color = "text-yellow-600	";
      icon = IoRemoveCircleOutline;
      break;
  }
  return (
    <div className={`flex content-center justify-center ${color}`}>
      <div className="text-xl">{createElement(icon)}</div>
    </div>
  );
}
