import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/auth";
import sidebarReducer from "../redux/Sidebar";
import userTableReducer from "../redux/TableData";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userTable: userTableReducer,
    sidebar: sidebarReducer,
  },
});
