import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import DonutChart from "../../../common/Charts/DonutChart";
import SparkLineChart from "../../../common/Charts/SparkLineChart";
import VulnerabilityOperationTable from "../table/VulnerabilityOperationTable";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import VocBarChart from "../../../common/Charts/VocBarChart";
import HorizontalBarChart from "../../../common/Charts/HorizontalBarChart";
import { VOCFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { useLocation, useNavigate } from "react-router-dom";
import { getFilter } from "../../../utils/utils";

const VulnerabilityOperationComponents = ({ type = null }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const scanID = userDetails.scan_id;

  const [vocStatus, setVocStatus] = useState([]);
  const [vocStatusOpen, setVocStatusOpen] = useState([]);
  const [vocSeverity, setVocSeverity] = useState([
    "Critical",
    "High",
    "Medium",
    "Low",
  ]);
  const [vocSeverityOpen, setVocSeverityOpen] = useState([]);
  const [vulnerabilityTableData, setVulnerabilityTableData] = useState([]);
  const [loadingVocTable, setLoadingVocTable] = useState(true);
  const [exploitStatusData, setExploitStatusData] = useState([]);
  const [vocStatusTotal, setVocStatusTotal] = useState();
  const [vocSeverityTotal, setVocSeverityTotal] = useState();
  const [exploitTotal, setExploitTotal] = useState();
  const [vocLegends, setVocLegends] = useState([]);
  const [severityLegends, setSeverityLegends] = useState([]);
  const [exploitLegends, setExploitLegends] = useState([]);
  const [meanTimeValue, setMeanTimeValue] = useState();
  const [meanTimeData, setMeanTimeData] = useState();
  const [meanTimePercentage, setMeanTimePercentage] = useState();
  const [averageAgeData, setAverageAgeData] = useState();
  const [averageAgeValue, setAverageAgeValue] = useState();
  const [averageAgePercentage, setAverageAgePercentage] = useState();
  const [FilterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });

  const vocByStatus = (payload) => {
    DashboardApi.dashboardVOCStatus(payload).then((data) => {
      setVocStatusTotal(data.count);
      const vocOpen = data.data.severity_open;
      const vocClose = data.data.severity_closed;
      const vocInprogress = data.data.severity_progress;
      const vocRisk = data.data.severity_risk;
      const vocData = [vocOpen, vocClose, vocInprogress, vocRisk];
      const vocOpenStatus = `Opened - ${vocOpen}`;
      const vocCloseStatus = `Closed - ${vocClose}`;
      const vocInprogressStatus = `In progress - ${vocInprogress}`;
      const vocRiskStatus = `Risk accepted - ${vocRisk}`;
      const vocDataStatus = [
        vocOpenStatus,
        vocCloseStatus,
        vocInprogressStatus,
        vocRiskStatus,
      ];
      const vocLegendStatus = [
        { name: vocOpenStatus, color: "#E8001D" },
        { name: vocCloseStatus, color: "#148842" },
        { name: vocInprogressStatus, color: "#F59107" },
        { name: vocRiskStatus, color: "#7B7DE2" },
      ];
      setVocStatus([...vocDataStatus]);
      setVocStatusOpen([...vocData]);
      setVocLegends([...vocLegendStatus]);
    });
  };

  const vocBySeverity = (payload) => {
    DashboardApi.dashboardVOCSeverity(payload).then((data) => {
      setVocSeverityTotal(data.count);
      const vocCritical = data.data.severity_critical;
      const vocHigh = data.data.severity_high;
      const vocMedium = data.data.severity_medium;
      const vocLow = data.data.severity_low;
      const vocData = [vocCritical, vocHigh, vocMedium, vocLow];
      const vocCriticalStatus = `Critical - ${vocCritical}`;
      const vocHighStatus = `High - ${vocHigh}`;
      const vocMediumStatus = `Medium - ${vocMedium}`;
      const vocLowStatus = `Low - ${vocLow}`;
      const severityLegendData = [
        { name: vocCriticalStatus, color: "#A30123" },
        { name: vocHighStatus, color: "#E8001D" },
        { name: vocMediumStatus, color: "#F5B207" },
        { name: vocLowStatus, color: "#148842" },
      ];
      setSeverityLegends([...severityLegendData]);
      // setVocSeverity([...vocDataStatus])
      setVocSeverityOpen([...vocData]);
    });
  };

  const exploitByStatus = (payload) => {
    DashboardApi.dashboardWebVulnStatus(payload).then((data) => {
      setExploitTotal(data.count);
      const exploitAvailable = data.data.server_vuln;
      const exploitWild = data.data.web_vuln;
      const exploitData = [exploitAvailable, exploitWild];
      const vocExploitWildStatus = `Web Application - ${exploitWild}`;
      const vocExploitAvailableStatus = `Web Server - ${exploitAvailable}`;
      const exploitLegendData = [
        {
          name: vocExploitWildStatus,
          color: "#FDCA3B",
          onClick: () =>
            navigate(
              "/attack-surface-management/web-application-vulnerabilities",
              { state: { from: location.pathname } }
            ),
        },
        {
          name: vocExploitAvailableStatus,
          color: "#F14D34",
          onClick: () =>
            navigate("/attack-surface-management/web-server-vulnerabilities", {
              state: { from: location.pathname },
            }),
        },
      ];
      setExploitLegends([...exploitLegendData]);
      setExploitStatusData([...exploitData]);
    });
  };

  const vocTableData = () => {
    setLoadingVocTable(true);
    // TableApi.vulnerabilityIntelligence(payload).then((data) => {
    //   if (data.status === "success") {
    //     setVulnerabilityTableData(data.data);
    //     setLoadingVocTable(false);
    //   }
    // });
    // let filter = null;
    // if (url.includes("web-server-vulnerabilities")) {
    //   filter = { ...filterParams, type: "network" };
    // } else if (url.includes("web-application-vulnerabilities")) {
    //   filter = { ...filterParams, type: "web" };
    // } else {
    //   filter = filterParams;
    // }
    VOCFilterAPI.post({
      ...getFilter(FilterData, filterParams),
      type,
      scanID: scanID,
    })
      .then((res) => {
        setVulnerabilityTableData(res.data);
        setLoadingVocTable(false);
      })
      .catch((err) => console.log(err));
  };
  const agevalue = { critical: 10, low: 20, high: 50, medium: 25 };

  const getAverageAgeVoc = (payload) => {
    DashboardApi.dashboardAverageAgeVocApi(payload).then((data) => {
      console.log("REPSONDEAverageAge", data, Object.values(data.data.avg_age));
      if (data.status === "success") {
        setAverageAgeValue(data.data.avg_age);
        let averageAge = Object.values(data.data.avg_age);
        setAverageAgeData(averageAge);
        setAverageAgePercentage(data.data.percentage_per_severity);
      }
    });
  };

  const getMeanTimeRemediate = (payload) => {
    DashboardApi.dashboardMeanTimeRemediateApi(payload).then((data) => {
      console.log("REPSONDEMealTime", data);
      if (data.status === "success") {
        setMeanTimeValue(data.data.mttr);
        let meanTime = Object.values(data.data.mttr);
        setMeanTimeData(meanTime);
        setMeanTimePercentage(data.data.percentage);
      }
    });
  };

  const getTableFilterData = () => {
    VOCFilterAPI.get({ scanID, type })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data, scanID: scanID });
      })
      .catch((err) => console.log(err));
  };

  const reloadData = () => {
    setLoadingVocTable(true);
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    vocByStatus(payload);
    vocBySeverity(payload);
    getTableFilterData();
    exploitByStatus(payload);
    getAverageAgeVoc(payload);
    getMeanTimeRemediate(payload);
  };

  useEffect(() => {
    reloadData();
  }, [userDetails]);

  useEffect(() => {
    if (FilterData) vocTableData();
  }, [filterParams, FilterData]);

  return (
    <div className="lg:grid lg:grid-cols-4 flex flex-col gap-6">
      {!type && (
        <>
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <DonutChart
                title="Risks by severity"
                type="normal"
                labels={vocSeverity}
                colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
                chartData={vocSeverityOpen}
                conditionType="Issues"
                total={vocSeverityTotal}
                legends={severityLegends}
                navigateLink="/vulnerability-operation-center"
                tooltip="Vulnerabilities are classified into distinct categories, allowing for clear risk assessment and effective prioritization."
              />
            </div>
          </div>
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <DonutChart
                title="Vulnerabilities by status"
                type="normal"
                labels={vocStatus}
                colors={["#E8001D", "#148842", "#F59107", "#7B7DE2"]}
                chartData={vocStatusOpen}
                conditionType="Issues"
                total={vocStatusTotal}
                legends={vocLegends}
                navigateLink="/vulnerability-operation-center"
                tooltip="Vulnerability intelligence sorts vulnerabilities by handling status, delivering a concise snapshot of security risk management."
              />
            </div>
          </div>
          <div
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <DonutChart
                title="Vulnerability Breakdown"
                type="normal"
                labels={vocSeverity}
                colors={["#F14D34", "#FDCA3B"]}
                chartData={exploitStatusData}
                conditionType="Total"
                total={exploitTotal}
                legends={exploitLegends}
                navigateLink="/attack-surface-management/web-vulnerabilities"
                tooltip="This Module tracks and analyzes emerging cyber threats from various sources, correlating them with organizational vulnerabilities for targeted defense prioritization."
              />
            </div>
          </div>
          <div className="lg:col-span-1 flex flex-col gap-6">
            <div
              className={`${
                isDarkMode ? "header-dark" : "bg-white"
              } lg:h-[50%] overflow-hidden h-[11.8rem] w-full rounded-2xl relative`}
            >
              {/* <VocBarChart title="Exploits Available" critical={meanTimeValue.critical} 
          low={meanTimeValue.low} medium={meanTimeValue.medium} high={meanTimeValue.high}/> */}
              {/* <VocBarChart title="Average Age of Vulnerabilities" 
           categories={averageAgeValue}
           /> */}
              <VocBarChart
                title="Average Age of Vulnerabilities"
                data={averageAgeData}
                // data={[38.2,17.9,14.0,4.9]}
                value={averageAgeValue}
                categories={["Critical", "High", "Medium", "Low"]}
                tooltip="Track the average age of vulnerabilities to assess persistence duration and remediation effectiveness."
              />

              {/* <SparkLineChart
            data={[40, 20, 35, 38, 60, 70, 15]}
            title="Exploits Available"
            growth="21.0 (-35.7%)"
            growthType="negative"
            startDate="May27, 2023: 59, 0 days"
            currentDate="Aug 18"
            totalDays="37.9"
          /> */}
            </div>
            <div
              className={`${
                isDarkMode ? "header-dark" : "bg-white"
              } lg:h-[50%] overflow-hidden h-[11.8rem] w-full rounded-2xl relative`}
            >
              <VocBarChart
                title="Mean Time to Remediate"
                data={meanTimeData}
                value={meanTimeValue}
                // data={[38.2,17.9,14.0,4.9]}
                categories={["Critical", "High", "Medium", "Low"]}
                //  critical={meanTimeValue?.critical}
                // low={meanTimeValue?.low}
                // medium={meanTimeValue?.medium}
                // high={meanTimeValue?.high}
                tooltip="Monitor Mean Time to Remediate (MTTR) to ensure timely addressing of vulnerabilities."
              />

              {/* 
          <SparkLineChart
            data={[40, 20, 35, 38, 60, 70, 15]}
            title="Average Age of Vulnerabilities"
            growth="31.4 (35.2%)"
            growthType="positive"
            startDate="May27, 2023: 37, 0 days"
            currentDate="Aug 18"
            totalDays="51.2"
          /> */}
            </div>
          </div>
        </>
      )}
      <div className="lg:col-span-4 md:col-span-1">
        <div
          className={`${
            isDarkMode ? "header-dark" : "bg-white"
          } lg:h-fit w-full rounded-2xl mt-6 `}
        >
          <VulnerabilityOperationTable
            vulnerabilityTableData={vulnerabilityTableData}
            loadingVocTable={loadingVocTable}
            vocByStatus={vocByStatus}
            userDetails={userDetails}
            vocTableData={vocTableData}
            FilterData={FilterData}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            getTableFilterData={reloadData}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityOperationComponents;
