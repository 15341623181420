import { Sidebar } from "primereact/sidebar";
import PdfViewer from "./PdfViewer";

export default function PdfViewerSideBar({ viewPDF, setViewPDF }) {
  return (
    <Sidebar
      visible={viewPDF}
      position="right"
      onHide={() => setViewPDF(null)}
      className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
    >
      <PdfViewer viewPDF={viewPDF} setViewPDF={setViewPDF} />
    </Sidebar>
  );
}
