import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { toast } from "react-toastify";

export default function AssignAgent({ visibleRight, handleCloseSideBar }) {
  const [agent, setAgent] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);

  useEffect(() => {
    DashboardApi.getAgentsApi()
      .then((res) => setAgent(res.data))
      .catch((err) => console.log(err));
    DashboardApi.getTargetAgentsApi(visibleRight)
      .then((res) => setSelectedAgent(res.data.map((item) => item.id)))
      .catch((err) => console.log(err));
  }, []);

  const onUpdate = (e) => {
    DashboardApi.updateTargetAgent(visibleRight, { agents: e })
      .then((res) => {
        toast.success("Agent Updated Successfully");
        setSelectedAgent(e);
        handleCloseSideBar();
      })
      .catch((err) => {
        toast.error("Agent Update Failed");
        console.log(err);
      });
  };

  return (
    <>
      <div className="w-full mt-3">
        <label className="block text-gray-500 text-sm ">Project Agents</label>
        <MultiSelect
          className="w-full"
          optionLabel="username"
          optionValue="id"
          value={selectedAgent}
          options={agent}
          filter
          placeholder="Select Agents"
          onChange={(e) => setSelectedAgent(e.value)}
        />
        <div className="flex w-full pt-5">
          <button
            // disabled={loading}
            onClick={() => onUpdate(selectedAgent)}
            className={`p-1 border rounded-[16px] border-[#AAAAAA] button-bg-color h-[40px] w-full bg-[#181818] text-[#ffff]`}
            type="submit"
          >
            <span> Save</span>
          </button>
          <button
            onClick={() => handleCloseSideBar()}
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2 "
            type="button"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
}
