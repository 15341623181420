import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import HandleResponseError from "../../utils/error";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { isAdminOrSuperAdmin } from "../../utils/accessCheck";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import UserInformationForm from "../sidebar/components/UserInformationForm";

export default function AddUser({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState("");
  const userDetails = useSelector(getUserDetails);

  const ROLES = [
    {
      label: "Super User",
      value: "Super User",
    },
    {
      label: "MT Admin",
      value: "MT Admin",
    },
  ]

  const handleCreate = (e) => {
    e.preventDefault();
    TableApi.createUser({
      email: email,
      role: role
    })
      .then((res) => {
        if (!res.error) {
          toast.success("User Created Successfully")
          onHide();
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        HandleResponseError(error);
      })
      .finally(() => {
        e.target.submit.removeAttribute("//disabled");
      });
  };

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Add"
      } User`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } User`}</p>
      
      {
        // Add
        !currentDetails 
        ? (
          <div className="flex flex-wrap my-2 gap-2">
          <form
            onSubmit={(e) => handleCreate(e)}
            className="w-full flex flex-col gap-3"
          >
            {/* Email */}
            <div className="w-full">
                <label className="block text-gray-500 text-sm ">Email</label>
                <input
                  className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                  type="email"
                  placeholder="Email"
                  value={data.email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error.name && (
                  <p className="text-red-500 text-sm">{error.name}</p>
                )}
            </div>
            {/* Roles */}
            {
              isAdminOrSuperAdmin(userDetails.role) && (
              <div className="w-full pt-3">
                <label className="block text-gray-500 text-sm">Role</label>
                <Dropdown
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  options={ROLES}
                  optionLabel="label"
                  optionvalue="value"
                  placeholder="Select"
                  className="w-full md:w-14rem my-1"
                />
              </div>
              )
            }
            {/* Buttons */}
            <div className="flex w-full mt-9">
              <button
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                type="submit"
                id="submit"
              >
                {currentDetails ? "Save" : "Add"}
              </button>
              <button
                className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
                onClick={cleanClose}
                type="reset"
              >
                Back
              </button>
            </div>
          </form>
          </div>
        )
        // Edit
        : 
        <UserInformationForm
          handleCloseSideBar={cleanClose}
          showRoles={false}
         />
      }

    </Sidebar>
  );
}
