import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import VerticalBarChart from "../../../common/Charts/VerticalBarChart";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import GeoChartCountry from "./GeoChartCountry";

const Hosts = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [complianceData, setComplianceData] = useState([]);
  const [dnsRecordData, setDNSRecordData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const userDetails = useSelector(getUserDetails);
  const dnsRecordLabels = ["A", "AAAA", "CNAME", "MX", "NS", "SOA", "TXT"];

  const getCompliance = (payload) => {
    DashboardApi.dashboardCompliancesData(payload).then((data) => {
      const iso = data.data.iso;
      const pci = data.data.pci;
      const soc = data.data.soc;
      const nist = data.data.nist;
      const owasp = data.data.owasp;
      const compliance = [iso, pci, soc, nist, owasp];
      setComplianceData([...compliance]);
    });
  };

  const getCountryData = (payload) => {
    DashboardApi.countryApi(payload).then((data) => {
      if (data.status === "success") {
        setCountryData(data.data);
      }
    });
  };
  const getDNSRecordData = (payload) => {
    DashboardApi.getDNSrecordApi(payload).then((data) => {
      if (data.status === "success") {
        let vocData = Object.values(data.data);
        setDNSRecordData(vocData);
      }
    });
  };

  const handleNavigate = (e, type) => {
    e.preventDefault();
    if (type === "dns-record") {
      navigate(`/attack-surface-management/${type}`);
    }
  };

  useEffect(() => {
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    getCompliance(payload);
    getCountryData(payload);
    getDNSRecordData(payload);
  }, [userDetails]);

  return (
    <div className="pb-10">
      <div className="pb-6">
        <h1 className="text-2xl font-bold">Hosts</h1>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-6 flex flex-col gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <GeoChartCountry
            title="Hosts by country"
            countryData={countryData}
            isDarkMode={isDarkMode}
            tooltip="Display a digital map highlighting host locations by country"
          />
        </div>
        <div
          onClick={(e) => handleNavigate(e, "dns-record")}
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl cursor-pointer`}
        >
          <VerticalBarChart
            title="DNS Record Types"
            data={dnsRecordData}
            labels={dnsRecordLabels}
            lockedWidget={true}
            tooltip="Track DNS record types for robust security oversight and threat detection."
            backgroundColors={[
              "#449DD1",
              "#F86624",
              "#EA3546",
              "#662E9B",
              "#C5D86D",
              "#449DD1",
              "#F86624",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Hosts;
