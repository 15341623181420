import React, {useState} from 'react'
import { useEffect } from 'react';

const Checkbox = ({ name, value,data, updateValue = ()=>{}, children }) => {
    const [checked, setChecked] = useState(false);

    const handleChange = (e) => {
        // e.preventDefault()
        console.log("selectAllhandleChange",value, name, data, e.target.id)
        updateValue(!value, name, data);
      };
     
      useEffect(()=>{
        // if(name === "all"){
        //     updateValue(value, name, data);
        // }

      },[])

  return (
    <div className="py-2">
    <input type="checkbox" id={`${name}-checkbox`} name={name} checked={value} onChange={(e)=>handleChange(e)} />
    <label for={`${name}-checkbox`} className="ml-1 capitalize">{children}</label>
  </div>
  )
}

export default Checkbox