import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setUserDetails } from "../../redux-toolkit/redux/auth";
import { UserIcon } from "../../assets/svg";

export default function OrganizationsList({ visibleOrg, setVisibleOrg }) {
  const userDetails = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const organizations = userDetails?.org || [];

  const setOrg = (id) => {
    dispatch(setUserDetails({ ...userDetails, org_id: id }));
    setVisibleOrg(false);
  };

  return (
    <Sidebar
      visible={visibleOrg}
      position="right"
      onHide={() => setVisibleOrg(false)}
    >
      <p className="font-bold text-2xl text-center">Select Organization</p>
      <ol className="relative border-l-2 mt-4">
        {organizations?.map((item) => (
          <li key={item.id} className="mb-10 ml-6">
            <div
              className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}
            ></div>
            <div
              onClick={() => {
                setOrg(item.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="py-2 flex gap-2 items-center">
                <img src={UserIcon} alt="user" />
                <h1 className="text-color text-xs">{item.email}</h1>
              </div>
              {userDetails.org_id == item.id && (
                <div>
                  <p className="ml-10 text-[12px] text-[#F14D34] font-normal">
                    Current on display
                  </p>
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </Sidebar>
  );
}
