import React from 'react'

const PageNotFound = () => {
  return (
    <>
    <div className='flex justify-center pt-10'>
      <p className='text-2xl font-extrabold'>OPPS! PAGE NOT FOUND</p>
      
    </div>
    <div className='text-center'>
      <p>Sorry, the page you are looking for doesn't exist.</p>
    </div>
    </>
  )
}

export default PageNotFound