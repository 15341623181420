import baseAPI, {baseUrl} from '../Repository.js' //just axios instance
import { removeAuthenticationData } from "../../utils/constants";


class TableFilterApiBase{
    constructor(path) {
        this.path = path;
        this.baseUrl = baseUrl
        this.endpoint = this.baseUrl + this.path
    }

    get(params){
        return new Promise((resolve, reject) => {
            baseAPI.get(this.endpoint, {params})
            .then(res => resolve(res.data))
            .catch(err => {
                if (err.response.status === 401)  removeAuthenticationData() //do this in axios instance itself if it do globally same
                else reject(err)
            }) // for now
        })
    }
    
    post(params){
        return new Promise((resolve, reject) => {
            baseAPI.post(this.endpoint, params)
            .then(res => resolve(res.data))
            .catch(err=> {
                if (err.response.status === 401)  removeAuthenticationData()
                else reject(err)
            }) // for now
        })
    }
}


export default TableFilterApiBase;