import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import DonutChart from "../../../common/Charts/DonutChart";
import HorizontalBarChart from "../../../common/Charts/HorizontalBarChart";
import { useNavigate } from "react-router-dom";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import ApplicationVulnerabilities from "./ApplicationVulnerabilities";
import NetworkVulnerabilities from "./NetworkVulnerabilities";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import Listing from "../../../common/Listing";

const VulnerabilityIntelligence = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const userDetails = useSelector(getUserDetails);

  const handleNavigate = (e, type) => {
    e.preventDefault();
    if (type === "ssl-status") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "exploit-intelligence") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "technologies") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "open-ports") {
      navigate(`/attack-surface-management/${type}`);
    }
  };

  const [vocStatus, setVocStatus] = useState([]);
  const [vocStatusOpen, setVocStatusOpen] = useState([]);
  const [vocSeverity, setVocSeverity] = useState([
    "Critical",
    "High",
    "Medium",
    "Low",
  ]);
  const [sslStatusLabel, setSslStatusLabel] = useState([
    "Active",
    "Expired",
    "Expire soon",
  ]);
  const [sslStatusData, setSslStatusData] = useState([]);
  const [exploitStatusLabel, setExploitStatusLabel] = useState([
    "Exploits",
    "Exploitability",
  ]);
  const [exploitStatusData, setExploitStatusData] = useState([]);
  const [vocSeverityOpen, setVocSeverityOpen] = useState([]);
  const [vulnerabilityTableData, setVulnerabilityTableData] = useState([]);
  const [loadingVocTable, setLoadingVocTable] = useState(false);
  const [vocStatusTotal, setVocStatusTotal] = useState();
  const [vocSeverityTotal, setVocSeverityTotal] = useState();
  const [sslTotal, setSSlTotal] = useState();
  const [exploitTotal, setExploitTotal] = useState();
  const [vocLegends, setVocLegends] = useState([]);
  const [severityLegends, setSeverityLegends] = useState([]);
  const [exploitLegends, setExploitLegends] = useState([]);
  const [sslLegends, setSslLegends] = useState([]);
  const [applicationVulnerability, setApplicationVulnerability] = useState([]);
  const [networkVulnerability, setNetworkVulnerability] = useState([]);
  const [technologiesData, setTechnologiesData] = useState([]);
  const [technologiesCategories, setTechnologiesCategories] = useState([]);
  const [exposedCriticalData, setExposedCriticalData] = useState([]);
  const [exposedCriticalCategories, setExposedCriticalCategories] = useState(
    []
  );

  const vocByStatus = (payload) => {
    DashboardApi.dashboardVOCStatus(payload).then((data) => {
      setVocStatusTotal(data.count);
      const vocOpen = data.data.severity_open;
      const vocClose = data.data.severity_closed;
      const vocInprogress = data.data.severity_progress;
      const vocRisk = data.data.severity_risk;
      const vocData = [vocOpen, vocClose, vocInprogress, vocRisk];
      const vocOpenStatus = `Opened - ${vocOpen}`;
      const vocCloseStatus = `Closed - ${vocClose}`;
      const vocInprogressStatus = `In progress - ${vocInprogress}`;
      const vocRiskStatus = `Risk accepted - ${vocRisk}`;
      const vocDataStatus = [
        vocOpenStatus,
        vocCloseStatus,
        vocInprogressStatus,
        vocRiskStatus,
      ];
      const vocLegendStatus = [
        { name: vocOpenStatus, color: "#E8001D" },
        { name: vocCloseStatus, color: "#148842" },
        { name: vocInprogressStatus, color: "#F59107" },
        { name: vocRiskStatus, color: "#7B7DE2" },
      ];
      setVocLegends([...vocLegendStatus]);
      setVocStatus([...vocDataStatus]);
      setVocStatusOpen([...vocData]);
    });
  };

  const vocBySeverity = (payload) => {
    DashboardApi.dashboardVOCSeverity(payload).then((data) => {
      setVocSeverityTotal(data.count);
      let vocData = Object.values(data.data);
      console.log("DADASD", vocData, data);
      const vocCritical = data.data.severity_critical;
      const vocHigh = data.data.severity_high;
      const vocMedium = data.data.severity_medium;
      const vocLow = data.data.severity_low;
      const vocCriticalStatus = `Critical - ${vocCritical}`;
      const vocHighStatus = `High - ${vocHigh}`;
      const vocMediumStatus = `Medium - ${vocMedium}`;
      const vocLowStatus = `Low - ${vocLow}`;
      const severityLegendData = [
        { name: vocCriticalStatus, color: "#A30123" },
        { name: vocHighStatus, color: "#E8001D" },
        { name: vocMediumStatus, color: "#F5B207" },
        { name: vocLowStatus, color: "#148842" },
      ];
      setSeverityLegends([...severityLegendData]);
      setVocSeverityOpen(vocData);
    });
  };

  const sslByStatus = (payload) => {
    DashboardApi.dashboardSSLStatus(payload).then((data) => {
      setSSlTotal(data.count);
      const sslActive = data.data.active;
      const sslExpireSoon = data.data.expire_soon;
      const sslExpired = data.data.expired;
      const sslData = [sslActive, sslExpireSoon, sslExpired];
      const vocActiveStatus = `Active - ${sslActive}`;
      const vocExpiredStatus = `Expired - ${sslExpired}`;
      const vocsslExpireSoonStatus = `Expire soon - ${sslExpireSoon}`;
      const exploitLegendData = [
        { name: vocActiveStatus, color: "#148842" },
        { name: vocExpiredStatus, color: "#E8001D" },
        { name: vocsslExpireSoonStatus, color: "#F14D34" },
      ];
      setSslLegends([...exploitLegendData]);
      setSslStatusData([...sslData]);
    });
  };

  const exploitByStatus = (payload) => {
    DashboardApi.dashboardExploitsStatus(payload).then((data) => {
      setExploitTotal(data.count);
      const exploitAvailable = data.data.exploit_available;
      const exploitWild = data.data.exploit_wild;
      const exploitData = [exploitAvailable, exploitWild];
      const vocExploitWildStatus = `Exploits - ${exploitAvailable}`;
      const vocExploitAvailableStatus = `Exploitability - ${exploitWild}`;
      const exploitLegendData = [
        { name: vocExploitWildStatus, color: "#F14D34" },
        { name: vocExploitAvailableStatus, color: "#FDCA3B" },
      ];
      setExploitLegends([...exploitLegendData]);
      setExploitStatusData([...exploitData]);
    });
  };

  const getApplicationVulnerability = (payload) => {
    DashboardApi.applicationVulnerabilityApi(payload).then((data) => {
      if (data.status === "success") {
        setApplicationVulnerability(data.data);
      }
    });
  };

  const getNetworkVulnerability = (payload) => {
    DashboardApi.networkVulnerabilityApi(payload).then((data) => {
      if (data.status === "success") {
        setNetworkVulnerability(data.data);
      }
    });
  };

  const getExposedCriticalPorts = (payload) => {
    DashboardApi.exposedCriticalPortsApi(payload).then((data) => {
      if (data.status === "success") {
        //   const exposedPort = data.data.map((port) => port.ports);
        //   const exposedCount = data.data.map((port) => port.count);
        setExposedCriticalData(
          data.data.map((item) => {
            return {
              key: item.ports.toUpperCase(),
              value: item.count,
              onClick: () =>
                navigate("/attack-surface-management/open-ports", {
                  state: { port_service: item.ports, critical_port: "True" },
                }),
            };
          })
        );
        // setExposedCriticalCategories(exposedPort);
      }
    });
  };

  const getTechnologies = (payload) => {
    DashboardApi.technologiesApi(payload).then((data) => {
      if (data.status === "success") {
        // const technology = data.data.map((tech) => tech.technology);
        // const count = data.data.map((tech) => tech.count);
        setTechnologiesData(
          data.data.map((item) => {
            return { key: item.technology, value: item.count };
          })
        );
        // setTechnologiesCategories(technology);
      }
    });
  };

  useEffect(() => {
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    vocByStatus(payload);
    vocBySeverity(payload);
    sslByStatus(payload);
    exploitByStatus(payload);
    getApplicationVulnerability(payload);
    getNetworkVulnerability(payload);
    getExposedCriticalPorts(payload);
    getTechnologies(payload);
  }, [userDetails]);

  return (
    <div>
      <div className="pb-6">
        <h1 className="text-2xl font-bold">Vulnerability Intelligence</h1>
      </div>
      <div className="lg:grid lg:grid-cols-4 lg:gap-6 flex flex-col gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Risks by severity"
              type="normal"
              labels={vocSeverity}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
              chartData={vocSeverityOpen}
              conditionType="Issues"
              total={vocSeverityTotal}
              legends={severityLegends}
              navigateLink="/vulnerability-operation-center"
              tooltip="Vulnerabilities are classified into distinct categories, allowing for clear risk assessment and effective prioritization."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Vulnerabilities by status"
              type="normal"
              labels={vocStatus}
              colors={["#E8001D", "#148842", "#F59107", "#7B7DE2"]}
              chartData={vocStatusOpen}
              conditionType="Issues"
              total={vocStatusTotal}
              legends={vocLegends}
              navigateLink="/vulnerability-operation-center"
              tooltip="Vulnerability intelligence sorts vulnerabilities by handling status, delivering a concise snapshot of security risk management."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="SSL"
              type="normal"
              labels={sslStatusLabel}
              colors={["#148842", "#E8001D", "#F14D34"]}
              chartData={sslStatusData}
              conditionType="Total"
              total={sslTotal}
              legends={sslLegends}
              navigateLink="/attack-surface-management/ssl-status"
              tooltip="SSL monitoring provides insights on active, expiring soon, and expired SSL certificates for comprehensive certificate management."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Exploits"
              type="normal"
              labels={exploitStatusLabel}
              colors={["#F14D34", "#FDCA3B"]}
              chartData={exploitStatusData}
              conditionType="Total"
              total={exploitTotal}
              legends={exploitLegends}
              navigateLink="/attack-surface-management/exploit-intelligence"
              tooltip="This Module tracks and analyzes emerging cyber threats from various sources, correlating them with organizational vulnerabilities for targeted defense prioritization."
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="lg:grid lg:grid-cols-4 lg:gap-6 flex flex-col gap-6">
          <div
            // onClick={() => navigate(`/vulnerability-operation-center`)}
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl cursor-pointer`}
          >
            <ApplicationVulnerabilities
              applicationVulnerability={applicationVulnerability}
              isDarkMode={isDarkMode}
            />
          </div>

          <div
            // onClick={() => navigate(`/vulnerability-operation-center`)}
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl cursor-pointer`}
          >
            <NetworkVulnerabilities
              networkVulnerability={networkVulnerability}
              isDarkMode={isDarkMode}
            />
          </div>
          <div
            // onClick={(e) => handleNavigate(e, "open-ports")}
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            {/* <HorizontalBarChart
              title="Exposed Critical Ports"
              data={exposedCriticalData}
              categories={exposedCriticalCategories}
              tooltip="Monitor exposed critical ports to enhance network security and mitigate potential risks."
            /> */}
            <Listing
              title="Exposed Critical Ports"
              data={exposedCriticalData}
              tooltip="Monitor exposed critical ports to enhance network security and mitigate potential risks."
            />
          </div>
          <div
            onClick={(e) => handleNavigate(e, "technologies")}
            className={`lg:col-span-1 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl cursor-pointer`}
          >
            {/* <HorizontalBarChart
              title="Technologies"
              data={technologiesData}
              categories={technologiesCategories}
              tooltip="Monitor technologies to ensure effective management and enhance security measures."
            /> */}
            <Listing
              title="Technologies"
              data={technologiesData}
              tooltip="Monitor technologies to ensure effective management and enhance security measures."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityIntelligence;
