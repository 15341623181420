import React, { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";
import { DownloadIcon } from "../../assets/svg";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import { BsDot } from "react-icons/bs";
import { FiArrowUpRight } from "react-icons/fi";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { DateTimeToDate } from "../../helpers";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../utils/paginationTemplate";

const WhoisTableData = (props) => {
  const {
    columns,
    userInformation,
    dt,
    globalFilter,
    setVisibleRight,
    action,
    invoice,
    setTableViewData,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [openVulnerabilitySideBar, setOpenVulnerabilitySideBar] =
    useState(false);
  const [loadingSidebarVocDetail, setLoadingSidebarVocDetail] = useState(false);
  const [sideBarVocIntelligenceDetail, setSideBarVocIntelligenceDetail] =
    useState({});

  useEffect(() => {
    setProducts(userInformation);
  }, [userInformation]);
  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const infoBody = (rowData) => {
    return (
      <button onClick={() => handleShowDetailVulnerability(rowData)}>
        <FiArrowUpRight size={20} color="#074EE8" />
      </button>
    );
  };

  const handleShowDetailVulnerability = (rowData) => {
    console.log("userInformation", userInformation);
    let response = Object.assign({}, userInformation[0]);
    console.log("userInformation789", response);
    setOpenVulnerabilitySideBar(true);
    setLoadingSidebarVocDetail(true);
    // setDNSTableData(res.data.map(item => {
    //   return {
    //     ...item,
    //     date_added: DateTimeToDate(item.date_added)
    //   }
    // }));
    setTimeout(() => {
      setSideBarVocIntelligenceDetail(response);
      setLoadingSidebarVocDetail(false);
    }, 1000);
    // TableApi.vulnerabilityIntelligenceDetail(rowData).then((data) => {
    //   if (data.status === "success") {
    //     setSideBarVocIntelligenceDetail(data.data);
    //     setLoadingSidebarVocDetail(false);
    //   }
    // });
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <>
      <DataTable
        removableSort
        value={products}
        ref={dt}
        selection={selectedUsers}
        onSelectionChange={(e) => setSelectedUsers(e.value)}
        paginator
        rows={10}
        globalFilter={globalFilter}
        paginatorTemplate={paginationTemplate()}
        className={`${
          isDarkMode ? "dark-mode text-dark" : " text-color"
        } text-xs`}
      >
        <Column selectionMode="multiple" exportable={false}></Column>
        {columns.map((col, i) => {
          if (col?.type === "date") {
            return (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                body={(rowData, options) =>
                  DateTimeToDate(rowData[options.field])
                }
              />
            );
          } else {
            return (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
              />
            );
          }
        })}
        <Column
          header="Full info"
          body={(rowData) => infoBody(rowData)}
          exportable={false}
        />
      </DataTable>
      <div>
        <SidebarSlide
          title="Whois Information"
          visibleRight={openVulnerabilitySideBar}
          setVisibleRight={setOpenVulnerabilitySideBar}
          customWidth={true}
          sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
          loadingSidebarVocDetail={loadingSidebarVocDetail}
        />
      </div>
    </>
  );
};

export default WhoisTableData;
