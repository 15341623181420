import { createElement } from "react";

import CompromisedFiles from "./CompromisedFiles";
import CompromisedURL from "./CompromisedURL";
import CompromisedUser from "./CompromisedUser";
import ComputerCompromised from "./ComputerCompromised";
import EmployeeCompromised from "./EmployeeCompromised";
import EmployeeCredentials from "./EmployeeCredentials";
import ThirdPartyCredentials from "./ThirdPartyCredentials";
import ThirdPartyURL from "./ThirdPartyURL";

export default function DataBreachIntelligence() {
  const hash = window.location.hash;
  const TABLES = {
    ComputerCompromised,
    EmployeeCompromised,
    CompromisedUser,
    EmployeeCredentials,
    CompromisedURL,
    ThirdPartyURL,
    CompromisedFiles,
    ThirdPartyCredentials,
  };
  return (
    <div className="ssl-status ">
      <h1 className="font-bold">Data Breach Intelligence</h1>
      {!hash
        ? Object.values(TABLES).map((Table, index) => <Table key={index} />)
        : createElement(TABLES[hash.substring(1)])}
    </div>
  );
}
