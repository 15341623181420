import { TbArrowUpRight } from "react-icons/tb";
import { useNavigate } from "react-router";
import { useParams, Navigate } from "react-router-dom";

import GenericLayout from "../../common/layout/GenericLayout";
import Employee from "./people/Employee";
import Policy from "./Policy";
import Evidence from "./Evidence";
import Vendor from "./Vendor";
import Risk from "./Risk";
import CorrectiveAction from "./CorrectiveAction";
import Controls from "./Controls";
import Requirement from "./framework/Requirement";
import Framework from "./framework/Framework";
// import VaultFolder from "./VaultFolder";
import VaultFile from "./VaultFile";
import Compliance from "./Compliance";
import Audit from "./Audit";
import SecurityAwareness from "./people/SecurityAwareness/index";
import ViewVendor from "../../modules/home/components/compliance/vendor/ViewVendor";
import Vault from "./Vault";
import TrainingPortal from "./people/TrainingPortal";
import ViewEmployee from "../../modules/home/components/compliance/people/ViewEmployee";
import EmployeeCampaign from "../../modules/home/components/compliance/people/EmployeeCampaign";
import TrustCenter from "./TrustCenter";
import ViewAudit from "../../modules/home/components/compliance/audit/ViewAudit";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setUserDetails } from "../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../utils/accessCheck";
import { useState } from "react";
import OrganizationsList from "./OrganizationsList";
import ViewRisk from "../../modules/home/components/compliance/risk/ViewRisk";
import { Dropdown } from "primereact/dropdown";
import Training from "../../modules/home/components/compliance/people/Training";
import TemplateLibrary from "./TemplateLibrary";
import IncidentManagement from "./IncidentManagement";
import Report from "./Report";
import { report } from "../../assets/svg";

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUrl = window.location.href;
  const userDetails = useSelector(getUserDetails);
  const [visibleOrg, setVisibleOrg] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [frameworks, setFrameworks] = useState([
    {
      label: "ISO 27001",
      key: "iso_27001",
    },
    {
      label: "SOC 2",
      key: "soc_2",
    },
    {
      label: "PCI DSS",
      key: "pci_dss",
    },
    {
      label: "GDPR",
      key: "gdpr",
    },
    {
      label: "RM-IT",
      key: "rm_it",
    },
    {
      label: "HIPAA",
      key: "hipaa",
    },
    {
      label: "DPTM",
      key: "dptm",
    },
    {
      label: "NCA",
      key: "nca",
    },
    {
      label: "SAMA",
      key: "sama",
    },
  ]);

  const { slug, id } = useParams();

  let slugComponents = {};
  let slugComponentsWithId = {};
  let defaultView =
    userDetails?.role === "Employee" ? (
      <Navigate to={`/compliance/employee/${userDetails?.id}`} />
    ) : userDetails?.role === "Vendor" ? (
      <Navigate to={`/compliance/vendor/${userDetails?.id}`} />
    ) : (
      <Compliance frameworks={frameworks} setFrameworks={setFrameworks} />
    );

  if (userDetails.role === "Employee") {
    slugComponents = {
      employee: <Navigate to={`/compliance/employee/${userDetails?.id}`} />,
      "security-awareness": <SecurityAwareness />,
      "employee-campaign": <EmployeeCampaign />,
      // "security-awareness/training": <Training />,
    };
    slugComponentsWithId = {
      employee: <ViewEmployee />,
      "security-awareness/training": <Training />,
    };
  } else if (userDetails.role === "Vendor") {
    slugComponents = {
      vendor: <Navigate to={`/compliance/vendor/${userDetails?.id}`} />,
    };
    slugComponentsWithId = {
      vendor: <ViewVendor />,
    };
  } else {
    const MODULE_MAPPING = {
      policy: "Policy",
      evidence: "Evidence",
      vendor: "Vendor Management",
      risk: "Risk Management",
      framework: "Framework",
      controls: "Controls",
      employee: "People",
      audit: "Audit Center",
      vault: "Vault",
      // "vault-folder": <VaultFolder />,
      // "vault-file": <VaultFile />,
      "corrective-action": <CorrectiveAction />,
      "security-awareness": "People",
      "training-portal": "People",
      "employee-campaign": "People",
      "trust-center": "Trust Center",
      "template-library": <TemplateLibrary />,
      "incident-management": "Incident Management",
    };
    if (
      userDetails.role === "Editor" &&
      !userDetails.module.includes(MODULE_MAPPING[slug])
    ) {
      slugComponentsWithId = slugComponents = {
        [slug]: <div className="flex justify-center">Forbidden</div>,
        undefined: defaultView,
      };
    } else {
      slugComponents = {
        policy: <Policy />,
        evidence: <Evidence />,
        vendor: <Vendor />,
        risk: <Risk />,
        framework: <Framework />,
        controls: <Controls />,
        employee: <Employee />,
        audit: <Audit />,
        vault: <Vault />,
        // "vault-folder": <VaultFolder />,
        // "vault-file": <VaultFile />,
        "corrective-action": <CorrectiveAction />,
        "security-awareness": <SecurityAwareness />,
        "training-portal": <TrainingPortal />,
        "employee-campaign": <EmployeeCampaign />,
        "trust-center": <TrustCenter />,
        "template-library": <TemplateLibrary />,
        "incident-management": <IncidentManagement />,
      };

      slugComponentsWithId = {
        framework: <Requirement />,
        vendor: <ViewVendor />,
        vault: <VaultFile />,
        employee: <ViewEmployee />,
        audit: <ViewAudit />,
        risk: <ViewRisk />,
        "trust-center": <TrustCenter />,
      };
    }
  }

  const onFrameworkChange = (value) => {
    dispatch(setUserDetails({ ...userDetails, framework: value }));
  };

  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Compliance</p>
        <div className="flex justify-around gap-3">
          {!slug && (
            <>
              <Dropdown
                value={userDetails?.framework}
                onChange={(e) => onFrameworkChange(e.value)}
                options={[
                  { label: "All", value: null },
                  ...frameworks.filter((i) => i.value),
                ]}
                optionLabel="label"
                optionValue="value"
                placeholder="Select compliance"
                className="w-50 md:w-14rem my-1"
              />
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() => setShowReport(true)}
              >
                Report
                <img src={report} />
              </button>
            </>
          )}
          {slug ? (
            <div className="flex justify-between">
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() => {
                  // history?.length > 1 ? navigate(-1) : navigate("/compliance");
                  // loc;
                  // console.log(currentUrl.split("/").slice(3, -1).join("/"));
                  navigate(`/${currentUrl.split("/").slice(3, -1).join("/")}`);
                }}
              >
                Go to dashboard
                <TbArrowUpRight />
              </button>
            </div>
          ) : isAdminOrSuperAdmin(userDetails.role) ||
            userDetails.role === "Auditor" ||
            userDetails.role === "MT Admin" ? (
            <div className="flex justify-between">
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() => setVisibleOrg(true)}
              >
                Select Organizations
                <TbArrowUpRight />
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {id
        ? slugComponentsWithId[slug] || defaultView
        : slugComponents[slug] || defaultView}

      {isAdminOrSuperAdmin(userDetails.role) ||
      userDetails.role === "Auditor" ||
      userDetails.role === "MT Admin" ? (
        <OrganizationsList
          visibleOrg={visibleOrg}
          setVisibleOrg={setVisibleOrg}
        />
      ) : null}
      <Report showReport={showReport} setShowReport={setShowReport} />
    </GenericLayout>
  );
}
