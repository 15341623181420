import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Header } from "../../Component/Header";
import SideBar from "../../Component/sideBar/SideBar";
import VulnerabilityOperationComponents from "../../modules/vulnerabilityOperations/components/VulnerabilityOperationComponents";
import { TbArrowUpRight } from "react-icons/tb";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { getLoggedCheck, neutralizeBack } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInValue } from "../../redux-toolkit/redux/auth";
import DashboardApi from "../../Api/AuthenticationApi/DashboardApi";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import { HiDocumentReport } from "react-icons/hi";
import GenericLayout from "../../common/layout/GenericLayout";
import { report } from "../../assets/svg";

const VulnerabilityOperationCenter = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserDetails);

  const [visibleRight, setVisibleRight] = useState(false);
  const [scanHistoryData, setScanHistoryData] = useState([]);
  const [scanStatus, setScanStatus] = useState(false);
  const [visibleRightReport, setVisibleRightReport] = useState(false);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const getScanHistory = () => {
    DashboardApi.dashboardScanHistory().then((data) => {
      if (data.status === "success") {
        setScanHistoryData(data.data);
        data.data.filter((item) => {
          if (item.id === userDetails.scan_id) {
            setScanStatus(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    getScanHistory();
  }, []);

  useEffect(() => {
    if (getLoggedCheck()) {
      neutralizeBack(window);
      const user = localStorage.getItem("token");
      if (user) {
        const decodedJwt = parseJwt(user);
        if (decodedJwt.exp * 1000 < Date.now()) {
          dispatch(setLoggedInValue(false));
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }
  }, []);

  return (
    <>
      <GenericLayout>
        <div id="responsiveScreen">
          <div className="flex justify-between items-center dashboard-header">
            <p className=" font-bold">Vulnerability Operation Center</p>

            <div className="flex justify-between">
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base px-3"
                onClick={() => setVisibleRightReport(true)}
              >
                <img src={report} />
                Report
              </button>
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
                onClick={() => setVisibleRight(true)}
              >
                Scanning history
                <TbArrowUpRight />
              </button>
            </div>
          </div>

          <div>
            <VulnerabilityOperationComponents />
          </div>
        </div>
      </GenericLayout>

      <div>
        <SidebarSlide
          title="Scan History"
          dashboardPage="ASM"
          description="See previous scanning results"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
        />
      </div>

      <div>
        <SidebarSlide
          title="Report"
          description="Generate Tailored Reports: Effortlessly Select Dates and Preferences for In-Depth Insights and Executive Overviews."
          visibleRight={visibleRightReport}
          setVisibleRight={setVisibleRightReport}
        />
      </div>
    </>
  );
};

export default VulnerabilityOperationCenter;
