import { useContext, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { controls } from "./data";
import { Sidebar } from "primereact/sidebar";
import ControlView from "./ControlView";
import { Status } from "./Compliance";

export default function Controls({ _data }) {
  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);

  const getData = () => {
    complianceAPI
      .post("/trust-center/control/filter/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (_data) {
      setData(_data);
    } else {
      getData();
    }
  }, []);

  return (
    <>
      <div
        className={`rounded-2xl p-5 ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <p className="font-bold">Controls</p>
        <div className="grid grid-cols-4 gap-3 mt-3 ">
          {controls.map((control) => (
            <div className="flex flex-col gap-3 border rounded-md p-3 h-full">
              <div className="flex flex-col gap-1 justify-center items-center w-full h-[100px]">
                <div
                  dangerouslySetInnerHTML={{ __html: control.icon }}
                  className="w-8 h-8"
                  style={{
                    stroke: isDarkMode ? "white" : "black",
                  }}
                ></div>
                <p>{control.name}</p>
              </div>
              <div>
                {control.sub_category.slice(0, 3).map((i) => (
                  <div className="flex justify-between">
                    <p>{i.name}</p>
                    <Status
                      status={
                        data?.find((item) => item.control === i.name)
                          ?.implementation_level
                      }
                    />
                  </div>
                ))}
              </div>
              <p
                className="flex items-center self-end cursor-pointer"
                onClick={() => setVisible(control)}
              >
                View all <MdOutlineNavigateNext />
              </p>
            </div>
          ))}
        </div>
      </div>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
      >
        <p className="font-bold text-2xl">{data?.name}</p>
        <div className="flex flex-col gap-3">
          {visible?.sub_category?.map((item) => (
            <div className="border rounded-md gap-2 p-3 flex justify-between items-center">
              <p className="flex gap-1 items-center">
                <Status
                  status={
                    data?.find((i) => i.control === item.name)
                      ?.implementation_level
                  }
                />
                {item?.name}
              </p>
              {!_data && (
                <button
                  className="p-button-text flex p-1 gap- items-center rounded-md"
                  onClick={() =>
                    setCurrentDetails({
                      ...item,
                      control: data.name,
                      ...data?.find((i) => i.control === item.name),
                    })
                  }
                >
                  <FaRegEdit />
                  Edit
                </button>
              )}
            </div>
          ))}
        </div>
      </Sidebar>
      {!_data && (
        <ControlView
          currentDetails={currentDetails}
          onHide={() => {
            setCurrentDetails(null);
            getData();
          }}
        />
      )}
    </>
  );
}
