import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import {
  ArrowDownIconDark,
  ArrowDownIconLight,
  LogoDark,
  LogoLight,
  MoonIconDark,
  MoonIconLight,
  NotificationIconDark,
  NotificationIconLight,
  UserIcon,
} from "../../assets/svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  setLoggedInValue,
  setUserDetails,
} from "../../redux-toolkit/redux/auth";
import { AiOutlineClose } from "react-icons/ai";
// import { notifications } from "../../constants";
import { convertIsoToReadableFormat } from "../../helpers";
import AuthApi from "../../Api/AuthenticationApi/AuthApi";
import { removeAuthenticationData } from "../../utils/constants";
import { SidebarSlide } from "./SidebarSlide";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { setScanViewData } from "../../redux-toolkit/redux/Sidebar";
import { toast } from "react-toastify";
import { isAdminOrSuperAdmin } from "../../utils/accessCheck";

export const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [openVulnerabilitySideBar, setOpenVulnerabilitySideBar] =
    useState(false);
  const [loadingSidebarVocDetail, setLoadingSidebarVocDetail] = useState(false);
  const [sideBarVocIntelligenceDetail, setSideBarVocIntelligenceDetail] =
    useState(false);
  const [visibleScaningView, setVisibleScaningView] = useState(false);

  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const notificationRef = useRef();

  const userInformation = JSON.parse(localStorage.getItem("userDetails"));
  const userDetails = useSelector(getUserDetails);

  const getNotificationData = () => {
    AuthApi.getNotificationApi().then((data) => {
      setNotifications(data.data);
      setNotificationCount(data.count);
    });
  };
  const getReadNotification = () => {
    AuthApi.readNotificationApi().then((data) => {
      setNotifications(data.data);
      setNotificationCount(data.count);
    });
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef]);

  const onClickHandler = (data) => {
    console.log(data, "asdasd");
    if (data.module === "vulnerability") {
      setLoadingSidebarVocDetail(true);
      setOpenVulnerabilitySideBar(true);
      TableApi.vulnerabilityIntelligenceDetail(data).then((data) => {
        if (data.status === "success") {
          setSideBarVocIntelligenceDetail(data.data);
          setLoadingSidebarVocDetail(false);
        }
      });
    }

    if (data.module === "scan") {
      TableApi.getViewScanDataApi(data.targetID).then((data) => {
        if (data.status === "success") {
          dispatch(setScanViewData(data.data));
          setVisibleScaningView(true);
        } else {
          toast.error(data.message);
        }
      });
    }
  };

  return (
    <div
      className={`py-4 px-6 border-b-[1px] flex justify-between items-center ${
        isDarkMode ? "header-dark border-dark" : "border-color"
      }`}
    >
      <Link to="/">
        <img
          src={
            userDetails?.logo
              ? `//${userDetails?.logo}`
              : isDarkMode
              ? LogoDark
              : LogoLight
          }
          alt="Logo"
          style={{ height: "40px" }}
        />
      </Link>
      <div className="flex gap-8 items-center">
        <div>
          <img
            src={isDarkMode ? MoonIconDark : MoonIconLight}
            alt="moon"
            onClick={toggleTheme}
            className="cursor-pointer"
          />
        </div>
        <div className="relative">
          <div className="relative">
            <img
              src={isDarkMode ? NotificationIconDark : NotificationIconLight}
              alt="notification"
              className="cursor-pointer"
              onClick={() => setOpenNotification(!openNotification)}
            />
            <div className="bg-red-500 rounded-full -top-1 right-0 absolute w-[15px] h-[15px]">
              <div className="flex items-center justify-center">
                <h1 className="notify rounded-full">{notificationCount}</h1>
              </div>
            </div>
          </div>
          <div ref={notificationRef}>
            {openNotification && (
              <div className="absolute top-[3.1rem] z-[999] -right-[10.5rem] w-80 h-[455px] notification-shadow bg-white border-[1px] rounded-lg border-[#F2F4F7]">
                <div className="px-4 py-3 flex justify-between items-center">
                  <div className="flex items-center gap-1">
                    <h1 className="text-color text-base font-bold">
                      Notification
                    </h1>
                    <button
                      className="px-3 text-sm text-[#074EE8]"
                      onClick={getReadNotification}
                    >
                      Mark all as read
                    </button>
                  </div>
                  <button onClick={() => setOpenNotification(false)}>
                    <AiOutlineClose size={18} />
                  </button>
                </div>
                <div className="h-[2px] w-full bg-[#F2F4F7]" />
                <div className="px-4 pt-3">
                  {notifications.length > 0 ? (
                    <div
                      className="overflow-y-scroll"
                      style={{ maxHeight: "360px" }}
                    >
                      {notifications.map((notification) => (
                        <div
                          key={notification.nid}
                          className="flex gap-3 pb-3"
                          onClick={() => {
                            onClickHandler(notification);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={UserIcon}
                            alt="user"
                            className="h-10 w-10 rounded-full"
                          />
                          <div>
                            <h1 className="text-color text-sm">
                              {notification.message}
                            </h1>
                            <p className="text-[#686868] text-sm">
                              {notification.date}
                            </p>
                            {/* <p className="text-[#686868] text-sm">{convertIsoToReadableFormat(notification.time)}</p> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <h1>No notifications Yet!</h1>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div onMouseLeave={() => setOpen(false)}>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onMouseEnter={() => setOpen(true)}
          >
            <img src={UserIcon} alt="user" />
            <h1
              className={`${
                isDarkMode ? "text-white" : "text-color"
              } text-base`}
            >
              {userInformation?.first_name}
            </h1>
            <img
              src={isDarkMode ? ArrowDownIconDark : ArrowDownIconLight}
              alt="downIcon"
              className="cursor-pointer"
            />
          </div>
          {isOpen && (
            <div className="dropdown absolute right-3 rounded shadow top-12 dropdown_click z-10 w-auto">
              <div className="bg-white text-black rounded-lg border-[1px] border-[#F2F4F7] shadow-xl text-lg w-full">
                <ul>
                  <li className="px-4 py-3 flex justify-evenly gap-3 border-b-[1px] border-[#F2F4F7]">
                    <div className="relative">
                      <img
                        src={UserIcon}
                        alt="user"
                        className="h-10 w-10 rounded-full"
                      />
                      <div className="absolute right-[2px] bottom-1 w-[10px] h-[10px] bg-[#12B76A] border-[1.5px] border-white rounded-full"></div>
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <h1 className="text-base text-color leading-[18px]">
                          {userInformation?.first_name}
                        </h1>
                        <h1 className="text-base text-color leading-[18px]">
                          {userInformation?.last_name}
                        </h1>
                      </div>
                      <p className="pt-[6px] text-[#686868] text-xs">
                        {userInformation?.email}
                      </p>
                    </div>
                  </li>
                  {isAdminOrSuperAdmin(userDetails.role) && (
                    <li className="">
                      <Link
                        to="/dashboard"
                        className="block py-2 px-4 hover:bg-gray-100"
                      >
                        <span className="text-sm text-color">Dashboard</span>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="/my-profile"
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="text-sm text-color">My Account</span>
                    </Link>
                  </li>
                  <li>
                    <div className="block py-2 px-4 pb-3 hover:bg-gray-100 rounded-b-lg">
                      <Link
                        onClick={(e) => {
                          removeAuthenticationData();
                          dispatch(setLoggedInValue(false));
                          dispatch(setUserDetails({}));
                        }}
                        to="/login"
                        className="flex items-center gap-4"
                      >
                        <span className="text-sm text-color">Log out</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <SidebarSlide
        title="Vulnerability Intelligence"
        visibleRight={openVulnerabilitySideBar}
        setVisibleRight={setOpenVulnerabilitySideBar}
        customWidth={true}
        sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
        loadingSidebarVocDetail={loadingSidebarVocDetail}
      />
      <SidebarSlide
        title="Target Initiate Scanning"
        description="Scan Configuration Settings"
        visibleRight={visibleScaningView}
        setVisibleRight={setVisibleScaningView}
      />
    </div>
  );
};
