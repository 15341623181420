import React from "react";
import CardCarousel from "./CardCarousel";
import Slider from "react-slick";
import slider1 from "../../assets/Images/carousal/carousal1.png";
import slider2 from "../../assets/Images/carousal/carousal2.png";
import slider3 from "../../assets/Images/carousal/carousal3.png";
import slider4 from "../../assets/Images/carousal/carousal4.png";
import slider5 from "../../assets/Images/carousal/carousal5.png";
import slider6 from "../../assets/Images/carousal/carousal6.png";

const HomeCarousel = () => {
  const dataa = [{ name: "dfsdfs" }, { name: "dfsdfs" }];
  var settings = {
    // className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    // centerPadding: "1px",
    slidesToScroll: 1,
    initialSlide: 1,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       dots: true,
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2,
    //     },
    //   },
    // ],
  };

  const cauroselImage = [
    {
      img: slider1,
    },
    {
      img: slider2,
    },
    {
      img: slider3,
    },
    {
      img: slider4,
    },
    {
      img: slider5,
    },
    {
      img: slider6,
    },
  ];

  const cauroselImage1 = [
    {
      text: "safsdgdsgsdgdsgsd",
    },
    {
      text: "safsdgdsgsdgdsgsd",
    },
    {
      text: "safsdgdsgsdgdsgsd",
    },
    {
      text: "safsdgdsgsdgdsgsd",
    },
    {
      text: "safsdgdsgsdgdsgsd",
    },
    {
      text: "safsdgdsgsdgdsgsd",
    },
  ];
  return (
    <>
      <div className="py-16 text-white flex justify-center">
        <p className="text-white text-center font-bold text-4xl ">
          Explore{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#F14D34] to-[#F2DE4E]">
            {" "}
            UltraHeals
          </span>{" "}
          new features!
        </p>
        <button className="border-[#FCFCFC] ml-6 h-[40px] border-[1px] rounded-[20px] text-[16px] px-3 text-[#FCFCFC]">
          Learn more{" "}
        </button>
      </div>
      {/* <div className="login-home-carousel pb-5">
        <Slider {...settings}>
          {cauroselImage1.map((item, i) => (
            <CardCarousel item={item} key={i} />
          ))}
        </Slider>
      </div>
      <div className="bg-[#171717]" style={{ height: "40px" }}>
        <p></p>
      </div> */}

      <div className="login-home-carousel pb-5">
        <Slider {...settings}>
          {cauroselImage.map((item, i) => (
            <CardCarousel item={item} key={i} />
          ))}
        </Slider>
      </div>
      <div className="bg-[#171717]" style={{ height: "40px" }}>
        <p></p>
      </div>
    </>
  );
};

export default HomeCarousel;
