import JustDonut from "../../../common/Charts/JustDonut";
// import Header from "../../../common/Charts/Header";

export default function DonutWithBars({
  data,
  // labels = ["Pass", "Fail", "Warning"],
  // colors = ["#008C1B", "#D11305", "#FDCA3B"],
}) {
  return (
    <>
      <div className={`grid grid-cols-${data.length}`}>
        {data.map((item) => {
          const {
            title = null,
            success = 0,
            failure = 0,
            unknown = 0,
            onClick = null,
          } = item;
          return (
            <JustDonut
              title={title}
              labels={["Pass", "Fail", "Unknown"]}
              colors={["#008C1B", "#D11305", "#FDCA3B"]}
              data={[success, failure, unknown]}
              centerText={`Total ${success + failure + unknown}`}
              onClick={onClick}
            />
          );
        })}
      </div>
      <div className={`grid grid-cols-${data.length} mt-3 gap-2`}>
        {data.map((item) => {
          const { success = 0, failure = 0, unknown = 0 } = item;
          return (
            <div className="grid justify-center">
              <div className="flex items-center gap-2">
                <div className="w-4 h-2 rounded-full bg-[#008C1B]"></div>
                Pass ({success})
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-2 rounded-full bg-[#D11305]"></div>
                Fail ({failure})
              </div>
              <div className="flex items-center  gap-2">
                <div className="w-4 h-2 rounded-full bg-[#FDCA3B]"></div>
                Unknown ({unknown})
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
