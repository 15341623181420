import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import TableApi from "../../../../Api/AuthenticationApi/TableApi";
// import {
//   getSearchKeywordGithub,
//   getSearchKeywordDarkweb,
//   getSearchKeywordCloud,
//   getSearchKeywordTwitter,
// } from "../../../../redux-toolkit/redux/Sidebar";
import { SearchIcon } from "../../../../assets/svg";
import { BiGlobe } from "react-icons/bi";
import { toast } from "react-toastify";
import { setUserDetails } from "../../../../redux-toolkit/redux/auth";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { getScanDateTime } from "../../../../redux-toolkit/redux/Sidebar";

const ScanConfigurationForm = (props) => {
  const {
    handleCloseSideBar,
    setActiveScanForm,
    selectedOption,
    setSelectedOption,
    getProjectTableData,
    type,
  } = props;
  // const searchKeywordGit = useSelector(getSearchKeywordGithub);
  // const searchKeywordDarkweb = useSelector(getSearchKeywordDarkweb);
  // const searchKeywordCloud = useSelector(getSearchKeywordCloud);
  // const searchKeywordTwitter = useSelector(getSearchKeywordTwitter);
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserDetails);
  const scanDateTime = useSelector(getScanDateTime);

  const [scanCount, setScanCount] = useState();
  const [inputFocus, setInputFocus] = useState(true);
  const [showAddSubdomainForm, setShowAddSubdomainForm] = useState(false);
  const [addSubdomain, setAddSubdomain] = useState('');
  const [searchSubdomainInput, setSearchSubdomainInput] = useState('');
  const [primaryDomain, setPrimaryDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubdomains, setLoadingSubdomains] = useState(true);
  // const [searchKeyword, setSearchKeyword] = useState("");

  const [checkboxes, setCheckboxes] = useState({
    attackSurface: false,
    darkIntelligence: false,
    dataLeakDetection: false,
    brandThreatMonitoring: false,
  });

  // const [configCheckboxes, setConfigCheckboxes] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxess, setSelectedCheckboxess] = useState([]);
  const [subDomainNames, setSubDomainNames] = useState([]);
  // const [activateScan, setActivateScan] = useState(false);
  // const [selectSubdomainRedux, setSelectSubdomainRedux] = useState([]);

  // const dummyCheckboxNames = [
  //   "Sub Domains",
  //   "Darkweb Scan",
  //   "Live Hosts",
  //   "Credential Leaks",
  //   "Endpoints",
  //   "Whois Information",
  //   "Cloud Scanning",
  //   "DNS Information",
  //   "Directory",
  //   "Telegram",
  //   "Networks",
  //   "Discord",
  //   "Web Applications",
  //   "Paste Leaks",
  //   "JS Leaks",
  //   "Twitter",
  //   "Domain Phishing",
  //   "Instagram",
  //   "Github Scan",
  //   "Youtube",
  // ];
  const getStartScan = (startScanId) => {
    const payload = {
      scan_id: startScanId,
    };
    TableApi.startScanWeb(payload).then((data) => {
      if (data.status === "success") {
        setTimeout(() => {
          toast.success(data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error(data.message);
        }, 1000);
      }
    });
  };
  const getSubDomainData = async (id) => {
    await DashboardApi.getSubDomainApi(id).then((data) => {
      setSubDomainNames(data.data);
      setPrimaryDomain(data.domain);
      setScanCount(data.count);
      setLoadingSubdomains(false);
      // setConfigCheckboxes(initialCheckboxes);
    });
  };
  const selectSubdomain = JSON.parse(localStorage.getItem("selectedSubDomain"))
    ? JSON.parse(localStorage.getItem("selectedSubDomain"))
    : [];

  const targetId = localStorage.getItem("targetID");

  useEffect(() => {
    getSubDomainData(targetId);
    // console.log("selectedCheckboxes", selectedCheckboxes)
    // setSelectSubdomainRedux(selectSubdomain);

    // setSelectedCheckboxess(selectSubdomain);

    // if (selectSubdomain.length > 0) {
    //   subDomainNames.filter((item) => {
    //     // selectedCheckboxes.includes(item)
    //     checkArr(selectSubdomain, item);
    //   });
    //   function checkArr(arr, value) {
    //     const found = arr.some((el) => el.value === value);
    //     if (found) {
    //       arr.forEach((element) => {
    //         document.getElementById(element.id).checked = true;
    //         document.getElementById(element.id).checked = "checked";
    //       });
    //     }

    //     return found;
    //   }

    //   // setConfigCheckboxes(selectSubdomain);
    // }
    // const initialCheckboxes = subDomainNames.reduce((acc, name) => {
    //   if (selectedOption === "fullScan") {
    //     acc[name] = true;
    //     return acc;
    //   } else {
    //     acc[name] = false;
    //     return acc;
    //   }
    // }, {});
    // setConfigCheckboxes(initialCheckboxes);
    if (selectSubdomain.length) {
      setSelectedCheckboxess(selectSubdomain);
      setSelectedCheckboxes(
        selectSubdomain.map((item) => {
          return item.value;
        })
      );
    }
    // console.log("configCheckboxes",selectedOption, selectedCheckboxes, selectSubdomain)
  }, []);

  useEffect(() => {
    console.log("showAddSubdomainForm", showAddSubdomainForm)
  }, [showAddSubdomainForm])

  // const handleOptionChange = (e) => {
  //   setSelectedOption(e.target.value);
  // };

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };
  const handleConfigCheckboxChange = (e, checkboxName) => {
    // const checkboxes = document.querySelectorAll(".custom-checkbox");
    // let checkedCount = 0;
    // let subdomains = [];
    // let subdomainss = [];

    if (!selectedCheckboxes.includes(checkboxName)) {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxName]);
      setSelectedCheckboxess([
        ...selectedCheckboxess,
        { id: e.target.id, value: e.target.value },
      ]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== checkboxName)
      );
      setSelectedCheckboxess(
        selectedCheckboxess.filter((item) => item.value !== e.target.value)
      );
    }
    // checkboxes.forEach((checkbox) => {
    //   if (checkbox.checked) {
    //     if (subdomains.length !== scanCount) {
    //       subdomains.push(checkbox.value); //stored the objects to final array
    //       subdomainss.push({ id: checkbox.id, value: checkbox.value }); //stored the objects to final array
    //       setSelectedCheckboxes(subdomains);
    //       setSelectedCheckboxess(subdomainss);
    //     }
    //   }
    //   checkbox.addEventListener("change", function () {
    //     // if (this.checked) {
    //     //   checkedCount++;
    //     //   // subdomains.push(this.value);
    //     // } else {
    //     //   checkedCount--;
    //     // }

    //     if (subdomains.length === scanCount) {
    //       checkboxes.forEach((cb) => {
    //         if (!cb.checked) {
    //           document.getElementById(cb.id).disabled = true;
    //         }
    //       });
    //     } else {
    //       checkboxes.forEach((cb) => {
    //         document.getElementById(cb.id).disabled = false;
    //       });
    //     }
    //   });
    // });
  };

  const handleScanSubmit = () => {
    if (selectedCheckboxes.length > scanCount) {
      alert("You can select only " + scanCount + " subdomains");
    } else {
      localStorage.setItem(
        "selectedSubDomain",
        JSON.stringify(selectedCheckboxess)
      );
      localStorage.setItem(
        "selectedSubDomains",
        JSON.stringify(selectedCheckboxes)
      );
      console.log(selectedOption);
      if (selectedOption === "full" || selectedOption === "default_scan") {
        setActiveScanForm((prev) => prev + 1);
      } else {
        console.log("gegege", type);
        const targetID = localStorage.getItem("targetID");
        const selectedSubDomains = JSON.parse(
          localStorage.getItem("selectedSubDomains")
        )
          ? JSON.parse(localStorage.getItem("selectedSubDomains"))
          : [];
        if (type === "initiate-scan") {
          const payload = {
            target: targetID,
            scan_type: selectedOption,
            subdomains: selectedSubDomains,
            scan_darkweb_keywords: null,
            scan_twitter_keywords: null,
            scan_github_keywords: null,
            scan_cloud_keywords: null,
          };
          console.log(payload);

          TableApi.initiateScanWeb(payload).then((data) => {
            if (data.status === "success") {
              let userData = { ...userDetails };
              const id = data.scan_id;
              const unique_id = data.unique_id;
              userData.scan_id = id;
              userData.unique_id = unique_id;
              userData.drp_scan_id = id;
              userData.drp_unique_id = unique_id;
              dispatch(setUserDetails(userData));
              getProjectTableData();
              localStorage.removeItem("selectedSubDomain");
              localStorage.removeItem("selectedSubDomains");
              handleCloseSideBar();
              setTimeout(() => {
                toast.success(data.message);
                getStartScan(data.scan_id);
              }, 1000);
            } else {
              localStorage.removeItem("selectedSubDomain");
              localStorage.removeItem("selectedSubDomains");
              toast.error(data.message);
            }
          });
        } else if (type === "schedule-scan") {
          const date_1 = scanDateTime.firstScanningDate?.split(".");
          const time_1 = scanDateTime.firstScanningTime?.split(":");
          const date_2 = scanDateTime.secondScanningDate?.split(".");
          const time_2 = scanDateTime.secondScanningTime?.split(":");
          console.log(date_1, date_2, time_1, time_2);
          const payload = {
            target: targetID,
            scan_type: selectedOption,
            subdomains: selectedSubDomains,
            scan_darkweb_keywords: null,
            scan_twitter_keywords: null,
            scan_github_keywords: null,
            scan_cloud_keywords: null,
            first_scanning_date: date_1
              ? new Date(
                  date_1[2],
                  parseInt(date_1[0]) - 1,
                  date_1[1],
                  time_1?.[0] | 0,
                  time_1?.[1] | 0
                ).toISOString()
              : null,
            second_scanning_date: date_2
              ? new Date(
                  date_2[2],
                  parseInt(date_2[0]) - 1,
                  date_2[1],
                  time_2?.[0] | 0,
                  time_2?.[1] | 0
                ).toISOString()
              : null,
          };

          TableApi.scheduleScanWeb(payload).then((data) => {
            if (data.status === "success") {
              let userData = { ...userDetails };
              const id = data.scan_id;
              const unique_id = data.unique_id;
              userData.scan_id = id;
              userData.unique_id = unique_id;
              dispatch(setUserDetails(userData));
              getProjectTableData();
              localStorage.removeItem("selectedSubDomain");
              localStorage.removeItem("selectedSubDomains");
              handleCloseSideBar();
              setTimeout(() => {
                toast.success(data.message);
              }, 1000);
            } else {
              localStorage.removeItem("selectedSubDomain");
              localStorage.removeItem("selectedSubDomains");
              toast.error(data.message);
            }
          });
        }
      }
    }
  };

  const searchSubdomain = (search) => {
    DashboardApi.getSubDomainApi(`${targetId}?search=${search}`).then(
      (data) => {
        setSubDomainNames(data.data);
        console.log(data);
      }
    );
  };

  const selectAll = () => {
    if (
      selectedCheckboxes.length === subDomainNames.length ||
      selectedCheckboxes.length === scanCount
    ) {
      setSelectedCheckboxes([]);
      setSelectedCheckboxess([]);
    } else {
      setSelectedCheckboxes(subDomainNames.slice(0, scanCount));
      setSelectedCheckboxess(
        subDomainNames.slice(0, scanCount).map((item, i) => {
          return {
            id: `subDomain${i}`,
            value: item,
          };
        })
      );
    }
  };

  const toggleAddSubdomainForm = () => {
    if (showAddSubdomainForm) {
      setShowAddSubdomainForm(false)
    } else {
      setShowAddSubdomainForm(true)
    }
  }

  function getPrimaryDomain(subdomain) {
    const parts = subdomain.split('.');
    
    if (parts.length >= 2) {
      const length = parts.length - 1
      return parts.slice(-length).join('.');
    } else {
      return subdomain;
    }
  }

  const handleAddSubdomain = async () => {
    if (addSubdomain.split('.').length > 2) {
      if (subDomainNames.includes(addSubdomain)) {
        toast.error(`${addSubdomain} already exists`);
        return false;
      }
      
      const addedSubdomain = getPrimaryDomain(addSubdomain);
      console.log(primaryDomain, addedSubdomain);

      if (primaryDomain == addedSubdomain) {
        setLoading(true);
        await DashboardApi.createSubDomainApi({
          id: localStorage.getItem("targetID"),
          subdomain: addSubdomain,
          host: primaryDomain,
        }).then(async (data) => {
          console.log(data)
          if (data.status === "success") {
            setTimeout(() => {
              toast.success(data.message);
            }, 1000);
            setAddSubdomain('');
            toggleAddSubdomainForm();
            setLoading(false);
            setLoadingSubdomains(true);
            await getSubDomainData(localStorage.getItem("targetID"));
          } else {
            setTimeout(() => {
              toast.error(data.message);
            }, 1000);
            setLoading(false);
          }
        });
      } else {
        toast.error("Please enter a valid subdomain");
      }
    } else {
      toast.error("Please enter a valid subdomain");
    }
  }

  console.log(selectedCheckboxes, "selectedCheckboxes");
  return (
    <div>
      <div className="">
        {selectedOption === "modules" && (
          <div className="ml-7">
            <div className="flex items-center gap-2 pt-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                checked={checkboxes.attackSurface}
                onChange={() => handleCheckboxChange("attackSurface")}
              />
              <label
                className={`text-base font-normal ${
                  checkboxes.attackSurface ? "text-color" : "text-inactive"
                }`}
              >
                Attack Surface Management
              </label>
            </div>
            <div className="flex items-center gap-2 pt-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                checked={checkboxes.darkIntelligence}
                onChange={() => handleCheckboxChange("darkIntelligence")}
              />
              <label
                className={`text-base font-normal ${
                  checkboxes.darkIntelligence ? "text-color" : "text-inactive"
                }`}
              >
                Dark & Deep Intelligence
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="pt-8">
        <div className="pb-2">
          <div className="flex justify-between items-center">
            <h1 className="text-base font-bold">
              Subdomains {!loadingSubdomains ? `(${selectedCheckboxes.length}/${scanCount})` : ('')}
            </h1>
            {!loadingSubdomains ? (
            !showAddSubdomainForm ? (<button className="pl-2 font-normal text-[#074EE8] text-base" onClick={toggleAddSubdomainForm}>
              Add Subdomain
            </button>) : (<button className="pl-2 font-normal text-[#074EE8] text-base" onClick={toggleAddSubdomainForm}>
              Close
            </button>)) : ('')}
          </div>
          {showAddSubdomainForm ? (
            <form className="mt-4">
              <div className="w-full relative">
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE] ${
                    inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""
                  }`}
                >
                  <BiGlobe size={20} color="#dedede" className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none" />
                  <input
                    type="text"
                    placeholder="app.cyberheals.com"
                    value={addSubdomain}
                    onChange={(e) => setAddSubdomain(e.target.value)}
                    className="w-full ml-4 focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color"
                  />
                </div>
              </div>
            </form>
          ) : (
            <form className="mt-4">
              <div className="w-full relative">
                <div
                  className={`relative py-2 px-4 text-lg border-[1px] rounded-2xl border-[#CECECE] ${
                    inputFocus ? "focus-within:ring-[0.5px] ring-[#717171]" : ""
                  }`}
                >
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchSubdomainInput}
                    onChange={(e) => searchSubdomain(e.target.value)}
                    className="w-full ml-4 focus:outline-none focus:ring-0 placeholder:text-[#DEDEDE] focus:border-none placeholder-color text-color"
                  />
                </div>
              </div>
            </form>
          )}
        </div>
        {!showAddSubdomainForm ? (
          !loadingSubdomains ? (
            <div className="grid grid-cols-1 gap-2" id="itemForm">
              <button className="text-[#074EE8] text-base text-right" onClick={selectAll}>
                Select all
              </button>
              {subDomainNames.map((checkboxName, index) => (
                <div key={index} className="flex items-center gap-2 pt-2">
                  <input
                    id={"subDomain" + index}
                    type="checkbox"
                    className="h-4 w-4 custom-checkbox"
                    value={checkboxName}
                    checked={selectedCheckboxes.includes(checkboxName)}
                    onChange={(e) => handleConfigCheckboxChange(e, checkboxName)}
                    />
                  <label
                    className={`text-base font-normal text-color`}
                    for={"subDomain" + index}
                    style={{ cursor: "pointer" }}
                  >
                    {/* {checkboxName.split("").length > 15
                      ? `${checkboxName.slice(0, 12)}...`
                      : checkboxName} */}
                    {checkboxName}
                  </label>
                </div>
              ))}
            </div>
          ) : ('Loading...')
        ) : ('')}
      </div>

      {!showAddSubdomainForm ? (
        <div className="flex w-full pt-5">
          <button
            onClick={handleScanSubmit}
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
          >
            Continue
          </button>
          <button
            onClick={() => setActiveScanForm((prev) => prev - 1)}
            className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
            type="button"
          >
            Back
          </button>
        </div>
      ): (
        <div className="flex w-full pt-5">
          <button
            disabled={loading}
            onClick={handleAddSubdomain}
            className={`p-1 border rounded-[16px] border-[#AAAAAA] ${
              loading ? "button-disabled" : "button-bg-color"
            } h-[40px] w-full bg-[#181818] text-[#ffff]`}
            type="submit"
          >
            {/* Download */}
            {loading ? (
              <div className="flex items-center justify-center">
                <span className="ml-14">Add Subdomain</span>
              </div>
            ) : (
              <>
                <span> Add Subdomain</span>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ScanConfigurationForm;
