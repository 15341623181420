import React from 'react'

export const CarouselImages = ({item, key}) => {
  return (
    <div className="image-style" key={key}>
    <img
      className="my-8 object-cover rounded w-full delay-450 ease-in-out transition-transform "
      src={item.img}
      alt="pic"
    />
  </div>
  )
}
