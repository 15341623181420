import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";

const VulnerabilityForm = (props) => {
  const { handleCloseSideBar, getTableFilterData, editData } = props;
  const userDetails = useSelector(getUserDetails);

  const [vulnerabilityType, setVulnerabilityType] = useState("");
  const [errorVulnerabilityType, setErrorVulnerabilityType] = useState(false);
  const [vulnerabilityTitle, setVulnerabilityTitle] = useState("");
  const [errorVulnerabilityTitle, setErrorVulnerabilityTitle] = useState(false);
  const [hostTest, setHostTest] = useState("");
  const [errorHostTest, setErrorHostTest] = useState(false);
  const [cveTitle, setCveTitle] = useState("");
  const [errorCveTitle, setErrorCveTitle] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [errorIpAddress, setErrorIpAddress] = useState(false);
  const [cweId, setCweId] = useState("");
  const [errorCweId, setErrorCweId] = useState(false);
  const [urlAddress, setUrlAddress] = useState("");
  const [errorUrlAddress, setErrorUrlAddress] = useState(false);
  const [exploitsValue, setExploitsValue] = useState(null);
  const [exploitabilityValue, setExploitabilityValue] = useState(null);
  const [severityValue, setSeverityValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [descriptionText, setDescriptionText] = useState("");
  const [errorDescriptionText, setErrorDescriptionText] = useState(false);
  const [impactText, setImpactText] = useState("");
  const [errorImpactText, setErrorImpactText] = useState(false);
  const [referenceText, setReferenceText] = useState("");
  const [errorReferenceText, setErrorReferenceText] = useState(false);
  const [migrationText, setMigrationText] = useState("");
  const [errorMigrationText, setErrorMigrationText] = useState(false);
  const [busnessRisk, setBusnessRisk] = useState("");
  const [disable, setDisable] = useState(false);

  const severityData = [
    { name: "Critical", code: "1" },
    { name: "High", code: "2" },
    { name: "Medium", code: "3" },
    { name: "Low", code: "4" },
    { name: "Info", code: "5" },
  ];

  const statusData = [
    { name: "Opened", code: "open" },
    { name: "Closed", code: "closed" },
    { name: "In progress", code: "progress" },
    { name: "Risk accepted", code: "risk" },
  ];

  const typeData = [
    { name: "Web Application", code: "nuclei" },
    { name: "Infrastructure", code: "shodan" },
  ];

  const BusnessRiskData = [
    { name: "Critical", code: 1 },
    { name: "High", code: 2 },
    { name: "Medium", code: 3 },
    { name: "Low", code: 4 },
  ];

  const handleSubmit = (e) => {
    console.log("RESPOCE", severityValue, vulnerabilityTitle);
    e.preventDefault();
    setDisable(true);
    if (vulnerabilityType === "") {
      setErrorVulnerabilityType(true);
    }
    if (vulnerabilityTitle === "") {
      setErrorVulnerabilityTitle(true);
    }
    if (hostTest === "") {
      setErrorHostTest(true);
    }
    if (ipAddress === "") {
      setErrorIpAddress(true);
    }
    // if (cveTitle === "") {
    //   setErrorCveTitle(true);
    // }
    if (cweId === "") {
      setErrorCweId(true);
    }
    if (urlAddress === "") {
      setErrorUrlAddress(true);
    }
    if (descriptionText === "") {
      setErrorDescriptionText(true);
    }
    if (impactText === "") {
      setErrorImpactText(true);
    }
    if (referenceText === "") {
      setErrorReferenceText(true);
    }
    if (migrationText === "") {
      setErrorMigrationText(true);
    }
    if (
      vulnerabilityTitle !== "" &&
      hostTest !== "" &&
      ipAddress !== "" &&
      // cveTitle !== "" &&
      cweId !== "" &&
      descriptionText != "" &&
      impactText != "" &&
      referenceText != "" &&
      migrationText !== ""
    ) {
      const payload = {
        user: userDetails.email,
        targetID: "",
        scan_id: userDetails.scan_id,
        unique_id: userDetails.unique_id,
        vulnerability: vulnerabilityTitle,
        type: vulnerabilityType?.code,
        host: hostTest,
        ip: ipAddress,
        cve: cveTitle,
        cwe: "",
        cvss: cweId,
        exploits: exploitsValue?.code ? exploitsValue.code : "",
        exploitability: exploitabilityValue?.code
          ? exploitabilityValue.code
          : "",
        url: urlAddress,
        severity: severityValue?.code ? severityValue.code : "",
        status: statusValue?.code ? statusValue.code : "",
        description: descriptionText,
        impact: impactText,
        reference: referenceText,
        mitigation: migrationText,
        business_risk: busnessRisk?.code || null,
      };

      if (!editData) {
        DashboardApi.addVulnerabilityApi(payload).then((data) => {
          if (data.status === "success") {
            toast.success(data.message);
            setVulnerabilityTitle("");
            setHostTest("");
            setIpAddress("");
            setCveTitle("");
            setCweId("");
            setDescriptionText("");
            setImpactText("");
            setReferenceText("");
            setMigrationText("");
            setTimeout(() => {
              handleCloseSideBar();
              // window.reload();
              setDisable(false);
            }, 1000);
            getTableFilterData();
          } else {
            toast.error(data.message);
            setDisable(false);
          }
        });
      } else {
        DashboardApi.updateVulnerabilityApi(editData.id, payload).then(
          (data) => {
            if (data.status === "success") {
              toast.success(data.message);
              setVulnerabilityTitle("");
              setHostTest("");
              setIpAddress("");
              setCveTitle("");
              setCweId("");
              setDescriptionText("");
              setImpactText("");
              setReferenceText("");
              setMigrationText("");
              setTimeout(() => {
                handleCloseSideBar();
                // window.reload();
                setDisable(false);
              }, 1000);
              getTableFilterData();
            } else {
              setDisable(false);
              toast.error(data.message);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    if (editData) {
      // setVulnerabilityType(
      //   typeData.find((item) => item.code === editData.type)
      // );
      setVulnerabilityType({ name: "Web Application", code: "nuclei" });
      setVulnerabilityTitle(editData.vul_name);
      setHostTest(editData.host);
      setCveTitle(editData.cve_id);
      setIpAddress(editData.ip);
      setCweId(editData.cvss_score);
      setUrlAddress(editData.matched_at);
      setExploitsValue(
        editData.exploits === "yes"
          ? { name: "Yes", code: "yes" }
          : { name: "No", code: "no" }
      );
      setExploitabilityValue(
        editData.exploitability === "yes"
          ? { name: "Yes", code: "yes" }
          : { name: "No", code: "no" }
      );
      setSeverityValue(
        severityData.find((item) => item.code === editData.severity)
      );
      setStatusValue(statusData.find((item) => item.code === editData.status));
      setDescriptionText(editData.description);
      setImpactText(editData.impact);
      setReferenceText(editData.reference);
      setMigrationText(editData.mitigation);
      setBusnessRisk(
        BusnessRiskData.find((item) => item.code === editData.business_risk)
      );
    }
  }, []);

  return (
    <div>
      <form className="pt-9 w-full px-5" onSubmit={handleSubmit}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Type</label>
          <Dropdown
            value={vulnerabilityType}
            onChange={(e) => {
              setVulnerabilityType(e.value);
            }}
            options={typeData}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
          {errorVulnerabilityType && (
            <p className="text-red-500 text-sm">Type is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Vulnerability</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="title"
            value={vulnerabilityTitle}
            onChange={(e) => {
              setErrorVulnerabilityTitle(false);
              setVulnerabilityTitle(e.target.value);
            }}
          />
          {errorVulnerabilityTitle && (
            <p className="text-red-500 text-sm">Title is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Host</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="test.com"
            value={hostTest}
            onChange={(e) => {
              setErrorHostTest(false);
              setHostTest(e.target.value);
            }}
          />
          {errorHostTest && (
            <p className="text-red-500 text-sm">Host is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">CVE</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="CVE-1111-1111"
            value={cveTitle}
            onChange={(e) => {
              setErrorCveTitle(false);
              setCveTitle(e.target.value);
            }}
          />
          {/* {errorCveTitle && (
            <p className="text-red-500 text-sm">CVE is required</p>
          )} */}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">IP Address</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="00.000.000.000"
            value={ipAddress}
            onChange={(e) => {
              setErrorIpAddress(false);
              setIpAddress(e.target.value);
            }}
          />
          {errorIpAddress && (
            <p className="text-red-500 text-sm">IP Address is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">CVSS Score</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="CVSS Score"
            value={cweId}
            onChange={(e) => {
              setErrorCweId(false);
              setCweId(e.target.value);
            }}
          />
          {errorCweId && (
            <p className="text-red-500 text-sm">CVSS Score is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Url</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="url"
            placeholder="Url"
            value={urlAddress}
            onChange={(e) => {
              setErrorUrlAddress(false);
              setUrlAddress(e.target.value);
            }}
          />
          {errorCweId && (
            <p className="text-red-500 text-sm">Url is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Exploits</label>
          <Dropdown
            value={exploitsValue}
            onChange={(e) => setExploitsValue(e.value)}
            options={[
              { name: "Yes", code: "yes" },
              { name: "No", code: "no" },
            ]}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Exploitability</label>
          <Dropdown
            value={exploitabilityValue}
            onChange={(e) => setExploitabilityValue(e.value)}
            options={[
              { name: "Yes", code: "yes" },
              { name: "No", code: "no" },
            ]}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Business risk</label>
          <Dropdown
            value={busnessRisk}
            onChange={(e) => setBusnessRisk(e.value)}
            options={BusnessRiskData}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Severity</label>
          <Dropdown
            value={severityValue}
            onChange={(e) => setSeverityValue(e.value)}
            options={severityData}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Status</label>
          <Dropdown
            value={statusValue}
            onChange={(e) => setStatusValue(e.value)}
            options={statusData}
            optionLabel="name"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Description</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Description"
            value={descriptionText}
            onChange={(e) => {
              setErrorDescriptionText(false);
              setDescriptionText(e.target.value);
            }}
          />
          {errorDescriptionText && (
            <p className="text-red-500 text-sm">Description is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Impact</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Impact"
            value={impactText}
            onChange={(e) => {
              setErrorImpactText(false);
              setImpactText(e.target.value);
            }}
          />
          {errorImpactText && (
            <p className="text-red-500 text-sm">Impact is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Reference</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Reference"
            value={referenceText}
            onChange={(e) => {
              setErrorReferenceText(false);
              setReferenceText(e.target.value);
            }}
          />
          {errorReferenceText && (
            <p className="text-red-500 text-sm">Reference is required</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Mitigation</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Mitigation"
            value={migrationText}
            onChange={(e) => {
              setErrorMigrationText(false);
              setMigrationText(e.target.value);
            }}
          />
          {errorMigrationText && (
            <p className="text-red-500 text-sm">Mitigation is required</p>
          )}
        </div>
        <div className="flex w-full mt-9">
          <button
            // onClick={(e) => handleSubmit(e)}
            type="submit"
            disabled={disable}
            className={`p-1 border mr-2 rounded-[16px] border-[#AAAAAA] 
            h-[40px] w-full bg-[#181818] text-[#ffff] `}
          >
            Submit
          </button>
          <button
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={handleCloseSideBar}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default VulnerabilityForm;
