import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ptaasAPI } from "../../Api/PTaaS/api";
import PdfViewer from "../../modules/home/components/compliance/trustcenter/PdfViewer";

export default function ViewCertificate() {
  const { unique_id } = useParams();
  const [data, setData] = useState(null);

  const getData = () => {
    ptaasAPI
      .get(`/public-certificate/${unique_id}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [unique_id]);

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="w-[1000px]">
        {data && <PdfViewer viewPDF={data} setViewPDF={setData} />}
      </div>
    </div>
  );
}
