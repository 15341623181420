import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import DonutChart from "./common/Charts/DonutChart";
import { Doughnut } from "react-chartjs-2";
import { BsInfoCircle } from "react-icons/bs";

const CardDesign = () => {
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        cutout: "90%",
        borderRadius: 50,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        // position: 'bottom',
        // labels: {
        //   usePointStyle: true,
        // },
      },
    },
    // elements: {
    //   arc: {
    //     spacing: 2,
    //   },
    // },
  };

  const header = (
    <img
      alt="Card"
      src="https://primefaces.org/cdn/primereact/images/usercard.png"
    />
  );
  const footer = (
    <div className="flex flex-wrap gap-2">
      <div className="h-[18px] w-[18px] bg-[#E8001D] rounded-full"></div>
      <p>Critical</p>
      <p>high</p>
      <p>Medium</p>
      <p>Low</p>
    </div>
  );
  const title = (
    // <p className=''>Sample</p>
    <div className="px-3 pb-5 flex items-center justify-evenly">
      <div className="w-[90%]">
        <h1 className="flex items-center justify-center text-base font-bold leading-normal">
          Sample
        </h1>
      </div>
      <div className="flex justify-end items-end w-[10%]">
        <BsInfoCircle />
      </div>
    </div>
  );
  return (
    <div className="card flex justify-content-center">
      <Card title={title} footer={footer} className="m-3">
        <div className="donut-chart">
          <Doughnut data={data} options={options} />
          <div className="absolute lg:top-[40%] top-[40%] flex flex-col items-center justify-center w-full ">
            <h1 className="text-4xl font-bold">12</h1>
            <p className="text-base font-normal ">Valiu</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardDesign;
