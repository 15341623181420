import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { FaRegEdit } from "react-icons/fa";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import { ThemeContext } from "../../../../../context/ThemeContext";
import AddRisk, { STATUS, category } from "./AddRisk";
import AddTreatment from "./AddTreatment";
import InherentRisk from "./InherentRisk";
import ResidualRisk from "./ResidualRisk";
import { ProgressBar } from "primereact/progressbar";

export const RiskView = (risk) => {
  if (risk <= 4) return "Very Low";
  else if (risk <= 8) return "Low";
  else if (risk <= 12) return "Medium";
  else if (risk <= 16) return "High";
  else return "Critical";
};

export default function DetailedView({ data, setData, getData }) {
  const { isDarkMode } = useContext(ThemeContext);

  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [entities, setEntities] = useState([]);

  const [editTreatment, setEditTreatment] = useState(null);
  const [editInherentRisk, setEditInherentRisk] = useState(null);
  const [editResidualRisk, setEditResidualRisk] = useState(null);
  const [edit, setEdit] = useState(false);

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getDepartment();
    getEntities();
  }, []);

  if (!data)
    return (
      <div className="flex justify-center items-center">
        <ProgressSpinner />
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`rounded-2xl userTable-style flex flex-col gap-2 ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div className="flex justify-between">
          <p className="text-sm">
            <span className="font-bold">Description</span>
            <br />
            {data?.description}
          </p>
          <span
            className="flex-end cursor-pointer"
            onClick={() => {
              setEdit(true);
            }}
          >
            <FaRegEdit />
          </span>
        </div>
        <div className="grid grid-cols-3 gap-3">
          <p className="text-sm">
            <span className="font-bold">Assignee</span>
            <br />
            {employees.find((e) => e.id === data?.assignee)?.email}
          </p>
          <p className="text-sm">
            <span className="font-bold">Entities</span>
            <br />
            {entities.find((e) => e.id === data?.entities)?.name}
          </p>
          <p className="text-sm">
            <span className="font-bold">Department</span>
            <br />
            {departments.find((e) => e.id === data?.department)?.name}
          </p>
          <p className="text-sm">
            <span className="font-bold">Category</span>
            <br />
            {category.find((e) => e.value === data?.category)?.label}
          </p>
          <p className="text-sm">
            <span className="font-bold">Application Name</span>
            <br />
            {data?.application_name}
          </p>
          <p className="text-sm">
            <span className="font-bold">Status</span>
            <br />
            {STATUS.find((e) => e.value === data?.status)?.label}
          </p>
          <p className="text-sm">
            <span className="font-bold">Source</span>
            <br />
            {data?.source || "--"}
          </p>
          <p className="text-sm">
            <span className="font-bold">Source Type</span>
            <br />
            {data?.source_type || "--"}
          </p>
        </div>
      </div>
      <div
        className={`rounded-2xl userTable-style ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <p className="text-sm flex justify-between">
          <span className="font-bold">Treatment Strategy</span>
          <span
            className="flex-end cursor-pointer"
            onClick={() => {
              setEditTreatment(data?.treatment_strategy || true);
            }}
          >
            <FaRegEdit />
          </span>
        </p>
        {data?.treatment_strategy || "--"}
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div
          className={`rounded-2xl userTable-style ${
            isDarkMode ? "header-dark" : "header-light white-background"
          }`}
        >
          <div className="flex justify-between pb-3">
            <h3 className="font-bold">Inherent Risk</h3>
            <span
              className="flex-end cursor-pointer"
              onClick={() => {
                setEditInherentRisk(true);
              }}
            >
              <FaRegEdit />
            </span>
          </div>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Inherent Risk</span>
            <span>
              {data?.likelihood * data?.impact} (
              {RiskView(data?.likelihood * data?.impact)})
            </span>
          </p>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Likelihood</span>
            <span>{data?.likelihood}</span>
          </p>
          <div className="progressBarSecure-Style2 flex flex-col gap-3">
            <ProgressBar
              id="pass"
              zIndex={-1}
              value={(data?.likelihood / 5) * 100}
              color={
                data?.likelihood > 3
                  ? "#E8001D"
                  : data?.likelihood === 3
                  ? "#F5B207"
                  : "#148842"
              }
              title={`Likelihood ${data?.likelihood}`}
            ></ProgressBar>
          </div>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Impact</span>
            <span>{data?.impact}</span>
          </p>
          <div className="progressBarSecure-Style2 flex flex-col gap-3">
            <ProgressBar
              id="pass"
              zIndex={-1}
              value={(data?.impact / 5) * 100}
              color={
                data?.impact > 3
                  ? "#E8001D"
                  : data?.impact === 3
                  ? "#F5B207"
                  : "#148842"
              }
              title={`Impact ${data?.impact}`}
            ></ProgressBar>
          </div>
        </div>
        <div
          className={`rounded-2xl userTable-style ${
            isDarkMode ? "header-dark" : "header-light white-background"
          }`}
        >
          <div className="flex justify-between">
            <h3 className="font-bold">Residual Risk</h3>
            <span
              className="flex-end cursor-pointer"
              onClick={() => {
                setEditResidualRisk(true);
              }}
            >
              <FaRegEdit />
            </span>
          </div>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Residual Risk</span>
            <span>
              {data?.residual_risk_likelihood * data?.residual_risk_impact} (
              {RiskView(
                data?.residual_risk_likelihood * data?.residual_risk_impact
              )}
              )
            </span>
          </p>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Residual Likelihood</span>
            <span>{data?.residual_risk_likelihood}</span>
          </p>
          <div className="progressBarSecure-Style2 flex flex-col gap-3">
            <ProgressBar
              id="pass"
              zIndex={-1}
              value={(data?.residual_risk_likelihood / 5) * 100}
              color={
                data?.residual_risk_likelihood > 3
                  ? "#E8001D"
                  : data?.residual_risk_likelihood === 3
                  ? "#F5B207"
                  : "#148842"
              }
              title={`Residual Likelihood ${data?.residual_risk_likelihood}`}
            ></ProgressBar>
          </div>
          <p className="text-sm flex justify-between pt-2">
            <span className="font-bold">Residual Impact</span>
            <span>{data?.residual_risk_impact}</span>
          </p>
          <div className="progressBarSecure-Style2 flex flex-col gap-3">
            <ProgressBar
              id="pass"
              zIndex={-1}
              value={(data?.residual_risk_impact / 5) * 100}
              color={
                data?.residual_risk_impact > 3
                  ? "#E8001D"
                  : data?.residual_risk_impact === 3
                  ? "#F5B207"
                  : "#148842"
              }
              title={`Residual Impact ${data?.residual_risk_impact}`}
            ></ProgressBar>
          </div>
        </div>
      </div>
      <AddTreatment
        editTreatment={editTreatment}
        setEditTreatment={setEditTreatment}
        data={data}
        setData={setData}
      />
      <InherentRisk
        editInherentRisk={editInherentRisk}
        setEditInherentRisk={setEditInherentRisk}
        data={data}
        setData={setData}
      />
      <ResidualRisk
        editResidualRisk={editResidualRisk}
        setEditResidualRisk={setEditResidualRisk}
        data={data}
        setData={setData}
      />
      <AddRisk
        visible={edit}
        onHide={() => {
          getData();
          setEdit(false);
        }}
        currentDetails={data}
      />
    </div>
  );
}
