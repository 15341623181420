import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";

export default function ResidualRisk({
  editResidualRisk,
  setEditResidualRisk,
  data,
  setData,
}) {
  const { id } = useParams();

  const options = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ];

  const [editInfo, setEditInfo] = useState({
    residual_risk_likelihood: data?.likelihood,
    residual_risk_impact: data?.impact,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    complianceAPI
      .patch(`/risk/${id}/`, { ...data, ...editInfo })
      .then((res) => {
        if (!res.id) {
          HandleResponseError(res.message);
          e.target.submit.removeAttribute("disabled");
        } else {
          toast.success("Inherent Risk updated successfully");
          setData(res);
          setEditResidualRisk(null);
          e.target.submit.removeAttribute("disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        e.target.submit.removeAttribute("disabled");
      });
  };

  return (
    <Sidebar
      visible={editResidualRisk}
      position="right"
      onHide={() => setEditResidualRisk(null)}
    >
      <p className="font-bold text-2xl">Risk Status</p>
      <p className="font-bold text-1xl py-2">Residual Risk</p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">
            Residual Likelihood
          </label>
          <Dropdown
            value={editInfo.residual_risk_likelihood}
            onChange={(e) =>
              setEditInfo({ ...editInfo, residual_risk_likelihood: e.value })
            }
            options={options}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Residual Impact</label>
          <Dropdown
            value={editInfo.residual_risk_impact}
            onChange={(e) =>
              setEditInfo({ ...editInfo, residual_risk_impact: e.value })
            }
            options={options}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Save
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={() => setEditResidualRisk(null)}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
