import GenericLayout from "../../common/layout/GenericLayout";
import PTaaSVOC from "./PTaaSVOC";
import PTaaSDashboard from "./PTaaSDashboard";

export default function PTAAS() {
  return (
    <GenericLayout>
      <PTaaSDashboard />
      <PTaaSVOC />
    </GenericLayout>
  );
}
