import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../../context/ThemeContext";
import GenericLayout from "../../../../../common/layout/GenericLayout";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { useLocation, useNavigate } from "react-router-dom";
import { complianceAPI } from "../../../../../Api/CSPM/api";

function Training() {
  const { isDarkMode } = useContext(ThemeContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(0);

  const getData = () => {
    complianceAPI
      .post("/campaign/lesson/filter/", { campaign: state.id })
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <GenericLayout>
      <div className="flex flex-col items-center gap-2 mt-3">
        <h1 className="text-lg font-semibold">{state?.name}</h1>
        {data[currentLesson] ? (
          <>
            <div className="w-[640px]">
              <div className={data[currentLesson]?.type === "pdf" && "hidden"}>
                <Plyr
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: data[currentLesson]?.file,
                        provider: data[currentLesson]?.file.includes("vimeo")
                          ? "vimeo"
                          : "youtube",
                      },
                    ],
                  }}
                />
              </div>
              <div
                className={data[currentLesson]?.type === "video" && "hidden"}
              >
                <iframe
                  width={640}
                  height={640}
                  src={data[currentLesson]?.file} // I know you can load any url
                  title={data[currentLesson]?.name}
                ></iframe>
              </div>
              {/* {data[currentLesson]?.type === "video" ? (
              ) : (
              )} */}
            </div>
            <div
              className={`${
                isDarkMode ? "bg-[#171717]" : "bg-white"
              } w-[640px] flex flex-col gap-5  p-3 rounded-md`}
            >
              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">Training Overview</h1>
                <p
                  className={` ${
                    isDarkMode ? "text-slate-400" : "text-slate-700"
                  } text-sm font-light `}
                >
                  {state.lessons} lessons ({state.duration} minutes)
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <h1>
                  {data[currentLesson]?.name}
                  <span>({data[currentLesson]?.duration} mins)</span>
                </h1>
                <div
                  className={` ${
                    isDarkMode ? "bg-gray-400" : "bg-gray-200"
                  } w-full rounded-full h-1.5`}
                >
                  <div
                    className="bg-orange-600 h-1.5 rounded-full"
                    style={{
                      width: `${((currentLesson + 1) / data?.length) * 100}%`,
                    }}
                  ></div>
                </div>
                <p
                  className={` ${
                    isDarkMode ? "text-slate-400" : "text-slate-700"
                  } text-sm font-light `}
                >
                  {Math.round(((currentLesson + 1) / data?.length) * 100)}% Completed
                </p>
              </div>
              <div className="flex justify-between text-white">
                <button
                  className={`bg-orange-600  px-4 py-2 rounded-lg ${
                    currentLesson === 0 ? "opacity-50" : "hover:bg-orange-700"
                  }`}
                  disabled={currentLesson === 0}
                  onClick={() => {
                    if (currentLesson !== 0)
                      setCurrentLesson((prev) => prev - 1);
                  }}
                >
                  Prev
                </button>
                <button
                  className={`bg-orange-600 px-4 py-2 rounded-lg hover:bg-orange-700`}
                  // disabled={currentLesson === data.length - 1}
                  onClick={() => {
                    if (currentLesson !== data.length - 1)
                      setCurrentLesson((prev) => prev + 1);
                    else if (currentLesson === data.length - 1)
                      navigate("/compliance/security-awareness/quiz", {
                        state,
                      });
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </GenericLayout>
  );
}

export default Training;
