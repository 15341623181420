import React, { useRef, useState } from "react";
import ComplianceDataTables from "../../../../../common/Tables/ComplianceTable";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const ComplianceFourthTable = (props) => {
  const {
    dnsTableData,
    loadingTable,
    FilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "host", header: "Host", value: FilterData?.host },
    {
      field: "vulnerability",
      header: "Vulnerability",
      value: FilterData?.vulnerability,
    },
    {
      field: "severity",
      header: "Severity",
      value: FilterData?.severity,
      type: "severity",
    },
    { field: "find", header: "Findings", value: FilterData?.find },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={dnsTableData}
          tableHeader={columns}
          setFilterParams={setFilterParams}
          dt={dt}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <ComplianceDataTables
            userInformation={dnsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};

export default ComplianceFourthTable;
