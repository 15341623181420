import axios from "axios";

export default function handleDownload(file) {
  axios
    .get(file.file, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.headers.contentType,
      });
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click event to download the file
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name; // Change file extension to .yml
      a.click();

      // Release the URL object
      URL.revokeObjectURL(url);
    })
    .catch((err) => console.log(err));
}
