import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { dataBreachAPI } from "../../../../Api/DRP/base";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import UrlsWithCounts from "../../components/UrlsWithCounts";
import CompromisedURLSidebar from "../../../sidebar/components/drp/CompromisedURLSidebar";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function CompromisedURL() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scan });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const getFilterData = () => {
    dataBreachAPI
      .get("compromised-url/", { scan })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    dataBreachAPI
      .post("compromised-url/", {
        ...getFilter(filterData, filterParams),
        scan,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    {
      field: "hwid",
      header: "System ID",
      value: filterData?.hwid,
    },
    { field: "ip", header: "IP Address", value: filterData?.ip },
    // { field: "update_field_name", header: "URL", value: [], type: "url_list" },
    { field: "username", header: "User name", value: filterData?.username },
    { field: "url_pass_strength.0.password", header: "Password" },
    { field: "date_compromised", header: "Date Compromised" },
  ];

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <CompromisedURLSidebar
        currentDetails={currentDetails}
        setCurrentDetails={setCurrentDetails}
      />
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        {" "}
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Compromised URL <span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              ref={ref}
              value={data}
              rows={10}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.type === "url_list") {
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={(rowData) => {
                        return UrlsWithCounts(rowData[col.field]);
                      }}
                      sortable
                    />
                  );
                } else {
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                }
              })}
              <Column
                header="Full info"
                body={(rowData) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentDetails(rowData);
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  );
                }}
                exportable={false}
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
