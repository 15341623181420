import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ptaasAPI } from "../../Api/PTaaS/api";
import PdfViewer from "../../modules/home/components/compliance/trustcenter/PdfViewer";

export default function ViewReport() {
  const { unique_id } = useParams();
  const [data, setData] = useState(null);

  // get type from params
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  const getData = () => {
    ptaasAPI
      .get(`/public-report/${unique_id}?type=${type}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [unique_id]);

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="w-[700px]">
        {data && <PdfViewer viewPDF={data} setViewPDF={setData} />}
      </div>
    </div>
  );
}
