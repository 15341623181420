import React, { useState, useRef } from "react";
import SSLTables from "../../../../../common/Tables/SSLTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";
import { sslTableData } from "../../../../../constants";

const SSLExpiredTable = (props) => {
  const {
    loadingTable,
    sslExpiredTableData,
    sslExpiredFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const columns = [
    { field: "host", header: "Host", value: sslExpiredFilterData?.host },
    {
      field: "expiry_date",
      header: "Expiry Date",
      value: sslExpiredFilterData?.expiry_date,
      type: "date",
    },
    {
      field: "provider",
      header: "SSL Provider",
      value: sslExpiredFilterData?.provider,
    },
  ];
  return (
    <>
      <TableFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
        columns={columns}
        dt={dt}
        tableData={sslExpiredTableData}
        tableHeader={columns}
      />
      {loadingTable ? (
        <SkeletonDataTable columns={columns} />
      ) : (
        <SSLTables
          userInformation={sslExpiredTableData}
          columns={columns}
          dt={dt}
          globalFilter={globalFilter}
        />
      )}
    </>
  );
};

export default SSLExpiredTable;
