import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

import PTaaSTable from "../../modules/home/components/ptaas/ptaasTable";
import AddVulnerability from "./addVulnerability";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import { isAgentOrAdminOrSuperAdmin } from "../../utils/accessCheck";
import ViewPTaaSVulnerability from "./viewPTaaSVulnerability";

export const SEVERITY = [
  {
    label: "Critical",
    value: "critical",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Info",
    value: "info",
  },
];

export const STATUS = [
  {
    label: "Open",
    value: "opened",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Risk Accepted",
    value: "risk_accepted",
  },
];

export const BOOLEAN_SELECT = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const SSL_STATUS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Expired",
    value: "expired",
  },
  {
    label: "Expire Soon",
    value: "expire_soon",
  },
];
export default function PTaaSVOC() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scan = userDetails?.ptaas_scan_id || 0;

  const columns = [
    {
      field: "host",
      header: "Host",
    },
    {
      field: "name",
      header: "Vulnerability",
    },
    {
      field: "severity",
      header: "Severity",
      type: "severity",
      value: SEVERITY,
    },
    {
      field: "exploits",
      header: "Exploits",
      type: "select",
      value: BOOLEAN_SELECT,
    },
    {
      field: "exploitability",
      header: "Exploitability",
      type: "select",
      value: BOOLEAN_SELECT,
    },
    {
      field: "risk",
      header: "Business Risk",
      type: "severity",
      value: SEVERITY,
    },
    {
      field: "cve_id",
      header: "CVE",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "full_info",
      header: "Full Info",
      type: "full_info",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
    },
  ];

  return (
    <div
      className={`${
        isDarkMode ? "header-dark" : "bg-white"
      } lg:h-fit w-full rounded-2xl mt-6 `}
    >
      <PTaaSTable
        key={scan}
        title="Vulnerability Summary"
        columns={columns}
        url_path="/vulnerability/"
        AddComponent={AddVulnerability}
        ViewComponent={ViewPTaaSVulnerability}
        filter={{ scan }}
        checkAccess={isAgentOrAdminOrSuperAdmin}
      />
    </div>
  );
}
