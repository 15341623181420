import React, { useRef, useState } from "react";
import DashboardCloudTables from "../../../../../common/Tables/DashboardCloudTables";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import SSLTables from "../../../../../common/Tables/SSLTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import WhoisTableData from "../../../../../common/Tables/WhoisTableData";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const WhoisTable = (props) => {
  const {
    dnsTableData,
    loadingTable,
    dnsFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    {
      field: "domain_info",
      header: "Domain",
      value: dnsFilterData?.domain_info,
    },
    {
      field: "register_info",
      header: "Register",
      value: dnsFilterData?.register_info,
    },
    {
      field: "server_info",
      header: "Server",
      value: dnsFilterData?.server_info,
    },
    {
      field: "createdate_info",
      header: "Created Date",
      value: dnsFilterData?.createdate_info,
      type: "date",
    },
    {
      field: "update_info",
      header: "Updated Date",
      value: dnsFilterData?.update_info,
      type: "date",
    },
    {
      field: "expirydate_info",
      header: "Expiry Date",
      value: dnsFilterData?.expirydate_info,
      type: "date",
    },
    //   { field: "date_added", header: "Full Info", value: dnsFilterData?.date_added },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={dnsTableData}
          tableHeader={columns}
          setFilterParams={setFilterParams}
          dt={dt}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <WhoisTableData
            userInformation={dnsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};

export default WhoisTable;
