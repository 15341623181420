import React, { useState, useRef, useEffect } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import OtpInputComponent from "../../../Component/otp/OtpInputComponent";
import { Toast } from "primereact/toast";
import { toast as rtoast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { maskedEmailAt } from "../../../utils/constants";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";

const MultifactorOTPverify = (props) => {
  const {
    emailVerify,
    mobileVerify,
    setAuthInstruction,
    authInstruction,
    setVisibleRight,
  } = props;
  const toast = useRef(null);
  const userDetails = useSelector(getUserDetails);
  const [seconds, setSeconds] = useState(2);
  const [maxLimit, setMaxLimit] = useState(2);

  const [otp, setOtp] = useState("");

  const vpwidth = document.documentElement.clientWidth;
  const inputStyle = {
    fontSize: "1.5rem",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    height: `${vpwidth > 667 ? "3rem" : "2rem"}`,
    width: `${vpwidth > 667 ? "3rem" : "2rem"}`,
    marginRight: "8px",
    lineHeight: "2rem",
  };

  const handleSubmit = () => {
    const userEmail = localStorage.getItem("userEmail");
    const payload = {
      email: userDetails.email,
      code: otp,
      authInstruction,
      method: "email",
    };
    TableApi.otpMfaEmailVerify(payload).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.message,
          life: 1000,
        });
        setVisibleRight(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.message,
          life: 3000,
        });
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (maxLimit === 0) {
      setSeconds(0);
    }
  }, [maxLimit]);

  const handleResendCode = (e) => {
    e.target.setAttribute("disabled", "disabled");

    if (seconds !== 0) {
      e.target.removeAttribute("disabled");
      return;
    }

    if (maxLimit === 0) {
      rtoast.warning("Max request exceeded. Please try after an hour.");
      e.target.removeAttribute("disabled");
      return;
    }

    AuthApi.resendCode({ email: userDetails.email }).then((data) => {
      if (data.status === "success") {
        rtoast.success(data.message);
        setSeconds(2 * 60);
        setMaxLimit((prev) => prev - 1);
        e.target.removeAttribute("disabled");
      } else {
        if (data?.message === "Max request reached") {
          rtoast.warning("Max request exceeded. Please try after an hour.");
          setSeconds(0);
          setMaxLimit(0);
          return;
        }
        rtoast.error(data?.message);
        e.target.removeAttribute("disabled");
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="py-5 w-full">
        <div className="py-5 w-full">
          <p className="text-base text-color">
            Enter the code sent to your email for added security.
          </p>
        </div>
        {mobileVerify && (
          <div className="text-center font-bold">
            <p className="text-base text-color">+44 ** *** *** 7890</p>
          </div>
        )}
        {emailVerify && (
          <div className="text-center font-bold">
            <p className="text-base text-color">
              {maskedEmailAt(userDetails.email)}
            </p>
          </div>
        )}
        <div className="p-3">
          <label className="text-sm text-[#686868]">Verification Code</label>
          <div>
            <OtpInputComponent
              numInputs={6}
              inputStyle={inputStyle}
              otp={otp}
              setOtp={setOtp}
            />
          </div>
        </div>
        <div className="flex w-full py-4">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
            onClick={() => setAuthInstruction(false)}
          >
            Back
          </button>
        </div>
        <div className="pt-6 text-center">
          <button
            className={`text-base border-none ${
              seconds !== 0 || maxLimit === 0 ? "text-slate-600" : "link-color"
            } `}
            onClick={(e) => handleResendCode(e)}
            disabled={seconds !== 0}
          >
            Resend a code{" "}
            {seconds === 0 ? "" : `(${seconds < 10 ? `${seconds}` : seconds}s)`}
          </button>
        </div>
      </div>
    </>
  );
};

export default MultifactorOTPverify;
