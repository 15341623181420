import React, { createElement } from "react";
import { LuLayout } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";

const Section = (props) => {
  const {
    section,
    subLinks,
    linkPadding,
    isOpen,
    toggleSection,
    width,
    textColor,
    padding,
    icon = null,
  } = props;

  return (
    <div key={section} className={`${width} ${textColor} `}>
      <div
        className={`cursor-pointer ${
          isOpen ? "text-primary" : ""
        } py-[12.5px] ${padding} ${
          isOpen ? "bg-[#18181819]" : ""
        } capitalize flex items-center gap-2`}
        onClick={toggleSection}
      >
        <div>{icon ? createElement(icon) : <LuLayout />}</div>
        <div className="flex justify-between items-center w-full">
          <h1 className="text-base">{section}</h1>
          <div>
            <IoIosArrowDown />
          </div>
        </div>
      </div>
      <ul className={`${isOpen ? "open" : "closed"}`}>
        {subLinks.map((subLink, subIndex) => (
          <li
            key={subIndex}
            className={`pr-3 py-[15px] capitalize text-base ${linkPadding}`}
          >
            <NavLink to={subLink.navLink}>{subLink.link}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Section;
