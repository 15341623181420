import { Sidebar } from "primereact/sidebar";
import { useEffect, useRef, useState } from "react";

import { FRAMEWORKS } from "./AddFramework";
import { MultiSelect } from "primereact/multiselect";
import { complianceAPI } from "../../../../Api/CSPM/api";
import { toast } from "react-toastify";
import HandleResponseError from "../../../../utils/error";
import EditorView from "./editor/EditorView";

export default function ViewTemplateLibrary({
  visible,
  setVisible,
  current,
  setCurrent,
  getData,
}) {
  const editorRef = useRef();
  const [data, setData] = useState(null);

  function cleanClose() {
    setCurrent(null);
    setData(null);
    setVisible(false);
    getData();
  }

  const onSubmit = (e) => {
    e.target.setAttribute("disabled", "disabled");
    if (editorRef.current) {
      if (current) {
        complianceAPI
          .patch(`/template-library/${current.id}/`, {
            ...data,
            content: editorRef.current.getContent(),
          })
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res);
              e.target.removeAttribute("disabled");
            } else {
              toast.success("Template updated successfully");
              cleanClose();
            }
          })
          .catch((err) => {
            toast.error(err.message);
            e.target.removeAttribute("disabled");
          });
      } else {
        complianceAPI
          .post("/template-library/", {
            ...data,
            content: editorRef.current.getContent(),
          })
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res);
              e.target.removeAttribute("disabled");
            } else {
              toast.success("Template created successfully");
              cleanClose();
            }
          })
          .catch((err) => {
            toast.error(err.message);
            e.target.removeAttribute("disabled");
          });
      }
    } else {
      e.target.removeAttribute("disabled");
      toast.error("Content can't be empty.");
    }
  };

  const onDelete = () => {
    complianceAPI
      .delete(`/template-library/${current.id}/`)
      .then(() => {
        toast.success("Template delete successfully");
        cleanClose();
      })
      .catch((err) => {
        console.log(err);
        toast.err(err.message);
      });
  };

  useEffect(() => {
    if (current) setData(current);
  }, [visible, current]);

  return (
    <Sidebar
      position="right"
      visible={visible}
      onHide={() => {
        cleanClose();
      }}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <div className="flex justify-between">
        <p className="font-bold text-2xl">{`${
          current ? "Edit" : "Add"
        } Policy Template`}</p>
        <div>
          <button
            className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
            onClick={(e) => onSubmit(e)}
          >
            {current ? "Save" : "Create"}
          </button>
          {current && (
            <button
              className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
              onClick={() => onDelete()}
            >
              Delete
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center my-2 gap-4">
        <div className="w-2/3">
          <label className="block text-gray-500 text-sm ">Title</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Title"
            value={data?.title}
            required
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
              // setError({ ...error, name: false });
            }}
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-500 text-sm">Frameworks</label>
          <MultiSelect
            required
            className="w-full"
            optionLabel="label"
            optionValue="value"
            value={data?.frameworks}
            options={FRAMEWORKS}
            placeholder="Select Frameworks"
            onChange={(e) => setData({ ...data, frameworks: e.value })}
          />
        </div>
        {/* {!edit ? (
          <>
            <p className="font-bold text-2xl">{data?.title}</p>
            <button
              className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          </>
        ) : (
          <>
            <div className="w-2/3 pt-3">
              <label className="block text-gray-500 text-sm ">Title</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Title"
                value={data?.title}
                required
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                  // setError({ ...error, name: false });
                }}
              />
            </div>
            <button
              className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
              onClick={onSubmit}
            >
              Save
            </button>
          </>
        )} */}
      </div>
      {/* {edit ? (
        <>
          <EditorView editorRef={editorRef} data={data} />
        </>
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: data?.value }}
            className="p-2"
          ></div>
        </>
      )} */}
      <EditorView editorRef={editorRef} data={data} />
    </Sidebar>
  );
}
