import React from "react";
import { Skeleton } from "antd";
function SkeletonAnimationLogin() {
  return (
    <div className="p-5 w-full item-center mx-10 skeleton-response">
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
    </div>
  );
}

export default SkeletonAnimationLogin;
