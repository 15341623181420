import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { Sidebar } from "primereact/sidebar";
import { useSelector } from "react-redux";

import { dataBreachAPI } from "../../../../Api/DRP/base";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function CompromisedUser() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scan });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const getFilterData = () => {
    dataBreachAPI
      .get("compo-list/", { scan })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    dataBreachAPI
      .post("compo-list/", { ...getFilter(filterData, filterParams), scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // "email": "calebh@tesla.com",
  // "ip_address": "No Data",
  // "username": "No Data",
  // "password": "No Data",
  // "hide_password": "No Data",
  // "hashed_password": "No Data",
  // "name": "Caleb Hudson",
  // "vin": "No Data",
  // "address": "No Data",
  // "phone": "No Data",
  // "database_name": "covve"
  const columns = [
    { field: "email", header: "Email", value: filterData?.email },
    {
      field: "ip_address",
      header: "IP Address",
      value: filterData?.ip_address,
    },
    { field: "username", header: "Username", value: filterData?.username },
    { field: "password", header: "Password", value: filterData?.password },
    {
      field: "hashed_password",
      header: "Hashed Password",
      value: filterData?.hashed_password,
    },
    { field: "name", header: "Name" },
    { field: "phone", header: "Phone" },
    {
      field: "database_name",
      header: "Database Name",
      value: filterData?.database_name,
    },
  ];

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={() => setCurrentDetails(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        closeOnEscape={false}
        dismissable={false}
      >
        <p className="font-bold text-2xl text-center">Compromised Employee</p>
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">ID</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?._id}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">IP Address</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.ip_address}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Name</h1>
              <p className="text-xs text-color font-normal leading-5 break-all">
                {currentDetails?.name}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Email</h1>
              <p className="text-xs text-color font-normal leading-5 break-all">
                {currentDetails?.email}
              </p>
            </div>
          </div>

          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Phone</h1>
              <p className="text-xs text-color font-normal leading-5 break-all">
                {currentDetails?.phone}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Username</h1>
              <p className="text-xs text-color font-normal leading-5 break-all">
                {currentDetails?.username}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Password</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.password}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Hide Password</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.hide_passwrd}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Hashed Password</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.hashed_password}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">VIN</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.vin}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Address</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.address}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Database Name</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.database_name}
              </p>
            </div>
          </div>
        </div>
      </Sidebar>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Combo List<span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              ref={ref}
              value={data}
              rows={10}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              ))}
              <Column
                header="Full info"
                body={(rowData) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentDetails(rowData);
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  );
                }}
                exportable={false}
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
