import React from 'react';
import { ThemeProvider } from './ThemeContext';

export default function ContextWrapper({ children }) {
  return (
    <ThemeProvider>
        {children}
    </ThemeProvider>
  );
}
