import React, { useContext, useState, useRef } from "react";
import { Header } from "../../Component/Header";
import SideBar from "../../Component/sideBar/SideBar";
import { ThemeContext } from "../../context/ThemeContext";
import { TbArrowUpRight } from "react-icons/tb";
import AttackSurfaceManagementComponent from "../../modules/attackSurfaceManagement/components/AttackSurfaceManagementComponent";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { useEffect } from "react";
import LiveHostsTable from "../../modules/attackSurfaceManagement/table/LiveHostsTable";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import OpenPortsTable from "../../modules/attackSurfaceManagement/table/OpenPortsTable";
import SubDomainTable from "../../modules/attackSurfaceManagement/table/SubDomainTable";
import IpAddressTable from "../../modules/attackSurfaceManagement/table/IpAddressTable";
import EndPointsTable from "../../modules/attackSurfaceManagement/table/EndPointsTable";
import CloudSurfaceAWS from "../../modules/attackSurfaceManagement/table/CloudSurfaceAWS";
import CloudSurfaceAzure from "../../modules/attackSurfaceManagement/table/CloudSurfaceAzure";
import CloudSurfaceGoogleCloud from "../../modules/attackSurfaceManagement/table/CloudSurfaceGoogleCloud";
import CloudSurfaceDigitalOcean from "../../modules/attackSurfaceManagement/table/CloudSurfaceDigitalOcean";
import DashboardApi from "../../Api/AuthenticationApi/DashboardApi";
import SSLActive from "../../modules/attackSurfaceManagement/table/SSLStatus/SSLActive";
import SSLStatus from "../../modules/attackSurfaceManagement/table/SSLStatus";
import ExploitIntelligence from "../../modules/attackSurfaceManagement/table/ExploitIntelligence";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import { HiDocumentReport } from "react-icons/hi";
import GenericLayout from "../../common/layout/GenericLayout";
import { report } from "../../assets/svg";
import Technologies from "../../modules/attackSurfaceManagement/table/Technologies";
import DNSRecord from "../../modules/attackSurfaceManagement/table/DNSRecord";
import Compliance from "../../modules/attackSurfaceManagement/table/Compliance";
import { ConfirmDialog } from "primereact/confirmdialog";
import WebApplicationVulnerabilities from "../../modules/attackSurfaceManagement/table/Vulnerabilities/WebApplicationVulnerabilities";

const hasWindow = typeof window !== "undefined";

const AttackSurfaceManagement = () => {
  const { isDarkMode } = useContext(ThemeContext);
  let { slug } = useParams();
  const refs = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector(getUserDetails);

  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRightReport, setVisibleRightReport] = useState(false);
  const [assetsData, setAssetsData] = useState({});
  const [scanHistoryData, setScanHistoryData] = useState([]);
  const [secureScoreData, setSecureScoreData] = useState();
  const [secureScoreGraphCategory, setSecureScoreGraphCategory] = useState();
  const [secureScoreGraphData, setSecureScoreGraphData] = useState();

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const getDashboardAttackSurfaceData = (payload) => {
    DashboardApi.dashboardAttackSurfaceData(payload).then((data) => {
      if (data.status === "success") {
        setAssetsData(data.data);
      }
    });
  };

  const getScanHistory = () => {
    DashboardApi.dashboardScanHistory().then((data) => {
      if (data.status === "success") {
        setScanHistoryData(data.data);
      }
    });
  };

  const getSecureScore = (payload) => {
    DashboardApi.getSecureScoreApi(payload).then((data) => {
      if (data.status === "success") {
        setSecureScoreData(data.data);
      }
    });
  };

  const getSecureScoreGraph = (payload) => {
    DashboardApi.getSecureScoreGraphApi(payload).then((data) => {
      if (data.status === "success") {
        setSecureScoreGraphCategory(data.categories);
        setSecureScoreGraphData(data.data);
      }
    });
  };

  useEffect(() => {
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    getDashboardAttackSurfaceData(payload);
    getSecureScore(payload);
    getSecureScoreGraph(payload);
    getScanHistory();
  }, [userDetails, slug]);

  const showActiveTableComponent = (slug) => {
    switch (slug) {
      case "live-hosts":
        return <LiveHostsTable title="Live Hosts" />;
      case "open-ports":
        return <OpenPortsTable title="Open Ports" />;
      case "sub-domain":
        return <SubDomainTable title="Subdomain" />;
      case "ip-address":
        return <IpAddressTable title="IP Address" />;
      case "end-points":
        return <EndPointsTable title="Endpoints" />;
      case "aws":
        return <CloudSurfaceAWS title="AWS" />;
      case "azure":
        return <CloudSurfaceAzure title="Azure" />;
      case "google-cloud":
        return <CloudSurfaceGoogleCloud title="Google Cloud" />;
      case "digital-ocean":
        return <CloudSurfaceDigitalOcean title="Digital Ocean" />;
      case "ssl-status":
        return <SSLStatus title="SSL Status" />;
      case "exploit-intelligence":
        return <ExploitIntelligence title="Exploit Intelligence" />;
      case "technologies":
        return <Technologies title="Vulnerability Intelligence" />;
      case "dns-record":
        return <DNSRecord title="Hosts" />;
      case "compliance":
        return <Compliance title="Compliance" />;
      case "web-application-vulnerabilities":
        return <WebApplicationVulnerabilities />;
      case "web-server-vulnerabilities":
        return <WebApplicationVulnerabilities />;
      case "web-vulnerabilities":
        return <WebApplicationVulnerabilities />;
      default:
        return;
    }
  };
  useEffect(() => {
    const container = document.getElementById("responsiveScreen");
    if (container) {
      const width = container.offsetWidth;
      if (width < 900) {
        container.classList.add("responseStyele");
      } else {
        container.classList.remove("responseStyele");
      }
    }
  }, []);

  return (
    <>
      <GenericLayout>
        <div
          id="responsiveScreen"
          // style={{ width: (width / 9) - 30 }}Attack Surface Management
          // className={`${vpwidth === 891 ? "responsiveScreenStyle":""}`}
        >
          <div className="flex justify-between dashboard-header items-center">
            <p className="font-bold">Attack Surface Management</p>
            {slug != undefined ? (
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() =>
                  location.state?.from
                    ? navigate(location.state.from)
                    : navigate("/attack-surface-management")
                }
              >
                Go to dashboard
                <TbArrowUpRight />
              </button>
            ) : (
              <div className="flex justify-between">
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base px-3"
                  onClick={() => setVisibleRightReport(true)}
                >
                  {/* <HiDocumentReport /> */}
                  <img src={report} />
                  Report
                </button>
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
                  onClick={() => setVisibleRight(true)}
                >
                  Scanning history
                  <TbArrowUpRight />
                </button>
              </div>
            )}
          </div>
          {slug != undefined ? (
            <div>{showActiveTableComponent(slug)}</div>
          ) : (
            <div>
              <AttackSurfaceManagementComponent
                assetsData={assetsData}
                secureScoreData={secureScoreData}
                secureScoreGraphCategory={secureScoreGraphCategory}
                secureScoreGraphData={secureScoreGraphData}
              />
            </div>
          )}
        </div>
      </GenericLayout>
      <ConfirmDialog />
      <div>
        <SidebarSlide
          title="Scan History"
          dashboardPage="ASM"
          description="See previous scanning results"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
        />
      </div>

      <div>
        <SidebarSlide
          title="Report"
          description="Generate Tailored Reports: Effortlessly Select Dates and Preferences for In-Depth Insights and Executive Overviews."
          visibleRight={visibleRightReport}
          setVisibleRight={setVisibleRightReport}
        />
      </div>
    </>
  );
};

export default AttackSurfaceManagement;
