export default function HorizontalLabelBar({
  title,
  data = {
    success: 0,
    failure: 0,
    unknown: 0,
  },
  onClick = null,
}) {
  const { success = 0, failure = 0, unknown = 0 } = data;
  const total = success + failure + unknown;
  const pass = ((success / total) * 100) | 0;
  const fail = ((failure / total) * 100) | 0;
  const unkn = ((unknown / total) * 100) | 0;

  return (
    <div className="p-2 cursor-pointer" onClick={onClick}>
      <h1 className="font-semibold">{title}</h1>
      <div className="flex flex-col gap-4 mt-4">
        <div>
          <p>Pass ({success})</p>
          <div className="h-5 bg-[#E4E4E4] rounded-md">
            <div
              className="h-5 bg-[#008C1B] rounded-md"
              style={{ width: `${pass}%` }}
            ></div>
          </div>
        </div>
        <div>
          <p>Fail ({failure})</p>
          <div className="h-5 bg-[#E4E4E4] rounded-md">
            <div
              className="h-5 bg-[#D11305] rounded-md"
              style={{ width: `${fail}%` }}
            ></div>
          </div>
        </div>
        <div>
          <p>Unknown ({unknown})</p>
          <div className="h-5 bg-[#E4E4E4] rounded-md">
            <div
              className="h-5 bg-[#FDCA3B] rounded-md"
              style={{ width: `${unkn}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
