import { Sidebar } from "primereact/sidebar";
import { ptaasAPI } from "../../Api/PTaaS/api";
import { toast } from "react-toastify";
import { STATUS } from "../../modules/home/components/ptaas/detailedPtaasView";
import { Dropdown } from "primereact/dropdown";
import { GrStatusInfo } from "react-icons/gr";
import { useState } from "react";

export default function UpdateStatus({
  // showUpdateStatus,
  // setShowUpdateStatus,
  scan,
  // setScan,
  getData,
}) {
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [status, setStatus] = useState(scan?.status || null);

  const handleSave = () => {
    ptaasAPI
      .patch(`/scan/${scan.id}/`, {
        status: status,
      })
      .then(() => {
        toast.success("Status updated successfully");
        cleanClose();
        getData();
      })
      .catch((err) => console.log(err));
  };

  const cleanClose = () => {
    setShowUpdateStatus(null);
  };

  return (
    <>
      <button
        className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
        onClick={() => setShowUpdateStatus(true)}
      >
        Update Status
        <GrStatusInfo />
      </button>
      <Sidebar visible={showUpdateStatus} onHide={cleanClose} position="right">
        <p className="font-bold text-2xl">Update Status</p>
        <label className="block text-gray-500 text-sm pt-3">
          Update Status
        </label>
        <div className="flex flex-col gap-3">
          <div className="w-full">
            <Dropdown
              value={status}
              onChange={(e) => setStatus(e.value)}
              options={Object.keys(STATUS).map((key) => ({
                label: STATUS[key],
                value: key,
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Update Status"
              required
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="flex w-full">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </div>
      </Sidebar>
    </>
  );
}
