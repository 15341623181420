import VulnerabilityOperationComponents from "../../../vulnerabilityOperations/components/VulnerabilityOperationComponents";

export default function WebApplicationVulnerabilities() {
  const url = window.location.href;
  return (
    <div className="ssl-status ">
      <h1 className="font-bold">Vulnerabilities intelligence analysis</h1>
      <div className="ssl-active">
        {url.includes("web-application-vulnerabilities") ? (
          <VulnerabilityOperationComponents type="web" />
        ) : url.includes("web-server-vulnerabilities") ? (
          <VulnerabilityOperationComponents type="network" />
        ) : (
          <>
            <VulnerabilityOperationComponents type="web" />
            <VulnerabilityOperationComponents type="network" />
          </>
        )}
      </div>
    </div>
  );
}
