import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TbArrowUpRight } from "react-icons/tb";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { ThemeContext } from "../../context/ThemeContext";
import BrandThreatIntelligenceComponent from "../../modules/brandmonitor/components/brandThreatIntelligenceComponent";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setUserDetails } from "../../redux-toolkit/redux/auth";
import DashboardApi from "../../Api/AuthenticationApi/DashboardApi";
import GenericLayout from "../../common/layout/GenericLayout";
import { report } from "../../assets/svg";
import BrandThreatIntelligence from "../../modules/attackSurfaceManagement/table/BrandThreatIntelligence";

const BrandThreatIntelligenceNew = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const hash = window.location.hash;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserDetails);

  const [scanHistoryData, setScanHistoryData] = useState([]);
  // const [scanStatus, setScanStatus] = useState(false);
  const [visibleRightReport, setVisibleRightReport] = useState(false);

  const [visibleRight, setVisibleRight] = useState(false);
  const getScanHistory = () => {
    DashboardApi.dashboardScanHistory().then((data) => {
      if (data.status === "success") {
        setScanHistoryData(data.data);
        if (!userDetails?.drp_scan_id && data.data.length > 0) {
          dispatch(
            setUserDetails({
              ...userDetails,
              drp_scan_id: data.data[0]?.id,
            })
          );
        }
      }
    });
  };

  const showActiveTableComponent = () => {
    if (hash) {
      return <BrandThreatIntelligence />;
    } else {
      return <BrandThreatIntelligenceComponent />;
    }
  };

  useEffect(() => {
    if (!userDetails?.drp_scan_id) {
      getScanHistory();
    } else if (scanHistoryData.length === 0) {
      getScanHistory();
    }
  }, [userDetails]);

  return (
    <>
      <GenericLayout>
        <div>
          <div className="flex justify-between items-center dashboard-header">
            <p className="font-bold">Brand Threat Intelligence</p>
            {hash != "" ? (
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() => navigate("/brand-threat-intelligence")}
              >
                Go to dashboard
                <TbArrowUpRight />
              </button>
            ) : (
              <div className="flex justify-between">
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base px-3"
                  onClick={() => setVisibleRightReport(true)}
                >
                  {/* <HiDocumentReport /> */}
                  <img src={report} />
                  Report
                </button>
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
                  onClick={() => setVisibleRight(true)}
                >
                  Scanning history
                  <TbArrowUpRight />
                </button>
              </div>
            )}
          </div>
          <div>{showActiveTableComponent()}</div>
        </div>
      </GenericLayout>

      <div>
        <SidebarSlide
          title="Scan History"
          dashboardPage="Brand"
          description="See previous scanning results"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
        />
      </div>

      <div>
        <SidebarSlide
          title="Report"
          description="Generate Tailored Reports: Effortlessly Select Dates and Preferences for In-Depth Insights and Executive Overviews."
          visibleRight={visibleRightReport}
          setVisibleRight={setVisibleRightReport}
        />
      </div>
    </>
  );
};

export default BrandThreatIntelligenceNew;
