import React, { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";
import { DownloadIcon } from "../../assets/svg";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import { BsDot } from "react-icons/bs";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../utils/paginationTemplate";

const TechnologiesDataTables = (props) => {
  const {
    columns,
    userInformation,
    dt,
    globalFilter,
    setVisibleRight,
    action,
    invoice,
    setTableViewData,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  useEffect(() => {
    setProducts(userInformation);
  }, [userInformation]);
  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <DataTable
      removableSort
      value={products}
      ref={dt}
      selection={selectedUsers}
      onSelectionChange={(e) => setSelectedUsers(e.value)}
      paginator
      rows={10}
      globalFilter={globalFilter}
      paginatorTemplate={paginationTemplate()}
      className={`${
        isDarkMode ? "dark-mode text-dark" : " text-color"
      } text-xs`}
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      {/* {columns.map((col, i) => (
        <Column key={col.field} field={col.field} header={col.header} sortable />
      ))} */}

      <Column
        field="host"
        header="Host"
        exportable={false}
        body={(rowData) => {
          return (
            <a
              href={`${rowData.host}`}
              target="_blank"
              className="text-blue-500"
            >
              {" "}
              {rowData.host}
            </a>
          );
        }}
        sortable
      />
      <Column field="tech" header="Technology" exportable={false} sortable />
      <Column field="category" header="Category" exportable={false} sortable />
      <Column
        header="Actions"
        body={actionBodyTemplate}
        exportable={false}
        sortable
      />
    </DataTable>
  );
};

export default TechnologiesDataTables;
