import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";

export default function AddLinkedPolicy({
  currentDetails,
  setCurrentDetails,
  departments,
  onHide,
}) {
  const [policies, setPolicies] = useState([]);

  const getPolicies = () => {
    complianceAPI
      .post("/policy/filter/")
      .then(({ data }) => {
        setPolicies(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    complianceAPI
      .post("/link-policy/", currentDetails)
      .then((res) => {
        if (res.status === "success") {
          toast.success("Policy linked successfully");
          cleanClose();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPolicies();
  }, []);

  const cleanClose = () => {
    setCurrentDetails(null);
    onHide();
  };

  return (
    <Sidebar
      visible={currentDetails}
      onHide={cleanClose}
      position="right"
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">
        Link Policy for{" "}
        {departments?.find((o) => o.id === currentDetails?.department)?.name}
      </p>
      <p className="text-sm py-1">Link policies with department.</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleSubmit(e)} className="w-full">
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Policy</label>
            <MultiSelect
              className="w-full"
              optionLabel="name"
              optionValue="id"
              filter
              value={currentDetails?.policies}
              options={policies}
              placeholder="Select policies"
              onChange={(e) =>
                setCurrentDetails({ ...currentDetails, policies: e.value })
              }
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Update" : "Link"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
