import React, { useContext, useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { ThemeContext } from "../../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import drpAPI, { dataBreachAPI } from "../../../Api/DRP/base";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";

const LoadingSpin = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

const DataBreachIntelligence = () => {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function getData() {
    dataBreachAPI
      .get("count/", {
        scan,
      })
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (scan) {
      getData();
    }
  }, [scan]);

  return (
    <>
      <div className="px-3 flex items-center justify-evenly my-5 relative">
        <div className="w-[100%] flex justify-center">
          <h1
            className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap cursor-pointer"
            // onClick={() => navigate("data-breach-intelligence")}
          >
            <span
              className="truncate text-link"
              title="Compliance Affect Assets"
            >
              Data Breach Intelligence
            </span>
          </h1>
        </div>

        <div className="flex justify-end items-end cursor-pointer absolute right-0">
          <img
            data-tooltip-id="dataBreach-tooltip"
            className="w-[18px]"
            src={isDarkMode ? InformationIconDark : InformationIconLight}
            alt="info"
          />
        </div>
      </div>
      <div className="">
        <div className="flex lg:flex-row justify-evenly items-center flex-col gap-6">
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() =>
              navigate("data-breach-intelligence#ComputerCompromised")
            }
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Compromised Computer</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.ComputerCompromised === undefined ? (
                <LoadingSpin />
              ) : (
                data?.ComputerCompromised | 0
              )}
            </h1>
          </div>
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() => navigate("data-breach-intelligence#CompromisedURL")}
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Compromised URL</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.CompromisedURL === undefined ? (
                <LoadingSpin />
              ) : (
                data?.CompromisedURL | 0
              )}
            </h1>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6">
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() =>
              navigate("data-breach-intelligence#EmployeeCompromised")
            }
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Compromised Employee</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.EmployeeCompromised === undefined ? (
                <LoadingSpin />
              ) : (
                data?.EmployeeCompromised | 0
              )}
            </h1>
          </div>
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() => navigate("data-breach-intelligence#ThirdPartyURL")}
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Third Party URL</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.ThirdPartyURL === undefined ? (
                <LoadingSpin />
              ) : (
                data?.ThirdPartyURL | 0
              )}
            </h1>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6">
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() => navigate("data-breach-intelligence#CompromisedUser")}
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Combo List</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.CompromisedUser === undefined ? (
                <LoadingSpin />
              ) : (
                data?.CompromisedUser | 0
              )}
            </h1>
          </div>
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() =>
              navigate("data-breach-intelligence#CompromisedFiles")
            }
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Compromised Files</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.CompromisedFile === undefined ? (
                <LoadingSpin />
              ) : (
                data?.CompromisedFile | 0
              )}
            </h1>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6">
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() =>
              navigate("data-breach-intelligence#EmployeeCredentials")
            }
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Compromised Credentials</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.EmployeeCredentials === undefined ? (
                <LoadingSpin />
              ) : (
                data?.EmployeeCredentials | 0
              )}
            </h1>
          </div>
          <div
            className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg cursor-pointer ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
            onClick={() =>
              navigate("data-breach-intelligence#ThirdPartyCredentials")
            }
          >
            <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate">Third Party Credentials</span>
            </h1>
            <h1 className="text-xl leading-normal font-bold">
              {data?.ThirdPartyCredentials === undefined ? (
                <LoadingSpin />
              ) : (
                data?.ThirdPartyCredentials | 0
              )}
            </h1>
          </div>
        </div>
      </div>
      <ReactTooltip
        id="dataBreach-tooltip"
        place="top"
        content="Explore real-time insights on cyber threats from our extensive database of compromised machines and malware campaigns."
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default DataBreachIntelligence;
