import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TbArrowUpRight } from "react-icons/tb";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { ThemeContext } from "../../context/ThemeContext";
import { Header } from "../../Component/Header";
import SideBar from "../../Component/sideBar/SideBar";
import DataLeakIntelligenceComponent from "../../modules/dataLeak/components/DataLeakIntelligenceComponent";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setUserDetails } from "../../redux-toolkit/redux/auth";
import DashboardApi from "../../Api/AuthenticationApi/DashboardApi";
import { HiDocumentReport } from "react-icons/hi";
import GenericLayout from "../../common/layout/GenericLayout";
import { report } from "../../assets/svg";
import DarkWeb from "../../modules/attackSurfaceManagement/table/DarkWeb";
import Phishing from "../../modules/attackSurfaceManagement/table/Phishing";
import DataLeakIntelligence from "../../modules/attackSurfaceManagement/table/DataLeakIntelligence";
import BrandThreatIntelligence from "../../modules/attackSurfaceManagement/table/BrandThreatIntelligence";
import DataBreachIntelligence from "../../modules/attackSurfaceManagement/table/DataBreachIntelligence";

const DigitalRiskProtection = () => {
  const { isDarkMode } = useContext(ThemeContext);
  let { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserDetails);

  const [scanHistoryData, setScanHistoryData] = useState([]);
  // const [scanStatus, setScanStatus] = useState(false);
  const [visibleRightReport, setVisibleRightReport] = useState(false);

  const [visibleRight, setVisibleRight] = useState(false);
  const getScanHistory = () => {
    DashboardApi.dashboardScanHistory().then((data) => {
      if (data.status === "success") {
        setScanHistoryData(data.data);
        if (!userDetails?.drp_scan_id && data.data.length > 0) {
          dispatch(
            setUserDetails({
              ...userDetails,
              drp_scan_id: data.data[0]?.id,
            })
          );
        }
        // data.data.filter((item) => {
        //   if (item.id === userDetails.drp_scan_id) {
        //     setScanStatus(true);
        //   }
        // });
      }
    });
  };

  const showActiveTableComponent = (slug) => {
    switch (slug) {
      case "dark-web":
        return <DarkWeb title="Dark Web Mentions" />;
      case "phishing":
        return <Phishing />;
      case "data-leak-lntelligence":
        return <DataLeakIntelligence />;
      case "brand-threat-intelligence":
        return <BrandThreatIntelligence />;
      case "data-breach-intelligence":
        return <DataBreachIntelligence />;
      default:
        return <DataLeakIntelligenceComponent />;
    }
  };

  useEffect(() => {
    if (!userDetails?.drp_scan_id) {
      getScanHistory();
    } else if (scanHistoryData.length === 0) {
      getScanHistory();
    }
  }, [userDetails]);

  return (
    <>
      <GenericLayout>
        <div>
          <div className="flex justify-between items-center dashboard-header">
            <p className="font-bold">Digital Risk Protection</p>
            {slug != undefined ? (
              <button
                className="flex items-center gap-[6px] text-[#074EE8] text-base"
                onClick={() => navigate("/digital-risk-protection")}
              >
                Go to dashboard
                <TbArrowUpRight />
              </button>
            ) : (
              <div className="flex justify-between">
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base px-3"
                  onClick={() => setVisibleRightReport(true)}
                >
                  {/* <HiDocumentReport /> */}
                  <img src={report} />
                  Report
                </button>
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
                  onClick={() => setVisibleRight(true)}
                >
                  Scanning history
                  <TbArrowUpRight />
                </button>
              </div>
            )}
          </div>
          <div>{showActiveTableComponent(slug)}</div>
        </div>
      </GenericLayout>

      <div>
        <SidebarSlide
          title="Scan History"
          dashboardPage="DRP"
          description="See previous scanning results"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
        />
      </div>

      <div>
        <SidebarSlide
          title="Report"
          description="Generate Tailored Reports: Effortlessly Select Dates and Preferences for In-Depth Insights and Executive Overviews."
          visibleRight={visibleRightReport}
          setVisibleRight={setVisibleRightReport}
        />
      </div>
    </>
  );
};

export default DigitalRiskProtection;
