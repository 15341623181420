import React, { useEffect, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DashboardApi from "../../Api/AuthenticationApi/DashboardApi";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";

const GeoChart = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  useEffect(() => {
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };

    (async () => {
      let result = [];
      if (window.location.href.includes("cloud-security-posture-management")) {
        console.log("props", props);
        result = {
          data: props?.data || [],
        };
      } else {
        result = await DashboardApi.geoMapApi(payload);
      }
      let arr = [];
      result.data.map((data) => {
        // let color = data.color.replace('"', "");
        arr.push({
          title: data.title,
          longitude: parseInt(data.longitude),
          latitude: parseInt(data.latitude),
          color: am4core.color("#f14d34"),
        });
      });
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create map instance
      let chart = am4core.create("chartdiv", am4maps.MapChart);

      // Disable chart logo
      if (chart.logo) {
        chart.logo.disabled = true;
      }

      // Set map definition
      chart.geodata = am4geodata_worldLow;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"];

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.polygon.fillOpacity = 0.6;

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart.colors.getIndex(0);

      // Add image series
      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";

      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 3;
      circle.propertyFields.fill = "color";
      circle.nonScaling = true;

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 3;
      circle2.propertyFields.fill = "color";

      circle2.events.on("inited", function (event) {
        animateBullet(event.target);
      });

      function animateBullet(circle) {
        let animation = circle.animate(
          [
            {
              property: "scale",
              from: 1 / chart.zoomLevel,
              to: 5 / chart.zoomLevel,
            },
            { property: "opacity", from: 1, to: 0 },
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on("animationended", function (event) {
          animateBullet(event.target.object);
        });
      }
      imageSeries.data = arr;
    })();
  }, [props]);

  return (
    <div
      id="chartdiv"
      style={{ width: props.width ? props.width : "70%", height: "300px" }}
    ></div>
  );
};

export default GeoChart;
