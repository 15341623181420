import React from "react";
import { modules, optionsIncluded } from "../../../../constants";
import { CheckBoxTick, CheckboxCross, Circle } from "../../../../assets/svg";
import { useSelector } from "react-redux";
import { getSubscription } from "../../../../redux-toolkit/redux/TableData";
import { useEffect } from "react";
import SkeletonBasic from "../../../SkeletonAnimation/SkeletonPlanCard";

const SubscriptionPlanCards = (props) => {
  const { isDarkMode, setPlanning, planPricing, loadingPlanCardData } = props;
  const subscriptionPlan = useSelector(getSubscription);

  const optionsKeywords = [
    "Domain",
    "Keywords",
    "Subdomains",
    "Scan Interval",
    "Users",
  ];

  useEffect(() => {
    let subscriptionOption = [];
    subscriptionOption.push(subscriptionPlan);
  }, []);

  console.log("Subscription Plan -> ", subscriptionPlan)

  return (
    <>
      <div className="flex flex-wrap mb-10 gap-5">
        <div
          className={`lg:w-[32%] w-full h-78  p-8 rounded-2xl ${
            isDarkMode
              ? "header-dark text-white"
              : "white-background text-color "
          }`}
        >
          {loadingPlanCardData ? (
            <SkeletonBasic plan={true} />
          ) : (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="flex justify-between items-center">
                  <h1 className="text-3xl font-bold">
                    {subscriptionPlan.plan}
                  </h1>
                  <div className=" subscription-card-status flex gap-2 items-center px-[14px] py-[4px] rounded-[14px] ">
                    <img src={Circle} alt="." />
                    <h1 className="text-base text-[#148842]">
                      {subscriptionPlan.status}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="pt-7">
                    <h1 className="text-sm">Activation Period</h1>
                    <p className="text-base pt-2">{subscriptionPlan.period}</p>
                  </div>
                  <div className="pt-7">
                    <h1 className="text-sm text-right">Next Billing Period</h1>
                    <p className="text-base text-right pt-2">
                      {subscriptionPlan.next_period}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className={`${
                    isDarkMode
                      ? "header-dark text-white border-[#FCFCFC] "
                      : "white-background"
                  } px-3 py-2  text-[#181818] rounded-[20px] border-[2px] border-[#181818] text-base`}
                  onClick={() => {
                    planPricing();
                  }}
                >
                  Upgrade License
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Cards */}
        <div
          className={`lg:w-[32%] w-full h-78 p-8 rounded-2xl ${
            isDarkMode
              ? "header-dark text-white"
              : "white-background text-color"
          }`}
        >
          <div>
            <h1 className="text-xl font-bold">Modules included</h1>
          </div>
          {loadingPlanCardData ? (
            <SkeletonBasic plan={false} />
          ) : (
            <div className="pt-4">
              {/* GRC */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.compliance ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.compliance ? (
                    <h1 className="text-base font-semibold capitalize">
                      Compliance
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      Compliance
                    </h1>
                  )}
                </div>
              </div>

              {/* CSPM */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.cloud ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.cloud ? (
                    <h1 className="text-base font-semibold capitalize">
                      Cloud Security Posture
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      Cloud Security Posture
                    </h1>
                  )}
                </div>
              </div>

              {/* PTaaS */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.pentest ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.pentest ? (
                    <h1 className="text-base font-semibold capitalize">
                      Pentest As A Servcie
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      Pentest As A Servcie
                    </h1>
                  )}
                </div>
              </div>

              {/* ASM */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.attack_surface == "1" ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.attack_surface == "1" ? (
                    <h1 className="text-base font-semibold capitalize">
                      attack surface management
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      attack surface management
                    </h1>
                  )}
                </div>
              </div>

              {/* VOC */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.voc == "1" ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.voc == "1" ? (
                    <h1 className="text-base font-semibold capitalize">
                      vulnerability operation center
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      vulnerability operation center
                    </h1>
                  )}
                </div>
              </div>

              {/* DRP */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.digital_risk_protection == "1" ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.digital_risk_protection == "1" ? (
                    <h1 className="text-base font-semibold capitalize">
                      digital risk protection
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      digital risk protection
                    </h1>
                  )}
                </div>
              </div>

              {/* BrandThreat */}
              <div className="flex gap-2 items-center pb-2">
                <div>
                  {subscriptionPlan.brand_threat == "1" ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
                <div>
                  {subscriptionPlan.brand_threat == "1" ? (
                    <h1 className="text-base font-semibold capitalize">
                      brand theat intelligence
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold capitalize text-inactive">
                      brand theat intelligence
                    </h1>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`lg:w-[32%] w-full h-78 p-8 rounded-2xl ${
            isDarkMode
              ? "header-dark text-white"
              : "white-background text-color"
          }`}
        >
          <div>
            <h1 className="text-xl font-bold">Options included</h1>
          </div>
          <div className="pt-4">
            {loadingPlanCardData ? (
              <SkeletonBasic plan={false} />
            ) : (
              <div className="flex justify-between">
                <ul>
                  {optionsKeywords.map((option, index) => (
                    <li
                      key={`${index}-${option}`}
                      className="text-base font-semibold capitalize pb-2"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
                <ul>
                  <li className="text-base font-semibold pb-2">
                    {subscriptionPlan.domains}
                  </li>
                  <li className="text-base font-semibold pb-2">
                    {subscriptionPlan.keywords}
                  </li>
                  <li className="text-base font-semibold pb-2">
                    {subscriptionPlan.subdomains}
                  </li>
                  <li className="text-base font-semibold pb-2">
                    {subscriptionPlan.scan_interval}
                  </li>
                  <li className="text-base font-semibold pb-2">
                    {subscriptionPlan.user_count}
                  </li>
                </ul>
              </div>
            )}

            {/* {optionsIncluded.map((option) => (
          <div key={option.id} className='flex justify-between'>
            <div>
              <h1 className='text-base font-semibold capitalize pb-2'>{option.optionName}</h1>
            </div>
            <div>
              <h1 className='text-base font-semibold pb-2'>{option.optionTime}</h1>
            </div>
          </div>
        ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlanCards;
