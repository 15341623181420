import { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../../context/ThemeContext";

export default function RadialBar({
  title,
  data = {
    success: 0,
    failure: 0,
    unknown: 0,
  },
  onClick = null,
}) {
  const { isDarkMode } = useContext(ThemeContext);
  const { success = 0, failure = 0, unknown = 0 } = data;

  const total = success + failure + unknown;
  const pass = ((success / total) * 100) | 0;
  const fail = ((failure / total) * 100) | 0;
  const unkn = ((unknown / total) * 100) | 0;
  const series = [
    // round off the values
    Math.round(pass),
    Math.round(fail),
    Math.round(unkn),
  ];
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
            color: isDarkMode ? "#fff" : "#000",
          },
          value: {
            fontSize: "16px",
            color: isDarkMode ? "#fff" : "#000",
          },
          total: {
            show: true,
            label: "Total",
            color: isDarkMode ? "#fff" : "#000",
            formatter: function (w) {
              return total;
            },
          },
        },
      },
    },
    labels: ["Pass", "Fail", "Unknown"],
    colors: ["#008C1B", "#D11305", "#FDCA3B"],
  };

  return (
    <div
      className="flex flex-col items-center cursor-pointer"
      onClick={onClick}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={350}
      />
      <h1 className="font-semibold text-center">{title}</h1>
      <div className="grid justify-center">
        <div className="flex items-center gap-2">
          <div className="w-4 h-2 rounded-full bg-[#008C1B]"></div>
          Pass ({success})
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-2 rounded-full bg-[#D11305]"></div>
          Fail ({failure})
        </div>
        <div className="flex items-center  gap-2">
          <div className="w-4 h-2 rounded-full bg-[#FDCA3B]"></div>
          Unknown ({unknown})
        </div>
      </div>
    </div>
  );
}
