import React, { useRef, useState } from "react";
import DashboardCloudTables from "../../../../../common/Tables/DashboardCloudTables";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const CloudSurfaceGoogleCloudTable = (props) => {
  const {
    googleCloudTableData,
    loadingTable,
    FilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "keyword", header: "Keyword", value: FilterData?.keyword },
    { field: "cloud", header: "Cloud Service", value: FilterData?.cloud },
    { field: "status", header: "Status", value: FilterData?.status },
    { field: "url", header: "URL", value: FilterData?.url },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={googleCloudTableData}
          tableHeader={columns}
          setFilterParams={setFilterParams}
          dt={dt}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DashboardCloudTables
            userInformation={googleCloudTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};

export default CloudSurfaceGoogleCloudTable;
