import { createElement, useContext, useState } from "react";

import { ThemeContext } from "../../../../../context/ThemeContext";
import Training from "./Training";

export default function EmployeeCampaign() {
  const { isDarkMode } = useContext(ThemeContext);

  const [currentTab, setCurrentTab] = useState("training");

  const TABS = [
    {
      id: "training",
      title: "Training",
      component: Training,
    },
  ];

  return (
    <>
      <h1 className="font-bold">Employee Campaign</h1>
      <div
        className={`flex gap-5 border-b-2 my-6 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-1 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component)}
      </div>
    </>
  );
}
