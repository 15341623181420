import React from "react";

const PlanPricing = (props) => {
  const { isDarkMode, title, description } = props;
  return (
    <>
      <div
        className={`rounded-2xl userSlide-style  ${
          isDarkMode ? " text-white" : " text-color"
        }`}
      >
        <div className="flex flex-wrap">
          <div>
            <h1 className="text-2xl font-bold">{title}</h1>
          </div>
        </div>
        <div className="pt-2">
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export default PlanPricing;
