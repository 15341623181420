import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../utils/utils";
import { getCSPMFilters, setCSPMFilters } from "../../redux-toolkit/redux/auth";

export default function CustomFilter({ visible, setVisible }) {
  const dispatch = useDispatch();
  const cspmFilters = useSelector(getCSPMFilters);

  const addFilter = (e) => {
    handleSubmitButtonPress(e);
    e.preventDefault();
    const key = e.target[0].value;
    const value = e.target[1].value;
    // check if key already exist
    dispatch(setCSPMFilters({ ...cspmFilters, [key]: value }));
    e.target[0].value = "";
    e.target[1].value = "";
    handleSubmitButtonRelease(e);
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={() => setVisible(false)}
      className="w-[400px]"
    >
      <p className="font-bold text-2xl">Custom Filter</p>
      <p className="text-sm py-1">
        Add custom filter based on you custom labels in AWS.
      </p>
      {cspmFilters && Object.keys(cspmFilters).length > 0 && (
        <>
          <div className="border p-1 mb-1">
            <div className="grid grid-cols-2 w-[90%]">
              <p className="text-sm font-bold">Key</p>
              <p className="text-sm font-bold">Value</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {Object.keys(cspmFilters).map((key) => (
              <div
                key={key}
                className="flex justify-between border p-1 items-center"
              >
                <div className="grid grid-cols-2 w-[90%]">
                  <p>{key}</p>
                  <p>{cspmFilters[key]}</p>
                </div>
                <button
                  className="border border-[#AAAAAA] rounded-md p-1 h-[35px] self-end"
                  onClick={() => {
                    const newFilters = { ...cspmFilters };
                    delete newFilters[key];
                    dispatch(setCSPMFilters(newFilters));
                  }}
                >
                  <MdOutlineDelete />
                </button>
              </div>
            ))}
          </div>
        </>
      )}
      <form
        className="flex gap-1"
        onSubmit={(e) => {
          e.preventDefault();
          addFilter(e);
        }}
      >
        <div className="flex gap-1 mt-3">
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Key</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Filer Key"
              required
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Value</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Filer Value"
              required
            />
          </div>
          <button
            name="submit"
            className="border border-[#AAAAAA] rounded-md p-1 h-[35px] self-end mb-2"
            type="submit"
          >
            <IoAddCircleOutline />
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
