import { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

import { ThemeContext } from "../../../../context/ThemeContext";
import { UserFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { complianceAPI } from "../../../../Api/CSPM/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";

export default function ComplianceSettings() {
  const { isDarkMode } = useContext(ThemeContext);

  const users = useSelector(getUserDetails)?.org;
  const [data, setData] = useState(null);

  const FRAMEWORKS = [
    {
      label: "SOC 2",
      value: "soc_2",
    },
    {
      label: "ISO 27001",
      value: "iso_27001",
    },
    {
      label: "HIPAA",
      value: "hipaa",
    },
    {
      label: "DPTM",
      value: "dptm",
    },
    {
      label: "NCA",
      value: "nca",
    },
    {
      label: "SAMA",
      value: "sama",
    },
    {
      label: "CMMC V2.0 Level 1",
      value: "cmmc_v2_0_level_1",
    },
    {
      label: "CMMC V2.0 Level 2",
      value: "cmmc_v2_0_level_2",
    },
  ];

  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    complianceAPI
      .post("/import/", data)
      .then((res) => {
        if (res.status === "success") {
          toast.success("Import Completed successfully");
          e.target.submit.removeAttribute("disabled");
        } else {
          toast.error(res.message);
          e.target.submit.removeAttribute("disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in Importing");
        e.target.submit.removeAttribute("disabled");
      });
  };

  return (
    <>
      <div
        className={`p-8 rounded-2xl relative flex flex-col justify-between ${
          isDarkMode ? "header-dark text-white" : "white-background text-color"
        }`}
      >
        {/* logo uploader */}
        <p className="font-bold">Import Compliance</p>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-3 mt-3">
            <div className="px-3">
              <label className="block text-gray-500 text-sm ">User</label>
              <Dropdown
                value={data?.user}
                onChange={(e) => {
                  setData({ ...data, user: e.value });
                }}
                options={users}
                optionLabel="email"
                filter
                optionValue="id"
                required
                placeholder="Select a User"
                className="w-full p-dropdown"
              />
            </div>
            <div className="px-3">
              <label className="block text-gray-500 text-sm">Frameworks</label>
              <MultiSelect
                className="w-full p-dropdown"
                optionLabel="label"
                optionValue="value"
                value={data?.frameworks}
                required
                filter
                options={FRAMEWORKS}
                placeholder="Select Frameworks"
                onChange={(e) => setData({ ...data, frameworks: e.value })}
              />
            </div>
            <div className="justify-items-center">
              <button
                className="mt-6 ml-2 px-4 border  rounded-[16px] border-[#AAAAAA] h-[40px] bg-[#181818] text-[#ffff] cursor-pointer"
                type="submit"
                id="submit"
                onClick={() => {
                  console.log(data);
                  if (!data) {
                    toast.error("Fill User and frameworks.");
                  } else if (data?.frameworks.length === 0) {
                    toast.error("At least select one framework.");
                  } else if (!data?.user) {
                    toast.error("User is required.");
                  }
                }}
              >
                Import
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
