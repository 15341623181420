import React, { useState } from "react";
import { useEffect } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaExclamationTriangle } from "react-icons/fa";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import TableApi from "../../../Api/AuthenticationApi/TableApi";

const ApplicationVulnerabilities = (props) => {
  const { applicationVulnerability, isDarkMode } = props;
  const title = "Top 10 Application Vulnerabilities";

  const [openVulnerabilitySideBar, setOpenVulnerabilitySideBar] =
    useState(false);
  const [loadingSidebarVocDetail, setLoadingSidebarVocDetail] = useState(false);
  const [sideBarVocIntelligenceDetail, setSideBarVocIntelligenceDetail] =
    useState(false);

  const onClickHandler = (data) => {
    console.log(data, "asdasd");
    setLoadingSidebarVocDetail(true);
    setOpenVulnerabilitySideBar(true);
    TableApi.vulnerabilityIntelligenceDetail(data).then((data) => {
      if (data.status === "success") {
        setSideBarVocIntelligenceDetail(data.data);
        setLoadingSidebarVocDetail(false);
      }
    });
  };

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {/* {title.split("").length > 30
                  ? `${title.slice(0, 25)}...`
                  : title} */}
                {title}
              </span>
            </h1>
          </div>
          <button className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              data-tooltip-id="voc-tooltip"
              className="w-[18px]"
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="px-3 h-full w-fit flex flex-col gap-1">
            {applicationVulnerability.map((vulnerability, index) => (
              <div
                className="grid grid-cols-6 gap-2 items-center"
                key={index}
                onClick={() => {
                  onClickHandler(vulnerability);
                }}
              >
                <div className=" items-center">
                  <FaExclamationTriangle
                    size={24}
                    color={vulnerability.colors}
                  />
                  {/* {vulnerability.colors.map((color, colorIndex) => (
                    
                    <div
                      className="h-[17px] w-[17px] rounded-full"
                      style={{ backgroundColor: color }}
                      key={colorIndex}
                    />
                  ))} */}
                </div>
                <h1
                  title={vulnerability.vulnerability}
                  className="text-sm col-span-5 truncate"
                >
                  {vulnerability.vulnerability}
                </h1>
              </div>
            ))}
          </div>

          <div className="px-3 flex flex-wrap items-center justify-center bottom-0 my-2 h-[60px]">
            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#A30123] rounded-full" />
              <h1 className="text-sm">Critical</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#E8001D] rounded-full" />
              <h1 className="text-sm">High</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#F5B207] rounded-full" />
              <h1 className="text-sm">Medium</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#148842] rounded-full" />
              <h1 className="text-sm">Low</h1>
            </div>
          </div>
        </div>
      </div>
      <SidebarSlide
        title="Vulnerability Intelligence"
        visibleRight={openVulnerabilitySideBar}
        setVisibleRight={setOpenVulnerabilitySideBar}
        customWidth={true}
        sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
        loadingSidebarVocDetail={loadingSidebarVocDetail}
      />
      <ReactTooltip
        id="voc-tooltip"
        place="bottom-end"
        content="Discover key insights into the Application vulnerabilities to strategically enhance your prioritization"
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default ApplicationVulnerabilities;
