import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import TableApi from "../../../../Api/AuthenticationApi/TableApi";
// import {
//   getSearchKeywordGithub,
//   getSearchKeywordDarkweb,
//   getSearchKeywordCloud,
//   getSearchKeywordTwitter,
// } from "../../../../redux-toolkit/redux/Sidebar";
import { CheckBoxTick, CheckboxCross } from "../../../../assets/svg";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";

const ScanEngineForm = (props) => {
  const {
    // handleBackToInitialScan,
    // handleMoveToScanEngine,
    handleCloseSideBar,
    setActiveScanForm,
    selectedOption,
    setSelectedOption,
    type,
  } = props;
  // const searchKeywordGit = useSelector(getSearchKeywordGithub);
  // const searchKeywordDarkweb = useSelector(getSearchKeywordDarkweb);
  // const searchKeywordCloud = useSelector(getSearchKeywordCloud);
  // const searchKeywordTwitter = useSelector(getSearchKeywordTwitter);

  const userDetails = useSelector(getUserDetails);
  const [checkboxes, setCheckboxes] = useState({
    attackSurface: false,
    darkIntelligence: false,
    dataLeakDetection: false,
    brandThreatMonitoring: false,
  });

  const [configCheckboxes, setConfigCheckboxes] = useState({});

  // const dummyCheckboxNames = Array.from({ length: 20 }, (_, index) => `checkbox${index + 1}`);
  // const dummyCheckboxNames = [
  //   "Sub Domains",
  //   "Darkweb Scan",
  //   "Live Hosts",
  //   "Credential Leaks",
  //   "Endpoints",
  //   "Whois Information",
  //   "Cloud Scanning",
  //   "DNS Information",
  //   "Directory",
  //   "Telegram",
  //   "Networks",
  //   "Discord",
  //   "Web Applications",
  //   "Paste Leaks",
  //   "JS Leaks",
  //   "Twitter",
  //   "Domain Phishing",
  //   "Instagram",
  //   "Github Scan",
  //   "Youtube",
  // ];
  const allModules = [
    "Subdomains",
    "Live Hosts",
    "Endpoints",
    "Shodan",
    "Hosts",
    "Cloud Scanning",
    "DNS",
    "Whois",
    "SSL",
    "Directory",
    "Technologies",
    "Networks",
    "Web Applications",
    "Compliance",
    "Darkweb",
    "Domain Phishing",
    "Secrets Leak",
    "Social Media",
    "IP Address",
  ];

  let SCAN_ENGINES = [];

  if (userDetails?.asm) {
    SCAN_ENGINES = [
      ...SCAN_ENGINES,
      {
        key: "default_scan",
        title: "Default Scan",
        modules: [
          "Subdomains",
          "Live Hosts",
          "Endpoints",
          "Shodan",
          "Hosts",
          "Cloud Scanning",
          "DNS",
          "Whois",
          "SSL",
          "Directory",
          "Technologies",
          "Networks",
          "Web Applications",
          "Compliance",
        ],
      },
      {
        key: "full",
        title: "Full Scan",
        modules: [
          "Subdomains",
          "Live Hosts",
          "Endpoints",
          "Shodan",
          "Hosts",
          "Cloud Scanning",
          "DNS",
          "Whois",
          "SSL",
          "Directory",
          "Technologies",
          "Networks",
          "Web Applications",
          "Compliance",
          "Darkweb",
          "Domain Phishing",
          "Secrets Leak",
          "Social Media",
        ],
      },
      {
        key: "cloud_intel",
        title: "Cloud Intelligence",
        modules: ["Cloud Scanning"],
      },
      {
        key: "discovery",
        title: "Discovery",
        modules: [
          "Subdomains",
          "IP Address",
          "Live Hosts",
          "Endpoints",
          "Hosts",
          "DNS",
        ],
      },
    ];
  }

  if (userDetails?.drp) {
    SCAN_ENGINES = [
      ...SCAN_ENGINES,
      {
        key: "drp",
        title: "Digital Risk Protection",
        modules: ["Darkweb", "Domain Phishing", "Secrets Leak", "Social Media"],
      },
    ];
  }

  if (userDetails?.bti) {
    SCAN_ENGINES = [
      ...SCAN_ENGINES,
      {
        key: "brand_threat",
        title: "Brand Threat Intelligence",
        modules: ["Social Media"],
      },
    ];
  }

  if (userDetails?.pentest_as_a_service) {
    SCAN_ENGINES = [
      ...SCAN_ENGINES,
      {
        key: "ptaas",
        title: "Penetration Testing as a Service",
        modules: [],
      },
    ];
  }

  // useEffect(() => {
  //   const initialCheckboxes = dummyCheckboxNames.reduce((acc, name) => {
  //     if (selectedOption === "fullScan") {
  //       acc[name] = true;
  //       return acc;
  //     } else {
  //       acc[name] = false;
  //       return acc;
  //     }
  //   }, {});
  //   setConfigCheckboxes(initialCheckboxes);
  // }, [selectedOption]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCheckboxChange = (checkboxName) => {
    // if(checkboxName === "attackSurface"){
    //   const initialCheckboxes = dummyCheckboxNames.reduce((acc, name) => {
    //     if(selectedOption === "modules"){
    //       // acc[name ] = false
    //       console.log("ARRTdfs",acc ,name, selectedOption)
    //     }
    //   },{})
    // }
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };

  const handleConfigCheckboxChange = (checkboxName) => {
    if (selectedOption === "modules") {
      setConfigCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxName]: !prevCheckboxes[checkboxName],
      }));
    }
  };

  const handleScanSubmit = () => {
    console.log(selectedOption, "selelee", selectedOption === "drp");
    if (
      (selectedOption === "drp" || selectedOption === "cloud_intel") &&
      type === "schedule-scan"
    ) {
      setActiveScanForm(3);
    } else if (
      selectedOption === "drp" ||
      selectedOption === "cloud_intel" ||
      selectedOption === "brand_threat"
    ) {
      setActiveScanForm(2);
    } else if (selectedOption === "ptaas") {
      setActiveScanForm(3);
    } else {
      setActiveScanForm((prev) => prev + 1);
    }
    // const payload = {
    //   target: "1",
    //   scan_type: "full",
    //   scan_darkweb_keywords: searchKeywordDarkweb,
    //   scan_twitter_keywords: searchKeywordTwitter,
    //   scan_github_keywords: searchKeywordGit,
    //   scan_cloud_keywords: searchKeywordCloud,
    // };
    // TableApi.initiateScanWeb(payload).then((data) => {});
  };

  return (
    <div>
      <div className="mt-8 ">
        <div className="pb-2">
          <h1 className="text-base font-bold">Scan Engine</h1>
        </div>
        {SCAN_ENGINES.map((item) => {
          return (
            <div className="flex items-center gap-2">
              <input
                type="radio"
                value={item.key}
                checked={selectedOption === item.key}
                onChange={handleOptionChange}
                className="h-4 w-4"
              />
              <label className="text-base font-normal text-color">
                {item.title}
              </label>
            </div>
          );
        })}

        {/* <div className="flex items-center gap-2 pt-2">
          <input
            type="radio"
            value="modules"
            checked={selectedOption === "modules"}
            onChange={handleOptionChange}
            className="h-4 w-4"
          />
          <label
            className={`text-base font-normal ${selectedOption === "modules" ? "text-color" : "text-inactive"
              }`}
          >
            Modules
          </label>
        </div> */}

        {selectedOption === "modules" && (
          <div className="ml-7">
            <div className="flex items-center gap-2 pt-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                checked={checkboxes.attackSurface}
                onChange={() => handleCheckboxChange("attackSurface")}
              />
              <label
                className={`text-base font-normal ${
                  checkboxes.attackSurface ? "text-color" : "text-inactive"
                }`}
              >
                Attack Surface Management
              </label>
            </div>
            <div className="flex items-center gap-2 pt-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                checked={checkboxes.darkIntelligence}
                onChange={() => handleCheckboxChange("darkIntelligence")}
              />
              <label
                className={`text-base font-normal ${
                  checkboxes.darkIntelligence ? "text-color" : "text-inactive"
                }`}
              >
                Dark & Deep Intelligence
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="pt-8">
        <div className="pb-2">
          <h1 className="text-base font-bold">Scan Configuration</h1>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {allModules.map((checkboxName, index) => {
            const _selected = SCAN_ENGINES.find(
              (item) => item.key === selectedOption
            )?.modules?.includes(checkboxName);
            if (
              selectedOption === "default_scan" &&
              checkboxName === "Web Applications"
            ) {
              checkboxName = "Web Applications (Limited)";
            }
            return (
              <div key={index} className="flex items-center gap-2 pt-2">
                {/* <input
                type="checkbox"
                className="h-4 w-4"
                // checked={selectedOption=="full"}
                checked={_selected}
                onChange={() => handleConfigCheckboxChange(checkboxName)}
              /> */}
                {_selected ? (
                  <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                ) : (
                  <img src={CheckboxCross} alt="cross" />
                )}
                <label
                  className={`text-base font-normal ${
                    // configCheckboxes[checkboxName]
                    _selected ? "text-color" : "text-inactive"
                  }`}
                >
                  {checkboxName}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex w-full pt-5">
        <button
          onClick={handleScanSubmit}
          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          type="submit"
        >
          Continue
        </button>
        <button
          onClick={handleCloseSideBar}
          className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
          type="button"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ScanEngineForm;
