import React, { useRef, useState } from "react";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import EndPointsDataTables from "../../../../../common/Tables/EndPointsTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import Tables from "../../../../../common/Tables/Tables";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const Endpoints = (props) => {
  const {
    endPointsTableData,
    loadingTable,
    endPointsFilterData,
    setFilterParams,
    filterParams,
  } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "endpoint", header: "URL", value: endPointsFilterData?.endpoint },
    {
      field: "status_code",
      header: "Status",
      value: endPointsFilterData?.status_code,
    },
    // { field: "hosting_provider", header: "Hosting Provider"  , value:[]},
    { field: "source", header: "Source", value: endPointsFilterData?.source },
    { field: "tag", header: "Tags", value: endPointsFilterData?.tag },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          dt={dt}
          tableData={endPointsTableData}
          tableHeader={columns}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <EndPointsDataTables
            userInformation={endPointsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
            action={true}
          />
        )}
      </div>
    </>
  );
};

export default Endpoints;
