import Repository, { baseUrl } from "../Repository";
import { Link, redirect } from "react-router-dom";
import { removeAuthenticationData } from "../../utils/constants";
import { Navigate } from "react-router-dom";

// const navigate = useNavigate()

class TableApiFetch {
  fetchTwitterData = (payload) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/social/twitter/1`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  userInformationTableData = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/team/users/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  createUser = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/team/user/create/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  createMTAdmin = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/register/superuser`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 500) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getUserRole = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/user/roles/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getUserTableDataView = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/team/user/details/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getUserTableDataEdit = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/team/user/details/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  changePassword = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/change/password/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getAllProjects = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/domains/target/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  createProject = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/domains/target/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  createSubdomain = async (payload) => {
    // try{
    //   const reponse = await Repository.post(
    //     `${baseUrl}ultraheals/v1/web/asm/subdomain/`,
    //     payload
    //   )
    //   console.log("RESPONSE",reponse)
    //   return reponse;
    // }
    // catch(error){
    //   console.log("ERRROR",error)
    // }
    const reponse = await Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/subdomain/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });

    return reponse;
  };

  planSubscription = (params) => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/subscription/`, {
      params,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  subscriptionTable = () => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/subscription/history`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  planPricing = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/subscription/plans`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  mfaToggleVerify = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/user/mfa/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  enableMfaVerify = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/mfa/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  otpMfaEmailVerify = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/mfa/activate/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  verifyMFA = (data) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/mfa/verify/`,
      data
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  disableMfa = (id) => {
    return new Promise((resolve, reject) => {
      Repository.delete(`${baseUrl}ultraheals/v1/user/mfa/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeAuthenticationData();
          }
          reject(error);
        });
    });
  };

  initiateScanWeb = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/scan/initiatescan/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  scheduleScanWeb = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/scan/schedulescan/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getViewScanDataApi = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/domains/target/view/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getScheduleScanWeb = (payload) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/scan/schedulescan/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  cancelScanApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/scan/cancelscan/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  deleteTarget = (id) => {
    const reponse = Repository.delete(
      `${baseUrl}ultraheals/v1/domains/target/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  initiateScanKeywords = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/scan/keywords/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getTargetKeywords = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/scan/keywords/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  startScanWeb = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/scan/startscan/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  vulnerabilityIntelligence = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/intelligence/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
        if (error.response.status === 500) {
          // removeAuthenticationData();
          <Link to="/ErrorPage" />;
        }
      });
    return reponse;
  };

  vulnerabilityIntelligenceDetail = (payload) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/dashboard/voc/intelligence/${payload.type}/${payload.id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  vulnerabilityIntelligenceStatus = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/intelligence/status/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  userProfileFilter = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/team/users/filter/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  myProjectsFilter = () => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/domains/target/filter/`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  subscriptionHistoryFilter = () => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/subscription/history/filter/`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  vocIntelligenceFilter = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/intelligence/filter/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getLoginHistory = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/user/login/history/`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getUserByRole = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/role/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };
}
export default new TableApiFetch();
