import React, { useEffect, useRef, useState } from "react";
import Tables from "../../../../common/Tables/Tables";
import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import TableApi from "../../../../Api/AuthenticationApi/TableApi";

const UserInformationTable = (props) => {
  const {
    role,
    userInformation,
    setTableViewData,
    loadingTable,
    isDarkMode,
    btnText,
    btnName,
    userTableViewData,
    getUserInformationTableData,
    FilterData,
    setFilterParams,
    filterParams,
    orgUsers,
  } = props;
  const [globalFilter, setGlobalFilter] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleUserRight, setVisibleUserRight] = useState(false);
  const [userFilterData, setUserFilterData] = useState([]);

  const dt = useRef(null);
  const columns = [
    { field: "first_name", header: "Name", value: FilterData?.first_name },
    { field: "last_name", header: "Surname", value: FilterData?.last_name },
    { field: "email", header: "Email", value: FilterData?.email },
    // {
    //   field: "projects",
    //   header: "Projects Assigned",
    //   value: FilterData?.projects,
    // },
    { field: "role", header: "Role", value: FilterData?.role },
    { field: "MFA", header: "MFA", value: FilterData?.mfa },
  ];

  useEffect(() => {
    // TableApi.userProfileFilter().then((data)=>{
    //   setUserFilterData(data.data)
    // })
  }, []);

  return (
    <>
      <div>
        <TableFilter
          dt={dt}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          isDarkMode={isDarkMode}
          setVisibleRight={setVisibleRight}
          btnName={btnName}
          btnText={btnText}
          columns={columns}
          userInformation={userInformation}
          tableData={userInformation}
          tableHeader={columns}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <Tables
            columns={columns}
            userInformation={userInformation}
            dt={dt}
            globalFilter={globalFilter}
            setVisibleRight={setVisibleUserRight}
            action={true}
            setTableViewData={setTableViewData}
          />
        )}
      </div>
      <div className="side-userstyle">
        <SidebarSlide
          title="Create User"
          description="Create a user account using an invite link"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          role={role}
          orgUsers={orgUsers}
          getUserInformationTableData={getUserInformationTableData}
        />
      </div>
      <div className="side-user-info-style">
        <SidebarSlide
          title="User Information"
          description="Edit the user account"
          visibleRight={visibleUserRight}
          setVisibleRight={setVisibleUserRight}
          userTableViewData={userTableViewData}
          role={role}
          orgUsers={orgUsers}
          getUserInformationTableData={getUserInformationTableData}
        />
      </div>
    </>
  );
};

export default UserInformationTable;
