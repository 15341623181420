import baseAPI, { baseUrl } from "../Repository.js"; //just axios instance
import { removeAuthenticationData } from "../../utils/constants";

class IntegrationsAPI {
  constructor() {
    this.baseUrl = baseUrl;
    this.endpoint = this.baseUrl + "ultraheals/v1/web/integrations";
  }

  get(path, params) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .get(url, { params })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  post(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  patch(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .patch(url, data)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  delete(path) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .delete(url)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }
}

const integrationsAPI = new IntegrationsAPI();

export default integrationsAPI;
