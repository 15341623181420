import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import SecureScore from "../../attackSurfaceManagement/components/SecureScore";
import DataLeakWidgets from "./DataLeakWidgets";
import ComprimisedEmployee from "./ComprimisedEmployee";
import ComprimisedProduct from "./ComprimisedProduct";
import ComprimisedUrl from "./ComprimisedUrl";
import CircularProgressBar from "../../../common/Charts/CircularProgressBar";
import DataBreachIntelligence from "./DataBreachIntelligence";
import DonutChart from "../../../common/Charts/DonutChart";
import LeakedSecrets from "./LeakedSecrets";
import VerticalBarChart from "../../../common/Charts/VerticalBarChart";
import BrandTreat from "./BrandTreat";
import { useNavigate } from "react-router-dom";
import baseAPI, { baseUrl } from "../../../Api/Repository";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import complianceLockImage from "../../../assets/lockscreen/compliance.jpg";

const DataLeakIntelligenceComponent = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleNavigate = (e, type) => {
    console.log("SGDGFDF", e, type);
    e.preventDefault();
    if (type === "dark-web") {
      navigate(`/digital-risk-protection/${type}`);
    }
  };

  const userDetails = useSelector(getUserDetails);

  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const [secretsLeak, setSecretsLeak] = useState({});
  const [darkwWeb, setDarkWeb] = useState({});
  const [domainPhishing, setDomainPhishing] = useState({});
  const [secureScoreData, setSecureScoreData] = useState({});
  const [secureScoreGraphCategory, setSecureScoreGraphCategory] = useState([]);
  const [secureScoreGraphData, setSecureScoreGraphData] = useState([]);
  const [brandThreat, setBrandThreat] = useState({});
  const [passwordStrength, setPasswordStrength] = useState({});
  const [VIPProtection, setVIPProtection] = useState({});
  const [darkWebMentions, setDarkWebMentions] = useState({});
  const [fakeapps, setFakeapps] = useState({});
  const [socialLeaks, setSocialLeaks] = useState({});

  // const getSecretsLeak = () => {
  //   baseAPI
  //     .get(`${baseUrl}ultraheals/v1/web/dataleak/secreats-leak/overview/`, {
  //       params: { date, domain }
  //     })
  //     .then((res) => {
  //       setSecretsLeak(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getDarkWeb = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/darkweb/dark-web-mention/overview/`, {
        params: { scan },
      })
      .then((res) => {
        setDarkWeb(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDomainPhishing = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/domainmonitor/phishing/graph/`, {
        params: { scan },
      })
      .then((res) => {
        setDomainPhishing(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSecureScore = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/darkweb/dashboard/securescore/`, {
        params: { scan },
      })
      .then((res) => {
        setSecureScoreData(res.data.data);
      });
  };

  const getSecureScoreGraph = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/darkweb/dashboard/securescoregraph/`, {
        params: { scan },
      })
      .then((res) => {
        setSecureScoreGraphCategory(res.data.data.categories);
        setSecureScoreGraphData(res.data.data.data);
      });
  };

  const getBrandThread = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/social/overview/`, {
        params: { scan },
      })
      .then((res) => {
        setBrandThreat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPasswordStrength = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/data-breach/password-strength/`, {
        params: { scan },
      })
      .then((res) => {
        console.log(res);
        setPasswordStrength(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getVIPProtection = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/digital-risk/impersonationleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getVIPProtection", res.data);
        setVIPProtection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDarkWebMentions = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/digital-risk/darkwebleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getDarkWebMentions", res.data);
        setDarkWebMentions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSecretsLeak = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/digital-risk/secretleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getSecretsLeak", res.data);
        setSecretsLeak(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFakeapps = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/digital-risk/fakeappsleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getFakeapps", res.data);
        setFakeapps(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSocialLeaks = () => {
    baseAPI
      .get(`${baseUrl}ultraheals/v1/web/digital-risk/socialleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getSocialLeaks", res.data);
        setSocialLeaks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (scan) {
      getSecretsLeak();
      // getDarkWeb();
      getDomainPhishing();
      getSecureScore();
      getSecureScoreGraph();
      // getBrandThread();
      getPasswordStrength();
      getVIPProtection();
      getDarkWebMentions();
      getFakeapps();
      getSocialLeaks();
    }
  }, [scan]);

  return (
    <div className="overflow-hidden">
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 pb-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl `}
        >
          {/* <SecureScore progressBarTitle={["Darkweb", "Brand", "Secrets"]} />
           */}
          <SecureScore
            secureScoreGraphCategory={secureScoreGraphCategory}
            secureScoreGraphData={secureScoreGraphData}
            avg={secureScoreData?.average_score}
            progressBar={[
              {
                name: "Darkweb",
                value: secureScoreData?.darkweb_vulnerability || 0,
              },
              {
                name: "Brand",
                value: secureScoreData?.brand_vulnerability || 0,
              },
              {
                name: "Secrets",
                value: secureScoreData?.secrets_vulnerability || 0,
              },
            ]}
            tooltip="Ultra-Heals Risk Score reflects the current state of your organization risk.
            A higher score denotes weaker security and more risks.
            A lower score denotes stronger security and less risks."
          />
        </div>
        <div
          className={`lg:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DataBreachIntelligence />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="VIP Protection"
              type="normal"
              labels={[
                // "Facebook",
                "Twitter",
                "Youtube",
                "Instagram",
              ]}
              colors={[
                // "#F14D34",
                "#FDCA3B",
                "#E8001D",
                "#148842",
              ]}
              chartData={[
                // VIPProtection?.facebook,
                VIPProtection?.twitter,
                VIPProtection?.youtube,
                VIPProtection?.instagram,
              ]}
              conditionType="Leaks"
              total={
                // (VIPProtection?.facebook || 0) +
                (VIPProtection?.twitter || 0) +
                (VIPProtection?.youtube || 0) +
                (VIPProtection?.instagram || 0)
              }
              legends={[
                // {
                //   name: `Facebook - ${
                //     VIPProtection?.facebook || 0
                //   }`,
                //   color: "#F14D34",
                // },
                {
                  name: `Twitter - ${VIPProtection?.twitter || 0}`,
                  color: "#FDCA3B",
                },
                {
                  name: `Youtube - ${VIPProtection?.youtube || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Instagram - ${VIPProtection?.instagram || 0}`,
                  color: "#148842",
                },
              ]}
              navigateLink="brand-threat-intelligence#Impersonation"
              tooltip="Gain insights into impersonation attempts targeting brands and executives on social media."
            />
          </div>
          {/* <CircularProgressBar  initialPercentage={9.0} title="Brand Score" isDarkMode={isDarkMode} /> */}
        </div>
      </div>

      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 pb-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Dark Web Mentions"
              type="normal"
              labels={["Telegram", "Hacker Forums", "Paste Sites"]}
              colors={["#FDCA3B", "#7B7DE2", "#F14D34"]}
              chartData={[
                darkWebMentions?.telegram,
                darkWebMentions?.ransomewatch + darkWebMentions?.loginrans,
                darkWebMentions?.pastecrawler,
              ]}
              conditionType="Issues"
              total={
                (darkWebMentions?.telegram | 0) +
                (darkWebMentions?.ransomewatch | 0) +
                (darkWebMentions?.loginrans | 0) +
                (darkWebMentions?.pastecrawler | 0)
              }
              legends={[
                {
                  name: `Telegram - ${darkWebMentions?.telegram | 0}`,
                  color: "#FDCA3B",
                },
                {
                  name: `Hacker Forums - ${
                    (darkWebMentions?.ransomewatch +
                      darkWebMentions?.loginrans) |
                    0
                  }`,
                  color: "#7B7DE2",
                },
                {
                  name: `Paste Sites- ${darkWebMentions?.pastecrawler | 0}`,
                  color: "#F14D34",
                },
              ]}
              navigateLink="dark-web"
              tooltip="Gain dark web insights to safeguard your organization from cybercriminal activities and exposures."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Secrets Leak"
              type="normal"
              // labels={["Github", "JavaScript"]}
              labels={["Github"]}
              // colors={["#F14D34", "#FDCA3B"]}
              colors={["#F14D34"]}
              // chartData={[secretsLeak?.github, secretsLeak?.javascript]}
              chartData={[secretsLeak?.github]}
              conditionType="Leaks"
              // total={(secretsLeak?.github | 0) + (secretsLeak?.javascript | 0)}
              total={secretsLeak?.github | 0}
              legends={[
                {
                  name: `Github - ${secretsLeak?.github | 0}`,
                  color: "#F14D34",
                },
                // {
                //   name: `JavaScript - ${secretsLeak?.javascript | 0}`,
                //   color: "#FDCA3B",
                // },
              ]}
              navigateLink="data-leak-lntelligence"
              tooltip="Gain insights by analyzing public code repositories and JavaScript for sensitive information exposure."
            />
            {/* <DonutChart
              title="Comprimised Data Threat Level"
              labels={[]}
              colors={[]}
              chartData={[]}
              conditionType="Critical"
              total="2.4"
              legends={[]}
              border="#A30123"
              background="linear-gradient(to bottom, transparent 76%, rgba(163, 1, 35, 0.4) 76%) "
            /> */}
          </div>
          {/* <CircularProgressBar initialPercentage={2.4} title="Comprimised Data Threat Level" isDarkMode={isDarkMode} /> */}
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl `}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Password Strength"
              labels={[]}
              colors={[]}
              chartData={[]}
              conditionType={passwordStrength?.category || "No Data"}
              total={passwordStrength?.score || "0.0"}
              legends={[]}
              border="#E8001D"
              background={`linear-gradient(to bottom, transparent ${
                100 - passwordStrength?.percentage
              }%, rgba(232, 0, 29, 0.25) ${
                100 - passwordStrength?.percentage
              }%)`}
              tooltip="Identify the password strength of your organization from the Data Breach."
            />
          </div>
          {/* <CircularProgressBar initialPercentage={4.3} title="Password Strength" isDarkMode={isDarkMode} /> */}
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="Fake Apps"
              type="normal"
              labels={["Fake Apps"]}
              colors={["#F14D34"]}
              chartData={[fakeapps?.fakeapps]}
              conditionType="Apps"
              total={fakeapps?.fakeapps || 0}
              legends={[
                {
                  name: `Fake Apps - ${fakeapps?.fakeapps | 0}`,
                  color: "#F14D34",
                },
              ]}
              navigateLink="brand-threat-intelligence#FakeApps"
              tooltip="Ensure your brand's safety by proactively monitoring the App Store and Play Store for counterfeit mobile applications."
            />
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 pb-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <ComprimisedEmployee isDarkMode={isDarkMode} />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <ComprimisedProduct isDarkMode={isDarkMode} />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <ComprimisedUrl isDarkMode={isDarkMode} />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <LeakedSecrets isDarkMode={isDarkMode} />
        </div>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 pb-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <VerticalBarChart
            title="Phishing Domain Detection"
            data={[
              domainPhishing?.phishing,
              domainPhishing?.suspicious,
              domainPhishing?.malware,
              domainPhishing?.spam,
              domainPhishing?.unsafe,
              domainPhishing?.proxy,
              domainPhishing?.vpn,
              domainPhishing?.tor,
            ]}
            labels={[
              "Phishing",
              "Suspicious",
              "Malware",
              "Spam",
              "Unsafe",
              "Proxy",
              "VPN",
              "Tor",
            ]}
            // labels={["Proxy", "VPN", "Tor", "Phishing", "Suspicious"]}
            lockedWidget={true}
            lockImage={complianceLockImage}
            navigateLink="phishing"
            backgroundColors={[
              "#449DD1",
              "#F86624",
              "#EA3546",
              "#662E9B",
              "#C5D86D",
              "#F4C543",
              "#FFA07A",
              "#FF7F50",
            ]}
            tooltip="Detect phishing and malicious activities by identifying look-alike domains and their hosting details targeting your organization."
          />
        </div>
        <div className={`lg:col-span-1`}>
          <BrandTreat isDarkMode={isDarkMode} data={socialLeaks} />
        </div>
      </div>
    </div>
  );
};

export default DataLeakIntelligenceComponent;
