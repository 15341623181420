import { useEffect } from "react";

export function useOutsideClickHandler(ref, setOpenMenu) {
    useEffect(() => {
      const handleDocumentClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setOpenMenu(false);
        }
      };
      
      document.addEventListener("click", handleDocumentClick);
      
      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }, [ref, setOpenMenu]);
  }