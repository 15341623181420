import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const CompanyLoginForm = () => {
  const [companyEmail, setCompanyEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Invalid Email");

  const navigate = useNavigate()

  const emailInputRef = useRef(null);

  useEffect(()=>{
  emailInputRef.current.focus();
}, [])

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleCompanyLoginSubmit = (e) => {
    e.preventDefault();
    setIsEmailError(false);

    if (companyEmail === "") {
      setIsEmailError(true);
      setEmailErrorMessage("Email Field required");
    }

    if (!validateEmail(companyEmail)) {
      setIsEmailError(true);
      setEmailErrorMessage("Invalid Email");
    }

    if (companyEmail !== "") {
    }
  };

  return (
    <div className="flex flex-col items-center w-full  py-24 lg:px-10 2xl:px-[115px] px-6 login-style h-screen">
      <div>
        <h1 className="text-2xl font-bold text-center text-color">Log In</h1>

        <p className="text-base pt-4 pb-9 text-center text-color">
          Have an account through your company, school, or organization? Enter
          your email below to sign in.
        </p>
      </div>
      <form
        onSubmit={handleCompanyLoginSubmit}
        className="flex flex-col w-full label-color"
      >
        <label className="label-color text-sm">Email</label>
        <input
          type="email"
          placeholder="john@gmail.com"
          className={` py-2 px-4 rounded-md text-lg placeholder-color text-color 
          ${
            isEmailError
              ? "border-color-error border-error-focus"
              : "border-color-disable border-focus"
          } `}
          ref={emailInputRef}
          value={companyEmail}
          onChange={(e) => setCompanyEmail(e.target.value)}
        />
        <div>
          {isEmailError && (
            <h1 className="error text-sm">{emailErrorMessage}</h1>
          )}
        </div>
        <div className="flex justify-evenly items-center gap-4 my-4">
            <button
              type="submit"
              className="w-full button-bg-color p-3 rounded-[48px] text-white"
            >
              Submit
            </button>
            <button
              type="button"
              className="w-full bg-white border-[2px] border-[#181818] p-3 rounded-[48px] text-color text-base"
              onClick={() => navigate('/')}
            >
              Cancel
            </button>
            </div> 
      </form>
    </div>
  );
};

export default CompanyLoginForm;
