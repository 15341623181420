import React from 'react'
import Checkboxes from './Checkboxes'

const CheckboxSample = () => {
  return (
    <div>
        <Checkboxes/>
    </div>
  )
}

export default CheckboxSample