import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { LineWave } from "react-loader-spinner";

import { complianceAPI } from "../../Api/CSPM/api";

export default function Report({ showReport, setShowReport }) {
  const [frameworks, setFrameworks] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getData = () => {
    complianceAPI
      .post("/framework/filter/")
      .then((response) => {
        setFrameworks(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDownload = (e) => {
    e.preventDefault();
    setLoading(true);
    complianceAPI
      .get(`/report/${data}`, {}, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res], { type: "application/pdf" });
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);
        // Create a download link and trigger a click event to initiate the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "Compliance Report.pdf"; // Set the desired file name
        // document.body.appendChild(a);
        a.click();
        // Clean up by revoking the object URL
        URL.revokeObjectURL(url);
        setLoading(false);
        setShowReport(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Sidebar
      visible={showReport}
      position="right"
      onHide={() => setShowReport(false)}
    >
      <p className="font-bold text-2xl">Report</p>
      <form onSubmit={handleDownload}>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Framework</label>
          <Dropdown
            value={data}
            onChange={(e) => setData(e.value)}
            options={frameworks}
            optionLabel="name"
            optionValue="id"
            placeholder="Select"
            required
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="flex w-full pt-3">
          <button
            disabled={loading}
            className={`p-1 border rounded-[16px] border-[#AAAAAA] ${
              loading ? "button-disabled" : "button-bg-color"
            } h-[40px] w-full bg-[#181818] text-[#ffff]`}
            type="submit"
            // onClick={handleDownload}
          >
            {/* Download */}
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="absolute bottom-1 right-[12rem]">
                  <LineWave color="#fff" height={100} width={100} />
                </div>
                <span>Downloading...</span>
              </div>
            ) : (
              <>
                <span> Download</span>
              </>
            )}
          </button>
          {!loading && (
            <button
              onClick={() => {
                setShowReport(false);
              }}
              className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2 "
              type="button"
            >
              Back
            </button>
          )}
        </div>
      </form>
    </Sidebar>
  );
}
