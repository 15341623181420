import React, { useState, useRef } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { isAdminOrSuperAdmin } from "../../../utils/accessCheck";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";

const CreateProject = (props) => {
  const {
    handleCloseSideBar,
    getProjectTableData,
    setSubDomainEnumeration,
    subDomainEnumeration,
    setVisibleRight,
    domainAdded,
    setDomainAdded,
    onFinish,
  } = props;

  const userInfo = useSelector(getUserDetails);
  const userRole = userInfo?.role;

  const [selectedOption, setSelectedOption] = useState("domain");
  const [domainName, setDomainName] = useState("");
  const [domainError, setDomainError] = useState(false);
  const [domainErrorText, setDomainErrorText] = useState("");
  const [ipAddressUrl, setIpAddressUrl] = useState("");
  const [ipAddressUrlError, setIpAddressUrlError] = useState(false);
  const [ipAddressUrlErrorText, setIpAddressUrlErrorText] = useState("");
  const [visibleDomainFinish, setVisibleDomainFinish] = useState(false);
  const [openScanningSchedule, setOpenScanningSchedule] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setDomainName("");
    setIpAddressUrl("");
  };

  const getSubdomain = async (scainId) => {
    const payload = {
      id: scainId,
    };
    const subdomain = await TableApi.createSubdomain(payload);
    if (subdomain.status === "success") {
      // toast.success(data.message)
      getProjectTableData();
      console.log("Subdomain success");
      return { status: subdomain.status };
    }
  };

  const createProjectApi = async (projectType, selectedOption) => {
    const target = await TableApi.createProject({
      domain: projectType,
      type: selectedOption === "domain" ? "web" : "network",
      client: client,
    });
    if (target.status !== "success") return target;
    return { status: target.status, targetID: target.data.id };
  };
  const handleCloseSubDomain = () => {
    setVisibleRight(false);
    setSubDomainEnumeration(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // createProjectApi(domainName, selectedOption);
    createProjectApi(domainName, selectedOption).then((targetID) => {
      if (targetID.status !== "success") {
        toast.error(targetID.message);
        return;
      }

      setSubDomainEnumeration(true);
      let progressText = "";
      let progress = "";
      let progressBarValue = "";
      setTimeout(() => {
        progressText = document.getElementById("progressText");
        progress = document.getElementById("progressBar");
        progressBarValue = progress.querySelector(".p-progressbar-value");
        progressText.textContent = "Subdomain Enumeration";
        progressBarValue.style.width = "10%";
      }, 2000);

      setTimeout(async () => {
        progressText.textContent = "Get access";
        progressBarValue.style.width = "20%";
        // const targetID = "success";
        if (targetID.status == "success") {
          console.log("TargetID:", targetID);
          // Second update after 2 seconds
          setTimeout(() => {
            progressText.textContent = "Scanning information";
            progressBarValue.style.width = "40%";
            console.log("2");
          }, 2000);

          setTimeout(async () => {
            progressText.textContent = "Files loading";
            progressBarValue.style.width = "60%";
            console.log("3", targetID);
            const subdomainID = await getSubdomain(targetID.targetID);
            // const subdomainID = "success";

            if (subdomainID.status == "success") {
              console.log("subdomainID:", subdomainID);
              setTimeout(() => {
                progressText.textContent = "Check information";
                progressBarValue.style.width = "80%";
                console.log("4");
              }, 2000);

              setTimeout(() => {
                progressText.textContent = "Finish";
                progressBarValue.style.width = "100%";
                console.log("5");
              }, 4000);

              setTimeout(() => {
                console.log("6");
                onFinish();
              }, 6000);
            }
          }, 4000);
        }
      }, 4000);
    });

    if (selectedOption === "ip") {
      if (ipAddressUrl === "") {
        setIpAddressUrlError(true);
        setIpAddressUrlErrorText("Enter the Ip Address");
      } else {
        createProjectApi(ipAddressUrl, selectedOption);
        getProjectTableData();
      }
    }
  };

  const handleFinishScan = async () => {
    console.log("CLOSE12");
    const response = await handleCloseSideBar();
    console.log("CLOSE1234res", response);
    // setOpenScanningSchedule(true);

    // setTimeout(()=>{
    //   console.log("CLOSE1234")
    //   if(response === "success"){
    //   console.log("inside")

    //     setOpenScanningSchedule(true);
    //   }

    // },2000)
  };

  const getClients = () => {
    if (isAdminOrSuperAdmin(userRole)) {
      DashboardApi.getClients().then((res) => {
        if (res.status === "success") {
          setClients(res.data);
        }
      });
    }
  };

  useEffect(() => {
    getClients();
  }, [subDomainEnumeration, openScanningSchedule]);

  return (
    <div className="pt-10 login-style">
      {subDomainEnumeration ? (
        <>
          <div className="pb-8">
            <p className="text-[16px] font-bold text-center  text-[#111]">
              {localStorage.getItem("domainName")}
            </p>
          </div>
          <div className="pb-8 progressBarSubDomain-Style">
            <span id="progressText">Subdomain Enumeration</span>
            <ProgressBar id="progressBar" value={0}></ProgressBar>
          </div>
          <div className=" flex w-full">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#707070] text-[#ffff]"
              type="button"
              // onClick={handleFinish}
              disabled
            >
              Finish
            </button>
            <button
              onClick={handleCloseSubDomain}
              // onClick={handleCloseSideBar}
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
              type="submit"
            >
              Close
            </button>
          </div>
        </>
      ) : (
        <>
          {domainAdded ? (
            <>
              <div className="pb-8">
                <p className="text-[16px] font-bold text-center  text-[#111]">
                  {localStorage.getItem("domainName")}
                </p>
              </div>
              <div className=" flex w-full">
                <button
                  className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                  type="button"
                  onClick={handleCloseSideBar}
                  // onClick={()=>{handleFinishScan(); setOpenScanningSchedule(true)}}
                >
                  Finish
                </button>
              </div>
            </>
          ) : (
            <form>
              <div className="flex items-center gap-10">
                <div className="flex items-center gap-2">
                  <input
                    id="domain"
                    type="radio"
                    value="domain"
                    checked={selectedOption === "domain"}
                    onChange={handleOptionChange}
                    className="h-5 w-5 bg-red-500"
                  />
                  <label
                    className="text-base font-normal text-color"
                    for="domain"
                  >
                    Domain name
                  </label>
                </div>
                {/* <div className="flex items-center gap-2">
            <input
              id="ipaddress"
              type="radio"
              value="ip"
              checked={selectedOption === "ip"}
              onChange={handleOptionChange}
              className="h-5 w-5 bg-red-500"
            />
            <label className="text-base font-normal text-color" for="ipaddress">
              IP address
            </label>
          </div> */}
              </div>
              {selectedOption === "domain" ? (
                <div className="w-full pt-4 pb-3">
                  <input
                    className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                    type="text"
                    placeholder="cyberheals.com"
                    value={domainName}
                    onChange={(e) => {
                      setDomainError(false);
                      setDomainName(e.target.value);
                      localStorage.setItem("domainName", e.target.value);
                    }}
                  />
                  {domainError && (
                    <p className="error text-sm">{domainErrorText}</p>
                  )}
                </div>
              ) : (
                <div className="w-full pt-4 pb-10">
                  <input
                    className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                    type="text"
                    placeholder="192.168.123.132"
                    value={ipAddressUrl}
                    onChange={(e) => {
                      setIpAddressUrlError(false);
                      setIpAddressUrl(e.target.value);
                    }}
                  />
                  {ipAddressUrlError && (
                    <p className="error text-sm">{ipAddressUrlErrorText}</p>
                  )}
                </div>
              )}
              {isAdminOrSuperAdmin(userRole) && (
                <div className="w-full pb-10">
                  <label className="block text-gray-500 text-sm ">Client</label>
                  <Dropdown
                    value={client}
                    onChange={(e) => setClient(e.value)}
                    options={clients}
                    optionValue="id"
                    optionLabel="username"
                    placeholder="Select"
                    className="w-full md:w-14rem"
                  />
                </div>
              )}

              <div className=" flex w-full">
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                  type="submit"
                >
                  Continue
                </button>
                <button
                  className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
                  type="button"
                  onClick={handleCloseSideBar}
                >
                  Back
                </button>
              </div>
            </form>
          )}
        </>
      )}
      {/* <div>
        <SidebarSlide title="Domain is added" visibleRight={visibleDomainFinish} setVisibleRight={setVisibleDomainFinish}/>
      </div> */}
    </div>
  );
};

export default CreateProject;
