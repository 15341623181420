import React, { useContext, useState } from "react";
import { CheckBoxTick, CheckboxCross, Circle } from "../../../assets/svg";
import { ThemeContext } from "../../../context/ThemeContext";
import { modules, optionsIncluded } from "../../../constants";
import SubscriptionTable from "./tables/SubscriptionTable";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";
import PlanPricing from "./PlanPricing/PlanPricing";
import PlanPricingCards from "./PlanPricing/PlanPricingCards";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setPlanAndPricingData,
  setSubscriptionData,
} from "../../../redux-toolkit/redux/TableData";
import SkeletonPlanPricingCard from "../../SkeletonAnimation/SkeletonPlanPricingCard";
import { useLocation } from "react-router-dom";

const Subscription = (props) => {
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);
  const [planning, setPlanning] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [planPricingData, setPlanPricingData] = useState([]);
  const [loadingPlanCardData, setloadingPlanCardData] = useState(false);
  const [loadingPlanPrincingCardData, setloadingPlanPrincingCardData] =
    useState(false);

  const { state } = useLocation();
  const user = state?.user?.id;

  const customPlanData = [
    {
      id: 4,
      plan: "Custom",
      keywords: "5",
      status: false,
    },
  ];

  const subscriptionData = () => {
    setloadingPlanCardData(true);
    TableApi.planSubscription({ user }).then((data) => {
      if (data.status === "success") {
        setSubscription(data.data);
        dispatch(setSubscriptionData(data.data));
        setloadingPlanCardData(false);
      }
    });
  };
  const planPricingApi = () => {
    setPlanning(true);
    setloadingPlanPrincingCardData(true);
    TableApi.planPricing().then((data) => {
      if (data.status === "success") {
        setPlanPricingData([...data.data]);
        dispatch(setPlanAndPricingData([...data.data]));
        setloadingPlanPrincingCardData(false);
      }
    });
  };

  useEffect(() => {
    subscriptionData();
  }, []);

  return (
    <div>
      {planning ? (
        <>
          {/* <PlanPricing
            title="Plans & Pricing"
            description="Lorem ipsum dolor per amos conta regardos"
            isDarkMode={isDarkMode}
          /> */}
          {loadingPlanPrincingCardData ? (
            <SkeletonPlanPricingCard />
          ) : (
            <PlanPricingCards
              isDarkMode={isDarkMode}
              setPlanning={setPlanning}
            />
          )}
        </>
      ) : (
        <SubscriptionPlan
          title="Subscription"
          description="Manage your subscription plan"
          isDarkMode={isDarkMode}
          btnText={props?.data?.btnText}
          action={props?.data?.action}
          setShowEdit={props?.data?.setShowEdit}
          setPlanning={setPlanning}
          planPricing={planPricingApi}
          loadingPlanCardData={loadingPlanCardData}
          email={props?.data?.email}
          setSubsData={props?.data?.setSubsData}
        />
      )}
    </div>
  );
};

export default Subscription;
