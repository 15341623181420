import React, { useRef, useState } from "react";
import DarkWebDataTables from "../../../../../common/Tables/DarkWebTable";
import TableFilter from "../../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";
import { SeverityLevelLabel } from "../../../../../helpers";

const DarkWebFirstTable = (props) => {
  const { data, filterData, setFilterParams, filterParams, loadingTable } =
    props;
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef(null);
  const columns = [
    { field: "keyword", header: "Keyword", value: filterData?.keyword },
    {
      field: "threat",
      header: "Threat Classification",
      // value: filterData?.threat_classify,
    },
    {
      field: "severity",
      header: "Severity",
      value: filterData?.severity,
      type: "severity",
    },
    {
      field: "date_added",
      header: "Date",
      value: filterData?.date_added,
      type: "date",
    },
    { field: "url", header: "Source", value: filterData?.url },
    { field: "find", header: "Tags" },
    { field: "find", header: "Status", value: filterData?.find },
    { field: "find", header: "Full info" },
  ];

  return (
    <>
      <div>
        <TableFilter
          dt={dt}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableData={data.map((item) => {
            return {
              ...item,
              severity: SeverityLevelLabel(item.severity),
            };
          })}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DarkWebDataTables
            columns={columns}
            dt={dt}
            data={data}
            globalFilter={globalFilter}
            type="Darkweb"
          />
        )}
      </div>
    </>
  );
};

export default DarkWebFirstTable;
