import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import IncludedOptions from "./pricingCalculator/IncludedOptions";
import IncludedModules from "./pricingCalculator/IncludedModules";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
// import { useSelector } from "react-redux";
// import { getPlanCardData } from '../../../redux-toolkit/redux/Sidebar';
// import { Navigate, useNavigate } from 'react-router-dom';

const PricingCalculator = (props) => {
  const { handleCloseSideBar, planTypeData, plansData } = props;

  // const planData = useSelector(getPlanCardData)
  // const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false);
  // const [selectedPricingOption, setSelectedPricingOption] = useState(selectedSubscriptionPlan);
  const [planPriceData, setPlanPriceData] = useState({
    // id: 3,
    plan: "Custom",
    domain: 1,
    keywords: 5,
    subdomains: 10,
    scanning: "bi-weekly",
    users: 5,
    scan: 1,
    // price: 100,
    asm: true,
    voc: true,
    complaince: true,
    // ddm: false,
    // dlm: false,
    // btm: false,
  });

  const dropdownRef = useRef(null);

  const handleItemClick = (item, id) => {
    // console.log("DROPCLICE", item, id)
    // setSelectedPricingOption(item);
    setIsOpen(false);
    updatePlanData(plansData.find((item) => item.id === id));
    // setPlanPriceData({ ...planPriceData, ... })
    // const payload = {
    //   plan_id: id
    // }
    // DashboardApi.getPlanTypeApi(payload).then((data) => {
    //   console.log("RESPONSE", data)
    //   if (data.status === "success") {
    //     setPlanPriceData(data.data)
    //   }
    // })
  };

  const updatePlanData = (data) => {
    console.log(data);
    if (data.plan === "Basic") {
      setPlanPriceData({
        ...planPriceData,
        ...data,
        asm: true,
        voc: true,
        complaince: false,
        ddm: false,
        dld: false,
        btm: false,
      });
    } else if (data.plan === "Standard") {
      setPlanPriceData({
        ...planPriceData,
        ...data,
        asm: true,
        voc: true,
        complaince: true,
        // ddm: true,
        // dld: true,
        // btm: false,
      });
    } else {
      setPlanPriceData((prev) => {
        console.log(prev);
        if (prev.plan === "Custom") {
          return {
            ...prev,
            ...data,
            // id: 3,
            plan: "Custom",
            asm: true,
            voc: true,
            // complaince: true,
            // ddm: true,
            // dld: true,
            // btm: false,
          };
        } else {
          return {
            ...data,
            ...prev,
            // id: 3,
            plan: "Custom",
            asm: true,
            voc: true,
            // complaince: true,
            // ddm: true,
            // dld: true,
            // btm: false,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (planTypeData.price) {
      updatePlanData(planTypeData);
    } else {
      updatePlanData({ interval: planTypeData.interval, id: planTypeData.id });
    }
  }, [planTypeData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = () => {
    console.log(planPriceData, "payload");
    // const payload = {
    //   plan: planData.id,
    //   interval: "month"
    // }
    DashboardApi.getSubscriptionPlanApi({
      ...planPriceData,
      plan: planPriceData.id,
    }).then((data) => {
      if (data.status === "success") {
        // navigate(`/${data.payment_url}`)
        window.location.href = data.payment_url;
      }
    });
  };

  const calculatePrice = () => {
    const domainPrice = 100 * planPriceData.domain;
    const subdomainsPrice = 75 * planPriceData.subdomains;
    const keywordsPrice = 50 * planPriceData.keywords;
    const userPrice = 100 * planPriceData.users;
    const scanPrice = 50 * planPriceData.scan;
    const complaincePrice = planPriceData.complaince ? 100 : 0;
    const months = planPriceData.interval === "year" ? 12 : 1;
    console.log(
      "CALCULATE",
      planPriceData.keywords,

      userPrice,
      domainPrice,
      keywordsPrice,
      subdomainsPrice,
      scanPrice,
      complaincePrice,
      months
    );
    return (
      (userPrice +
        domainPrice +
        keywordsPrice +
        subdomainsPrice +
        scanPrice +
        complaincePrice) *
      months
    );
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <p className="text-base font-semibold text-color">Subscription plan</p>

        <div className="relative inline-block" ref={dropdownRef}>
          <button
            className="bg-[#F14D34] text-white  rounded-[16px] text-[16px] font-bold px-4 
            border-none py-2 cursor-pointer flex justify-between items-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            {/* {selectedPricingOption && selectedPricingOption} */}
            {planPriceData.plan}
            <div className="ml-4">
              {isOpen ? <FiChevronUp size={18} /> : <FiChevronDown size={18} />}
            </div>
          </button>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } absolute bg-[#F14D34] w-full rounded text-white shadow-md z-10 mt-1`}
          >
            {plansData.map((item) => {
              return (
                <div
                  className={`${
                    planPriceData.plan === item.plan ? "bg-[#d18074d4]" : ""
                  } py-2 px-4 cursor-pointer rounded-t hover:bg-[#d18074d4] font-semibold text-[16px]`}
                  onClick={() => handleItemClick(item.plan, item.id)}
                >
                  {item.plan}
                </div>
              );
            })}
            {/*
            <div
              className={`${selectedPricingOption === 'Standard' ? 'bg-[#d18074d4]' : ''} py-2 px-4 cursor-pointer rounded-t hover:bg-[#d18074d4] font-semibold text-[16px]`}
              onClick={() => handleItemClick(, "1")}
            >
              Basic
            </div>
             <div
              className={`${selectedPricingOption === 'Standard' ? 'bg-[#d18074d4]' : ''} py-2 px-4 cursor-pointer hover:bg-[#d18074d4] font-semibold text-[16px]`}
              onClick={() => handleItemClick('Standard', "2")}
            >
              Standard
            </div>
            <div
              className={`${selectedPricingOption === 'Enterprise' ? 'bg-[#d18074d4]' : ''} py-2 px-4 cursor-pointer hover:bg-[#d18074d4] font-semibold text-[16px]`}
              onClick={() => handleItemClick('Enterprise')}
            >
              Enterprise
            </div>
            <div
              className={`${selectedPricingOption === 'Custom' ? 'bg-[#d18074d4]' : ''} py-2 px-4 cursor-pointer rounded-b hover:bg-[#d18074d4] font-semibold text-[16px]`}
              onClick={() => handleItemClick('Custom', "4")}
            >
              Custom
            </div> */}
          </div>
        </div>
      </div>

      {planPriceData.plan !== "Custom" && (
        <div className="pt-8 flex justify-between items-center">
          <div>
            <h1 className="text-base font-semibold text-color">Users</h1>
          </div>
          <div>
            <p className="text-base font-semibold text-color">
              {planPriceData.users}
            </p>
          </div>
        </div>
      )}

      <div className="pt-8">
        <IncludedOptions
          // selectedPricingOption={selectedPricingOption}
          updatePlanData={updatePlanData}
          planPriceData={planPriceData}
          // setToCustomPlan={setToCustomPlan}
        />
      </div>

      <div className="pt-8">
        <IncludedModules
          updatePlanData={updatePlanData}
          planPriceData={planPriceData}
        />
      </div>

      <div className="pt-8 flex justify-between items-center">
        <div>
          <h1 className="text-sm font-semibold text-color">
            Price{planTypeData.interval !== "year" && " per month"}
          </h1>
        </div>
        <div>
          <h1 className="text-xl font-bold text-color">
            $
            {planPriceData.plan !== "Custom"
              ? planTypeData.interval === "year"
                ? planPriceData.annual_price * 12
                : planPriceData.price
              : calculatePrice()}
          </h1>
        </div>
      </div>

      <div className="flex w-full my-8">
        <button
          onClick={handleSubmit}
          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
        >
          Buy
        </button>
        <button
          className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
          onClick={handleCloseSideBar}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default PricingCalculator;
