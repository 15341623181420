import { useContext, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

import { ThemeContext } from "../../context/ThemeContext";

export default function JustDonut({
  title,
  labels,
  colors,
  data = [],
  centerText = null,
  onClick = null,
  direction = "horizontal",
}) {
  const { isDarkMode } = useContext(ThemeContext);

  const isEmpty = data.length === 0 || data?.every((d) => d === 0);

  return (
    <div
      className={`grid ${
        direction === "vertical"
          ? "grid-cols-3 w-full"
          : "grid-cols-1 h-full my-2 "
      } ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
    >
      <div
        className={`flex items-center justify-center m-1 ${
          direction === "vertical" ? "h-20" : ""
        } `}
      >
        <Doughnut
          data={{
            labels: labels,
            datasets: [
              {
                data: isEmpty ? [1] : data,
                backgroundColor: isEmpty ? ["#E5E5E5"] : colors,
                cutout: "90%",
                borderRadius: 50,
                // offset: 10,
                spacing: 1,
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            _elements: {
              arc: {
                spacing: 1,
                borderWidth: 0,
              },
            },
            get elements() {
              return this._elements;
            },
            set elements(value) {
              this._elements = value;
            },
          }}
        />
        <p className="self-center absolute text-base font-medium">
          {centerText}
        </p>
      </div>
      {/* <div className="flex flex-col items-center justify-center w-full "> */}
      {title && (
        <h1
          className={`${
            isDarkMode ? "dark-mode text-white" : " text-[#111]"
          } font-medium self-center  ${
            direction === "vertical" ? "col-span-2 p-3" : "text-center"
          }`}
        >
          {title}
        </h1>
      )}
      {/* </div> */}
    </div>
  );
}
