import FakeApps from "./FakeApps";
import ModApps from "./ModApps";
import Impersonation from "./Impersonation";
import SocialMedia from "./SocialMedia";

export default function BrandThreatIntelligence() {
  const hash = window.location.hash;
  const TABLES = {
    Impersonation: <Impersonation />,
    SocialMedia: <SocialMedia />,
    Instagram: <SocialMedia platform="instagram" />,
    Twitter: <SocialMedia platform="twitter" />,
    Youtube: <SocialMedia platform="youtube" />,
    Facebook: <SocialMedia platform="facebook" />,
    FakeApps: <FakeApps />,
    ModApps: <ModApps />,
    Apps: (
      <>
        <FakeApps />
        <ModApps />
      </>
    ),
  };

  return (
    <div className="ssl-status ">
      <h1 className="font-bold">Brand Threat Intelligence</h1>
      {!hash
        ? Object.keys(TABLES).map((key) => TABLES[key])
        : TABLES[hash.substring(1)]}
    </div>
  );
}
