import React from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import AuthenticateLoginForm from "../../modules/login/components/AuthenticateLoginForm";
import AuthSideBar from "../../Component/Header/AuthSideBar";

const AuthenticateLogin = () => {
  return (
    <div className="lg:h-screen overflow-x-hidden">
      <LoginHeader title="" subTitle="" navigate="" />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
        <AuthenticateLoginForm />
        </div>
      </div>
      <hr />
      <div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default AuthenticateLogin;
