import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { ThemeContext } from "../../context/ThemeContext";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SparkLineChart = (props) => {
  const { data, title, growth, growthType, startDate, currentDate, totalDays } =
    props;

    const { isDarkMode } = useContext(ThemeContext);

  const chartData = {
    seriesSpark3: [
      {
        data: data,
        markers: {
          size: 0,
        },
      },
    ],
    optionsSpark3: {
      chart: {
        type: "area",
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#F14D34", "#FEEDEB"],
      stroke: {
        curve: "smooth",
      },
      fill: {
        opacity: 0.3,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: "",
        offsetX: 0,
        style: {
          fontSize: "16px",
          fontWeight: 600,
          display: "hidden",
        },
      },
      legend: {
        position: "top",
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <>
     <div className="px-3 flex flex-col h-full">
      <div className="px-3 flex items-center justify-evenly my-5">
        <div className="w-[90%] flex justify-center">
          <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
            <span className="truncate" title={title}>{title}</span>
          </h1>
        </div>
        <div className="flex justify-end items-end w-[10%] cursor-pointer z-10">
          <img  data-tooltip-id="sparkchart-tooltip" className="w-[18px]" src={isDarkMode ? InformationIconDark : InformationIconLight } alt="info" />
        </div>
      </div>
     
      <div className="absolute bottom-0 w-full mt-6">
        <ReactApexChart
          options={chartData.optionsSpark3}
          series={chartData.seriesSpark3}
          type="area"
          height={160}
        />
      </div>
      <div className="absolute z-auto w-full bottom-5">
        <div className="flex flex-col items-center">
          <h1 className="text-xs font-medium">{currentDate}</h1>
          <h1 className="text-4xl font-bold">
            {totalDays} <span className="text-2xl font-normal">days</span>
          </h1>
        </div>
        <div className="flex items-center justify-center gap-3">
          <div
            className={`${
              growthType === "negative" ? "text-[#148842]" : "text-[#CD1F43]"
            } flex gap-1 items-center`}
          >
            <div>
              {growthType === "negative" ? (
                <BiTrendingUp />
              ) : (
                <BiTrendingDown />
              )}
            </div>
            <p className="text-xs font-bold">{growth}</p>
          </div>
          <div>
            <p className="text-xs font-medium">{startDate}</p>
          </div>
        </div>
      </div>
      </div>
      <ReactTooltip
        id="sparkchart-tooltip"
        place="top"
        content="Hello world! I'm a Tooltip"
      />
    </>
  );
};

export default SparkLineChart;
