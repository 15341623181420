import React, { useState } from "react";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
// import { planData } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { getPlanAndPricingData } from "../../../../redux-toolkit/redux/TableData";
import { useEffect } from "react";
import Toggle from "react-toggle";
import { BsCheckLg } from "react-icons/bs";
import { TbArrowUpRight } from "react-icons/tb";

import { setPlanCardData } from "../../../../redux-toolkit/redux/Sidebar";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";

const PlanPricingCards = (props) => {
  const { isDarkMode, setPlanning } = props;
  const dispatch = useDispatch();
  const planPricingData = useSelector(getPlanAndPricingData);
  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");
  const [planData, setPlanData] = useState([]);
  const [planTypeData, setPlanTypeData] = useState({ interval: "year" });

  const handleOpenSideBar = (data) => {
    console.log("DATARSPS", data);
    dispatch(setPlanCardData(data));
    setSelectedSubscriptionPlan(data.plan);
    const payload = {
      plan_id: data.id,
    };
    DashboardApi.getPlanTypeApi(payload).then((data) => {
      console.log("RESPONSE", data);
      if (data.status === "success") {
        setPlanTypeData({ ...planTypeData, ...data.data });
        if (data.data.plan === "Enterprise") {
          setVisibleRight(false);
        } else {
          setVisibleRight(true);
        }
      }
    });
  };
  useEffect(() => {
    console.log("RESPONSE", planData);
    setPlanData(planPricingData);
  }, [planPricingData]);

  const toggleIntervel = () => {
    if (planTypeData.interval === "year") {
      setPlanTypeData({ ...planTypeData, interval: "month" });
    } else {
      setPlanTypeData({ ...planTypeData, interval: "year" });
    }
  };

  return (
    <>
      <div className="flex" style={{ justifyContent: "end" }}>
        <button
          className="flex items-center gap-[6px] text-[#074EE8] text-base"
          onClick={() => setPlanning(false)}
        >
          Back
          <TbArrowUpRight />
        </button>
      </div>
      <div className="flex justify-center gap-3 mb-3">
        <p className="font-bold">Monthly</p>
        <Toggle
          checked={planTypeData.interval === "year"}
          icons={{
            checked: (
              <BsCheckLg
                size={20}
                style={{
                  color: "white",
                  position: "absolute",
                  left: "-6px",
                  top: "-5px",
                }}
              />
            ),
            unchecked: null,
          }}
          onChange={toggleIntervel}
        />
        <p className="font-bold">Yearly</p>
      </div>
      <div className="flex flex-wrap items-end gap-6 mb-10 ">
        {planData.map((data) => (
          <div
            key={data.id}
            className={`${
              data.status
                ? "bg-[#181818] p-2 rounded-2xl"
                : "rounded-[16px] border-[4px] border-[#000]"
            } ${
              isDarkMode && data.status && "bg-[#F14D34]"
            } lg:w-[23%] sm:w-full md:w-[48%] `}
          >
            {data.status && (
              <div className="flex justify-center items-center py-2 pb-3">
                <h1 className="text-white text-base font-medium">
                  Current Plan
                </h1>
              </div>
            )}
            <div
              className={`h-[484px] p-8 rounded-2xl text-center relative flex flex-col justify-between ${
                isDarkMode
                  ? "header-dark text-white"
                  : "white-background text-color"
              }`}
            >
              <div>
                <p className="text-xl font-bold">{data.plan}</p>
                <p className="text-xs text-[#686868]">
                  {data.plan === "Custom"
                    ? "Create your custom plan"
                    : data.description}
                </p>
              </div>

              {data.plan !== "Custom" && (
                <p className="pt-1 text-xl font-bold">
                  $
                  {planTypeData.interval === "month"
                    ? data.price
                    : data.annual_price}
                  /m
                </p>
              )}
              {data.plan === "Custom" ? (
                <div className="py-8 ">
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">custom</p>
                    <p className="text-xs">options</p>
                  </div>
                </div>
              ) : (
                <div className="py-2 ">
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.domain}
                    </p>
                    <p className="text-xs">Domain</p>
                  </div>
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.subdomains}
                    </p>
                    <p className="text-xs">Applications</p>
                  </div>
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.infrastructure}
                    </p>
                    <p className="text-xs">Infrastructure</p>
                  </div>
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.keywords}
                    </p>
                    <p className="text-xs">Cloud</p>
                  </div>
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.scanning}
                    </p>
                    <p className="text-xs">Scan</p>
                  </div>
                  <div className="p-2">
                    <p className="text-[#F14D34] text-sm font-bold">
                      {data.users}
                    </p>
                    <p className="text-xs">Users</p>
                  </div>
                </div>
              )}

              <div className=" w-full  ">
                <button
                  className={`p-1 font-bold text-base border rounded-[16px] border-[#AAAAAA] 
                h-[40px] w-full bg-[#181818] text-[#ffff] ${
                  data.status ? "hidden" : ""
                }`}
                  onClick={() => handleOpenSideBar(data)}
                >
                  {data.plan === "Enterprise" ? "Contact Us" : "Calculate"}
                </button>
              </div>
            </div>
          </div>
        ))}

        <div>
          <SidebarSlide
            title="Pricing Calculator"
            visibleRight={visibleRight}
            setVisibleRight={setVisibleRight}
            selectedSubscriptionPlan={selectedSubscriptionPlan}
            planTypeData={planTypeData}
            plansData={planData}
          />
        </div>
      </div>
    </>
  );
};

export default PlanPricingCards;
