import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaExclamationTriangle } from "react-icons/fa";
import ThirdPartyURLSidebar from "../../sidebar/components/drp/ThirdPartyURLSidebar";
import { dataBreachAPI } from "../../../Api/DRP/base";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { Skeleton } from "antd";

const LeakedSecrets = (props) => {
  const { isDarkMode } = props;

  const title = "Top 10 Third Party URL";
  const [currentDetails, setCurrentDetails] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const getData = () => {
    dataBreachAPI
      .post("top-10-compromised-third-party/", { scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickHandler = (data) => {
    console.log(data);
    setCurrentDetails(data);
  };

  useEffect(() => {
    getData();
  }, [scan]);

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1
              className="flex card-title items-center justify-center text-base font-bold leading-normal"
              title={title}
            >
              {title}
            </h1>
          </div>
          <button className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id="leaked-tooltip"
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="h-full w-full flex flex-col gap-1 pb-4 px-1">
            {loading
              ? (() => {
                  const elements = [];
                  for (let index = 0; index < 10; index++) {
                    elements.push(
                      <Skeleton.Input key={index} active size="small" block />
                    );
                  }
                  return elements;
                })()
              : data?.map((item, index) => (
                  <div
                    className="grid grid-cols-6 gap-2 items-center cursor-pointer"
                    key={index}
                    onClick={() => onClickHandler(item)}
                  >
                    <div className=" items-center">
                      <FaExclamationTriangle size={24} color={"#A30123"} />
                    </div>
                    <h1
                      title={
                        item?.url_pass_strength?.length
                          ? item?.url_pass_strength[0]?.url
                          : ""
                      }
                      className="text-sm col-span-5 truncate"
                    >
                      {item?.url_pass_strength?.length
                        ? item?.url_pass_strength[0]?.url
                        : ""}
                    </h1>
                  </div>
                ))}
          </div>

          <div className="flex flex-wrap items-center justify-center pb-4">
            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#A30123] rounded-full" />
              <h1 className="text-sm">Critical</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#E8001D] rounded-full" />
              <h1 className="text-sm">High</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#F5B207] rounded-full" />
              <h1 className="text-sm">Medium</h1>
            </div>

            <div className="flex gap-2 items-center mx-1 pb-2">
              <div className="h-[8px] w-[8px] bg-[#148842] rounded-full" />
              <h1 className="text-sm">Low</h1>
            </div>
          </div>
        </div>
      </div>
      <ThirdPartyURLSidebar
        currentDetails={currentDetails}
        setCurrentDetails={setCurrentDetails}
      />
      <ReactTooltip
        id="leaked-tooltip"
        place="top"
        content="Insights about Top 10 Compromised Third Party URL used by your organization infected by infostealer malware."
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default LeakedSecrets;
