
import React from 'react';
import { Skeleton } from 'primereact/skeleton';


function SkeletonPlanPricingCard() {
    return (
        <div className="card">
            <div className="flex flex-wrap items-end gap-6 p-3 justify-between mx-10">
            <Skeleton width="17rem" height="25rem" ></Skeleton>
            <Skeleton width="17rem" height="25rem" ></Skeleton>
            <Skeleton width="17rem" height="25rem" ></Skeleton>
            <Skeleton width="17rem" height="25rem"></Skeleton>
            </div>
        </div>
    );
}
         
export default SkeletonPlanPricingCard