import { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../../context/ThemeContext";

export default function AngleBar({ type, value = 0 }) {
  const color =
    type === "pass" ? "#20E647" : type === "fail" ? "#FF0000" : "#FFA500";
  const text = type === "pass" ? "Pass" : type === "fail" ? "Fail" : "Warning";

  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    series: [value],
    colors: [color],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#E4E4E4",
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  return (
    <div className="flex flex-col items-center">
      <ReactApexChart
        options={options}
        series={[value]}
        type="radialBar"
        height={350}
      />
      <div className="flex items-center gap-2">
        <div
          className="w-4 h-2 rounded-full"
          style={{ backgroundColor: color }}
        ></div>
        {text} ({value | 0})
      </div>
    </div>
  );
}
