import React, { useEffect, useRef, useState } from "react";
import { DownloadIcon, SearchIcon, filter, share } from "../../assets/svg";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import { BsFiletypeCsv, BsFiletypePdf } from "react-icons/bs";
import { toast } from "react-toastify";

const TableFilter = (props) => {
  const {
    dt,
    globalFilter,
    setGlobalFilter,
    isDarkMode,
    setVisibleRight,
    btnName,
    btnText,
    columns,
    importButton,
    tableData,
    tableHeader,
    setFilterParams,
    filterParams,
    IntegrationComponent = null,
  } = props;

  const [visibleFilterRight, setVisibleFilterRight] = useState(false);
  const [showExports, setShowExports] = useState(false);

  const dropdownRef = useRef();

  const handleShowExports = () => {
    setShowExports(!showExports);
  };

  const exportCSV = () => {
    setShowExports(false);
    if (dt.current) dt.current.exportCSV();
    else console.log("Table render not completed");
  };

  const filterActionColumn = (tableHeader) => {
    const hasActionField = tableHeader?.some(
      (col) => col?.field?.toLowerCase() === "action"
    );
    return hasActionField
      ? tableHeader.filter((col) => col?.field?.toLowerCase() !== "action")
      : tableHeader;
  };

  const exportPDF = () => {
    setShowExports(false);
    const filteredHeaders = filterActionColumn(tableHeader || columns);

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const selectedColumnsData = tableData.map((product) => {
          const selectedData = {};
          filteredHeaders.forEach((col) => {
            selectedData[col.field] = product[col.field];
          });
          return selectedData;
        });

        const exportColumns = filteredHeaders.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, selectedColumnsData);
        doc.save("downloads.pdf");
      });
    });
  };

  const handleShare = async () => {
    const filteredHeaders = filterActionColumn(tableHeader);

    try {
      const pdf = await generatePDF(filteredHeaders, tableData);

      const pdfDataUrl = `data:application/pdf;base64,${btoa(pdf)}`;

      const emailSubject = "Table Data PDF";
      const emailBody =
        "Please find the table data in the attached PDF file:\n\n" + pdfDataUrl;
      const emailRecipient = "recipient@example.com";

      const mailtoLink = `mailto:${emailRecipient}?subject=${emailSubject}&body=${emailBody}`;

      window.location.href = mailtoLink;
    } catch (error) {
      console.error("Error creating data URL for PDF:", error);
    }
  };

  const generatePDF = async (headers, data) => {
    return new Promise((resolve, reject) => {
      import("jspdf")
        .then((jsPDF) => {
          import("jspdf-autotable")
            .then(() => {
              const selectedColumnsData = data.map((product) => {
                const selectedData = {};
                headers.forEach((col) => {
                  selectedData[col.field] = product[col.field];
                });
                return selectedData;
              });

              const exportColumns = headers.map((col) => ({
                title: col.header,
                dataKey: col.field,
              }));
              const doc = new jsPDF.default(0, 0);
              doc.autoTable(exportColumns, selectedColumnsData);
              const pdfOutput = doc.output("datauristring");
              resolve(pdfOutput);
            })
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowExports(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  
  return (
    <>
      <div className="flex lg:flex-nowrap flex-wrap lg:gap-0 gap-6 justify-between">
        <div className="flex flex-wrap">
          <div className="card flex gap-4 justify-content-end usertable-button">
          {btnText && (
             <>
              <button
                className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
                  isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
                }`}
                onClick={() => setVisibleRight(true)}
              >
                {btnName}
              </button>
              {importButton && (
                <button
                  className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
                    isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
                  }`}
                  onClick={importButton}
                >
                  Import
                </button>
              )}</>
            )}
            {IntegrationComponent && <IntegrationComponent />}
            </div>
        </div>
        <div className="flex flex-wrap items-center gap-6">
          <div className="flex gap-1 rounded-2xl items-center px-3 py-[11px] border-[1px] lg:w-80 w-fit h-10 overflow-hidden">
            <div>
              <img src={SearchIcon} alt="search" />
            </div>
            <input
              placeholder="Search"
              className="bg-transparent border-none w-full px-2 py-1 outline-none text-sm"
              value={globalFilter}
              onChange={(e) =>
                setGlobalFilter && setGlobalFilter(e.target.value)
              }
            />
          </div>
          <div>
            <button className="flex gap-2 items-center">
              <img src={filter} alt="download" />
              <span
                className="table-filter-font text-[#074EE8]"
                onClick={() => setVisibleFilterRight(true)}
              >
                Filter
              </span>
            </button>
          </div>
          <div className="relative">
            <button
              className="flex gap-2 items-center"
              onClick={handleShowExports}
            >
              <img src={DownloadIcon} alt="download" />
              <span className="table-filter-font text-[#074EE8]">Export</span>
            </button>
            <div ref={dropdownRef}>
              {showExports && (
                <div className="absolute z-[999px] bottom-7 flex flex-col w-36 bg-white text-black rounded-lg border-[1px] border-[#F2F4F7] shadow-xl">
                  <button
                    onClick={exportCSV}
                    className="flex gap-2 items-center px-4 py-2 hover:bg-gray-100"
                  >
                    <BsFiletypeCsv color="#074EE8" />
                    <span className="table-filter-font text-[#074EE8]">
                      Export CSV
                    </span>
                  </button>
                  <div className="h-[1px] w-full bg-[#F2F4F7]" />
                  <button
                    onClick={exportPDF}
                    className="flex gap-2 items-center px-4 py-2 hover:bg-gray-100"
                  >
                    <BsFiletypePdf color="#074EE8" />
                    <span className="table-filter-font text-[#074EE8]">
                      Export PDF
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* <div>
            <button className="flex gap-2 items-center" onClick={handleShare}>
              <img src={share} alt="download" />
              <span className="table-filter-font text-[#074EE8]">Share</span>
            </button>
          </div> */}
        </div>
      </div>
      <div>
        <SidebarSlide
          title="Filter"
          visibleRight={visibleFilterRight}
          setVisibleRight={setVisibleFilterRight}
          columns={columns}
          filter={true}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default TableFilter;
