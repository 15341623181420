import { useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ThemeContext } from "../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../assets/svg";
import Header from "./Charts/Header";

export default function Listing(props) {
  const { title, data, tooltip } = props;
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <Header title={title} tooltip={tooltip} />
        <div className="flex flex-col gap-2 mb-2">
          {data.length ? (
            data.map((item) => {
              return (
                <div
                  key={item.key}
                  className={`flex justify-between items-center w-full px-3 py-1 border-[1px] rounded-lg ${
                    isDarkMode ? "tab-dark-border" : "border-color"
                  } ${item.onClick && "cursor-pointer"}`}
                  onClick={() => item.onClick && item.onClick()}
                >
                  <p className="text-sm font-bold truncate" title={item.key}>
                    {item.key}
                  </p>
                  <h1 className="text-xl font-bold">{item.value}</h1>
                </div>
              );
            })
          ) : (
            <div className="text-center">No data to show</div>
          )}
        </div>
      </div>
    </>
  );
}
