import React from "react";
import { Skeleton } from "antd";
function SkeletonAnimation() {
  return (
    <div className="p-5 w-full item-center mx-10 skeleton-response">
      <p className="w-full flex mb-10 skeltonfirst">
        <Skeleton.Input active={true} height={160} style={{ width: "15rem" }} />{" "}
        <Skeleton.Input
          active={true}
          height={160}
          style={{ width: "15rem", marginLeft: "2rem" }}
        />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>

      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>

      <p className="w-full mb-10">
        <Skeleton.Input active={true} height={160} style={{ width: "32rem" }} />{" "}
      </p>
    </div>
  );
}

export default SkeletonAnimation;
