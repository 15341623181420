import DarkWebFirst from "./DarkWebFirst";
import DarkWebSecond from "./DarkWebSecond";
import DarkWebThird from "./DarkWebThird";

const DarkWeb = (props) => {
  const { title } = props;
  const hash = window.location.hash;

  const TABLES = {
    DarkWeb: <DarkWebFirst title="Hacker Forums" />,
    Telegram: <DarkWebSecond title="Telegram" />,
    PasteSites: <DarkWebThird title="Paste Sites" />,
  };

  return (
    <div className="ssl-status ">
      <h1 className="font-bold">{title}</h1>
      {!hash ? (
        Object.keys(TABLES).map((key) => (
          <div className="ssl-active">{TABLES[key]}</div>
        ))
      ) : (
        <div className="ssl-active">{TABLES[hash.substring(1)]}</div>
      )}
    </div>
  );
};

export default DarkWeb;
