import React,{useState, useRef, useEffect} from 'react'
import OtpInput from 'react-otp-input';

const OtpInputComponent = (props) => {
  const{inputStyle,otp,setOtp, }= props

  return (
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      inputStyle={inputStyle}
      shouldAutoFocus
      
    //   renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} placeholder="_" className='input-style'/>}
    />
  )
}

export default OtpInputComponent