import { Sidebar } from "primereact/sidebar";
import ListWithReadMore from "./ListWithReadMore";

export default function EmployeeCompromisedSidebar({
  currentDetails,
  setCurrentDetails,
}) {
  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={() => setCurrentDetails(null)}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
      closeOnEscape={false}
      dismissable={false}
    >
      <p className="font-bold text-2xl text-center">Compromised Employee</p>
      <div className="px-6">
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">System ID</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.hwid}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">IP Address</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.ip}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Username</h1>
            <p className="text-xs text-color font-normal leading-5 break-all">
              {currentDetails?.username}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Password</h1>
            <p className="text-xs text-color font-normal leading-5 break-all">
              {currentDetails?.url_pass_strength?.length
                ? currentDetails?.url_pass_strength[0].password
                : ""}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">URL</h1>
            <a
              href={
                currentDetails?.url_pass_strength?.length
                  ? currentDetails?.url_pass_strength[0].url
                  : ""
              }
              target="_blank"
            >
              <p className="text-xs text-color font-normal leading-5 break-all">
                {currentDetails?.url_pass_strength?.length
                  ? currentDetails?.url_pass_strength[0].url
                  : ""}
              </p>
            </a>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Date Compromised</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.date_compromised}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Computer Name</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.computer_name}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Date Added</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.network}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Operating System</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.operating_system}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Password Strength</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.network}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Malware Path</h1>
            <p className="text-xs text-wrap text-color font-normal leading-5">
              {currentDetails?.malware_path}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Malware Family</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.malware_family}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Compromised Method</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.compromised_method}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Antivirus</h1>
            <p className="text-xs text-color font-normal leading-5">
              <ListWithReadMore data={currentDetails?.antiviruses} />
              {/* {currentDetails?.antiviruses.map((data) => (
                <li className="text-xs text-color font-normal leading-5">
                  {data}
                </li>
              ))} */}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Installed Software</h1>
            <p className="text-xs text-color font-normal leading-5">
              <ListWithReadMore data={currentDetails?.installed_softwares} />
              {/* {currentDetails?.installed_softwares.map((data) => (
                <li className="text-xs text-color font-normal leading-5">
                  {data}
                </li>
              ))} */}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Files</h1>
            <p className="text-xs text-color font-normal leading-5">
              <ListWithReadMore data={currentDetails?.filenames} />
              {/* {currentDetails?.filenames.map((data) => (
                <li className="text-xs text-color font-normal leading-5">
                  {data}
                </li>
              ))} */}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">
              Credit Card Details
            </h1>
            <p className="text-xs text-color font-normal leading-5">
              {/* {currentDetails?.credit_cards} */}
            </p>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
