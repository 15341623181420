import React, { useEffect, useState } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import BrandTweetTable from "../../home/components/tables/BrandTweetTable";

const BrandMonitorTables = (props) => {
  const { isDarkMode } = props;
  const [fetchTwitter, setFetchTwitter] = useState([]);
  useEffect(() => {
    TableApi.fetchTwitterData().then((data) => {
      setFetchTwitter(data.data);
    });
  }, []);
  return (
    <>
      <div
        className={`p-8 rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "white-background  text-color"
        }`}
      >
        <BrandTweetTable
          isDarkMode={isDarkMode}
          userInformation={fetchTwitter}
        />
      </div>
    </>
  );
};

export default BrandMonitorTables;
