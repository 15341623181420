import { useEffect, useState } from "react";
import ComplianceTable from "../complianceTable";
import AddAccess from "./AddAccess";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { useNavigate, useParams } from "react-router-dom";

export default function Access() {
  const { id } = useParams();
  const [currentDetails, setCurrentDetails] = useState(null);
  const navigate = useNavigate();

  const getCurrentDetails = () => {
    complianceAPI
      .get(`/trust-center/access/${id}/`)
      .then((res) => {
        setCurrentDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      getCurrentDetails();
    }
  }, [id]);

  const columns = [
    {
      field: "company",
      header: "Company Name",
    },
    {
      field: "email",
      header: "User",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Active",
          value: true,
        },
        {
          label: "Inactive",
          value: false,
        },
      ],
    },
    {
      field: "created_at",
      header: "Created At",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <>
      <ComplianceTable
        title="Access"
        columns={columns}
        url_path="/trust-center/access/"
        AddComponent={AddAccess}
      />
      <AddAccess
        visible={currentDetails}
        onHide={() => {
          setCurrentDetails(null);
          navigate("/compliance/trust-center");
        }}
        currentDetails={currentDetails}
      />
    </>
  );
}
