import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth0 from "../../../services/auth0";
import { LoginFooter, LoginHeader } from "../../../Component/Header";
import AuthSideBar from "../../../Component/Header/AuthSideBar";
import { maskedEmailAt } from "../../../utils/constants";

export default function ForcePasswordReset() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const sendPasswordReset = () => {
    Auth0.changePassword(
      {
        connection: "Username-Password-Authentication",
        email: state.email,
      },
      (err, response) => {
        if (err) toast.error(err.errorDescription);
        else
          toast.success("We've just sent you an email to reset your password.");
        setTimeout(() => navigate("/login"), 4000);
      }
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <LoginHeader title="" subTitle="" navigate="" />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
          {/* <LoginForm /> */}
          <div className="flex flex-col items-center py-24 lg:px-10 2xl:px-[115px] px-6 h-screen">
            <div className="w-full label-color">
              <div className="flex flex-col justify-center mx-5">
                <h1 className="text-2xl font-bold text-color text-center pb-4">
                  Password Change is required.
                </h1>
                <p className=" pb-2 text-base text-center">
                  Please change your password to continue using the app.
                </p>
                <div className="text-center my-5">
                  <h1 className="text-base font-bold text-color ">
                    {maskedEmailAt(state.email)}
                  </h1>
                </div>
                <button
                  className="w-full button-bg-color p-3 rounded-[48px] text-white text-base"
                  onClick={() => {
                    sendPasswordReset();
                  }}
                >
                  reset password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <LoginFooter />
      </div>
    </div>
  );
}
