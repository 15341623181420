import Github from "./github";
import JavaScriptLeaks from "./javascriptleaks";

export default function DataLeakIntelligence() {
  const hash = window.location.hash;

  const TABLES = {
    Github: <Github />,
    JavaScriptLeaks: <JavaScriptLeaks />,
  };

  return (
    <div className="ssl-status ">
      <h1 className="font-bold">Data Leak Intelligence</h1>
      {!hash
        ? Object.keys(TABLES).map((key) => TABLES[key])
        : TABLES[hash.substring(1)]}
    </div>
  );
}
