import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import BrandMonitorTables from "../../modules/brandmonitor/components/BrandMonitorTables";
import GenericLayout from "../../common/layout/GenericLayout";

const BrandMonitor = () => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div>
      <GenericLayout>
        <BrandMonitorTables/>
      </GenericLayout>
    </div>
  );
};

export default BrandMonitor;
