import React from "react";
import { Card } from "primereact/card";

const CardCarousel = ({item, key}) => {
  return (
    // <div className="flex justify-center" key={key}>
    //   <Card className="h-[500px] w-[1000px]" key={key}>
    //     <div className="grid  grid-cols-3 gap-2">
    //       <div className="py-12 px-10">
    //         <p className="font-bold text-[24px] pb-4">Enhance your Attack Surface Protection</p>
    //         <p className="text-[16px]">
    //           The Attack Surface Intelligence module is an essential security
    //           module that automates vulnerability identification in network and
    //           web applications, empowering organizations to proactively mitigate
    //           potential threats and enhance their overall security posture
    //         </p>
    //       </div>
    //       <div className="col-span-2">
    //       <p>
    //         {item.text}
    //           {/* The Attack Surface Intelligence module is an essential security
    //           module that automates vulnerability identification in network and
    //           web applications, empowering organizations to proactively mitigate
    //           potential threats and enhance their overall security posture */}
    //         </p>
    //       </div>
    //     </div>
    //   </Card>
    // </div>

    <div key={key} className="flex justify-center ">
    <img
      className="h-[500px] w-[1000px] object-cover rounded-[24px] delay-450 ease-in-out transition-transform text-center"
      src={item.img}
      alt="pic"
    />
 </div> 
  );
};

export default CardCarousel;
