import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import { BiPlus } from "react-icons/bi";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import { confirmDialog } from "primereact/confirmdialog";

const Accordion = ({ title, content }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div
      className={`mt-5 p-2 bg-slate-100 rounded-lg border transition-all duration-300 ease-in-out ${
        accordionOpen
          ? "border-transparent border-l-[3px] border-l-orange-400"
          : ""
      }`}
    >
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex justify-between items-center w-full border-none outline-none"
      >
        <h1 className="ml-1">{title}</h1>
        <span className="text-xl">
          {accordionOpen ? <TbChevronUp /> : <TbChevronDown />}
        </span>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="flex flex-col gap-3 overflow-hidden">{content}</div>
      </div>
    </div>
  );
};

export default function AddQuiz({ showQuiz, setShowQuiz, currentDetails }) {
  // const [visible, setVisible] = useState(v);

  const [quiz, setQuiz] = useState([]);

  const getQuiz = () => {
    complianceAPI
      .post("/campaign/question/filter/", { campaign: showQuiz.id })
      .then(({ data }) => {
        setQuiz(data);
      })
      .catch((err) => console.log(err));
  };

  const cleanClose = () => {
    setShowQuiz(false);
  };

  const QuizForm = ({ item, i }) => {
    const [data, setData] = useState({ ...item, campaign: showQuiz.id });
    const [error, setError] = useState({});

    const handleCreate = (e) => {
      e.preventDefault();
      if (!data?.answer && data?.answer !== 0) {
        toast.error("Please mark a correct answer.");
        return;
      }
      e.target.submit.setAttribute("disabled", "disabled");
      if (item?.id) {
        complianceAPI
          .patch(`/campaign/question/${item.id}/`, data)
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res.message);
              e.target.submit.removeAttribute("disabled");
            } else {
              toast.success("Question updated successfully");
              e.target.submit.removeAttribute("disabled");
              cleanClose();
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
            e.target.submit.removeAttribute("disabled");
          });
      } else {
        complianceAPI
          .post("/campaign/question/", data)
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res.message);
              e.target.submit.removeAttribute("disabled");
            } else {
              toast.success("Question created successfully");
              e.target.submit.removeAttribute("disabled");
              cleanClose();
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
            e.target.submit.removeAttribute("disabled");
          });
      }
    };

    const handleDelete = () => {
      confirmDialog({
        header: `Delete ${item.name}`,
        message: "Are you Sure..?",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          if (item.id)
            complianceAPI
              .delete(`/campaign/question/${item.id}/`)
              .then((res) => {
                toast.success("Question deleted successfully");
                setQuiz(quiz.filter((_, j) => i !== j));
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message);
              });
          else setQuiz(quiz.filter((_, j) => i !== j));
        },
      });
    };

    // useEffect(() => {
    //   if (currentDetails) setData(currentDetails);
    // }, [currentDetails]);
    console.log(data);
    const cleanClose = () => {
      // setData({});
      getQuiz();
      setError({});
    };

    return (
      <div className="w-[90%] flex flex-wrap mt-6 mb-2 gap-2 mx-auto">
        <form
          onSubmit={(e) => handleCreate(e)}
          className="w-full flex items-center flex-col gap-4"
        >
          {/* Name */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Question</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter Lesson name"
              value={data.question}
              required
              onChange={(e) => {
                setData({ ...data, question: e.target.value });
                setError({ ...error, question: false });
              }}
            />
            {error.question && (
              <p className="text-red-500 text-sm">{error.question}</p>
            )}
          </div>
          {/* Name */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">
              No. of options
            </label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="number"
              placeholder="Enter a number"
              onWheel={(event) => event.currentTarget.blur()}
              value={data.number_of_options}
              required
              onChange={(e) => {
                setData({
                  ...data,
                  number_of_options: e.target.value,
                  options: Array.from({ length: e.target.value }).map(
                    () => null
                  ),
                });
                setError({ ...error, number_of_options: false });
              }}
            />
            {error.number_of_options && (
              <p className="text-red-500 text-sm">{error.number_of_options}</p>
            )}
          </div>
          {
            // Options
            Array.from({ length: data?.number_of_options }).map((_, i) => {
              return (
                <div className="w-full">
                  <div className="flex justify-between">
                    <label className="block text-gray-500 text-sm ">
                      Option {i + 1}
                    </label>
                    <div className="flex gap-1 items-center">
                      <label htmlFor="answer">Answer</label>
                      <input
                        id="answer"
                        name="answer"
                        type="checkbox"
                        checked={data?.answer == i}
                        onChange={() => setData({ ...data, answer: i })}
                      />
                    </div>
                  </div>
                  <input
                    className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                    type="text"
                    placeholder="Enter option"
                    value={data?.options?.[i]}
                    required
                    onChange={(e) => {
                      setData({
                        ...data,
                        options: data?.options?.map((item, j) => {
                          if (j === i) {
                            return e.target.value;
                          } else {
                            return item;
                          }
                        }),
                      });

                      // setError({ ...error, name: false });
                    }}
                  />
                  {/* {error.name && (
                    <p className="text-red-500 text-sm">{error.name}</p>
                  )} */}
                </div>
              );
            })
          }
          {/* Button */}
          <div className="flex w-full">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {data?.id ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={() => handleDelete()}
              type="reset"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    if (showQuiz?.id) getQuiz();
  }, [showQuiz]);

  return (
    <Sidebar
      visible={showQuiz}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-40rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Quiz`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } quiz`}</p>
      {quiz.length ? (
        quiz.map((item, i) => {
          return (
            <Accordion
              title={`Question ${i + 1}`}
              content={<QuizForm item={item} i={i} />}
            />
          );
        })
      ) : (
        <div className="flex justify-center">No Quiz added..!</div>
      )}
      <button
        onClick={() => {
          setQuiz([
            ...quiz,
            {
              question: null,
              number_of_options: null,
              options: [],
              answer: null,
            },
          ]);
        }}
        className="mt-4 px-4 py-2 border-none outline-none rounded-md bg-black flex items-center justify-between gap-3 text-white"
      >
        <BiPlus />
        <span>Add Question</span>
      </button>
    </Sidebar>
  );
}
