import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ComplianceTable from "../complianceTable";
import AddMitigationTask from "./AddMitigationTask";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function MitigationTask() {
  const { id, slug } = useParams();
  const [employee, setEmployee] = useState([]);

  const columns = [
    { field: "name", header: "Mitigation Task Name" },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "due_date",
      header: "Due Date",
      type: "date",
    },

    {
      field: "status",
      header: "Status",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <ComplianceTable
      title="Mitigation Task"
      columns={columns}
      filter={{ [slug]: id }}
      url_path="/mitigation-task/"
      AddComponent={AddMitigationTask}
    />
  );
}
