import axios from "axios";

const baseDomain = process.env.REACT_APP_DOMAIN;
export const baseUrl = `${baseDomain}`;

console.log(`baseDomain`, baseDomain);
console.log(`baseDomain`, `${baseDomain}ultraheals`);
console.log(`REACT_APP_GITHUB_APP_URL`, process.env.REACT_APP_GITHUB_APP_URL);

export const customHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  // "withCredentials": false,
};

const customAxios = axios.create({
  baseUrl,
  // timeout: 30000,
  headers: customHeaders,
});

let _token = "";

customAxios.interceptors.request.use(
  (config) => {
    if (!_token || !_token.length) {
      const userData = window.localStorage.getItem("userDetails");
      _token = userData ? JSON.parse(userData).access : "";
    }

    if (_token) {
      config.headers.Authorization = `Bearer ${_token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// customAxios.defaults.timeout = 30000;

export default customAxios;
