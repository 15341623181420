import { createElement, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { ThemeContext } from "../../../../../context/ThemeContext";
import Details from "./Details";
import Questionnaire from "./Questionnaire";
import MitigationTask from "./MitigationTask";
import Documents from "./Documents";

export default function ViewVendor() {
  const { state } = useLocation();

  const { isDarkMode } = useContext(ThemeContext);

  const [currentTab, setCurrentTab] = useState("details");

  const TABS = [
    {
      id: "details",
      title: "Details",
      component: Details,
    },
    {
      id: "questionnaire",
      title: "Questionnaire",
      component: Questionnaire,
    },
    {
      id: "mitigation",
      title: "Mitigation Tasks",
      component: MitigationTask,
    },
    {
      id: "documents",
      title: "Documents",
      component: Documents,
    },
  ];

  return (
    <>
      <h1 className="font-bold">{state?.name}</h1>
      <div
        className={`flex gap-5 border-b-2 my-6 mx-8 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="py-4 px-8">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component)}
      </div>
    </>
  );
}
