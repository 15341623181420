import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";

export const category = [
  {
    label: "Finance",
    value: "finance",
  },
  {
    label: "Saas Software",
    value: "saas_software",
  },
  {
    label: "Human Resources",
    value: "human_resources",
  },
  {
    label: "Marketing & Sales",
    value: "marketing_sales",
  },
  {
    label: "Infrastructure",
    value: "infrastructure",
  },
  {
    label: "Security",
    value: "security",
  },
  {
    label: "Other",
    value: "other",
  },
];
export const risk_impact = [
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "High",
    value: "high",
  },
];
export default function AddVendor({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [employee, setEmployee] = useState([]);
  const [entities, setEntities] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/vendor/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            if (typeof res.message === "string") {
              toast.error(res.message);
            } else {
              HandleResponseError(res);
            }
          } else {
            toast.success("Vendor updated successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
        })
        .finally(() => {
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/vendor/", data)
        .then((res) => {
          if (!res.id) {
            if (typeof res.message === "string") {
              toast.error(res.message);
            } else {
              HandleResponseError(res);
            }
          } else {
            toast.success("Vendor added successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
        })
        .finally(() => {
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getEntities();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Vendor`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Vendor`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <p className="font-bold text-xl">Vendor Details</p>
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Vendor name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm">Vendor URL</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="url"
                placeholder="Vendor URL"
                value={data.url}
                required
                onChange={(e) => {
                  setData({ ...data, url: e.target.value });
                  setError({ ...error, url: false });
                }}
              />
              {error.url && <p className="text-red-500 text-sm">{error.url}</p>}
            </div>
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm">Category</label>
              <Dropdown
                value={category.find((e) => e.value === data.category)?.value}
                onChange={(e) => setData({ ...data, category: e.value })}
                options={category}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
          </div>
          <div className="flex w-full gap-3">
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Entities</label>
              <Dropdown
                value={entities.find((e) => e.id === data.entities)?.id}
                onChange={(e) => setData({ ...data, entities: e.value })}
                options={entities}
                filter
                optionLabel="name"
                optionValue="id"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Assignee</label>
              <Dropdown
                value={employee.find((e) => e.id === data.assignee)?.id}
                onChange={(e) => setData({ ...data, assignee: e.value })}
                options={employee}
                filter
                optionLabel="email"
                optionValue="id"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm">Risk Impact</label>
              <Dropdown
                value={
                  risk_impact.find((e) => e.value === data.risk_impact)?.value
                }
                onChange={(e) => setData({ ...data, risk_impact: e.value })}
                options={risk_impact}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
          </div>
          <hr className="border-[#CECECE] my-4"></hr>
          <p className="font-bold text-xl">POC Details</p>

          <div className="w-full">
            <label className="block text-gray-500 text-sm">POC Name</label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="POC name"
              value={data.poc_name}
              required
              onChange={(e) => {
                setData({ ...data, poc_name: e.target.value });
                setError({ ...error, poc_name: false });
              }}
            />
            {error.poc_name && (
              <p className="text-red-500 text-sm">{error.poc_name}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">POC Email</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="email"
              placeholder="POC Email"
              value={data.poc_email}
              required
              onChange={(e) => {
                setData({ ...data, poc_email: e.target.value });
                setError({ ...error, poc_email: false });
              }}
            />
            {error.poc_email && (
              <p className="text-red-500 text-sm">{error.poc_email}</p>
            )}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
