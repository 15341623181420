import React from "react";
import SkeletonBasic from "../../SkeletonAnimation/SkeletonPlanCard";
import SkeletonWebText from "../../SkeletonAnimation/SkeletonWebText";

const WhoisFullinfo = (props) => {
  const { sideBarVocIntelligenceDetail, loadingSidebarVocDetail } = props;

  console.log("REgisterValue", sideBarVocIntelligenceDetail)

  const getTextColor = (sideBarVocIntelligenceDetail) => {
    if (sideBarVocIntelligenceDetail.severity_level === "Medium") {
      return (
        <p className="text-xs font-normal leading-5 text-[#F5B207]">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "Critical") {
      return (
        <p className="text-[#A30123] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "High") {
      return (
        <p className="text-[#E8001D] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "Low") {
      return (
        <p className="text-[#148842] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else {
      return (
        <p className="text-[#7B7DE2] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    }
  };
  return (
    <>
      {loadingSidebarVocDetail ? (
        <SkeletonWebText />
      ) : (
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Domain</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.domain_info}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Register</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.register_info}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Server</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.server_info}
              </p>
            </div>
          </div>
          <div className="flex  justify-between gap-2 pt-6">
            <div className="w-1/3">
              <div>
                <h1 className="text-sm text-color font-bold">Updated Date</h1>
                {sideBarVocIntelligenceDetail.update_info}
              </div>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Created Date</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.createdate_info}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Expiry Date</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.expirydate_info}
              </p>
            </div>
          </div>

          
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Name Server</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.nameserver_info}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Status</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.status_info}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Email</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.email_info}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">DNS</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.dnssec_info}
              </p>
            </div>
          </div>
         
        
        </div>
      )}
    </>
  );
};

export default WhoisFullinfo;
