import React from "react";
import { toast } from "react-toastify";
import { CheckBoxTick, CheckboxCross } from "../../../../assets/svg";

const IncludedModules = ({ planPriceData, updatePlanData }) => {
  const onChange = (key) => {
    updatePlanData({ [key]: !planPriceData[key] });
  };

  const MODULES = [
    {
      key: "asm",
      name: "Attack Surface Management",
    },
    {
      key: "voc",
      name: "Vulnerability Operation Center",
    },
    {
      key: "complaince",
      name: "Compliance",
    },
    // {
    //     key: 'ddm',
    //     name: 'Deep & Darkweb Monitoring'
    // },
    // {
    //     key: 'dld',
    //     name: 'Data Leak Detection'
    // },
    // {
    //     key: 'btm',
    //     name: 'Brand Threat Monitoring'
    // },
  ];

  return (
    <div>
      <h1 className="text-[#11111166]">Included Modules</h1>
      <div className="mx-2">
        {MODULES.map((item) => {
          return (
            <div
              className="flex justify-between items-center py-2"
              key={item.key}
            >
              <div>
                <h1 className="text-sm font-semibold text-color">
                  {item.name}
                </h1>
              </div>
              <div>
                <div
                  onClick={() => {
                    if (planPriceData.plan === "Custom") {
                      onChange(item.key);
                    } else {
                      toast.warning(
                        "Upgrade to Custom plan for customizing modules."
                      );
                    }
                  }}
                >
                  {planPriceData[item.key] ? (
                    <img src={CheckBoxTick} alt="tick" className="ml-1 mr-1" />
                  ) : (
                    <img src={CheckboxCross} alt="cross" />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IncludedModules;
