import React from "react";
import SkeletonBasic from "../../SkeletonAnimation/SkeletonPlanCard";
import SkeletonWebText from "../../SkeletonAnimation/SkeletonWebText";

const WebApplicationVulnerability = (props) => {
  const { sideBarVocIntelligenceDetail, loadingSidebarVocDetail } = props;

  const getTextColor = (sideBarVocIntelligenceDetail) => {
    if (sideBarVocIntelligenceDetail.severity_level === "Medium") {
      return (
        <p className="text-xs font-normal leading-5 text-[#F5B207]">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "Critical") {
      return (
        <p className="text-[#A30123] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "High") {
      return (
        <p className="text-[#E8001D] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else if (sideBarVocIntelligenceDetail.severity_level === "Low") {
      return (
        <p className="text-[#148842] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    } else {
      return (
        <p className="text-[#7B7DE2] text-xs font-normal leading-5">
          {sideBarVocIntelligenceDetail.severity_level}
        </p>
      );
    }
  };

  const getBusinessRisk = (rowData) => {
    /**
     * Exploit	Exploitability	Business Risk
        Yes	Yes	Critical
        Yes	No	High
        No	Yes	High
        No	No	Low
     */

    const exploit = rowData?.exploits?.toLowerCase();
    const exploitability = rowData?.exploitability?.toLowerCase();

    if (!exploit && !exploitability) return null;

    if (exploit === "yes" && exploitability === "yes") {
      return <p className="text-[#E8001D]">High</p>;
    } else if (exploit === "yes" && exploitability === "no") {
      return <p className="text-[#F5B207]">Medium</p>;
    } else if (exploit === "no" && exploitability === "yes") {
      return <p className="text-[#F5B207]">Medium</p>;
    } else if (exploit === "no" && exploitability === "no") {
      return <p className="text-[#148842]">Low</p>;
    } else {
      return null;
    }
  };

  return (
    <>
      {loadingSidebarVocDetail ? (
        <SkeletonWebText />
      ) : (
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Host</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.hosts}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">IP Address</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.ip}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Type</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.type}
              </p>
            </div>
          </div>
          <div className="flex  justify-between gap-2 pt-6">
            <div className="w-1/3">
              <div>
                <h1 className="text-sm text-color font-bold">Severity</h1>
                {getTextColor(sideBarVocIntelligenceDetail)}
              </div>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">CVE</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.cve}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">CVSS Score</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail?.cvss_score ||
                  sideBarVocIntelligenceDetail?.cvs_score}
              </p>
            </div>
          </div>

          <div className="flex  gap-2 py-6">
            <div className="w-1/3">
              <div>
                <h1 className="text-sm text-color font-bold">Exploits</h1>
                <p className="text-xs text-color font-normal leading-5">
                  {sideBarVocIntelligenceDetail.exploits}
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Exploitability</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.exploitability}
              </p>
            </div>
            <div className="w-1/3">
              <h1 className="text-sm text-color font-bold">Business Risk</h1>
              <p className="text-xs text-color font-normal leading-5">
                {getBusinessRisk(sideBarVocIntelligenceDetail)}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">URL</h1>
              <p className="text-xs text-color font-normal leading-5">
                <a
                  className="text-blue-500"
                  href={sideBarVocIntelligenceDetail.url}
                  target="_blank"
                >
                  {sideBarVocIntelligenceDetail.url}
                </a>
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Vulnerability</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.vulnerability}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Description</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail?.description ||
                  sideBarVocIntelligenceDetail?.vul_description}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Impact</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail?.impact ||
                  sideBarVocIntelligenceDetail?.vul_impact}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Mitigation</h1>
              <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail?.mitigation ||
                  sideBarVocIntelligenceDetail?.vul_mitigation}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 pb-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Reference</h1>
              <ul className="list-disc">
                {sideBarVocIntelligenceDetail.reference
                  ? sideBarVocIntelligenceDetail.reference.map((data) => (
                      <li className="text-xs text-color font-normal leading-5">
                        <a href={data} target="_blank">
                          {data}
                        </a>
                      </li>
                    ))
                  : sideBarVocIntelligenceDetail?.vul_reference?.map((data) => (
                      <li className="text-xs text-color font-normal leading-5">
                        <a href={data} target="_blank">
                          {data}
                        </a>
                      </li>
                    ))}
              </ul>
              {/* <p className="text-xs text-color font-normal leading-5">
                {sideBarVocIntelligenceDetail.reference}
              </p> */}
            </div>
          </div>
          {/* {sideBarVocIntelligenceDetail.type == "Manual" ? (
            <>
              <div className="flex items-center justify-between gap-2 pb-6">
                <div className="w-1/1">
                  <h1 className="text-sm text-color font-bold">
                    Proof of Concept
                  </h1>
                  <p className="text-xs text-color font-normal leading-5">
                    {sideBarVocIntelligenceDetail.reference}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between gap-2 pb-6">
                <div className="w-1/1">
                  <h1 className="text-sm text-color font-bold">
                    Steps to Reproduce
                  </h1>
                  <p className="text-xs text-color font-normal leading-5">
                    {sideBarVocIntelligenceDetail.reference}
                  </p>
                </div>
              </div>
            </>
          ) : (
            ""
          )} */}
        </div>
      )}
    </>
  );
};

export default WebApplicationVulnerability;
