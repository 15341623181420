import React, { createElement, useContext, useRef, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { NavLink } from "react-router-dom";
import {
  ChartIconDark,
  ChartIconLight,
  DashBoardIconDark,
  DashBoardIconLight,
  GlobeIconDark,
  GlobeIconLight,
  SideBarIconDark,
  SideBarIconLight,
  SystemIconDark,
  SystemIconLight,
} from "../../assets/svg";
import { AiOutlineMenu } from "react-icons/ai";
import { TbCloudLock } from "react-icons/tb";
import { TbCheckupList } from "react-icons/tb";
import { LiaCertificateSolid } from "react-icons/lia";
import { MdDashboard } from "react-icons/md";
import { MdOutlineBugReport } from "react-icons/md";

import {
  attackSurfaceDropdownItems,
  digitalThreatDropdownItems,
  complianceDropdownItems,
} from "../../constants";
import Section from "./Section";
import { useOutsideClickHandler } from "./utils";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import {
  isAdminOrSuperAdmin,
  isAdminOrSuperAdminInfoAccount,
  isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin,
} from "../../utils/accessCheck";
import { MODULES } from "../../modules/sidebar/components/CreateUserForm";

const NonExpandableSideBar = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const { expandSideBar, setExpandSideBar } = props;

  const [openAttackSurfaceMenu, setOpenAttackSurfaceMenu] = useState(false);
  const [openDigitalThreatMenu, setOpenDigitalThreatMenu] = useState(false);
  const [openComplianceMenu, setOpenComplianceMenu] = useState(false);
  const [currentOpenSection, setCurrentOpenSection] = useState(null);

  const sectionRef = useRef(null);
  const digitalThreatMenuRef = useRef(null);
  const complianceMenuRef = useRef(null);

  const toggleSection = (section) => {
    if (currentOpenSection === section) {
      setCurrentOpenSection(null);
    } else {
      setCurrentOpenSection(section);
    }
  };

  useOutsideClickHandler(sectionRef, setOpenAttackSurfaceMenu);
  useOutsideClickHandler(digitalThreatMenuRef, setOpenDigitalThreatMenu);
  useOutsideClickHandler(complianceMenuRef, setOpenComplianceMenu);

  const toggleSidebar = () => {
    const container = document.getElementById("responsiveScreen");
    if (container) {
      const width = container.offsetWidth;
      // if ( width === 1387 || width === 1131) {
      if (width > 900 && width < 1140) {
        container.classList.add("responseStyele");
      } else {
        container.classList.remove("responseStyele");
      }
    }
    setExpandSideBar(!expandSideBar);
  };

  const checkEditorPerm = (index) => {
    if (userDetails.role !== "Editor") return true;
    return MODULES[index].submodules.some((i) =>
      userDetails.module.includes(i)
    );
  };

  return (
    <nav className="my-5 h-full">
      <div
        onClick={toggleSidebar}
        className={`my-2 py-3 cursor-pointer border-l-[3px] border-transparent flex items-center justify-center w-full ${
          isDarkMode ? "text-white" : "text-color"
        }`}
      >
        <img
          src={`${isDarkMode ? SideBarIconDark : SideBarIconLight}`}
          alt="chart"
          className="cursor-pointer"
        />
      </div>

      {userDetails?.compliance && checkEditorPerm(0) && (
        <NavLink
          to="/compliance"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div
            className={`relative ${isDarkMode ? "text-white" : "text-color"}`}
            ref={complianceMenuRef}
          >
            <div
              className="mx-[10px] my-2"
              onClick={() => setOpenComplianceMenu(!openComplianceMenu)}
            >
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="flex justify-center w-full">
                  <TbCheckupList size={24} />
                </div>
              </div>
            </div>
            {openComplianceMenu && (
              <div className="absolute bg-white left-[5.1rem] top-0 z-auto menu-shadow max-h-auto overflow-y-auto overflow-x-hidden">
                <div className="px-[14px] py-[15px] section-border bg-[#18181819]">
                  <h1 className="text-color text-base font-bold">Compliance</h1>
                </div>
                {complianceDropdownItems.map((item, index) => {
                  if (
                    item.isAdmin &&
                    !isAdminOrSuperAdminInfoAccount(
                      userDetails.role,
                      userDetails.email
                    )
                  )
                    return null;
                  if (
                    userDetails.role === "Employee" &&
                    item.section !== "People"
                  )
                    return null;
                  if (
                    userDetails.role === "Vendor" &&
                    item.link !== "Vendor Management"
                  )
                    return null;
                  if (item.section) {
                    if (
                      userDetails.role === "Editor" &&
                      !userDetails.module.includes(item.section)
                    )
                      return null;
                    return (
                      <div
                        key={item.section}
                        className="section-border"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Section
                          textColor="text-color"
                          section={item.section}
                          subLinks={item.subLinks.filter((i) => {
                            if (
                              i.isAdmin &&
                              !isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                                userDetails.role
                              )
                            ) {
                              return false;
                            }
                            return true;
                          })}
                          isDarkMode={isDarkMode}
                          isOpen={currentOpenSection === item.section}
                          toggleSection={() => toggleSection(item.section)}
                          width="w-[273px]"
                          padding="px-3"
                          linkPadding="pl-[35px]"
                        />
                      </div>
                    );
                  } else {
                    if (
                      userDetails.role === "Editor" &&
                      !userDetails.module.includes(item.link)
                    )
                      return null;
                    return (
                      <NavLink to={item.navLink}>
                        <div key={item.link} className="section-border">
                          <div
                            className={`cursor-pointer py-[12.5px] px-3 capitalize flex items-center gap-2`}
                            onClick={toggleSection}
                          >
                            <div className="text-color">
                              {item.icon ? createElement(item.icon) : null}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <h1 className="text-base text-color">
                                {item.link}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails?.cspm && checkEditorPerm(1) && (
        <NavLink
          to="/cloud-security-posture-management"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div
            className={`relative ${isDarkMode ? "text-white" : "text-color"}`}
          >
            <div className="mx-[10px] my-2">
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="flex justify-center w-full">
                  <TbCloudLock size={24} />
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails?.pentest_as_a_service && (
        <NavLink
          to="/ptaas"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="relative">
            <div className="mx-[10px] my-2">
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="flex justify-center w-full">
                  {isDarkMode ? (
                    <MdOutlineBugReport
                      style={{ fontSize: "32px", color: "#FFF" }}
                    />
                  ) : (
                    <MdOutlineBugReport style={{ fontSize: "32px" }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails.asm && checkEditorPerm(2) && (
        <NavLink
          to="/attack-surface-management"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="relative" ref={sectionRef}>
            <div
              className="mx-[10px] my-2"
              onClick={() => setOpenAttackSurfaceMenu(!openAttackSurfaceMenu)}
            >
              <div className="py-3 flex items-center">
                <div className="inner-bar items-start" />
                <div className="w-full flex items-center justify-center">
                  <img
                    src={`${isDarkMode ? ChartIconDark : ChartIconLight}`}
                    alt="chart"
                    className="cursor-pointer flex items-center justify-center"
                  />
                </div>
              </div>
            </div>
            {openAttackSurfaceMenu && (
              <div className="absolute section-border bg-white left-[5.1rem] top-0 z-auto menu-shadow max-h-auto overflow-y-auto overflow-x-hidden">
                <div className="px-[14px] py-[15px] bg-[#18181819]">
                  <h1 className="text-color text-base font-bold">
                    Attack Surface Management
                  </h1>
                </div>
                {attackSurfaceDropdownItems.map((item, index) => (
                  <div key={index} className="section-border">
                    <Section
                      textColor="text-color"
                      section={item.section}
                      subLinks={item.subLinks}
                      isDarkMode={isDarkMode}
                      isOpen={currentOpenSection === item.section}
                      toggleSection={() => toggleSection(item.section)}
                      width="w-[273px]"
                      padding="px-3"
                      linkPadding="pl-[35px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails.voc && checkEditorPerm(2) && (
        <NavLink
          to="/vulnerability-operation-center"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="relative">
            <div className="mx-[10px] my-2">
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="flex justify-center w-full">
                  <img
                    src={`${isDarkMode ? SystemIconDark : SystemIconLight}`}
                    alt="system"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails.drp && checkEditorPerm(3) && (
        <NavLink
          to="/digital-risk-protection"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="relative" ref={digitalThreatMenuRef}>
            <div
              className="mx-[10px] my-2"
              onClick={() => setOpenDigitalThreatMenu(true)}
            >
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="w-full flex justify-center">
                  <img
                    src={`${isDarkMode ? GlobeIconDark : GlobeIconLight}`}
                    alt="globe"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
            {openDigitalThreatMenu && (
              <div className="absolute bg-white left-[5.1rem] top-0 z-auto menu-shadow max-h-auto overflow-y-auto overflow-x-hidden">
                <div className="px-[14px] py-[15px] section-border bg-[#18181819]">
                  <h1 className="text-color text-base font-bold">
                    Digital Risk Protection
                  </h1>
                </div>
                {digitalThreatDropdownItems.map((item, index) => (
                  <div key={index} className="section-border">
                    <Section
                      textColor="text-color"
                      key={index}
                      section={item.section}
                      subLinks={item.subLinks}
                      isDarkMode={isDarkMode}
                      isOpen={currentOpenSection === item.section}
                      toggleSection={() => toggleSection(item.section)}
                      width="w-[273px]"
                      padding="px-3"
                      linkPadding="pl-[35px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails?.bti && checkEditorPerm(4) && (
        <NavLink
          to="/brand-threat-intelligence"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="relative">
            <div className="mx-[10px] my-2">
              <div className="py-3 flex items-center">
                <div className="inner-bar" />
                <div className="flex justify-center w-full">
                  {isDarkMode ? (
                    <LiaCertificateSolid
                      style={{ fontSize: "32px", color: "#FFF" }}
                    />
                  ) : (
                    <LiaCertificateSolid style={{ fontSize: "32px" }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}
    </nav>
  );
};

export default NonExpandableSideBar;
