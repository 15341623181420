import React, { useRef, useState } from "react";
import DashboardTables from "../../../../../common/Tables/DashboardTables";
import TableFilter from "../../../../../common/Tables/TableFilter";
import Tables from "../../../../../common/Tables/Tables";
import SkeletonDataTable from "../../../../SkeletonAnimation/SkeletonDataTable";

const OpenPorts = (props) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const {
    openPortsTableData,
    loadingTable,
    openPortsFilterData,
    setFilterParams,
    filterParams,
  } = props;

  const dt = useRef(null);
  const columns = [
    { field: "ip", header: "IP Address", value: openPortsFilterData?.ip },
    {
      field: "port_number",
      header: "Port Number",
      value: openPortsFilterData?.port_number,
    },
    {
      field: "port_protocol",
      header: "Protocol",
      value: openPortsFilterData?.port_protocol,
    },
    {
      field: "port_state",
      header: "Port State",
      value: openPortsFilterData?.port_state,
    },
    {
      field: "port_service",
      header: "Service",
      value: openPortsFilterData?.port_service,
    },
    {
      field: "product_version",
      header: "Product Version",
      value: openPortsFilterData?.product_version,
    },
    {
      field: "port_product",
      header: "Technology",
      value: openPortsFilterData?.port_product,
    },
    { field: "tags", header: "Tags", value: [] },
  ];

  return (
    <>
      <div>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          dt={dt}
          tableData={openPortsTableData}
          tableHeader={columns}
        />
        {loadingTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <DashboardTables
            userInformation={openPortsTableData}
            columns={columns}
            dt={dt}
            globalFilter={globalFilter}
            action={true}
          />
        )}
      </div>
    </>
  );
};

export default OpenPorts;
