import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import TechnologiesTable from "../../../home/components/tables/AttackSurface/TechnologiesTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { TechnologiesFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { getFilter } from "../../../../utils/utils";

const TechnologiesIntelligence = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const { title } = props;
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [technologyTableData, setTechnologyTableData] = useState([]);
  const [technologyFilterData, setTechnologyFitlerData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });

  const getTechnologyDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.getTechnologyApi(id).then((data)=>{
    // if(data.status === "success"){
    //   setTechnologyTableData(data.data)
    //   setCountData(data.count)
    //   setLoadingTable(false)
    // }
    // })
    TechnologiesFilterAPI.post({
      ...getFilter(technologyFilterData, filterParams),
      scanID: scanID,
    })
      .then((res) => {
        setTechnologyTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    TechnologiesFilterAPI.get({ scanID: scanID })
      .then((res) => {
        setTechnologyFitlerData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (technologyFilterData) getTechnologyDataTable();
  }, [filterParams, technologyFilterData]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <TechnologiesTable
          loadingTable={loadingTable}
          technologyTableData={technologyTableData}
          technologyFilterData={technologyFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default TechnologiesIntelligence;
