import Chart from "react-apexcharts";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

import Header from "../../../../common/Charts/Header";

export default function RiskHeatMap({ risk_heat_map }) {
  const [showing, setShowing] = useState("inherent_risk");

  const [data, setData] = useState({
    impact: {
      high: 0,
      medium: 0,
      low: 1,
    },
    likelihood: {
      high: 1,
      medium: 0,
      low: 0,
    },
  });

  useEffect(() => {
    if (risk_heat_map?.[showing]) setData(risk_heat_map[showing]);
  }, [showing]);

  return (
    <div className="flex flex-col h-full justify-center">
      <div className="px-3">
        <Header
          title="Risk Assessment"
          tooltip="Overview of risks identified during the implementation stage."
        />
      </div>
      <div className="px-5">
        <Dropdown
          value={showing}
          onChange={(e) => setShowing(e.value)}
          options={[
            {
              label: "Inherent Risk",
              value: "inherent_risk",
            },
            {
              label: "Residual Risk",
              value: "residual_risk",
            },
          ]}
          optionLabel="label"
          optionValue="value"
          placeholder="Select"
          className="w-full md:w-14rem my-1"
        />
      </div>
      <p className="text-gray-500 -rotate-90 absolute ml-[-15px]">Impact</p>
      <Chart
        options={{
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        }}
        series={[
          {
            name: "Low",
            color: "#148842",
            data: [
              {
                x: "Low",
                y: data?.impact?.low * data?.likelihood?.low,
              },
              {
                x: "Medium",
                y: data?.impact?.low * data?.likelihood?.medium,
              },
              {
                x: "High",
                y: data?.impact?.low * data?.likelihood?.high,
              },
            ],
          },
          {
            name: "Medium",
            color: "#F5B207",
            data: [
              {
                x: "Low",
                y: data?.impact?.medium * data?.likelihood?.low,
              },
              {
                x: "Medium",
                y: data?.impact?.medium * data?.likelihood?.medium,
              },
              {
                x: "High",
                y: data?.impact?.medium * data?.likelihood?.high,
              },
            ],
          },
          {
            name: "High",
            color: "#E8001D",
            data: [
              {
                x: "Low",
                y: data?.impact?.high * data?.likelihood?.low,
              },
              {
                x: "Medium",
                y: data?.impact?.high * data?.likelihood?.medium,
              },
              {
                x: "High",
                y: data?.impact?.high * data?.likelihood?.high,
              },
            ],
          },
        ]}
        type="heatmap"
        // width="500"
      />

      <div className="flex w-fill justify-center">
        <p className="text-gray-500	">Likelihood</p>
      </div>
    </div>
  );
}