import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";

export default function paginationTemplate() {
  let totalPages = 0;
  return {
    layout: "PrevPageLink FirstPageLink PageLinks LastPageLink NextPageLink",
    FirstPageLink: (options) => {
      const className = `p-paginator-page p-paginator-element p-link ${
        options.disabled && "p-highlight"
      }`;
      return (
        <span className="p-paginator-pages">
          <button type="button" className={className} onClick={options.onClick}>
            1
            <Ripple />
          </button>
        </span>
      );
    },
    PageLinks: (options) => {
      totalPages = options.totalPages;
      if (options.page === 0 || options.page + 1 === options.totalPages) return;
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, {
          "p-disabled": true,
        });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    LastPageLink: (options) => {
      if (totalPages < 2) return;
      const className = `p-paginator-page p-paginator-element p-link ${
        options.disabled && "p-highlight"
      }`;
      return (
        <span className="p-paginator-pages">
          <button type="button" className={className} onClick={options.onClick}>
            {totalPages}
            <Ripple />
          </button>
        </span>
      );
    },
  };
}
