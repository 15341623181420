import React from 'react'
import { LoginHeader } from '../../Component/Header'
import HomeCarousel from './HomeCarousel'

const LoginCarousel = () => {
  return (
    <div className="flex flex-col min-h-screen">
        <LoginHeader btnText = {true} navigate = "/login" />
        <div className="w-full bg-[#171717] h-screen">
            <HomeCarousel />

        </div>
    </div>
  )
}

export default LoginCarousel