import React, { useContext } from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThemeContext } from "../../context/ThemeContext";

export default function SkeletonDataTable(props) {
  const { columns, length } = props;
  const { isDarkMode } = useContext(ThemeContext);

  const items = Array.from({ length: length ? 5 : 10 }, (v, i) => i);

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  return (
    <div className="card">
      <DataTable
        value={items}
        className={`p-datatable-striped ${
          isDarkMode ? "dark-mode text-dark" : " text-color"
        }`}
      >
        {columns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{ width: "25%" }}
            body={bodyTemplate}
          />
        ))}
      </DataTable>
    </div>
  );
}
