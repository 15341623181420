import { createElement, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ThemeContext } from "../../context/ThemeContext";
import UserInformation from "../home/components/UserInformation";
import Subscription from "../home/components/Subscription";
import GenericLayout from "../../common/layout/GenericLayout";
import EditSubscription from "./EditSubscription"
import { useNavigate } from "react-router-dom";
import { TbArrowUpRight } from "react-icons/tb";


export default function ViewUser() {
  const { isDarkMode } = useContext(ThemeContext);
  const { state } = useLocation();
  const nav = useNavigate();
  
  const [subsData, setSubsData] = useState({});
  const [currentTab, setCurrentTab] = useState("team");
  const [showEdit, setShowEdit] = useState(false);
  const user = state?.user;

  const TABS = [
    {
      id: "team",
      title: "Team",
      component: UserInformation,
      props: { btnText: "Add" },
    },
    {
      id: "subscriptions",
      title: "Subscription",
      component: Subscription,
      props: { btnText: "Add", action: true, setShowEdit: setShowEdit, email: user?.email, setSubsData: setSubsData },
    },
  ];

  useEffect(() => {
    if (!state?.user) {
      nav('/dashboard');
    }
  }, [state?.user, nav]);
  
  return (
    <GenericLayout>
      <>
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold my-5">Users</h1>
          <div className="flex justify-between">
                <button
                  className="flex items-center gap-[6px] text-[#074EE8] text-base"
                  onClick={() => {
                    nav(-1);
                  }}
                >
                Go to dashboard
                <TbArrowUpRight />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div
            className={`rounded-2xl vaultFolder ${
              isDarkMode ? "header-dark" : "header-light white-background"
            }`}
          >
            <div className="flex">
              <p className="flex w-full gap-2 m-5">
                <span className="font-semibold">Name:</span>
                {user?.first_name} {user?.last_name}
              </p>
            </div>
            <div className="ml-5 mb-5">
              <p>
                <span className="font-semibold">Email: </span> {user?.email}
              </p>
            </div>
          </div>
          <div
            className={`rounded-2xl vaultFolder ${
              isDarkMode ? "header-dark" : "header-light white-background"
            }`}
          >
            <div className="flex">
              <p className="flex w-full gap-2 m-5">
                <span className="font-semibold">Company:</span>
                {user?.company}
              </p>
            </div>
            <div className="ml-5 mb-5">
              <p>
                <span className="font-semibold">Role: </span> {user?.role}
              </p>
            </div>
          </div>
        </div>
        {/* <h1 className="font-bold text-lg mt-3 mx-2">Assign To Team</h1> */}
        <div
          className={`flex gap-5 border-b-2 mt-4 mx-2 border-[#DEDEDE] ${
            isDarkMode ? "tab-dark-border" : "border-color"
          }`}
        >
          {TABS.map((tab) => {
            return (
              <div
                key={tab.id}
                className="cursor-pointer py-2"
                onClick={() => setCurrentTab(tab.id)}
              >
                <p
                  className={`capitalize text-base cursor-pointer ${
                    isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                  }  ${
                    currentTab === tab.id
                      ? "selected-tab-color font-medium"
                      : "font-normal"
                  }`}
                >
                  {tab.title}
                </p>
                <div
                  className={`${
                    currentTab === tab.id
                      ? "selected-tab-color relative border-b-2"
                      : ""
                  } top-[0.53rem] `}
                ></div>
              </div>
            );
          })}
        </div>
        <div className="py-4 px-2">
          {createElement(TABS.find((tab) => tab.id === currentTab)?.component, {
            data: TABS.find((tab) => tab.id === currentTab)?.props,
          })}
        </div>
        <ConfirmDialog />
        {
          showEdit && <EditSubscription setShowEdit={setShowEdit} data={subsData} setData={setSubsData} />
        }
      </>
    </GenericLayout>
  );
}
