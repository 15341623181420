import { useState } from "react";

export default function UrlsWithCounts(data) {
  const [openRow, setOpenRow] = useState(false);

  if (data === "-") return;
  return (
    <>
      <ul className="list-disc">
        {data.slice(0, openRow ? -1 : 5).map((data) => (
          <li
            className="pb-2 text-[#074EE8]"
            onClick={() => console.log("click", { data })}
          >
            <a href={data.url} rel="noreferrer" target="_blank">
              {data.url} ({data.count})
            </a>
          </li>
        ))}
        <span
          className="text-[red]"
          onClick={() => {
            setOpenRow(!openRow);
          }}
        >
          {openRow ? "...show less" : "Read more..."}
        </span>
      </ul>
    </>
  );
}
