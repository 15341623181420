import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FiArrowUpRight, FiMoreVertical } from "react-icons/fi";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";

import { ThemeContext } from "../../context/ThemeContext";
import TableFilter from "../../common/Tables/TableFilter";
import SkeletonDataTable from "../../modules/SkeletonAnimation/SkeletonDataTable";
import paginationTemplate from "../../utils/paginationTemplate";
import { complianceAPI } from "../../Api/CSPM/api";
import { DateTimeToDate } from "../../helpers";
import HandleResponseError from "../../utils/error";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import {
  isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin,
  isVendorOrSubscriberOrAdminOrSuperAdmin,
} from "../../utils/accessCheck";
import {
  MTAdminUserFilterAPI,
  AdminTeamUserFilterAPI,
} from "../../Api/FIlterAPI/tableFilterApi";
import { useNavigate } from "react-router-dom";
import { getFilter } from "../../utils/utils";

export default function AdminUserTable({
  title,
  columns,
  url_path,
  filter = {},
  AddComponent = null,
  ViewComponent = null,
  IntegrationComponent = null,
  defaultOpen = null,
  defaultClose = null,
  allowAdd = true,
}) {
  const ref = useRef(null);

  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({});
  const [editDetails, setEditDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [first, setFirst] = useState(0);

  const getFilterData = () => {
    MTAdminUserFilterAPI.get()
      .then(({ data }) => {
        // setFilterParams(data);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    MTAdminUserFilterAPI.post({
      ...getFilter(filterData, filterParams),
      ...filter,
    })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
        if (defaultOpen)
          setViewDetails(data.find((item) => item.id == defaultOpen));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteItem = (id) => {
    complianceAPI
      .delete(`${url_path}${id}`)
      .then((res) => {
        if (res.error) {
          HandleResponseError(res.message);
        } else {
          toast.success("Deleted Successfully.");
          getFilterData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleDownload = (file) => {
  //   axios
  //     .get(file.file, { responseType: "blob" })
  //     .then((res) => {
  //       const blob = new Blob([res.data], {
  //         type: res.headers.contentType,
  //       });
  //       const url = URL.createObjectURL(blob);

  //       // Create a link and trigger a click event to download the file
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = file.name; // Change file extension to .yml
  //       a.click();

  //       // Release the URL object
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const renderActions = (item) => {
    return [
      {
        label: "Edit",
        icon: "pi pi-refresh",
        command: () => {
          setEditDetails(data?.find((i) => item.id === i.id));
          TableApi.getUserTableDataView(data?.filter(i => item.id === i.id).map(i => i.id)[0]).then((data) => {
            dispatch(setUserTableData(data));
          });
        },
      },
      {
        label: "Delete",
        icon: "pi pi-plus",
        command: () => {
          confirmDialog({
            header: `Delete ${item.name}`,
            message: "Are you Sure..?",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Delete",
            rejectLabel: "Cancel",
            accept: () => deleteItem(item.id),
          });
        },
      },
    ];
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setFilterParams={setFilterParams}
            getFilterData={getFilterData}
            filterParams={filterParams}
            columns={columns.map((item) => ({
              ...item,
              value: filterData?.hasOwnProperty(item.field)
                ? item.value?.filter((v) =>
                    filterData[item.field]?.includes(v.value)
                  ) || filterData[item.field]
                : null,
            }))}
            tableData={data}
            tableHeader={columns}
            dt={ref}
            isDarkMode={isDarkMode}
            btnName="Add"
            btnText={
              (allowAdd &&
                (isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                  userDetails.role
                ) ||
                  (userDetails.role === "Editor" &&
                    userDetails.module.includes("Action Button")))) ||
              userDetails.role === "MT Admin"
            }
            setVisibleRight={() => {
              setAddVisibility(true);
            }}
            IntegrationComponent={IntegrationComponent}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              ref={ref}
              removableSort
              value={
                data &&
                data.map((d) => {
                  const newData = { ...d };
                  columns.forEach((col) => {
                    if (col.type === "select") {
                      // if any select option in update data from here itself
                      newData[col.field] = col.value.find(
                        (o) => o.value === d[col.field]
                      )?.label;
                    } else if (col.type === "multi_select") {
                      newData[col.field] = col.value
                        .filter((o) => d[col.field].includes(o.value))
                        .map((o) => o.label)
                        .join(", ");
                    } else if (col.type === "date") {
                      // for date type data
                      if (col.field?.includes(".")) {
                        const nestedFields = col.field.split(".");
                        let value = newData;
                        for (let i = 0; i < nestedFields.length; i++) {
                          value = value[nestedFields[i]];
                        }
                        newData[col.field] = DateTimeToDate(value);
                      } else {
                        newData[col.field] = DateTimeToDate(d[col.field]);
                      }
                    }
                  });
                  return newData;
                })
              }
              paginator
              rows={10}
              first={first}
              onPage={(e) => {
                setFirst(e.first);
              }}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              onRowClick={(e) => {
                navigate("user", { state: { user: e.data } });
              }}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs cursor-pointer`}
            >
              {columns
                .filter((item) => {
                  if (item.admins) {
                    return (
                      isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                        userDetails.role
                      ) ||
                      (userDetails.role === "Editor" &&
                        userDetails.module.includes("Action Button"))
                    );
                  } else {
                    return true;
                  }
                })
                .map((col, i) => {
                  if (col.type === "full_info") {
                    return (
                      <Column
                        header="Full info"
                        exportable={false}
                        body={(rowData) => (
                          <div className="flex gap-2">
                            <button onClick={() => setViewDetails(rowData)}>
                              <FiArrowUpRight size={20} color="#074EE8" />
                            </button>
                          </div>
                        )}
                      />
                    );
                  } else if (col.type === "func") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => col.func(e)}
                      />
                    );
                  } else if (col.type === "action") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => (
                          <SplitButton
                            icon={<FiMoreVertical size={24} />}
                            className="p-button-text outline-none split-style"
                            model={renderActions(e)}
                          ></SplitButton>
                        )}
                      />
                    );
                  }
                  // } else if (col.type === "linkOrFile") {
                  //   return (
                  //     <Column
                  //       key={i}
                  //       field={col.field}
                  //       header={col.header}
                  //       exportable={false}
                  //       body={(e) => {
                  //         if (e.type === "link") {
                  //           return (
                  //             <a
                  //               href={e.link}
                  //               target="_blank"
                  //               className="text-blue-500"
                  //             >
                  //               {e.link}
                  //             </a>
                  //           );
                  //         } else {
                  //           return (
                  //             <p
                  //               className="text-blue-500 cursor-pointer"
                  //               // onClick={() => handleDownload(e.file_info)}
                  //             >
                  //               {e.file_info.name}
                  //             </p>
                  //           );
                  //         }
                  //       }}
                  //     />
                  //   );
                  // } else if (col.type === "fileWithName") {
                  //   return (
                  //     <Column
                  //       key={i}
                  //       field={col.field}
                  //       header={col.header}
                  //       exportable={false}
                  //       body={(e) => {
                  //         return (
                  //           <p
                  //             className="text-blue-500 cursor-pointer"
                  //             // onClick={() => handleDownload(e.file_info)}
                  //           >
                  //             {e.name}
                  //           </p>
                  //         );
                  //       }}
                  //     />
                  //   );
                  // } else if (col.type === "file") {
                  //   return (
                  //     <Column
                  //       key={i}
                  //       field={col.field}
                  //       header={col.header}
                  //       exportable={false}
                  //       body={(e) => {
                  //         return (
                  //           <p
                  //             className="text-blue-500 cursor-pointer"
                  //             // onClick={() => handleDownload(e[col.field])}
                  //           >
                  //             {e[col.field].name}
                  //           </p>
                  //         );
                  //       }}
                  //     />
                  //   );
                  // }
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                })}
            </DataTable>
          )}
        </>
      </div>
      <ConfirmDialog />
      {AddComponent && (
        <AddComponent
          visible={addVisibility || editDetails}
          currentDetails={editDetails}
          onHide={() => {
            setAddVisibility(false);
            if (editDetails) setEditDetails(null);
            getFilterData();
          }}
        />
      )}
      {ViewComponent && (
        <ViewComponent
          currentDetails={viewDetails}
          setCurrentDetails={setViewDetails}
          columns={columns}
          onHide={() => {
            setViewDetails(null);
            getFilterData();
            if (defaultClose) defaultClose();
          }}
        />
      )}
    </>
  );
}
