import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Header } from "../../Component/Header";
import ExpandableSideBar from "../../Component/sideBar/ExpandableSideBar";
import NonExpandableSideBar from "../../Component/sideBar/NonExpandableSideBar";
import { useDispatch, useSelector } from "react-redux";
import { getToggleSidebar, setToggleSidebar } from "../../redux-toolkit/redux/Sidebar";

const GenericLayout = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { children } = props;
  const dispatch = useDispatch()
  const toggleSidebar = useSelector(getToggleSidebar)
  const [expandSideBar, setExpandSideBar] = useState(
    // localStorage.getItem("expandSideBar") === "true" ? true : false
    toggleSidebar
  );

  useEffect(() => {
console.log("toggleSidebar",toggleSidebar)
// if(!toggleSidebar){
//   localStorage.setItem("expandSideBar", expandSideBar);
// }
dispatch(setToggleSidebar(expandSideBar))
   
  }, [expandSideBar]);

  return (
    <div>
      <div
        className={`h-screen flex flex-col ${
          isDarkMode ? "header-dark" : ""
        } overflow-x-hidden`}
      >
        <div className="fixed w-full bg-white z-50">
          <Header />
        </div>
        <div className="flex flex-grow">
          {expandSideBar ? (
            <div
              className={`fixed z-20 top-[3.9rem] h-full w-[320px] overflow-x-hidden overflow-y-scroll ${
                isDarkMode ? "header-dark" : "bg-white"
              } transition-width duration-300 ease-in-out`}
            >
              <div className="mb-20">
                <ExpandableSideBar
                  setExpandSideBar={setExpandSideBar}
                  expandSideBar={expandSideBar}
                />
              </div>
            </div>
          ) : (
            <div
              className={`fixed z-20 top-[3.9rem] h-full w-20 ${
                isDarkMode ? "header-dark" : "bg-white"
              } transition-width duration-300 ease-in-out`}
            >
              <NonExpandableSideBar
                setExpandSideBar={setExpandSideBar}
                expandSideBar={expandSideBar}
              />
            </div>
          )}
          <div
            className={`w-full overflow-y-auto py-11 dashboard-style relative ${
              expandSideBar ? "ml-[320px]" : "ml-20"
            } transition-width duration-500 ease-in-out  top-[4.5rem] ${
              isDarkMode
                ? "body-dark text-white"
                : "body-light text-light-color"
            } `}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericLayout;
