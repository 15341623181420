import { useState } from "react";
import { toast } from "react-toastify";
import APIbase, { baseUrl } from "../../../Api/Repository.js";

export default function ImportVulnerability({ handleCloseSideBar }) {
  const [file, setFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (!file) {
      toast.error("No file selected..!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    APIbase.post(
      `${baseUrl}ultraheals/v1/voc/user-vulnerability/import/`,
      formData,
      config
    )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          handleCloseSideBar();
        } else {
          toast.error(res.data.message);
          e.target.submit.removeAttribute("disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        e.target.submit.removeAttribute("disabled");
      });
  };
  return (
    <div>
      <form className="pt-9 w-full px-5" onSubmit={handleSubmit}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm" for="file_input">
            Upload file
          </label>
          <input
            className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
            id="file_input"
            name="file"
            type="file"
            accept=".csv"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <div className="text-center mt-4">
            <a href="/samples/voc.csv" download className="text-blue-500">
              Download demo format
            </a>
          </div>
        </div>
        <div className="flex w-full mt-9">
          <button
            // onClick={(e) => handleSubmit(e)}
            type="submit"
            className={`p-1 border mr-2 rounded-[16px] border-[#AAAAAA] 
            h-[40px] w-full bg-[#181818] text-[#ffff] `}
            id="submit"
          >
            Submit
          </button>
          <button
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={handleCloseSideBar}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
