import { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TbArrowUpRight } from "react-icons/tb";
import { toast } from "react-toastify";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";

import { ThemeContext } from "../../../../context/ThemeContext";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import paginationTemplate from "../../../../utils/paginationTemplate";
import integrationsAPI from "../../../../Api/integerations/api";

export default function APIKeyCompany({ type, setCurrentConfig }) {
  const { isDarkMode } = useContext(ThemeContext);

  const [loadingTable, setLoadingTable] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [data, setData] = useState([]);

  const getData = () => {
    integrationsAPI
      .get("/", { type })
      .then((res) => {
        setData(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  };

  const columns = [
    {
      field: "account_id",
      header: "Account id",
    },
    {
      field: "provider",
      header: "Provider",
    },
    {
      field: "status",
      header: "Status",
    },
    // {
    //   field: "actions",
    //   header: "Actions",
    // },
  ];

  useEffect(() => {
    getData();
  }, []);

  const removeIntegration = (id) => {
    setLoadingTable(true);
    integrationsAPI
      .delete(`/${id}`)
      .then((res) => {
        getData();
        toast.success("Integration removed successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  };

  const actionBodyTemplate = (id) => {
    console.log("data", id);
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={[
            {
              label: "Remove",
              command: () => {
                removeIntegration(id);
              },
            },
          ]}
        ></SplitButton>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="flex mb-2" style={{ justifyContent: "end" }}>
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base"
            onClick={() => setCurrentConfig(null)}
          >
            Back
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background text-color"
        }`}
      >
        <div className="flex gap-4 justify-between">
          <p className="text-[16px] font-bold pb-2">
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </p>
          <button
            className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
              isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
            }`}
            onClick={() => setVisibleRight(true)}
          >
            Integrate
          </button>
        </div>
        {loadingTable ? (
          <SkeletonDataTable columns={columns} length={true} />
        ) : (
          <div>
            <DataTable
              removableSort
              value={data}
              paginator
              rows={5}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              <Column field="account_id" header="Account ID" />
              <Column
                field="is_active"
                header="Status"
                body={({ is_active }) =>
                  is_active ? (
                    <span className="text-[#148842]">Active</span>
                  ) : (
                    <span className="text-[#E8001D]">Inactive</span>
                  )
                }
              />
              <Column
                field="actions"
                header="Actions"
                body={({ id }) => actionBodyTemplate(id)}
              />
            </DataTable>
          </div>
        )}
        <SidebarSlide
          title="API Keys and Company Id Integration"
          visibleRight={visibleRight}
          description="Integrate your third-party tools with our platform"
          setVisibleRight={setVisibleRight}
          type={type}
          getTableFilterData={getData}
        />
      </div>
    </>
  );
}
