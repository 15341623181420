import auth0 from "auth0-js";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const Auth0 = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  redirectUri: window.location.origin + "/authorize",
  responseType: "token",
  scope: "openid profile email read:authenticators",
});

export default Auth0;
