import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchKeywordGithub: "",
  searchKeywordDarkweb: "",
  searchKeywordCloud: "",
  searchKeywordTwitter: "",
  scanDateTime :{},
  scanViewData:{},
  toggleSidebar:false,
  planCardData:{}
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSearchKeywordGithub: (state, action) => {
      state.searchKeywordGithub = action.payload;
    },
    setSearchKeywordDarkweb: (state, action) => {
      state.searchKeywordDarkweb = action.payload;
    },
    setSearchKeywordCloud: (state, action) => {
      state.searchKeywordCloud = action.payload;
    },
    setSearchKeywordTwitter: (state, action) => {
      state.searchKeywordTwitter = action.payload;
    },
    setScanDateTime: (state, action)=>{
      state.scanDateTime = action.payload
    },
    setScanViewData:(state, action)=>{
      state.scanViewData=action.payload
    },
    setToggleSidebar:(state, action)=>{
      state.toggleSidebar=action.payload
    },
    setPlanCardData:(state,action)=>{
      state.planCardData=action.payload
    }
  },
});

export const {
  setSearchKeywordGithub,
  setSearchKeywordDarkweb,
  setSearchKeywordCloud,
  setSearchKeywordTwitter,
  setScanDateTime,
  setScanViewData,
  setToggleSidebar,
  setPlanCardData
} = sidebarSlice.actions;
export const getSearchKeywordGithub = (state) =>
  state.sidebar.searchKeywordGithub;
export const getSearchKeywordDarkweb = (state) =>
  state.sidebar.searchKeywordDarkweb;
export const getSearchKeywordCloud = (state) =>
  state.sidebar.searchKeywordCloud;
export const getSearchKeywordTwitter = (state) =>
  state.sidebar.searchKeywordTwitter;
export const getScanDateTime = (state) => 
  state.sidebar.scanDateTime;
export const getScanViewData = (state)=>
  state.sidebar.scanViewData;
export const getToggleSidebar = (state)=>
  state.sidebar.toggleSidebar;
export const getPlanCardData = (state)=>
  state.sidebar.planCardData;
export default sidebarSlice.reducer;
