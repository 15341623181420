import React, { useState, useEffect, useRef } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { REGEX } from "../../../utils/constants";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { message } from "antd";
import SkeletonForgotPassword from "../../SkeletonAnimation/SkeletonForgotPassword";
import { LineWave } from "react-loader-spinner";
import Auth0 from "../../../services/auth0";
const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Invalid Email");
  const [loading, setLoading] = useState(false);

  const emailInputRef = useRef(null);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const navigate = useNavigate();

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    if (email === "") {
      setIsEmailError(true);
      setEmailErrorMessage("Email Field required");
    } else if (email) {
      if (REGEX.EMAIL_ID.test(email)) {
        setIsEmailError(false);
      } else {
        setIsEmailError(true);
        setEmailErrorMessage("Invalid Email");
      }
    }

    if (email !== "" && REGEX.EMAIL_ID.test(email)) {
      setLoading(true);
      Auth0.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: email,
        },
        (err, resp) => {
          if (err) message.error(err.description);
          else message.success(resp);
          setLoading(false);
        }
      );
      // AuthApi.forgotPassword({ email: email }).then((data) => {
      //   if (data.status === "success") {
      //     setLoading(false);
      //     message.success(data.message);
      //     setTimeout(() => {
      //       navigate("/reset/recoveryLink", { state: { email: email } });
      //     }, 1000);
      //   } else {
      //     setLoading(false);
      //     message.error(data.message);
      //   }
      // });
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-screen py-24 lg:px-10 2xl:px-[115px] px-6 login-style">
      <div>
        <div>
          <h1 className="text-2xl font-bold text-center text-color">
            Forgot Password
          </h1>
          <p className="text-base text-color pt-4 text-center">
            Reset your password by entering your email address to receive a
            reset link.
          </p>
          <form
            className="pt-9 flex flex-col w-full label-color"
            onSubmit={handleForgotPassword}
          >
            <label className="label-color text-sm">Email</label>
            <input
              type="email"
              placeholder="john@gmail.com"
              autoComplete="on"
              className={`py-2 px-4 rounded-md text-lg placeholder-color text-color 
                ${
                  isEmailError
                    ? "border-color-error border-error-focus"
                    : "border-color-disable border-focus"
                }`}
              ref={emailInputRef}
              value={email}
              onChange={(e) => {
                setIsEmailError(false);
                setEmail(e.target.value);
              }}
            />
            <div>
              {isEmailError && (
                <h1 className="error text-sm">{emailErrorMessage}</h1>
              )}
            </div>

            <button
              type="submit"
              className={`w-full p-3 my-4 rounded-[48px] text-white text-base relative ${
                loading ? "button-disabled" : "button-bg-color"
              } flex justify-center items-center`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <div className="absolute -bottom-3">
                    <LineWave color="#fff" height={100} width={100} />
                  </div>
                  <span className="ml-16">Sending a link..</span>
                </div>
              ) : (
                <>
                  <span>Send a link</span>
                </>
              )}
            </button>
          </form>
        </div>
      </div>
      <Link to="/login">
        <div className="link-color pt-8 flex gap-2 items-center cursor-pointer mb-20">
          <div>
            <AiOutlineArrowLeft size={18} />
          </div>
          <h1 className="text-base">Back to Log in</h1>
        </div>
      </Link>
    </div>
  );
};

export default ForgotPasswordForm;
