import { useParams } from "react-router-dom";
import ComplianceTable from "../complianceTable";
import AddDocuments from "./AddDocuments";

export default function Documents() {
  const { id } = useParams();
  const columns = [
    { field: "name", header: "Name", type: "linkOrFile" },
    {
      field: "created_at",
      header: "Created At",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Documents"
      columns={columns}
      filter={{ vendor: id }}
      url_path="/vendor-document/"
      AddComponent={AddDocuments}
    />
  );
}
