import React, {useState} from 'react'
import Checkbox from './Checkbox';

const Checkboxes = () => {
    const [selected, setSelected] = useState([]);
 
    const listOptions = [ "apple", "banana", "cherry", "date", "elderberry", "fig", "honeydew melon"];

     
    function handleSelect(value, name) {
        if (value) {
          setSelected([...selected, name]);
        } else {
          setSelected(selected.filter((item) => item !== name));
        }
      };

      function selectAll(value) {
        if (value) { // if true
         setSelected(listOptions); // select all
        } else { // if false
          setSelected([]); // unselect all
        }
      };
  return (
    <div className="flex w-full min-h-screen items-center justify-center p-5">
      <div className="w-full max-w-md">
        <h1 className="font-semibold text-lg mb-2">Checkbox List</h1>
        <div className="-mx-5 px-5 py-0 rounded bg-gray-100 font-medium">
          <Checkbox name="all" value={selected.length === listOptions.length} updateValue={selectAll}>Select All</Checkbox>
        </div>
        {/* { listOptions.map((item) => {
          return <Checkbox name={item} value={selected.includes(item)} updateValue={handleSelect}>{item}</Checkbox>
        }) } */}
      </div>
     </div>
  )
}

export default Checkboxes