import React from 'react';
import { Skeleton } from 'antd';

const SkeletonBasic = ({plan}) => {
    return (
    
    <div className=" w-full  ">
      <p className={`w-full mb-5 ${plan && "text-right"}`}>
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
      <p className="w-full mb-5">
        <Skeleton.Input active={true} height={160}  />{" "}
      </p>
    </div>
      
    );
};

export default SkeletonBasic;