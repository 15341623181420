import { Sidebar } from "primereact/sidebar";
import PdfViewerSideBar from "./trustcenter/pdfViewerSidebar";

export default function PreviewDocs({ data, setData }) {
  console.log(data);
  if (data?.name?.includes(".pdf")) {
    return <PdfViewerSideBar viewPDF={data.file} setViewPDF={setData} />;
  } else if (
    data?.name?.includes(".png") ||
    data?.name?.includes(".jpg") ||
    data?.name?.includes(".jpeg")
  ) {
    return (
      <Sidebar
        visible={true}
        position="right"
        onHide={() => setData(null)}
        className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
      >
        <div className="flex flex-col gap-3 w-full justify-center">
          <img
            src={data?.file}
            alt="preview"
            className="object-contain w-full"
            width="100%"
          />
        </div>
      </Sidebar>
    );
  } else {
    return (
      <Sidebar
        visible={true}
        position="right"
        onHide={() => setData(null)}
        className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
      >
        <div className="flex flex-col gap-3 w-full justify-center">
          <span>Preview not available (Unsupported file type)</span>
        </div>
      </Sidebar>
    );
  }
}
