import { Sidebar } from "primereact/sidebar";
import { useContext, useState, useCallback } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { RiExternalLinkLine } from "react-icons/ri";

// Integration images import
import asana from "../../../../../assets/integraions/asana.svg";
import clickup from "../../../../../assets/integraions/clickup.svg";
import monday from "../../../../../assets/svg/monday-icon.svg";
import jira from "../../../../../assets/svg/Jira-icon-blue.svg";
import coda from "../../../../../assets/svg/coda-icon.svg";
import airtable from "./../../../../../assets/svg/airtable-icon.svg";
import notion from "../../../../../assets/svg/notion-logo-svgrepo-com.svg";
import confluence from "../../../../../assets/svg/confluence-svgrepo-com.svg";
import hubspot from "../../../../../assets/svg/hubspot-icon.svg";
import zendesk from "../../../../../assets/svg/zendesk.svg";
import freshdesk from "../../../../../assets/svg/freshdesk-icon.svg";
import miro from "../../../../../assets/svg/miro-seeklogo.svg";
import azuredevops from "../../../../../assets/svg/azure-devops-svgrepo-com.svg";
import docusign from "../../../../../assets/svg/docusign.svg";
import attio from "../../../../../assets/svg/attio.svg";
import contentful from "../../../../../assets/svg/contentful.svg";
import hive from "../../../../../assets/svg/hive.svg";
import linear from "../../../../../assets/svg/linear-icon.svg";
import klaviyo from "../../../../../assets/svg/klaviyo.svg";
import pagerduty from "../../../../../assets/svg/pagerduty.svg";
import pivotaltracker from "../../../../../assets/svg/pivotaltracker.svg";
import wrike from "../../../../../assets/svg/wrike.svg";
import shortcut from "../../../../../assets/svg/wrike.svg";
import tines from "../../../../../assets/svg/tines.svg";
import pipedrive from "../../../../../assets/svg/pipedrive.svg";
// import { azuredevops, docusign } from "../../../../../assets/svg";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function IntegrateEvidence({ id }) {
  const { isDarkMode } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const [service, setService] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [apiItems, setApiItems] = useState([]);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const SERVICES = [
    {
      label: "Azuredevops",
      value: "azuredevops",
    },
    {
      label: "GitLab",
      value: "gitlab",
    },
    {
      label: "GitHub",
      value: "github",
    },
    {
      label: "Jira",
      value: "jira",
    },
    {
      label: "Bitbucket",
      value: "bitbucket",
    },
    {
      label: "Semgrep",
      value: "semgrep",
    },
    {
      label: "Asana",
      value: "asana",
    },
    {
      label: "FreshDesk",
      value: "freshdesk",
    },
    {
      label: "Confluence",
      value: "confluence",
    },
    {
      label: "Monday",
      value: "monday",
    },
    {
      label: "Airtable",
      value: "airtable",
    },
    {
      label: "Coda",
      value: "coda",
    },
    {
      label: "Notion",
      value: "notion",
    },
    {
      label: "Hubspot",
      value: "hubspot",
    },
    {
      label: "ClickUp",
      value: "clickup",
    },
    {
      label: "Miro",
      value: "miro",
    },
    {
      label: "Docusign",
      value: "docusign",
    },
    {
      label: "Attio",
      value: "attio",
    },

    {
      label: "Contentful",
      value: "contentful",
    },
    {
      label: "Hive",
      value: "hive",
    },
    {
      label: "Linear",
      value: "linear",
    },

    {
      label: "Klaviyo",
      value: "klaviyo",
    },
    {
      label: "Pagerduty",
      value: "pagerduty",
    },
    {
      label: "Pivotal Tracker",
      value: "pivotaltracker",
    },
    {
      label: "Shortcut",
      value: "shortcut",
    },
    {
      label: "Tines",
      value: "tines",
    },
    {
      label: "Wrike",
      value: "wrike",
    },
    {
      label: "Pipedrive",
      value: "pipedrive",
    },
  ];

  // const runService = () => {
  //   complianceAPI
  //     .post(`/collect/${service}/`)
  //     .then((res) => {
  //       if (res.status === "success") {
  //         toast.success(
  //           "Evidence collection started in background, will update once completed"
  //         );
  //         setVisible(false);
  //       } else {
  //         toast.error(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong");
  //       console.log(err);
  //     });
  //   }
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  const handleServiceChange = (e) => {
    setService(e.value);
    setApiItems([]);
  };

  const runService = () => {
    if (["github", "gitlab", "bitbucket", "semgrep"].includes(service)) {
      complianceAPI
        .post(`/collect/${service}/`, { id })
        .then((res) => {
          if (res.status === "success") {
            toast.success(
              "Evidence collection started in the background, will update once completed"
            );
            setVisible(false);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
          console.error(err);
        });
    } else {
      setPopupVisible(true);
      setVisible(false);
    }
  };

  const handleSubmitWithOptions = () => {
    // Handle the submission of evidence collection with selected options
    console.log(selectedItems);
    complianceAPI
      .post(`/collect-evidence/${service}/`, {
        [service]: selectedItems,
        id,
      })
      .then((res) => {
        toast.success("Options selected, evidence collection will start.");
        setPopupVisible(false);
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = async (keyword) => {
    setLoading(true);
    console.log("Keyword -> ", keyword);
    if (keyword && keyword != "") {
      await complianceAPI
        .get(`/search-evidence/${service}`, { query: keyword })
        .then(({ data }) => {
          console.log("Data -> ", data);
          data ? setApiItems(data) : setApiItems([]);
          setLoading(false);
          console.log("Handle Input change Api-Items -> ", apiItems);
        })
        .catch((err) => {
          toast.error("Something went wrong");
          console.error(err);
          setLoading(false);
        });
    } else {
      console.log("Else Ran !!");
    }
  };

  // Debounce
  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  const debouncedHandleSearch = useCallback(
    debounce((event) => {
      handleInputChange(event.target.value);
    }, 250),
    [handleInputChange]
  );
  const getServiceImage = () => {
    switch (service) {
      case "asana":
        return asana;
      case "clickup":
        return clickup;
      case "jira":
        return jira;
      case "monday":
        return monday;
      case "coda":
        return coda;
      case "airtable":
        return airtable;
      case "notion":
        return notion;
      case "confluence":
        return confluence;
      case "hubspot":
        return hubspot;
      case "freshdesk":
        return freshdesk;
      case "zendesk":
        return zendesk;
      case "miro":
        return miro;
      case "docusign":
        return docusign;
      case "azuredevops":
        return azuredevops;
      case "attio":
        return attio;
      case "hive":
        return hive;
      case "linear":
        return linear;
      case "klaviyo":
        return klaviyo;
      case "pivotaltracker":
        return pivotaltracker;
      case "wrike":
        return wrike;
      case "shortcut":
        return shortcut;
      case "tines":
        return tines;
      case "pipedrive":
        return pipedrive;
      default:
        break;
    }
  };

  return (
    <>
      <button
        className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
          isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
        }`}
        onClick={() => setVisible(true)}
      >
        Integrate
      </button>

      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Integrate Evidence</p>
        <p className="text-sm py-1">
          Collect Evidence Data from third-party services via Integration.
        </p>
        <div className="w-full pt-3">
          <label className="blocgit push origin integrate-ui k text-gray-500 text-sm">
            Service
          </label>
          <Dropdown
            value={service}
            onChange={handleServiceChange}
            options={SERVICES}
            optionLabel="label"
            optionValue="value"
            placeholder="Select Service"
            className="w-full md:w-14rem my-1"
          />
        </div>
        {service && (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Evidence from{" "}
              {service.charAt(0).toUpperCase() + service.slice(1)}
            </button>
          </div>
        )}
      </Sidebar>

      <Dialog
        header="Link a task"
        visible={popupVisible}
        onHide={() => {
          setPopupVisible(false);
          setApiItems([]);
          setSelectedItems([]);
          setService(null);
        }}
        modal
        style={{ width: "40vw" }}
      >
        <div style={{ padding: "20px" }}>
          <input
            type="text"
            placeholder="Enter search keyword..."
            onChange={debouncedHandleSearch}
            style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
            className="w-full p-3 mb-3 bg-gray-100 rounded-md"
          />
          {loading && <div className="loading">Loading...</div>}
          <div>
            {apiItems?.map((item) => (
              <div
                key={item.name}
                style={{ marginBottom: "5px" }}
                className="flex flex-col gap-2 divide-y divide-gray-400"
              >
                <div className="flex items-start justify-center gap-4 p-1">
                  <div className="pl-3">
                    <Checkbox
                      inputId={item.gid}
                      checked={selectedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <h1 className="font-semibold flex gap-2">
                      <img src={getServiceImage()} className="w-4" alt="" />
                      {item.name}
                    </h1>
                    <p>{item.description}</p>
                  </div>
                  <div className="flex items-center justify-center pr-4">
                    <a href={item.web_url} className="text-xl">
                      <RiExternalLinkLine />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex w-full mt-9">
            <Button
              label="Link"
              icon="pi pi-check"
              onClick={() => {
                handleSubmitWithOptions();
                // getFilterData();
                setTimeout(() => {
                  setService(null);
                  setApiItems([]);
                }, 3000);
              }}
              disabled={selectedItems.length === 0}
              className={`p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]`}
            />
          </div>
          <div>{response && <p>{response}</p>}</div>
        </div>
      </Dialog>
    </>
  );
}
