import { useState } from "react";

export default function ListWithReadMore({ data, color = "text-color" }) {
  const [openRow, setOpenRow] = useState(false);

  if (!data || data.length === 0)
    return (
      <span className="text-xs font-normal leading-5">No data available</span>
    );

  return (
    <ul className="list-disc">
      {data?.slice(0, openRow ? -1 : 5).map((data) => (
        <li
          className={`text-xs ${
            color === "text-color" && "text-color"
          } font-normal leading-5`}
        >
          {data}
        </li>
      ))}
      {data.length > 5 && (
        <span
          className="text-[red]"
          onClick={() => {
            setOpenRow(!openRow);
          }}
        >
          {openRow ? "...show less" : "Read more..."}
        </span>
      )}
    </ul>
  );
}
