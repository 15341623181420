import React from "react";
import { useEffect } from "react";

import AreaChart from "../../../common/Charts/AreaChart";
import ProgressChart from "../../../common/Charts/ProgressChart";

const SecureScore = (props) => {
  const {
    secureScoreGraphCategory,
    secureScoreGraphData,
    progressBar,
    avg,
    tooltip,
    title = "Risk Score",
  } = props;

  useEffect(() => {}, [secureScoreGraphCategory, secureScoreGraphData]);

  return (
    <div>
      <div>
        <AreaChart
          title={title}
          data={secureScoreGraphData}
          colors={["#F14D34", "#FEEDEB"]}
          categories={secureScoreGraphCategory}
          value={avg}
          tooltip={tooltip}
        />
      </div>
      <div className="p-2 text-center">
        {progressBar?.map((item) => (
          <div className="px-3">
            <ProgressChart
              title={item.name}
              value={Number.parseFloat(item.value).toFixed(0)}
              secure={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecureScore;
