import React, { useState } from 'react';
import { useEffect } from 'react';
import SliderBar from '../../../../common/Tables/Slider/Slider';

const IncludedOption = ({ title, defaultValue, value, setValue, maxValue }) => {
  // useEffect(() => {
  //   console.log("VALDSd", value)
  // }, [defaultValue, value])
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value > 0) setValue(parseInt(e.target.value))
    // setSelectedPricingOption("Custom")
    // if(e.target.value === ""){
    //   setValue("")
    // }else{
    //   setValue(e.target.value)
    // }

  }
  return (
    <div className='flex items-center justify-between gap-2 py-4'>
      <div className='w-1/4'>
        <h1 className='text-sm font-semibold text-color'>{title}</h1>
      </div>
      <div className='flex-1'>
        <SliderBar
          value={value}
          setValue={setValue}
          maxValue={maxValue}
          title={title}
        />
      </div>
      <div className='w-[15%]'>

        <div className='border-[1px] border-[#DEDEDE] rounded'>
          <input className='text-color font-bold text-sm text-center outline-none w-full' type="number" value={defaultValue} onChange={(e) => { handleChange(e) }} />
          {/* <h1 className='text-color font-bold text-sm py-2 px-3'>{defaultValue}</h1> */}
        </div>
      </div>
    </div>
  );
};


const IncludedOptions = (props) => {
  const { planPriceData, updatePlanData } = props;

  // console.log("FSFDS", selectedPricingOption, planPriceData,)


  // const [domainValue, setDomainValue] = useState(planPriceData.domain || 1);
  // const [keyWordValue, setKeyWordValue] = useState(planPriceData.keywords || 5);
  // const [subDomainsValue, setSubDomainsValue] = useState(planPriceData.subdomains || 10);
  // const [scanIntervalValue, setScanIntervalValue] = useState(15);
  // const [userValue, setUserValue] = useState(planPriceData.users || 2);

  // useEffect(() => {
  //   // console.log("PLANBASIC", planPriceData)
  //   setDomainValue(planPriceData.domain)
  //   setKeyWordValue(planPriceData.keywords)
  //   setSubDomainsValue(planPriceData.subdomains)
  // }, [planPriceData])

  return (
    <div>
      <h1 className='text-[#11111166]'>Included Options</h1>

      <IncludedOption title="Domains" defaultValue={planPriceData.domain} maxValue='100' value={planPriceData.domain}
        setValue={(e) => updatePlanData({ domain: e })}
      />
      <IncludedOption title="Applications" defaultValue={planPriceData.subdomains} maxValue='1000' value={planPriceData.subdomains}
        setValue={(e) => updatePlanData({ subdomains: e })} />
      <IncludedOption title="Cloud" defaultValue={planPriceData.keywords} maxValue='1000' value={planPriceData.keywords}
        setValue={(e) => updatePlanData({ keywords: e })}
      />
      {planPriceData.plan === 'Custom' && (
       <>
        <IncludedOption
          title="Users"
          defaultValue={planPriceData.users}
          maxValue='100'
          value={planPriceData.users}
          setValue={(e) => updatePlanData({ users: e })} />

          <IncludedOption 
            title="Scan" 
            defaultValue={planPriceData.scan} 
            maxValue='100' 
            value={planPriceData.scan}
            setValue={(e) => updatePlanData({ scan: e })}
        />
        </>
      )}

      {/* <IncludedOption title="Scan Interval" defaultValue={scanIntervalValue} maxValue='30' value={scanIntervalValue} setValue={setScanIntervalValue} /> */}
    </div>
  );
};

export default IncludedOptions;
