import { useRef, createRef, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { TbBulb, TbCheck, TbX } from "react-icons/tb";
import { useLocation } from "react-router-dom";

import GenericLayout from "../../../../../common/layout/GenericLayout";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { getUserDetails } from "../../../../../redux-toolkit/redux/auth";
import { toast } from "react-toastify";

function Quiz() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [answered, setAnswered] = useState({});
  const [results, setResults] = useState({});
  const [showCompleted, setShowCompleted] = useState(false);

  const getData = () => {
    complianceAPI
      .get(`/campaign/${state.id}/questions/`)
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(answered).length < data.length) {
      toast.error("Please answer for all questions.");
      return;
    }
    complianceAPI
      .post(`/campaign/${state.id}/questions/`, answered)
      .then(({ data }) => {
        setResults(data);
        if (data.progress > 70) setShowCompleted(true);
      })
      .catch((err) => console.log(err));
  };

  const handleDownload = (file) => {
    complianceAPI
      .get(
        `/awareness-certificate/${state.id}`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const blob = new Blob([res], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        // Create a link and trigger a click event to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = `${userDetails.first_name}_certificate.pdf`; // Change file extension to .yml
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, [state]);

  return (
    <GenericLayout>
      <div className="w-full h-full flex flex-col items-center gap-2 px-3 pb-5">
        {/* Quiz Passed */}
        <div
          className={`fixed flex justify-center items-center z-10 w-full h-full bg-neutral-500/30 ${
            showCompleted ? "" : "hidden"
          }`}
        >
          <div className="w-fit px-4 py-3 bg-white rounded-lg border border-slate-200 shadow-sm flex flex-col gap-7">
            <div
              className="flex items-center justify-end cursor-pointer"
              onClick={() => setShowCompleted(false)}
            >
              <TbX />
            </div>
            <div className="flex items-center gap-5">
              <div className="p-2 bg-green-300 rounded-full text-green-700 text-2xl font-semibold">
                <TbCheck />
              </div>
              <div>
                <h1 className="text-xl font-semibold text-black">
                  Congrats! You've successfully completed the training.
                </h1>
                <p className="text-sm font-light text-slate-500">
                  You've got {results.progress}% of the quiz right!
                </p>
              </div>
            </div>
            <button
              className="w-full py-2 rounded-md bg-orange-600 hover:bg-orange-700 text-white"
              type="submit"
              onClick={() => handleDownload()}
            >
              View Certificate
            </button>
          </div>
        </div>

        {/* Quiz */}
        <h1 className="text-3xl font-semibold my-3">🎓Quiz</h1>

        {/* Quiz Failed */}
        {results.progress < 70 ? (
          <div className="w-full p-5 flex items-center justify-start gap-4 bg-red-200 rounded-lg border-2 border-red-500">
            <div className="p-2 bg-red-700 rounded-lg text-white text-4xl">
              <TbBulb />
            </div>
            <div className="flex flex-col gap-1 text-red-700">
              <p>You've got {results.progress}% of the quiz.</p>
              <p>
                You must answer 70% of the quiz correctly to pass. Please try
                again
              </p>
            </div>
          </div>
        ) : null}

        {/* Quiz Form */}
        <div
          className={` ${
            isDarkMode
              ? "bg-[#171717] border-gray-800"
              : "bg-white border-slate-100"
          } w-full flex flex-col items-start gap-6  p-7 mb-5 border rounded-lg`}
        >
          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3">
            {data.map((question, i) => (
              <div className="flex flex-col gap-1" key={question.id}>
                <div className="font-semibold">
                  {i + 1 + ". " + question.question}
                </div>
                <div className="ml-5 flex flex-col gap-1">
                  {/* {!a ? (
                    <h1 className="w-fit px-2 py-1 my-1 bg-green-500 text-black rounded-sm">
                      Correct
                    </h1>
                  ) : (
                    <h1 className="w-fit px-2 py-1 my-1 bg-red-500 text-black rounded-sm">
                      Incorrect
                    </h1>
                  )} */}
                  {results?.[question.id] === true ? (
                    <h1 className="w-fit px-2 py-1 my-1 bg-green-500 text-black rounded-sm">
                      Correct
                    </h1>
                  ) : results?.[question.id] === false ? (
                    <h1 className="w-fit px-2 py-1 my-1 bg-red-500 text-black rounded-sm">
                      Incorrect
                    </h1>
                  ) : null}
                  {question.options.map((option, j) => {
                    return (
                      <div key={j} className="flex gap-2">
                        <input
                          type="radio"
                          name={question.id}
                          className="border-none outline-none"
                          value={answered?.[question.id]}
                          onClick={() => {
                            setAnswered({ ...answered, [question.id]: j });
                          }}
                        />
                        <label className="cursor-pointer">{option}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </form>
          <hr
            className={`${
              isDarkMode ? "border-gray-500" : "border-slate-200"
            } w-full border rounded-full`}
          />
          <button
            className="px-5 py-2 rounded-md bg-orange-600 hover:bg-orange-700 text-white"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>
        </div>
      </div>
    </GenericLayout>
  );
}

export default Quiz;
