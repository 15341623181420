import React, { useEffect, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { InformationIconDark, InformationIconLight } from '../../assets/svg';

const CircularProgressBar = ({ initialPercentage, title, isDarkMode }) => {
  const [percentage, setPercentage] = useState(initialPercentage * 10);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    switch (true) {
      case initialPercentage < 4:
        setPrimaryColor('#A30123');
        setSecondaryColor('#A3012366');
        setMessage('critical');
        break;
      case initialPercentage > 4 && initialPercentage < 8:
        setPrimaryColor('#E8001D');
        setSecondaryColor('#E8001D40');
        setMessage('Bad');
        break;
      case initialPercentage >= 8:
        setPrimaryColor('#148842');
        setSecondaryColor('#14884240');
        setMessage('good');
        break;
      default:
        setPrimaryColor('');
        setSecondaryColor('');
        setMessage('');
    }
  }, [initialPercentage]);

  return (
    <div className="w-full h-full">
      <div className="px-3 relative">
        <div className="w-[100%]">
          <h1 className={`flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap ${title.length > 18 && 'w-[90%]'}`}>
            <span className="truncate" title={title}>{title}</span>
          </h1>
        </div>
        <div className="absolute top-0 right-0 flex justify-end items-end w-[18px]">
          <img src={isDarkMode ? InformationIconDark : InformationIconLight } alt="info" />
        </div>
      </div>
      <div className="flex justify-center h-full pt-[42px]">
        <div
          className={`lg:h-[15vw] h-[30vw] lg:w-[15vw] w-[30vw] rounded-[50%] `}
          style={{
            background: `linear-gradient(transparent ${100 - percentage}%, ${secondaryColor} ${100 - percentage}%)`,
            borderColor: primaryColor,
            borderWidth: 10,
          }}
        >
          <div className="flex flex-col justify-center items-center h-full">
            <p className="text-4xl leading-[50px] font-bold">
              {initialPercentage}
              <span className="text-lg font-medium">/10</span>
            </p>
            <p className="capitalize text-base font-normal" style={{ color: primaryColor }}>
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgressBar;
