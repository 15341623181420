import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Sidebar } from "primereact/sidebar";
import { MultiSelect } from "primereact/multiselect";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function ResendInvitation({ data }) {
  console.log(data, "asd");
  const { isDarkMode } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState([]);

  const cleanClose = () => {
    setVisible(false);
    setUsers([]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(users);
    complianceAPI
      .post("/employees/resend-invitations/", { users })
      .then((res) => {
        console.log(res);
        toast.success("Invitation Resent Successfully");
        cleanClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to Resend Invitation");
      });
  };

  return (
    <>
      <button
        className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
          isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
        }`}
        onClick={() => setVisible(true)}
      >
        Resend Invitation
      </button>
      <Sidebar visible={visible} position="right" onHide={cleanClose}>
        <p className="font-bold text-2xl">Resend Invitation</p>
        <p className="text-sm py-1">
          Resend the invitation to the selected user
        </p>
        <form onSubmit={onSubmit} className="flex flex-col gap-3">
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Select User</label>
            <MultiSelect
              value={users}
              options={data}
              onChange={(e) => setUsers(e.value)}
              optionLabel="email"
              optionValue="id"
              placeholder="Select User"
              className="w-full"
              filter
            />
          </div>
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Resend Invitation
          </button>
        </form>
      </Sidebar>
    </>
  );
}
