import React, { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";
import { DownloadIcon } from "../../assets/svg";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import { BsDot } from "react-icons/bs";
import { GetSubString } from "../../utils/constants";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../utils/paginationTemplate";

const ExplosedLinks = ({ data }) => {
  const [openRow, setOpenRow] = useState(false);

  if (data === "-") return;
  return (
    <>
      <ul className="list-disc">
        {JSON.parse(data)
          .slice(0, openRow ? -1 : 5)
          .map((data) => (
            <li
              className="pb-2 text-[#074EE8]"
              onClick={() => console.log("click", { data })}
            >
              <a href={data} rel="noreferrer" target="_blank">
                {data}
              </a>
            </li>
          ))}
        <span
          className="text-[red]"
          onClick={() => {
            setOpenRow(!openRow);
          }}
        >
          {openRow ? "...show less" : "Read more..."}
        </span>
      </ul>
    </>
  );
};

const DashboardCloudTables = (props) => {
  const {
    columns,
    userInformation,
    dt,
    globalFilter,
    setVisibleRight,
    action,
    invoice,
    setTableViewData,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const toggleReadMore = (rowData) => {
    console.log("SFSF", rowData, selectedRowId);
    setSelectedRowId(rowData);
    if (rowData === selectedRowId) {
      setSelectedRowId(null);
    }
  };

  useEffect(() => {
    setProducts(userInformation);
  }, [userInformation]);
  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <DataTable
      removableSort
      value={products}
      ref={dt}
      selection={selectedUsers}
      onSelectionChange={(e) => setSelectedUsers(e.value)}
      paginator
      rows={10}
      globalFilter={globalFilter}
      paginatorTemplate={paginationTemplate()}
      className={`${
        isDarkMode ? "dark-mode text-dark" : " text-color"
      } text-xs`}
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      {columns.map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable
        />
      ))}

      <Column
        field="url"
        header="URL"
        body={(rowData) => renderLink(rowData.url)}
        exportable={false}
      />
      <Column
        field="expose_data_url"
        header="Exposed URL"
        body={
          (rowData) => {
            return <ExplosedLinks data={rowData.expose_data_url} />;
          }

          //   const isCurrentRowOpen = selectedRowId === rowData.row;
          //   // const urlWithoutAnchor = rowData.expose_data_url?.replace(/<\/?a[^>]*>""/g, '');
          //   console.log("ROWDATA123", isCurrentRowOpen, rowData.expose_data_url);

          //   return (<>

          //     <ul className="list-disc">
          //       {rowData.expose_data_url.map((data) => (
          //         <li className="pb-2 text-[#074EE8]" onClick={() => toggleReadMore(rowData.row)}>{data}
          //         </li>
          //       )
          //       )}
          //       <span className="text-[red]">{isCurrentRowOpen ? "...show less" : "Read more..."}</span>
          //     </ul>

          //     {/* <a className="text-[#074EE8]" onClick={() => toggleReadMore(rowData.row)}>
          //       {isCurrentRowOpen ?  (`${urlWithoutAnchor} `) : (`${GetSubString(urlWithoutAnchor, 100)} '`) }
          //     <span className="text-[red]">{isCurrentRowOpen ? "...show less":"Read more..."}</span>
          //     </a> */}
          //   </>
          //   )
          // }
        }
        exportable={false}
      />

      <Column header="Actions" body={actionBodyTemplate} exportable={false} />
    </DataTable>
  );
};

export default DashboardCloudTables;
