import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { TbArrowUpRight } from "react-icons/tb";

import { DateTimeToDate } from "../../helpers";
import { UserIcon } from "../../assets/svg";
import { STATUS } from "../../modules/home/components/ptaas/detailedPtaasView.jsx";
import {
  getUserDetails,
  setUserDetails,
} from "../../redux-toolkit/redux/auth.js";

export default function ScanHistory({ data }) {
  const userDetails = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    if (userDetails?.ptaas_scan_id) {
      const date = data.find(
        (item) => item.id === userDetails.ptaas_scan_id
      )?.created_at;
      setSelectedYear(new Date(date).getFullYear());
    }
  }, [userDetails]);

  const years = Array.from(
    new Set(
      data.map((item) => {
        return new Date(item.created_at).getFullYear();
      })
    )
  );

  return (
    <>
      <button
        className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
        onClick={() => setVisible(true)}
      >
        Scanning history
        <TbArrowUpRight />
      </button>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Pentest History</p>
        <div className="py-8 px-5">
          <div>
            {years.map((year) => {
              return (
                <>
                  <button
                    className="px-10 text-[#F14D34] font-bold text-sm flex items-center gap-3 py-3"
                    onClick={(e) => {
                      if (selectedYear === year) setSelectedYear(null);
                      else setSelectedYear(year);
                    }}
                  >
                    {year}
                    <span>
                      {year === selectedYear ? (
                        <IoIosArrowDown size={20} color="#181818" />
                      ) : (
                        <IoIosArrowForward size={20} color="#181818" />
                      )}
                    </span>
                  </button>
                  <ol className="relative border-l-2 mt-4">
                    {year === selectedYear &&
                      data
                        .filter(
                          (item) =>
                            year === new Date(item.created_at).getFullYear()
                        )
                        .map((item) => (
                          <li key={item.id} className="mb-10 ml-6">
                            <div
                              className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}
                            ></div>
                            <div
                              onClick={() => {
                                dispatch(
                                  setUserDetails({
                                    ...userDetails,
                                    ptaas_scan_id: item.id,
                                  })
                                );
                                setVisible(false);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="text-base font-semibold text-color leading-4 cursor-pointer">
                                {DateTimeToDate(item.created_at)}
                              </p>
                              <div className="py-2 flex gap-2 items-center">
                                <img src={UserIcon} alt="user" />
                                <p className="text-xs font-normal text-[#9F9F9F] py-1">
                                  {item.user}
                                </p>
                              </div>
                              <div>
                                <p className="text-xs font-normal text-[#9F9F9F] py-1">
                                  {item.target} ({STATUS[item.status]})
                                </p>
                              </div>
                              {userDetails?.ptaas_scan_id == item.id && (
                                <div>
                                  <p className=" text-[12px] text-[#F14D34] font-normal">
                                    Current on display
                                  </p>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                  </ol>
                </>
              );
            })}
          </div>
        </div>
      </Sidebar>
    </>
  );
}
