import React from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import { useNavigate } from "react-router";
import AuthSideBar from "../../Component/Header/AuthSideBar";

const PasswordUpdated = () => {
  const navigate = useNavigate();

  const handleGoToLoginPage = () => {
    navigate("/login");
  };

  return (
    <div className="lg:h-screen">
      <LoginHeader title="" subTitle="" navigate="" />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div  className="md:w-[40%] sm:w-full">
        <div className="flex flex-col items-center w-full lg:p-10 p-5 login-style">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold text-center text-color">
              Your password has been changed!
            </h1>
            <div className="text-center mt-6">
              <button
                className="p-4 text-base link-color"
                onClick={handleGoToLoginPage}
              >
                Go to Log in page
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
      <hr />
      <div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default PasswordUpdated;
