import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DownloadIcon, SearchIcon, filter } from "../../../../assets/svg";
import { FiMoreVertical } from "react-icons/fi";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { SplitButton } from "primereact/splitbutton";
import paginationTemplate from "../../../../utils/paginationTemplate";
// import { userInformationData } from "../../../../constants";

const BrandTweetTable = (props) => {
  const { isDarkMode, setVisibleRight, userInformation } = props;
  const dt = useRef(null);

  const [isOpen, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [users, setUsers] = useState(userInformation);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [statuses] = useState(["edit", "view"]);
  useEffect(() => {
    setUsers(userInformation);
  }, [userInformation]);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const items = (data) => [
    {
      label: "Edit",
      icon: "pi pi-refresh",
      command: (e) => {
        setVisibleRight(true);
      },
    },
    {
      label: "View",
      icon: "pi pi-times",
      command: (e) => {
        // console.log(e, data);
        setVisibleRight(true);
      },
    },
  ];

  const splitBtn = (d) => {
    console.log(d); /* Getting data here*/
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return <a href={urlWithoutAnchor}>{urlWithoutAnchor}</a>;
  };

  return (
    <>
      <div className="m-2 p-2">
        <div className="flex justify-between mx-4 p-1">
          <div className="flex flex-wrap">
            <button className="flex gap-2 items-center" onClick={exportCSV}>
              <img src={DownloadIcon} alt="download" />
              <span className="text-[16px]  text-[#074EE8]">Export</span>
            </button>
            <button
              className="flex gap-2 items-center mx-3 px-3"
              onClick={exportCSV}
            >
              <img src={DownloadIcon} alt="download" />
              <span className="text-[16px]   text-[#074EE8]">Share</span>
            </button>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex gap-1 rounded-2xl items-center px-3 py-[11px] border-[1px] w-80 h-10 overflow-hidden">
              <div>
                <img src={SearchIcon} alt="search" />
              </div>
              <input
                placeholder="Search"
                className="bg-transparent border-none w-full px-2 py-1 outline-none text-sm"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
            <div>
              <button className="flex gap-2 items-center">
                <img src={filter} alt="download" />
                <span className="text-[16px]  text-[#074EE8]">Filter</span>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          removableSort
          selection={selectedUsers}
          onSelectionChange={(e) => setSelectedUsers(e.value)}
          ref={dt}
          value={users}
          paginator
          rows={10}
          paginatorTemplate={paginationTemplate()}
          globalFilter={globalFilter}
          className={`${
            isDarkMode ? "dark-mode text-white" : "text-color"
          } text-xs`}
        >
          <Column field="row" header="S.No" sortable />
          <Column field="keyword" header="Keyword" sortable />
          <Column
            field="account_url"
            header="Account"
            body={(rowData) => renderLink(rowData.account_url)}
            sortable
          />
          <Column
            field="tweet_url"
            header="URL"
            body={(rowData) => renderLink(rowData.tweet_url)}
            sortable
          />
          <Column field="sentimental_analysis" header="Analysis" sortable />

          {/* <Column selectionMode="multiple" exportable={false}></Column>
          <Column field="row" header="S.No" sortable></Column>
          <Column field="keyword" header="Keyword" sortable></Column>
          <Column field="account_url" header="Account" sortable></Column>
          <Column
            field="tweet_url"
            header="URL"
            sortable
          ></Column>
          <Column field="tweets" header="Tweet" sortable></Column>
          <Column field="sentimental_analysis" header="Analysis" sortable></Column> */}
        </DataTable>
      </div>
    </>
  );
};

export default BrandTweetTable;
