import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../../context/ThemeContext";
import CloudSurfaceAWSTable from "../../../home/components/tables/AttackSurface/CloudSurfaceAWSTable";
import LiveHosts from "../../../home/components/tables/AttackSurface/LiveHosts";
import SSLActiveTable from "../../../home/components/tables/AttackSurface/SSLActiveTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { SSLFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { DateTimeToDate } from "../../../../helpers";
import { getFilter } from "../../../../utils/utils";

const SSLActive = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const [sslActiveTableData, setSSLActiveTableData] = useState([]);
  const [sslActiveFilterData, setSSLActiveFitlerData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const { title } = props;

  const getSSLActiveDataTable = (id) => {
    setLoadingTable(true);
    // DashboardApi.dashboardSSLActiveTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setSSLActiveTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    SSLFilterAPI.post({
      ...getFilter(sslActiveFilterData, filterParams),
      ssl_status: ["Active", "Unknown"],
      scanID: scanID,
    })
      .then((res) => {
        setSSLActiveTableData(
          res.data.map((item) => {
            return {
              ...item,
              expiry_date: DateTimeToDate(item.expiry_date),
            };
          })
        );
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    SSLFilterAPI.get({ scanID: scanID, ssl_status: ["Active", "Unknown"] })
      .then((res) => {
        setSSLActiveFitlerData(res.data);
        // setFilterParams({...filterParams, ...res.data})
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (sslActiveFilterData) getSSLActiveDataTable();
  }, [filterParams, sslActiveFilterData]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <SSLActiveTable
          sslActiveFilterData={sslActiveFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          loadingTable={loadingTable}
          sslActiveTableData={sslActiveTableData}
        />
      </div>
    </>
  );
};

export default SSLActive;
