import Repository, { baseUrl } from "../Repository";

class AuthApiFetch {
  login(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/login/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  loginOtp(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/login/otp`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  register(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/register/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  otpVerify(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/login/verify/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  forgotPassword(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/forgot/password/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  checkToken(payload) {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/user/reset/password/`,
      { params: payload }
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  resetPassword(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/reset/password/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  resetNewUserPassword(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/update/new/password/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  updatePassword(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/reset/password/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  accountActivate(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/activate/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  setUpProfile(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/team/user/setup/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  resendCode(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/login/resend/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  getNotificationApi(payload) {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/user/notifications/`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  readNotificationApi(payload) {
    const reponse = Repository.put(
      `${baseUrl}ultraheals/v1/user/notifications/`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }

  loginStatusApi(payload) {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/user/login/status/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  getMe(payload) {
    return new Promise((resolve, reject) => {
      Repository.get(`${baseUrl}ultraheals/v1/user/me/`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updateMe(payload) {
    const reponse = Repository.post(`${baseUrl}ultraheals/v1/user/me/`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return {};
      });
    return reponse;
  }
  checkEmail(payload) {
    return new Promise((resolve, reject) => {
      Repository.post(`${baseUrl}ultraheals/v1/user/check-email/`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default new AuthApiFetch();
