import React, { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";
import { DownloadIcon } from "../../assets/svg";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import { BsDot } from "react-icons/bs";
import { SeverityLevelLabel } from "../../helpers";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../utils/paginationTemplate";

const ComplianceDataTables = (props) => {
  const {
    columns,
    userInformation,
    dt,
    globalFilter,
    setVisibleRight,
    action,
    invoice,
    setTableViewData,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  useEffect(() => {
    setProducts(userInformation);
  }, [userInformation]);
  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <DataTable
      removableSort
      value={products}
      ref={dt}
      selection={selectedUsers}
      onSelectionChange={(e) => setSelectedUsers(e.value)}
      paginator
      rows={10}
      sortField="severity"
      globalFilter={globalFilter}
      paginatorTemplate={paginationTemplate()}
      className={`${
        isDarkMode ? "dark-mode text-dark" : " text-color"
      } text-xs`}
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      <Column field="host" header="Host" sortable></Column>
      <Column
        field="vulnerability"
        header="Vulnerability"
        sortable
        style={{ width: "50%" }}
      ></Column>
      <Column
        field="severity"
        header="Severity"
        sortable
        body={(rowData) => {
          if (rowData.severity === "3") {
            return (
              <p className="text-[#F5B207]">
                {SeverityLevelLabel(rowData.severity)}
              </p>
            );
          } else if (rowData.severity === "1") {
            return (
              <p className="text-[#A30123]">
                {SeverityLevelLabel(rowData.severity)}
              </p>
            );
          } else if (rowData.severity === "2") {
            return (
              <p className="text-[#E8001D]">
                {SeverityLevelLabel(rowData.severity)}
              </p>
            );
          } else if (rowData.severity === "4") {
            return (
              <p className="text-[#148842]">
                {SeverityLevelLabel(rowData.severity)}
              </p>
            );
          } else {
            return (
              <p className="text-[#7B7DE2]">
                {SeverityLevelLabel(rowData.severity)}
              </p>
            );
          }
        }}
      ></Column>
      <Column
        field="findings"
        header="Findings"
        sortable
        body={(rowData) => {
          if (typeof rowData.findings === "string") {
            return (
              <ul>
                <li className="pb-2">{JSON.parse(rowData.findings)}</li>
              </ul>
            );
          } else {
            return (
              <ul>
                {JSON.parse(rowData.findings).map((data) => (
                  <li className="pb-2">{data}</li>
                ))}
              </ul>
            );
          }
        }}
      ></Column>
    </DataTable>
  );
};

export default ComplianceDataTables;
