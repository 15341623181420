import React, { useEffect, useRef } from 'react';

const SliderBar = (props) => {
  const { value, setValue, maxValue, identifier, title } = props;
  const elRangeBar = useRef(null);

  useEffect(() => {
    const intThumbWidth = elRangeBar.current.clientHeight * 3;
    const intRangeBarWidth = elRangeBar.current.clientWidth - intThumbWidth;
    const intThumbWidthOffset = intThumbWidth / 2;

    const intProgressPosition = (value - 0) / (maxValue - 0);
    const intRangePosition = intRangeBarWidth * intProgressPosition + intThumbWidthOffset;

    elRangeBar.current.style.background = `linear-gradient(to right, #F14D34 ${intRangePosition}px, #F2F2F2 ${intRangePosition}px)`;
  }, [value, identifier, maxValue]);

  const allowedScanIntervals = [0, 7, 14, 30];

  const handleChange = (event) => {

    const newValue = parseInt(event.target.value, 10); 

    if(title === 'Scan Interval') {
      if (allowedScanIntervals.includes(newValue)) {
        setValue(newValue)
      }
    }else {
      setValue(event.target.value);
    }
  };

  const stepValue = () => {
    if (title === 'Scan Interval') return 5 
    else return 1
  } 

  return (
    <div>
      <div className='range' ref={elRangeBar}>
        <input
          type="range"
          min={0}
          max={maxValue}
          value={value}
          onChange={handleChange}
          className="range-input"
          step={stepValue}
        />
        <div className="flex justify-between mt-2 absolute w-full">
          <span className='text-xs text-color'>0</span>
          <span className='text-xs text-color'>{maxValue}</span>
        </div>
      </div>
    </div>
  );
};

export default SliderBar;
