import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
  userDetails:
    localStorage.getItem("userDetails") != undefined
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {},
  loginToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedInValue: (state, action) => {
      state.isLoggedIn = action.payload;
      localStorage.setItem("isLoggedIn", action.payload);
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
    setLoginToken: (state, action) => {
      state.loginToken = action.payload;
    },
    setCSPMFilters: (state, action) => {
      state.cspmFilters = action.payload;
    },
  },
});

export const {
  setLoggedInValue,
  setUserDetails,
  setLoginToken,
  setCSPMFilters,
} = authSlice.actions;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getLoginEmail = (state) => state.auth.loginToken;
export const getUserDetails = (state) => state.auth.userDetails;
export const getCSPMFilters = (state) => state.auth.cspmFilters;

export default authSlice.reducer;
