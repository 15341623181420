// import React, { useEffect, useRef, useState } from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { subscriptionTableData } from '../../../../constants';
// import { Toolbar } from 'primereact/toolbar';
// import { DownloadIcon, SearchIcon } from '../../../../assets/svg';
// import { BsDot } from 'react-icons/bs';

// const SubscriptionTable = ({ isDarkMode }) => {
//   const dt = useRef(null);

//   const [products, setProducts] = useState(null);
//   const [globalFilter, setGlobalFilter] = useState('');
//   const [selectedProducts, setSelectedProducts] = useState(null);

//   useEffect(() => {
//     setProducts(subscriptionTableData);
//   }, []);

//   const exportCSV = () => {
//     dt.current.exportCSV();
//   };

//   const leftToolbarTemplate = () => {
//     return <h1 className="text-2xl font-bold">Billing history (3)</h1>;
//   };

// const actionBodyTemplate = () => {
// return (
//   <button className="flex gap-2 items-center" onClick={exportCSV}>
//     <img src={DownloadIcon} alt="download" />
//   </button>
// );
// };

//   const headerStart = () => {
//     return (
//       <div>
//       <button className="flex gap-2 items-center" onClick={exportCSV}>
//         <img src={DownloadIcon} alt="download" />
//         <span className="text-xs font-semibold">Export</span>
//       </button>
//     </div>
//     )
//   }

//   const headerEnd = () => {
//     return (
//       <div className='flex items-center gap-4'>
//         <div>
//             <h1 className='text-sm'>69 items</h1>
//         </div>
//         <div className='flex gap-1 items-center px-3 py-[11px] border-[1px] w-80 h-10 rounded overflow-hidden'>
//             <div>
//                 <img src={SearchIcon} alt="search" />
//             </div>
//             <input
//                 placeholder='Search'
//                 className='bg-transparent border-none w-full px-2 py-1 outline-none text-sm'
//                 value={globalFilter}
//                 onChange={(e) => setGlobalFilter(e.target.value)}
//             />
//         </div>
//       </div>
//     )
//   }

//   return (
//     <div>
//       <Toolbar className={`${isDarkMode ? 'bg-dark-mode' : ''} border-none border-zero`} start={leftToolbarTemplate}></Toolbar>
//       <Toolbar className={`${isDarkMode ? 'bg-dark-mode' : ''} border-none border-zero`} start={headerStart} end={headerEnd}></Toolbar>
//       <DataTable
//         selection={selectedProducts}
//         onSelectionChange={(e) => setSelectedProducts(e.value)}
//         ref={dt}
//         value={products}
//         dataKey="id"
//         paginator
//         rows={10}
//         rowsPerPageOptions={[5, 10, 25]}
//         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
//         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
//         globalFilter={globalFilter}
//         className={`${isDarkMode ? 'dark-mode text-white' : 'text-color'} text-xs`}
//       >
//         <Column selectionMode="multiple" exportable={false}></Column>
//         <Column field="subscriptionPlan" header="Subscription plan" sortable></Column>
//         <Column field="activationPeriod" header="Activation period" sortable></Column>
//         <Column field="amount" header="Amount" sortable></Column>
//         <Column field="date" header="Date" sortable></Column>
//         <Column field="status" header="Status" sortable body={(rowData) => {
//           const statusClass = rowData.status === 'Active' ? 'activeStatusClass' : 'expiredStatusClass';
//           return <div className={`${statusClass} flex gap-1 items-center`}><BsDot size={24} />{rowData.status}</div>;
//         }}></Column>
//         <Column header="Invoice" body={actionBodyTemplate} exportable={false} />
//       </DataTable>
//     </div>
//   );
// };

// export default SubscriptionTable;

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Tables from "../../../../common/Tables/Tables";
import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { SubscriptionHistoryFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { DateTimeToDate } from "../../../../helpers";
import { getFilter } from "../../../../utils/utils";
import AddSubscription from "../../../adminDashboard/AddSubscription";
import customAxios from "../../../../Api/Repository";
import { toast } from "react-toastify";



const SubscriptionTable = (props) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [subscriptionTableData, setSubscriptionTableData] = useState([]);
  const [loadingSubscriptionTable, setLoadingSubscriptionTable] =
    useState(true);
  const [historyFilterData, setHistoryFilterData] = useState([]);
  const [FilterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({});
  


  const { state } = useLocation();
  const user = state?.user?.id;

  const dt = useRef(null);
  const columns = [
    { field: "plan", header: "Subscription plan", value: FilterData?.plan },
    { field: "period", header: "Activation period", value: FilterData?.period },
    { field: "amount", header: "Amount", value: FilterData?.amount },
    {
      field: "date_added",
      header: "Date",
      value: FilterData?.date_added,
      type: "date",
    }
  ];

  useEffect(() => {
    setLoadingSubscriptionTable(true);
    // TableApi.subscriptionTable().then((data) => {
    //   if (data.status === "success") {
    //     setSubscriptionTableData(data.data);
    //     setLoadingSubscriptionTable(false);
    //   }
    // });

    // TableApi.subscriptionHistoryFilter().then((data) => {
    //   setHistoryFilterData(data.data);
    // });
    SubscriptionHistoryFilterAPI.get({ user })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (FilterData) {
      SubscriptionHistoryFilterAPI.post({
        ...getFilter(FilterData, filterParams),
        user,
      })
        .then((res) => {
          setSubscriptionTableData(
            res.data.map((item) => {
              return {
                ...item,
                plan: item.plan.name,
                period: `${DateTimeToDate(
                  item.period.split(" - ")[0]
                )} - ${DateTimeToDate(item.period.split(" - ")[1])}`,
                date_added: DateTimeToDate(item.date_added),
              };
            })
          );
          setLoadingSubscriptionTable(false);
        })
        .catch((err) => console.log(err));
    }
  }, [filterParams, FilterData]);

  const deleteSubscription = (id) => {
    customAxios
      .delete(`http://localhost:7000/ultraheals/v1/subscription/${id}`)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
      });
  }

  return (
    <>
      <div>
        <TableFilter
          dt={dt}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          tableHeader={columns}
          tableData={subscriptionTableData}
          FilterData={FilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          isDarkMode={props.isDarkMode}
          IntegrationComponent={() => <AddSubscription email={props?.email} />}
        />
        {loadingSubscriptionTable ? (
          <SkeletonDataTable columns={columns} />
        ) : (
          <Tables
            columns={columns}
            userInformation={subscriptionTableData}
            dt={dt}
            globalFilter={globalFilter}
            invoice={props?.action ? !props?.action : true}
            action={ props?.action ? props?.action : false }
            setShowEdit={props?.setShowEdit}
            setSubsData={props?.setSubsData}
            email={props?.email}
            deleteSubscription={deleteSubscription}
          />
        )}

      </div>
    </>
  );
};

export default SubscriptionTable;
