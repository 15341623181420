import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { BsInfoCircle } from "react-icons/bs";
import { ThemeContext } from "../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";

const HorizontalBarChart = (props) => {
  const { title, data, categories, tooltip } = props;
  const { isDarkMode } = useContext(ThemeContext);

  const chartData = {
    series: [
      {
        name: title,
        data: data,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          horizontal: true,
          barHeight: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: categories,
        axisBorder: {
          color: "transparent",
        },
        labels: {
          style: {
            colors: isDarkMode ? "#fff" : "#111",
            fontSize: "0.875rem",
            offsetX: 10,
          },
        },
      },
      colors: ["#f14d34"],
      yaxis: {
        show: true,
        labels: {
          maxWidth: 50,
          align: "left",
          style: {
            colors: isDarkMode ? "#fff" : "#111",
            fontSize: "0.875rem",
            // offsetX: 10,
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>
          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id={title}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        <div className="h-full">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={300}
          />
        </div>
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default HorizontalBarChart;
