import { useParams } from "react-router-dom";
import { createElement, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import Overview from "../../modules/home/components/compliance/trustcenter/Overview";
import Dashboard from "../../modules/home/components/compliance/trustcenter/Dashboard";
import Access from "../../modules/home/components/compliance/trustcenter/Access";
import NDA from "../../modules/home/components/compliance/trustcenter/NDA";
import { FaEye } from "react-icons/fa";
import { complianceAPI } from "../../Api/CSPM/api";

export default function TrustCenter() {
  const { id } = useParams();
  const { isDarkMode } = useContext(ThemeContext);

  const [currentTab, setCurrentTab] = useState("dashboard");

  const TABS = [
    {
      id: "dashboard",
      title: "Dashboard",
      component: Dashboard,
    },
    // {
    //   id: "overview",
    //   title: "Overview",
    //   component: Overview,
    // },
    {
      id: "access",
      title: "Access",
      component: Access,
    },
    {
      id: "nda",
      title: "NDA Settings",
      component: NDA,
    },
  ];

  useEffect(() => {
    if (id) {
      setCurrentTab("access");
    }
  }, [id]);

  const getPreview = () => {
    complianceAPI
      .get("/trust-center/preview-token/")
      .then((res) => {
        window.open(
          window.location.origin + "/trust-center/" + res.data.token,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Trust Center</h1>
        <button
          className="p-button-text flex p-1 gap-2 items-center border rounded-md"
          onClick={() => getPreview()}
        >
          <FaEye /> Preview
        </button>
      </div>
      <div
        className={`flex gap-5 border-b-2 my-6 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-1 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component)}
      </div>
    </>
  );
}
