import React, { useState } from "react";
// import { scanHistoryData } from '../../../constants';
import { useNavigate } from "react-router-dom";
import {
  DateTimeToDateTime,
  convertIsoToReadableFormat,
  groupDataByYear,
} from "../../../helpers";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { UserIcon } from "../../../assets/svg";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../redux-toolkit/redux/auth";
import { isAgentOrAdminOrSuperAdmin } from "../../../utils/accessCheck";

const ScanHistory = (props) => {
  const { scanHistoryData, userDetails, handleCloseSideBar } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(null);

  // const groupedData = groupDataByYear(scanHistoryData);

  const years = Array.from(
    new Set(
      scanHistoryData.map((item) => {
        return new Date(item.date).getFullYear();
      })
    )
  );

  useEffect(() => {
    if (userDetails.drp_scan_id) {
      const date = scanHistoryData.find(
        (item) => item.id === userDetails.drp_scan_id
      )?.date;
      setSelectedYear(new Date(date).getFullYear());
    } else {
      setSelectedYear(new Date().getFullYear());
    }
  }, [userDetails]);

  const handleScanData = (e, item) => {
    e.preventDefault();
    let userData = { ...userDetails };
    console.log("RSFDDFD", item, userData);
    const id = item.id;
    const unique_id = item.unique_id;
    // alert(`id: ${id}, unique_id: ${unique_id}, scan: ${item.scan[0]}`);
    if (item.scan[0] == "DRP scan") {
      userData.drp_scan_id = id;
      userData.drp_unique_id = unique_id;
    } else if (item.scan[0] == "Full scan") {
      userData.drp_scan_id = id;
      userData.drp_unique_id = unique_id;
    } else if (item.scan[0] == "Brand Threat scan") {
      userData.brand_scan_id = id;
      userData.brand_unique_id = unique_id;
    }
    userData.scan_id = id;
    userData.unique_id = unique_id;
    console.log("handleScanData", userData);
    // dispatch(
    //   setUserDetails({
    //     ...userDetails,
    //     drp_scan_id: item.id,
    //   })
    // );
    if (item.scan[0] === "DRP scan" || item.scan[0] === "Full scan") {
      // setTimeout(() => {
      handleCloseSideBar();
      // navigate("/digital-risk-protection");
      // }, 1000);
    } else if (item.scan[0] === "Brand Threat scan") {
      // setTimeout(() => {
      handleCloseSideBar();
      navigate("/brand-threat-intelligence");
      // }, 1000);
    } else {
      // setTimeout(() => {
      handleCloseSideBar();
      navigate("/attack-surface-management");
      // }, 1000);
    }
    // setTimeout(() => {
    //   handleCloseSideBar();
    // }, 1000);
    dispatch(setUserDetails(userData));
  };
  const STATUS = {
    pending: "Pending",
    in_progress: "In Progress",
    completed: "Completed",
    failed: "Failed",
    cancelled: "Cancelled",
  };

  return (
    <div className="py-8 px-5">
      <div>
        {years.map((year) => {
          return (
            <>
              <button
                className="px-10 text-[#F14D34] font-bold text-sm flex items-center gap-3 py-3"
                onClick={(e) => {
                  if (selectedYear === year) setSelectedYear(null);
                  else setSelectedYear(year);
                }}
              >
                {year}
                <span>
                  {year === selectedYear ? (
                    <IoIosArrowDown size={20} color="#181818" />
                  ) : (
                    <IoIosArrowForward size={20} color="#181818" />
                  )}
                </span>
              </button>
              <ol className="relative border-l-2 mt-4">
                {year === selectedYear &&
                  scanHistoryData
                    .filter(
                      (item) => year === new Date(item.date).getFullYear()
                    )
                    .map((item) => (
                      <li key={item.id} className="mb-10 ml-6">
                        <div
                          className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}
                        ></div>
                        <div
                          onClick={(e) => handleScanData(e, item)}
                          style={{ cursor: "pointer" }}
                        >
                          <p className="text-base font-semibold text-color leading-4 cursor-pointer">
                            {DateTimeToDateTime(item.date)}
                          </p>
                          <div className="py-2 flex gap-2 items-center">
                            <img src={UserIcon} alt="user" />
                            <h1 className="text-color text-xs">{item.user}</h1>
                          </div>
                          <div>
                            <p className="text-xs font-normal text-[#9F9F9F] py-1">
                              {`${item.scan} - ${item.domain} (${
                                STATUS[item.status]
                              }${
                                item.status === "in_progress"
                                  ? " - " + parseInt(item.scan_progress) + "%"
                                  : ""
                              })`}
                            </p>
                          </div>

                          {
                            // userDetails?.drp_date === item.date &&
                            //   userDetails?.drp_domain === item.id &&
                            userDetails?.drp_scan_id === item.id && (
                              <div>
                                <p className=" text-[12px] text-[#F14D34] font-normal">
                                  Current on display
                                </p>
                              </div>
                            )
                          }
                        </div>
                      </li>
                    ))}
              </ol>
            </>
          );
        })}
      </div>
    </div>
    // <div className='py-8 px-5'>
    //   {years.map((year) => (
    //     <div key={year}>
    // <button
    //   className="px-10 text-[#F14D34] font-bold text-sm flex items-center gap-3 py-3"
    //   onClick={() => handleYearClick(year)}
    // >
    //   {year}
    //   <span><IoIosArrowDown size={20} color='#181818' /></span>
    // </button>
    //       {selectedYear === year && (
    // <ol className="relative border-l-2 mt-4">
    //   {groupedData[year].map((item) => (
    //     <li key={item.id} className="mb-10 ml-6">
    //       <div className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}></div>
    //       <div>
    //         <p className="text-base font-semibold text-color leading-4">{convertIsoToReadableFormat(item.date)}</p>
    //           <div className='py-2 flex gap-2 items-center'>
    //             <img src={UserIcon} alt="user" />
    //             <h1 className='text-color text-xs'>John Hammond</h1>
    //           </div>
    //           <div>
    //             <p className='text-xs font-normal text-[#9F9F9F]'>Full scanning</p>
    //           </div>
    //       </div>
    //     </li>
    //   ))}
    // </ol>
    //       )}
    //     </div>
    //   ))}
    // </div>
  );
};

export default ScanHistory;
