import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import AddDepartments from "../../modules/home/components/compliance/AddDepartments";

export default function Departments() {
  const columns = [
    { field: "name", header: "Name" },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Departments"
      columns={columns}
      url_path="/departments/"
      AddComponent={AddDepartments}
    />
  );
}
