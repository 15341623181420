import React, { useState } from "react";
import ExploitAbility from "./ExploitAbility";
import Exploit from "./Exploits";

const ExploitIntelligence = (props) => {
  const { title } = props;

  const [countData, setCountData] = useState();
  return (
    <div className="ssl-status ">
      <h1 className="font-bold ">{title}</h1>

      <div className="ssl-active">
        <Exploit title="Exploits" />
      </div>
      <div className="ssl-active">
        <ExploitAbility title="Exploitability" />
      </div>
    </div>
  );
};

export default ExploitIntelligence;
