import React from 'react'
import { FaTimes } from 'react-icons/fa';

const Chip = ({ keyword, onDeleteKeyword }) => {
  return (
  <div className="flex items-center px-3 py-1 gap-1 rounded-2xl bg-[#F5F5F7]">
    <h1 className="text-color text-sm capitalize">{keyword}</h1>
    <div>
        <FaTimes onClick={() => onDeleteKeyword(keyword)} color='text-color' />
    </div>
  </div>
  )
}

export default Chip