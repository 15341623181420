import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { BsInfoCircle } from "react-icons/bs";
import { ThemeContext } from "../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";

const AreaChart = (props) => {
  const { title, data, colors, categories, value, tooltip } = props;

  const { isDarkMode } = useContext(ThemeContext);

  const chartData = {
    series: [
      {
        name: title,
        data: data,
      },
    ],
    options: {
      colors: colors,
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: categories,
      },
      yaxis: {
        labels: {
          show: true,
        },
        min: 0,
        max: 100,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <div className="px-3 flex flex-col h-full">
      <div className="px-3 flex items-center justify-evenly my-5 relative">
        <div className="w-[100%] flex justify-center">
          <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
            <span className="truncate" title={title}>
              {title}
            </span>
          </h1>
        </div>
        <div className="flex justify-end items-end cursor-pointer absolute right-0">
          <img
            data-tooltip-id="secure-tooltip"
            className="w-[18px]"
            src={isDarkMode ? InformationIconDark : InformationIconLight}
            alt="info"
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <h1 className="text-3xl font-bold leading-normal">{value || 0}%</h1>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={180}
      />
      <ReactTooltip
        id="secure-tooltip"
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </div>
  );
};

export default AreaChart;
