import React from 'react'
import { BsInfoCircle } from 'react-icons/bs';
import { BiTrendingDown } from 'react-icons/bi'
import { FiUmbrella } from 'react-icons/fi'

const DataLeakWidgets = (props) => {
    const {title, value } = props;

  return (
    <div>
    <div className="flex items-center justify-evenly">
        <div className="w-[90%]">
          <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
            <span className="truncate" title={title}>{title}</span>
          </h1>
        </div>
        <div className="flex justify-end items-end w-[10%]">
          <BsInfoCircle />
        </div>
      </div>
      <div className='py-6 flex justify-between items-center'>
        <div className='flex items-end gap-1'>
            <h1 className='text-5xl font-bold'>{value}</h1>
            <div className='flex items-end gap-1'>
                <BiTrendingDown color='#148842' />
                <p className='text-[#148842] text-xs font-bold'>21%</p>
            </div>
        </div>
        <div className='bg-[#F14D341A] p-3 rounded-full'>
            <FiUmbrella size={20} color='#F14D34' />
        </div>
      </div>
    </div>
  )
}

export default DataLeakWidgets