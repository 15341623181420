import { useState, useMemo, useCallback, useContext } from "react";
import ForceGraph3D from "react-force-graph-3d";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/addons/renderers/CSS2DRenderer.js";

import { ThemeContext } from "../../../context/ThemeContext";
import {
  Azure,
  aws,
  AWSWhite,
  google,
  LocationPin,
  ec2,
  glue,
  guardduty,
  athena,
  cloudtrail,
  config,
  firewall,
  inspector,
  macie,
  securityhub,
  vpc,
  s3,
  rds,
  LocationPinDark,
} from "../../../assets/svg";

export default function ExpandableGraph({ graphData, height, width }) {
  const rootId = "user-account";
  const extraRenderers = [new CSS2DRenderer()];
  const { isDarkMode } = useContext(ThemeContext);

  const SRC = {
    aws: isDarkMode ? AWSWhite : aws,
    azure: Azure,
    gcp: google,
    ec2: ec2,
    glue: glue,
    guardduty: guardduty,
    athena: athena,
    cloudtrail: cloudtrail,
    config: config,
    firewall: firewall,
    inspector: inspector,
    inspector2: inspector,
    macie: macie,
    securityhub: securityhub,
    vpc: vpc,
    s3: s3,
    region: isDarkMode ? LocationPin : LocationPinDark,
    rds: rds,
  };

  const nodesById = useMemo(() => {
    const nodesById = Object.fromEntries(
      graphData.nodes.map((node) => [node.id, node])
    );

    // link parent/children
    graphData.nodes.forEach((node) => {
      node.collapsed = node.id !== rootId;
      // node.collapsed = node.name !== rootName;
      node.childLinks = [];
    });
    graphData.links.forEach((link) =>
      nodesById[link.source].childLinks.push(link)
    );

    return nodesById;
  }, [graphData]);

  const getPrunedTree = useCallback(() => {
    const visibleNodes = [];
    const visibleLinks = [];
    (function traverseTree(node = nodesById[rootId]) {
      visibleNodes.push(node);
      if (node.collapsed) return;
      visibleLinks.push(...node.childLinks);
      node.childLinks
        .map((link) =>
          typeof link.target === "object" ? link.target : nodesById[link.target]
        ) // get child node
        .forEach(traverseTree);
    })();

    return { nodes: visibleNodes, links: visibleLinks };
  }, [nodesById]);

  const [prunedTree, setPrunedTree] = useState(getPrunedTree());

  const handleNodeClick = useCallback((node) => {
    node.collapsed = !node.collapsed; // toggle collapse state
    setPrunedTree(getPrunedTree());
  }, []);

  const renderNode = (node) => {
    const nodeEl = document.createElement("div");
    nodeEl.style.pointerEvents = "all";

    if (node.childLinks.length) {
      nodeEl.style.cursor = "pointer";
      nodeEl.addEventListener("mousedown", (event) => {
        // if (event.button === 2)
        handleNodeClick(node);
      });
    }

    if (node.name === "cloud") {
      nodeEl.innerHTML = `<img src=${
        SRC[node.value]
      } style="width: 20px; height: 20px;"/>`;
    } else if (node.name in SRC) {
      nodeEl.innerHTML = `<img src=${
        SRC[node.name]
      } style="width: 20px; height: 20px;"/>
              <p style="transform: translate(-50%, -50%); font-size: 10px; color: ${
                isDarkMode ? "white" : "black"
              }; margin:5px;">${node.value}</p>
              `;
    } else {
      nodeEl.style.color = isDarkMode ? "white" : "black";
      nodeEl.textContent = node.value;
    }

    return new CSS2DObject(nodeEl);
  };

  return (
    <ForceGraph3D
      height={height}
      width={width}
      graphData={prunedTree}
      linkDirectionalParticles={2}
      linkWidth={1}
      linkOpacity={0.5}
      linkResolution={10}
      backgroundColor={isDarkMode ? "#171717" : "#fff"}
      extraRenderers={extraRenderers}
      nodeThreeObject={renderNode}
    />
  );
}
