import { useEffect, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { LuFilter } from "react-icons/lu";

import GenericLayout from "../../common/layout/GenericLayout";
import { report } from "../../assets/svg";
import { cspmAPI } from "../../Api/CSPM/api";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";
import {
  getCSPMFilters,
  getUserDetails,
  setUserDetails,
} from "../../redux-toolkit/redux/auth";
// import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import CustomFilter from "./CustomFilter";
import AWSDashboard from "./aws/AWSDashboard";
import AzureDashboard from "./azure/AzureDashboard";

export default function CloudPostureSecurityManagement() {
  const userDetails = useSelector(getUserDetails);
  const cspmFilters = useSelector(getCSPMFilters);

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [history, setHistory] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [showHistorySideBar, setshowHistorySideBar] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showCustomFilter, setShowCustomFilter] = useState(false);

  // const [vocStatus, setVocStatus] = useState([]);
  // const [vocStatusOpen, setVocStatusOpen] = useState([]);
  // const [vocStatusTotal, setVocStatusTotal] = useState();
  // const [vocLegends, setVocLegends] = useState([]);
  // const vocByStatus = () => {
  //   DashboardApi.dashboardVOCStatus({
  //     unique_id: userDetails.unique_id,
  //     scan_id: userDetails.scan_id,
  //   }).then((data) => {
  //     setVocStatusTotal(data.count);
  //     const vocOpen = data.data.severity_open;
  //     const vocClose = data.data.severity_closed;
  //     const vocInprogress = data.data.severity_progress;
  //     const vocRisk = data.data.severity_risk;
  //     const vocData = [vocOpen, vocClose, vocInprogress, vocRisk];
  //     const vocOpenStatus = `Opened - ${vocOpen}`;
  //     const vocCloseStatus = `Closed - ${vocClose}`;
  //     const vocInprogressStatus = `In progress - ${vocInprogress}`;
  //     const vocRiskStatus = `Risk accepted - ${vocRisk}`;
  //     const vocDataStatus = [
  //       vocOpenStatus,
  //       vocCloseStatus,
  //       vocInprogressStatus,
  //       vocRiskStatus,
  //     ];
  //     const vocLegendStatus = [
  //       { name: vocOpenStatus, color: "#E8001D" },
  //       { name: vocCloseStatus, color: "#148842" },
  //       { name: vocInprogressStatus, color: "#F59107" },
  //       { name: vocRiskStatus, color: "#7B7DE2" },
  //     ];
  //     setVocLegends([...vocLegendStatus]);
  //     setVocStatus([...vocDataStatus]);
  //     setVocStatusOpen([...vocData]);
  //   });
  // };

  const getData = () => {
    cspmAPI
      .get("/dashboard/", {
        scan: userDetails.cpsm_scan_id,
        provider: userDetails.cspm_provider,
        resource__labels__contains: JSON.stringify(cspmFilters),
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccountData = () => {
    cspmAPI
      .get("/dashboard/account/")
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getHistory = () => {
    cspmAPI
      .get("/scan-history/")
      .then((data) => {
        setHistory(data.data);
        if (data.data.length) {
          dispatch(
            setUserDetails({
              ...userDetails,
              cpsm_scan_id: data.data[0]?.id,
              cspm_provider: data.data[0]?.provider,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userDetails?.cpsm_scan_id) {
      getData();
      getAccountData();
      // vocByStatus();
    } else {
      if (history.length === 0) {
        getHistory();
      } else {
        dispatch(
          setUserDetails({
            ...userDetails,
            cpsm_scan_id: history[0]?.id,
            cspm_provider: history[0]?.provider,
          })
        );
      }
    }
  }, [userDetails, cspmFilters]);

  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Cloud Security Posture Management</p>
        <div className="flex justify-around gap-3">
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base"
            onClick={() => setShowReport(true)}
          >
            Report
            <img src={report} />
          </button>
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base"
            onClick={() => setShowCustomFilter(true)}
          >
            Custom Filter
            <LuFilter />
          </button>
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base"
            onClick={() => setshowHistorySideBar(true)}
          >
            Scanning history
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      {userDetails?.cspm_provider === "aws" ? (
        <AWSDashboard data={data} accountData={accountData} />
      ) : userDetails?.cspm_provider === "azure" ? (
        <AzureDashboard data={data} accountData={accountData} />
      ) : null}
      <SidebarSlide
        title="Scan History"
        dashboardPage="CPSM"
        description="See previous scanning results"
        visibleRight={showHistorySideBar}
        setVisibleRight={setshowHistorySideBar}
        scanHistoryData={history}
      />
      <SidebarSlide
        title="Report"
        dashboardPage="CPSM"
        description="Generate Tailored Reports: Effortlessly Select Dates and Preferences for In-Depth Insights and Executive Overviews."
        visibleRight={showReport}
        setVisibleRight={setShowReport}
      />
      <CustomFilter
        visible={showCustomFilter}
        setVisible={setShowCustomFilter}
      />
    </GenericLayout>
  );
}
