import React from "react";
import  {LoginHeader, LoginFooter}  from "../../Component/Header";
import {SignUpForm} from "../../modules/SignUp";
import AuthSideBar from "../../Component/Header/AuthSideBar";
// import {LoginFooter} from '../../Component/Header/LoginFooter'

const SignUp = () => {
  return (
    <div className="lg:h-screen">
      <LoginHeader
        title=""
        subTitle=""
        navigate=""
      />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
        <SignUpForm />
        </div>
      </div>
      <hr/>
      <div>
        <LoginFooter/>
      </div>
    </div>
  );
};

export default SignUp;
