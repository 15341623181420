import React, { useState } from "react";
import ScanEngineForm from "./initiateScanForms/ScanEngineForm";
import InitialScanForm from "./initiateScanForms/InitialScanForm";
import ScanConfigurationForm from "./initiateScanForms/ScanConfigurationForm";
import ScheduleScanForm from "./initiateScanForms/ScheduleScanForm";
import PtaasConfig from "./PTAAS/ptaasConfig";

const InitiateScanForm = (props) => {
  const {
    handleCloseSideBar,
    userDetails,
    getProjectTableData,
    showScheduleScan,
    type,
  } = props;

  const [activeScanForm, setActiveScanForm] = useState(0);
  const [selectedOption, setSelectedOption] = useState("default_scan");

  const showScheduleScanForm = () => {
    switch (activeScanForm) {
      case 0:
        return (
          <ScheduleScanForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
          />
        );
      case 1:
        return (
          <ScanEngineForm
            handleCloseSideBar={() => setActiveScanForm(activeScanForm - 1)}
            setActiveScanForm={setActiveScanForm}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            type="schedule-scan"
          />
        );
      case 2:
        return (
          <ScanConfigurationForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            getProjectTableData={getProjectTableData}
            type="schedule-scan"
          />
        );
      case 3:
        return (
          <InitialScanForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
            getProjectTableData={getProjectTableData}
            type="schedule-scan"
            selectedOption={selectedOption}
          />
        );
      default:
        return null;
    }
  };

  const showActiveScanForm = () => {
    switch (activeScanForm) {
      case 0:
        return (
          <ScanEngineForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        );
      case 1:
        return (
          <ScanConfigurationForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            getProjectTableData={getProjectTableData}
            type="initiate-scan"
          />
        );
      case 2:
        return (
          <InitialScanForm
            handleCloseSideBar={handleCloseSideBar}
            setActiveScanForm={setActiveScanForm}
            getProjectTableData={getProjectTableData}
            type="initiate-scan"
            selectedOption={selectedOption}
          />
        );
      case 3:
        return (
          <PtaasConfig
            setActiveScanForm={setActiveScanForm}
            handleCloseSideBar={handleCloseSideBar}
          />
        );
      default:
        return null;
    }
  };

  useState(() => {
    console.log(activeScanForm);
  }, [activeScanForm]);

  return (
    <div>
      {showScheduleScan ? showScheduleScanForm() : showActiveScanForm()}
    </div>
  );
};

export default InitiateScanForm;
