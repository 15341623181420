import { message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { REGEX } from "../../../utils/constants";
import SkeletonAnimationLogin from "../../SkeletonAnimation/SkeletonAnimationLogin";

const NewUserPasswordForm = () => {
  const { token, user } = useParams();
  const [data, setData] = useState({
    first_name: null,
    last_name: null,
    // phone: null,
    user,
    token,
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] =
    useState("Invalid Password");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("Invalid Password");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");

  const name = useRef(null);

  useEffect(() => {
    name.current.focus();
  }, []);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordInputFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmPasswordInputFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordInputBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setIsPasswordError(false);
    setIsConfirmPasswordError(false);
    if (password === "") {
      setIsPasswordError(true);
      setPasswordErrorMessage("Password is required");
    } else if (password) {
      REGEX.PASSWORD.test(password)
        ? setIsPasswordError(false)
        : setIsPasswordError(true);
      setPasswordErrorMessage(
        "Minimum 12 to 16 characters with uppercase and symbols"
      );
    }
    if (confirmPassword === "") {
      setIsConfirmPasswordError(true);
      setConfirmPasswordErrorMessage("Confirm password is required");
    } else if (confirmPassword) {
      if (REGEX.PASSWORD.test(confirmPassword)) {
        if (password !== confirmPassword) {
          setIsConfirmPasswordError(true);
          setConfirmPasswordErrorMessage(
            "Password and confirm password does not match"
          );
        } else {
          setIsConfirmPasswordError(false);
        }
      } else {
        setIsConfirmPasswordError(true);
        setConfirmPasswordErrorMessage(
          "Minimum 12 to 16 characters with uppercase and symbols"
        );
      }
    }
    if (REGEX.PASSWORD.test(confirmPassword) && password === confirmPassword) {
      setLoading(true);
      AuthApi.setUpProfile({
        password: confirmPassword,
        ...data,
      }).then((data) => {
        if (data.status === "success") {
          setLoading(false);
          message.success(data.message);
          // setTimeout(() => {
          // navigate("/reset/successful");
          // }, 1000);
          navigate("/login");
        } else {
          setLoading(false);
          message.error(data.message);
        }
      });
    }
  };

  // useEffect(() => {
  //   let tokenCode = window.location.search.split("?").length
  //     ? window.location.search.split("?")[1]
  //     : "";
  //   console.log("TOKENCODE", tokenCode);
  //   if (tokenCode) {
  //     navigate("/reset/newUserPassword");
  //     AuthApi.resetNewUserPassword({ code: tokenCode }).then((data) => {
  //       if (data.status === "success") {
  //         console.log("TOKENCODEReponse", data);
  //         localStorage.setItem("userEmail", data.data);
  //         // message.success(data.message)
  //       } else {
  //         // message.error(data.message ? data.message : "account Not activated")
  //       }
  //     });
  //   }
  // }, [loading]);

  return (
    <div className="flex flex-col items-center py-24 lg:px-10 2xl:px-[115px] px-6 h-screen login-style">
      <div>
        <h1 className="text-xl font-bold text-center text-color">
          Setup User Profile
        </h1>
        <p className="text-base text-color pt-4 text-center">
          Please enter the information below to setup your user profile
        </p>
        {loading ? (
          <SkeletonAnimationLogin />
        ) : (
          <form
            className="py-5 flex flex-col w-full label-color"
            onSubmit={handleChangePassword}
          >
            <div className="pt-3">
              <label className="label-color text-sm">First Name</label>
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  value={data.first_name}
                  ref={name}
                  onChange={(e) => {
                    setData({ ...data, first_name: e.target.value });
                  }}
                  required
                  className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-md focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                />
              </div>
            </div>
            <div className="pt-3">
              <label className="label-color text-sm">Last Name</label>
              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={data.last_name}
                  onChange={(e) => {
                    setData({ ...data, last_name: e.target.value });
                  }}
                  required
                  className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-md focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                />
              </div>
            </div>
            <div className="pt-3">
              <label className="label-color text-sm">
                Password (Min 12 - 16 characters)
              </label>
              <div
                className={`relative py-2 px-4 rounded-md text-lg 
                                ${
                                  isPasswordFocused
                                    ? "focus-within:ring-[0.5px] ring-[#717171]"
                                    : ""
                                }
                                ${
                                  isPasswordError
                                    ? "border-color-error"
                                    : "border-color-disable"
                                }
                                ${
                                  isPasswordError && isPasswordFocused
                                    ? "focus-within:ring-[0.5px] ring-[#CD1F43]"
                                    : ""
                                }
                                `}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setIsPasswordError(false);
                  }}
                  onFocus={handlePasswordInputFocus}
                  onBlur={handlePasswordInputBlur}
                  // ref={passwordInputRef}
                  className="focus:outline-none focus:ring-0 focus:border-none placeholder-color text-color w-[95%]"
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  onClick={handlePasswordVisibilityToggle}
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </button>
              </div>
              <div>
                {isPasswordError && (
                  <h1 className="error text-sm">{passwordErrorMessage}</h1>
                )}
              </div>
            </div>

            <div className="pt-3">
              <label className="label-color text-sm">
                Confirm Password (Min 12 - 16 characters)
              </label>
              <div
                className={`relative py-2 px-4 rounded-md text-lg 
                                ${
                                  isConfirmPasswordFocused
                                    ? "focus-within:ring-[0.5px] ring-[#717171]"
                                    : ""
                                }
                                ${
                                  isConfirmPasswordError
                                    ? "border-color-error"
                                    : "border-color-disable"
                                }
                                ${
                                  isConfirmPasswordError &&
                                  isConfirmPasswordFocused
                                    ? "focus-within:ring-[0.5px] ring-[#CD1F43]"
                                    : ""
                                }
                                `}
              >
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="********"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setIsConfirmPasswordError(false);
                  }}
                  onFocus={handleConfirmPasswordInputFocus}
                  onBlur={handleConfirmPasswordInputBlur}
                  className="focus:outline-none focus:ring-0 focus:border-none placeholder-color text-color w-[95%]"
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                  onClick={handleConfirmPasswordVisibilityToggle}
                >
                  {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </button>
              </div>
              <div>
                {isConfirmPasswordError && (
                  <h1 className="error text-sm">
                    {confirmPasswordErrorMessage}
                  </h1>
                )}
              </div>
            </div>

            <button
              title="Submit"
              type="submit"
              className="w-full button-bg-color p-3 my-4 rounded-[48px] text-white"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewUserPasswordForm;
