import React from "react";

const SkeletonDonut = () => {
  return (
    <>
      <div className="border nodata-style"></div>
    </>
  );
};

export default SkeletonDonut;
