import React, { useContext } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { ThemeContext } from "../../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import lockicon from "../../../assets/lockscreen/lock.svg";
import LockedWidget from "../../sidebar/components/LockedWidget";

const ComplianceAffectAssets = (props) => {
  const { title, complianceAffectAssetData, lockedWidget, lockImage, tooltip } =
    props;
  console.log("complianceAffectAssetData", complianceAffectAssetData);

  const { isDarkMode } = useContext(ThemeContext);
  const complianceAffectAssetData1 = [
    { host: "asfsafasssdfsfasfsasfafsfsfdsfdsfd", count: 56 },
    { host: "asfsafasfasfas", count: 156 },
  ];
  const complianceipAddress = [
    "iawbs.smsexpress.com",
    "iawbs.smsexpress.com",
    "iawbs.smsexpress.com",
    "iawbs.smsexpress.com",
  ];
  const complianceQuantity = ["11", "11", "11", "11"];

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>

          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              data-tooltip-id={title}
              className="w-[18px]"
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        {lockedWidget ? (
          <table
            className={`w-full table-auto px-2 py-2 w-full border-r-[1px] border-r-[#dedede] ${
              isDarkMode
                ? "tab-dark-border text-white"
                : "border-color text-color"
            }`}
          >
            <thead
              className={`${
                !isDarkMode && "bg-[#f5f5f7] "
              }border-collapse border border-[#dedede]`}
            >
              <th className="p-4 text-[16px] text-left border border-[#dedede]">
                Host
              </th>
              <th className="p-4 text-[16px] text-left border border-[#dedede]">
                IP address
              </th>
              <th className="p-4 text-[16px] border border-[#dedede]">
                Quantity
              </th>
            </thead>
            <tbody>
              {complianceAffectAssetData.map((item) => (
                <tr>
                  <td
                    className={`border-[1px] p-4 text-left text-[14px] border-[#f5f5f7] ${
                      isDarkMode
                        ? "tab-dark-border text-white"
                        : "border-color text-color"
                    }`}
                  >
                    {item.host}
                  </td>
                  <td
                    className={`border-[1px] p-4 text-left text-[14px] border-[#f5f5f7] ${
                      isDarkMode
                        ? "tab-dark-border text-white"
                        : "border-color text-color"
                    }`}
                  >
                    {item.ip}
                  </td>
                  <td
                    className={`border-[1px] p-4 text-center font-bold text-[14px] border-[#f5f5f7] ${
                      isDarkMode
                        ? "tab-dark-border text-white"
                        : "border-color text-color"
                    }`}
                  >
                    {item.count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LockedWidget lockImage={lockImage} lockicon={lockicon} />
        )}

        {/* <div className="grid grid-cols-2 gap-6">
          {complianceAffectAssetData.map((compliance) => (
            <div
              className={`flex justify-between items-center px-4 py-3 border-[1px] w-full rounded-lg ${
                isDarkMode
                  ? "tab-dark-border text-white"
                  : "border-color text-color"
              }`}
            >
              <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                <span className="truncate" title="iawbs.smsexpress.com">
                  {compliance.host}
                </span>
              </h1>
              <h1 className="text-xl leading-normal font-bold">
                {compliance.count}
              </h1>
            </div>
          ))}
        </div> */}

        {/* <div className='flex lg:flex-row justify-evenly items-center flex-col gap-6'>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">iawbs.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>11</h1>
                </div>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">extentions.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>116</h1>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6'>
            <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">valid.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>17</h1>
                </div>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">smsacc.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>11</h1>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6'>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">validate.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>2</h1>
                </div>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">qr.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>71</h1>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col gap-6 lg:pt-3 pt-6'>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">ship.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>73</h1>
                </div>
                <div 
                className={`flex justify-between items-center px-4 py-3 border-[1px] lg:w-[50%] w-full rounded-lg ${
                isDarkMode ? "tab-dark-border text-white" : "border-color text-color"
                }`}
                >
                    <h1 className="flex items-center justify-center text-sm leading-normal overflow-hidden whitespace-nowrap">
                        <span className="truncate">sam.smsexpress.com</span>
                    </h1>
                    <h1 className='text-xl leading-normal font-bold'>20</h1>
                </div>
            </div> */}
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default ComplianceAffectAssets;
