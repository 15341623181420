import React, { useState } from "react";
import GeoChart from "../../../common/Charts/GeoChart";
import { BsInfoCircle } from "react-icons/bs";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { InformationIconDark, InformationIconLight } from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const GeoChartCountry = (props) => {
  const { title, countryData, isDarkMode, tooltip } = props;
  const navigate = useNavigate();
  //   const countryData = [
  //     {
  //       country: "US",
  //       count: 138,
  //     },
  //     {
  //         country: "United Kingdom",
  //         count: 38
  //     },
  //     {
  //         country: "Saudi Arabia",
  //         count: 36
  //     },
  //   ];

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {" "}
                {title}
              </span>
            </h1>
          </div>
          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id={title}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        <div className="h-full" style={{ minHeight: "300px" }}>
          <div className="flex ">
            <GeoChart />
            <div className=" p-5" style={{ width: "30%", height: "300px" }}>
              <div className="flex justify-between pb-2">
                <p className="font-bold text-[12px]">Country</p>
                <p className="font-bold text-[12px]">Hosts</p>
              </div>
              {countryData.map((country) => (
                <div
                  className="flex justify-between pb-2 cursor-pointer"
                  onClick={() =>
                    navigate("/attack-surface-management/live-hosts", {
                      state: { country: country.country },
                    })
                  }
                >
                  <p className="text-[14px]">{country.country}</p>
                  <p className="text-[14px]">{country.count}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
};

export default GeoChartCountry;
