import { useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Toggle from "react-toggle";
import { BsCheckLg } from "react-icons/bs";

import { ThemeContext } from "../../context/ThemeContext";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";

export default function ListingWithBtn(props) {
  const { title, data, tooltip } = props;
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <>
      <div className="px-3 flex flex-col h-full">
        <div className="px-3 flex items-center justify-evenly my-5 relative">
          <div className="w-[100%] flex justify-center">
            <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
              <span className="truncate" title={title}>
                {title}
              </span>
            </h1>
          </div>
          <div className="flex justify-end items-end cursor-pointer absolute right-0">
            <img
              className="w-[18px]"
              data-tooltip-id={title}
              src={isDarkMode ? InformationIconDark : InformationIconLight}
              alt="info"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          {data.length ? (
            data.map((item, i) => {
              return (
                // <div className="py-1 assetStyle flex lg:flex-row flex-col gap-1 justify-evenly w-full">
                //   <div
                //     className={`flex justify-between items-center w-full asset-padding border-[1px] rounded-lg ${
                //       isDarkMode ? "tab-dark-border" : "border-color"
                //     }`}
                //   >
                //     <div className="flex items-center">{item.key}</div>
                //     <h1 className="text-xl font-bold">{item.value}</h1>
                //   </div>
                // </div>
                <div
                  key={i}
                  className={`flex justify-between items-center w-full px-3 py-1 border-[1px] rounded-lg ${
                    isDarkMode ? "tab-dark-border" : "border-color"
                  } ${item.onClick && "cursor-pointer"}`}
                  onClick={() => item.onClick && item.onClick()}
                >
                  <p className="text-sm font-bold truncate" title={item.key}>
                    {item.key}
                  </p>
                  <Toggle
                    checked={item.value}
                    icons={{
                      checked: (
                        <BsCheckLg
                          size={20}
                          style={{
                            color: "white",
                            position: "absolute",
                            left: "-6px",
                            top: "-5px",
                          }}
                        />
                      ),
                      unchecked: null,
                    }}
                    onChange={(e) => console.log(e.target.checked)}
                  />
                </div>
              );
            })
          ) : (
            <div className="text-center">No data to show</div>
          )}
        </div>
      </div>
      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
  );
}
