import ComplianceTable from "../complianceTable";
import AddRequest from "./AddRequest";

export default function Requests({ data }) {
  const columns = [
    {
      field: "name",
      header: "Request Name",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Open",
          value: "open",
        },
        {
          label: "Need Review",
          value: "need_review",
        },
        {
          label: "Need Revision",
          value: "need_revision",
        },
        {
          label: "Closed",
          value: "closed",
        },
      ],
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: data?.employees.map((emp) => ({
        label: emp.email,
        value: emp.id,
      })),
    },
    {
      field: "user",
      header: "Created By",
      type: "select",
      value: data?.employees.map((emp) => ({
        label: emp.email,
        value: emp.id,
      })),
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Requests"
      columns={columns}
      url_path="/request/"
      AddComponent={AddRequest}
      filter={data?.filters}
    />
  );
}
